import { Injectable } from '@angular/core';
import { applicationVerticalTypes, moduleTypes, permissionFeatures, roleTypes, securityModuleKeys, storageKeys } from '@app/app.constants';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { PortalModule } from '@app/shared/portal-module.model';
import { BCPlanTypeFeature } from '@app/shared/business-continuity/bc-feature.model';

@Injectable({
	providedIn: 'root'
})
export class PermissionService {
	permissionsMap: any;
	modulesMap: any;
	featureMap: any;
	portalPlanTypeId: string = null;

	constructor(private localStorageSvc: LocalStorageService) {
		this.init();
	}

	get isSuperAdmin(): boolean {
		return this.hasPermission(permissionFeatures.administration.key, [permissionFeatures.administration.actions.managePortal]);
	}

	hasPermission(key: string, actions: any): boolean {
		if (key == null || actions == null) {
			return true;
		}
		const _actions = (actions)
			? ((typeof actions === 'string') ? actions.split(',') : ((Array.isArray(actions)) ? actions : null))
			: null;
		return _actions.some((action) => {
			return this.permissionsMap[key][action.trim()];
		});
	}

	hasModule(key: string): boolean {
		try {
			return !!this.modulesMap[key];
		}
		catch (err) {
			return false;
		}
	}

	hasFeature(key: string, portalPlanTypeId?: string): boolean {
		try {
			if (portalPlanTypeId) {
				if (this.featureMap[portalPlanTypeId]) {
					return !!this.featureMap[portalPlanTypeId][key];
				}
				else {
					return false;
				}
			}
			else {
				let hasFeature: boolean = false;
				Object.entries(this.featureMap).forEach(([k, v]) => {
					if (v[key] === true) {
						hasFeature = true;
						return;
					}
				});
				return hasFeature;
			}
		}
		catch (err) {
			return false;
		}
	}

	mapPermissions(userRoles) {
		const permissionsMap = this.initPermissions();

		userRoles.forEach((role) => {
			switch (role.roleId) {
				case roleTypes.login:
					permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.alerts] = true;
					permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.crisisTeam] = true;
					permissionsMap[permissionFeatures.documents.key][permissionFeatures.documents.actions.documents] = true;
					permissionsMap[permissionFeatures.resources.key][permissionFeatures.resources.actions.resources] = true;
					permissionsMap[permissionFeatures.resources.key][permissionFeatures.resources.actions.viewAllResources] = true;
					permissionsMap[permissionFeatures.resources.key][permissionFeatures.resources.actions.threatTypeIcon] = true;
					permissionsMap[permissionFeatures.resources.key][permissionFeatures.resources.actions.resourceName] = true;
					permissionsMap[permissionFeatures.help.key][permissionFeatures.help.actions.help] = true;
					permissionsMap[permissionFeatures.myProfile.key][permissionFeatures.myProfile.actions.myProfile] = true;
					permissionsMap[permissionFeatures.myPreparis.key][permissionFeatures.myPreparis.actions.myPreparis] = true;
					permissionsMap[permissionFeatures.agilityRisk.key][permissionFeatures.agilityRisk.actions.risk] = true;
					break;
				case roleTypes.sendMessages:
					permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.sendEmergencyMessage] = true;
					permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.viewAllOptions] = true;
					break;
				case roleTypes.configureMessages:
					permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.configureEmergencyMessage] = true;
					permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.viewAllOptions] = true;
					break;
				case roleTypes.conferenceCall:
					permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.conferenceCall] = true;
					break;
				case roleTypes.callInAnnouncement:
					permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.callInAnnouncement] = true;
					break;
				case roleTypes.splashMessage:
					permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.splashMessage] = true;
					break;
				case roleTypes.incidentManagement:
					permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.incidentHistory] = true;
					permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.newIncident] = true;
					permissionsMap[permissionFeatures.incidentManagement.key][permissionFeatures.incidentManagement.actions.incidentManagement] = true;
					permissionsMap[permissionFeatures.exerciseManagement.key][permissionFeatures.exerciseManagement.actions.exerciseManager] = true;
					break;
				case roleTypes.manageDocuments:
					permissionsMap[permissionFeatures.documents.key][permissionFeatures.documents.actions.deleteDocuments] = true;
					permissionsMap[permissionFeatures.documents.key][permissionFeatures.documents.actions.editDocuments] = true;
					permissionsMap[permissionFeatures.documents.key][permissionFeatures.documents.actions.addUploadDocuments] = true;
					break;
				case roleTypes.uploadDocuments:
					permissionsMap[permissionFeatures.documents.key][permissionFeatures.documents.actions.addUploadDocuments] = true;
					break;
				case roleTypes.viewTraining:
					permissionsMap[permissionFeatures.training.key][permissionFeatures.training.actions.training] = true;
					permissionsMap[permissionFeatures.training.key][permissionFeatures.training.actions.goToTraining] = true;
					permissionsMap[permissionFeatures.training.key][permissionFeatures.training.actions.courseName] = true;
					break;
				case roleTypes.viewReports:
					permissionsMap[permissionFeatures.reports.key][permissionFeatures.reports.actions.reports] = true;
					break;
				case roleTypes.viewAdminReports:
					permissionsMap[permissionFeatures.reports.key][permissionFeatures.reports.actions.adminReports] = true;
					break;
				case roleTypes.viewIncidentsReports:
					permissionsMap[permissionFeatures.reports.key][permissionFeatures.reports.actions.incidentsReports] = true;
					break;
				case roleTypes.viewTrainingReports:
					permissionsMap[permissionFeatures.reports.key][permissionFeatures.reports.actions.trainingReports] = true;
					break;
				case roleTypes.viewIncidentManagementReports:
					permissionsMap[permissionFeatures.reports.key][permissionFeatures.reports.actions.incidentManagementReports] = true;
					break;
				case roleTypes.companySettings:
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.manage] = true;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.companyProfile] = true;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.companyApi] = true;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.security] = true;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.systemEmails] = true;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.companySettings] = true;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.customFields] = true;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.selfRegistration] = true;
					break;
				case roleTypes.manageEntities:
					const verticalTypeId = this.localStorageSvc.get(storageKeys.verticalTypeId);
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.manage] = true;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.locations] = true;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.groups] = true;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.departments] = verticalTypeId === applicationVerticalTypes.standard;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.tenants] = verticalTypeId === applicationVerticalTypes.realEstate;
					break;
				case roleTypes.manageUsers:
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.manage] = true;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.users] = true;
					break;
				case roleTypes.manageRoles:
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.manage] = true;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.roles] = true;
					break;
				case roleTypes.messageSettings:
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.manage] = true;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.manageSettings] = true;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.savedMessages] = true;
					break;
				case roleTypes.preparisAdministrators:
				case roleTypes.systemAdministrators:
					permissionsMap[permissionFeatures.administration.key][permissionFeatures.administration.actions.managePortal] = true;
					permissionsMap[permissionFeatures.administration.key][permissionFeatures.administration.actions.managePlanner] = true;
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.navigatePortal] = true;
					permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.simulation] = true;
					permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.createRecoveryProfile] = true;
					break;
				case roleTypes.navigatePortal: // Navigate Portal
					permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.navigatePortal] = true;
					break;
				case roleTypes.iQAdministration:
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.iQ] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.admin] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.overview] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.scopes] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.teams] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.teamMembers] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.createTeamMember] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.deleteTeamMember] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.updateTeamMember] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.verifyTeamMember] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.reports] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.plans] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.complianceSchedule] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.complianceCategory] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.createTeamResource] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.deleteTeamResource] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.archiveTeamResource] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.verifyTeamResource] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.updateTeamResource] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.priority] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.establishDate] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.createIssue] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.deleteIssue] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.resolveIssue] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.updateIssue] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.issueAssignee] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.updatePlan] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.verifyPlan] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.generatePlan] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.createPlan] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.selectEstablishedDate] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.reassignTeamMemberTasks] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.reassignTeamMemberTasksAcrossTeam] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.moveTeamResource] = true;
					break;
				case roleTypes.iQManagement:
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.iQ] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.overview] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.scopes] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.teams] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.teamMembers] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.createTeamMember] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.deleteTeamMember] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.updateTeamMember] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.verifyTeamMember] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.reports] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.plans] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.complianceSchedule] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.complianceCategory] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.createTeamResource] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.deleteTeamResource] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.archiveTeamResource] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.verifyTeamResource] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.updateTeamResource] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.priority] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.establishDate] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.createIssue] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.deleteIssue] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.resolveIssue] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.updateIssue] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.issueAssignee] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.updatePlan] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.verifyPlan] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.generatePlan] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.createPlan] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.selectEstablishedDate] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.reassignTeamMemberTasks] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.reassignTeamMemberTasksAcrossTeam] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.moveTeamResource] = true;
					break;
				case roleTypes.iQTeamAccess:
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.iQ] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.overview] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.teamMembers] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.reports] = true;
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.plans] = true;
					break;
				case roleTypes.iQAssignmentAccess:
					permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.iQ] = true;
					break;
				case roleTypes.viewRecoveryProfile:
					permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.viewRecoveryProfile] = true;
					break;
				case roleTypes.editRecoveryProfile:
					permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.editRecoveryProfile] = true;
					permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.viewRecoveryProfile] = true;
					break;
				case roleTypes.editRecoveryProfileContact:
					permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.editRecoveryProfileContact] = true;
					permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.viewRecoveryProfile] = true;
					break;
				case roleTypes.editRecoveryProfileGenerator:
					permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.editRecoveryProfileGenerator] = true;
					permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.viewRecoveryProfile] = true;
					break;
				case roleTypes.editRecoveryProfileSite:
					permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.editRecoveryProfileSite] = true;
					permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.viewRecoveryProfile] = true;
					break;
				case roleTypes.businessContinuityManagement:
					permissionsMap[permissionFeatures.agilityPlanner.key][permissionFeatures.agilityPlanner.actions.businessContinuityManagement] = true;
					permissionsMap[permissionFeatures.exerciseManagement.key][permissionFeatures.exerciseManagement.actions.exerciseManager] = true;
					break;
				case roleTypes.businessContinuityAuditor:
					permissionsMap[permissionFeatures.agilityPlanner.key][permissionFeatures.agilityPlanner.actions.businessContinuityAudit] = true;
					permissionsMap[permissionFeatures.exerciseManagement.key][permissionFeatures.exerciseManagement.actions.exerciseManager] = true;
					break;
				case roleTypes.managePlans:
					permissionsMap[permissionFeatures.incidentManagement.key][permissionFeatures.incidentManagement.actions.managePlans] = true;
					permissionsMap[permissionFeatures.exerciseManagement.key][permissionFeatures.exerciseManagement.actions.exerciseManager] = true;
					break;
				case roleTypes.viewPlans:
					permissionsMap[permissionFeatures.incidentManagement.key][permissionFeatures.incidentManagement.actions.viewPlans] = true;
					permissionsMap[permissionFeatures.exerciseManagement.key][permissionFeatures.exerciseManagement.actions.exerciseManager] = true;
					break;
				case roleTypes.safetyPass:
					permissionsMap[permissionFeatures.agilitySafetyPass.key][permissionFeatures.agilitySafetyPass.actions.safetyPass] = true;
					break;
				case roleTypes.manageMessages:
					permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.manageMessages] = true;
					break;
			}
		});

		return permissionsMap;
	}

	setPermissions() {
		const userRights: any = this.localStorageSvc.get(storageKeys.userRights);
		this.permissionsMap = this.mapPermissions(userRights.roles);
		this.modulesMap = this.mapModules(userRights.modules);
	}

	mapFeature(portalFeatures: Array<BCPlanTypeFeature>) {
		const featuresMap = this.initFeatures();
		if (portalFeatures && portalFeatures.length > 0) {
			this.portalPlanTypeId = portalFeatures[0].portalPlanTypeId;
			portalFeatures.forEach((feature) => {
				if (featuresMap[feature.portalPlanTypeId] == null) {
					featuresMap[feature.portalPlanTypeId] = {};
				}
				featuresMap[feature.portalPlanTypeId][feature.featureId] = true;
			});
		}
		return featuresMap;
	}

	setFeatures(features?: Array<any>) {
		if (features) {
			this.localStorageSvc.set(storageKeys.portalFeatures, features);
			const portalPlanTypes = [...new Map(features.map(f => [f.portalPlanTypeId, f])).values()].map((p) => {
				return { portalPlanTypeId: p.portalPlanTypeId, planTypeId: p.planTypeId };
			});
			this.localStorageSvc.set(storageKeys.portalPlanTypes, portalPlanTypes);
		}
		this.featureMap = this.mapFeature(this.localStorageSvc.get(storageKeys.portalFeatures));
	}

	mapModules(userModules) {
		const modulesMap = this.initModules();
		for (let i = 0; i < userModules.length; i++) {
			switch (userModules[i].moduleId) {
				case moduleTypes.core:
					break;
				case moduleTypes.DocumentManagement:
					modulesMap[securityModuleKeys.documents] = true;
					break;
				case moduleTypes.AgilityAlerts:
					modulesMap[securityModuleKeys.agilityAlerts] = true;
					break;
				case moduleTypes.Manage:
					modulesMap[securityModuleKeys.manage] = true;
					break;
				case moduleTypes.MyPreparis:
					modulesMap[securityModuleKeys.myPreparis] = true;
					break;
				case moduleTypes.Help:
					modulesMap[securityModuleKeys.help] = true;
					break;
				case moduleTypes.Reporting:
					modulesMap[securityModuleKeys.reports] = true;
					break;
				case moduleTypes.AgilityTrainingAndResource:
					modulesMap[securityModuleKeys.resources] = true;
					break;
				case moduleTypes.AgilityTraining:
					modulesMap[securityModuleKeys.training] = true;
					break;
				case moduleTypes.MyProfile:
					modulesMap[securityModuleKeys.myProfile] = true;
					break;
				case moduleTypes.Administration:
					modulesMap[securityModuleKeys.administration] = true;
					break;
				case moduleTypes.iQ:
					modulesMap[securityModuleKeys.iQ] = true;
					break;
				case moduleTypes.Panic:
					modulesMap[securityModuleKeys.panic] = true;
					break;
				case moduleTypes.LiteMessaging:
					modulesMap[securityModuleKeys.liteMessaging] = true;
					break;
				case moduleTypes.RecoveryProfiles:
					modulesMap[securityModuleKeys.recoveryProfiles] = true;
					break;
				case moduleTypes.AgilityPlanner:
					modulesMap[securityModuleKeys.agilityPlanner] = true;
					break;
				case moduleTypes.IncidentManagement:
					modulesMap[securityModuleKeys.incidentManagement] = true;
					break;
				case moduleTypes.AgilitySafetyPass:
					modulesMap[securityModuleKeys.agilitySafetyPass] = true;
					break;
				case moduleTypes.AgilityRisk:
					modulesMap[securityModuleKeys.agilityRisk] = true;
					break;
				case moduleTypes.AgilityExerciseManager:
					modulesMap[securityModuleKeys.agilityExerciseManager] = true;
					break;
			}
		}

		return modulesMap;
	}

	resetModules(userModules: Array<PortalModule>) {
		this.modulesMap = this.mapModules(userModules);
		const userRights: any = this.localStorageSvc.get(storageKeys.userRights);
		userRights.modules = userModules.map((m) => {
			return {
				moduleId: m.moduleId,
				moduleNameAsBase64: m.moduleNameAsBase64,
				moduleName: m.moduleName
			};
		});
		this.localStorageSvc.set(storageKeys.userRights, userRights);
	}

	init() {
		this.permissionsMap = this.initPermissions();
		if (this.localStorageSvc.get(storageKeys.userRights)) {
			this.setPermissions();
		}
		const features = this.localStorageSvc.get(storageKeys.portalFeatures) as Array<BCPlanTypeFeature>;
		if (features && features.length > 0) {
			this.setFeatures();
		}
	}

	private initPermissions() {
		const permissionsMap: any = {};

		// agility Alerts
		permissionsMap[permissionFeatures.agilityAlerts.key] = {};
		permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.alerts] = false;
		permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.sendEmergencyMessage] = false;
		permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.configureEmergencyMessage] = false;
		permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.conferenceCall] = false;
		permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.splashMessage] = false;
		permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.callInAnnouncement] = false;
		permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.viewAllOptions] = false;
		permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.newIncident] = false;
		permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.incidentHistory] = false;
		permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.crisisTeam] = false;
		permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.simulation] = false;
		permissionsMap[permissionFeatures.agilityAlerts.key][permissionFeatures.agilityAlerts.actions.manageMessages] = false;

		// documents
		permissionsMap[permissionFeatures.documents.key] = {};
		permissionsMap[permissionFeatures.documents.key][permissionFeatures.documents.actions.documents] = false;
		permissionsMap[permissionFeatures.documents.key][permissionFeatures.documents.actions.addUploadDocuments] = false;
		permissionsMap[permissionFeatures.documents.key][permissionFeatures.documents.actions.deleteDocuments] = false;
		permissionsMap[permissionFeatures.documents.key][permissionFeatures.documents.actions.editDocuments] = false;

		// training
		permissionsMap[permissionFeatures.training.key] = {};
		permissionsMap[permissionFeatures.training.key][permissionFeatures.training.actions.training] = false;
		permissionsMap[permissionFeatures.training.key][permissionFeatures.training.actions.goToTraining] = false;
		permissionsMap[permissionFeatures.training.key][permissionFeatures.training.actions.courseName] = false;

		// resources
		permissionsMap[permissionFeatures.resources.key] = {};
		permissionsMap[permissionFeatures.resources.key][permissionFeatures.resources.actions.resources] = false;
		permissionsMap[permissionFeatures.resources.key][permissionFeatures.resources.actions.viewAllResources] = false;
		permissionsMap[permissionFeatures.resources.key][permissionFeatures.resources.actions.threatTypeIcon] = false;
		permissionsMap[permissionFeatures.resources.key][permissionFeatures.resources.actions.resourceName] = false;

		// reports
		permissionsMap[permissionFeatures.reports.key] = {};
		permissionsMap[permissionFeatures.reports.key][permissionFeatures.reports.actions.reports] = false;
		permissionsMap[permissionFeatures.reports.key][permissionFeatures.reports.actions.adminReports] = false;
		permissionsMap[permissionFeatures.reports.key][permissionFeatures.reports.actions.incidentsReports] = false;
		permissionsMap[permissionFeatures.reports.key][permissionFeatures.reports.actions.trainingReports] = false;
		permissionsMap[permissionFeatures.reports.key][permissionFeatures.reports.actions.incidentManagementReports] = false;

		// manage
		permissionsMap[permissionFeatures.manage.key] = {};
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.manage] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.roles] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.users] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.locations] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.groups] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.departments] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.manageSettings] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.savedMessages] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.companyProfile] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.security] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.systemEmails] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.tenants] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.navigatePortal] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.companySettings] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.customFields] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.companyApi] = false;
		permissionsMap[permissionFeatures.manage.key][permissionFeatures.manage.actions.selfRegistration] = false;

		// help
		permissionsMap[permissionFeatures.help.key] = {};
		permissionsMap[permissionFeatures.help.key][permissionFeatures.help.actions.help] = false;

		// my profile
		permissionsMap[permissionFeatures.myProfile.key] = {};
		permissionsMap[permissionFeatures.myProfile.key][permissionFeatures.myProfile.actions.myProfile] = false;

		// my preparis
		permissionsMap[permissionFeatures.myPreparis.key] = {};
		permissionsMap[permissionFeatures.myPreparis.key][permissionFeatures.myPreparis.actions.myPreparis] = false;

		// administration
		permissionsMap[permissionFeatures.administration.key] = {};
		permissionsMap[permissionFeatures.administration.key][permissionFeatures.administration.actions.managePortal] = false;
		permissionsMap[permissionFeatures.administration.key][permissionFeatures.administration.actions.managePlanner] = false;

		// iQ
		permissionsMap[permissionFeatures.iQ.key] = {};
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.iQ] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.admin] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.overview] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.scopes] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.teams] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.teamMembers] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.createTeamMember] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.deleteTeamMember] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.updateTeamMember] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.verifyTeamMember] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.reports] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.plans] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.complianceSchedule] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.createTeamResource] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.deleteTeamResource] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.archiveTeamResource] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.verifyTeamResource] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.updateTeamResource] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.createIssue] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.deleteIssue] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.resolveIssue] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.updateIssue] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.complianceCategory] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.priority] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.establishDate] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.issueAssignee] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.updatePlan] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.verifyPlan] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.generatePlan] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.createPlan] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.selectEstablishedDate] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.reassignTeamMemberTasks] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.reassignTeamMemberTasksAcrossTeam] = false;
		permissionsMap[permissionFeatures.iQ.key][permissionFeatures.iQ.actions.moveTeamResource] = false;

		// Recovery Profiles
		permissionsMap[permissionFeatures.recoveryProfiles.key] = {};
		permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.viewRecoveryProfile] = false;
		permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.editRecoveryProfile] = false;
		permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.editRecoveryProfileContact] = false;
		permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.editRecoveryProfileGenerator] = false;
		permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.editRecoveryProfileSite] = false;
		permissionsMap[permissionFeatures.recoveryProfiles.key][permissionFeatures.recoveryProfiles.actions.createRecoveryProfile] = false;

		// Business Continuity Planner
		permissionsMap[permissionFeatures.agilityPlanner.key] = {};
		permissionsMap[permissionFeatures.agilityPlanner.key][permissionFeatures.agilityPlanner.actions.businessContinuityManagement] = false;
		permissionsMap[permissionFeatures.agilityPlanner.key][permissionFeatures.agilityPlanner.actions.businessContinuityAudit] = false;

		// incident Management
		permissionsMap[permissionFeatures.incidentManagement.key] = {};
		permissionsMap[permissionFeatures.incidentManagement.key][permissionFeatures.incidentManagement.actions.managePlans] = false;
		permissionsMap[permissionFeatures.incidentManagement.key][permissionFeatures.incidentManagement.actions.viewPlans] = false;
		permissionsMap[permissionFeatures.incidentManagement.key][permissionFeatures.incidentManagement.actions.incidentManagement] = false;

		// Agility Safety Pass
		permissionsMap[permissionFeatures.agilitySafetyPass.key] = {};
		permissionsMap[permissionFeatures.agilitySafetyPass.key][permissionFeatures.agilitySafetyPass.actions.safetyPass] = false;

		// Agility Risk
		permissionsMap[permissionFeatures.agilityRisk.key] = {};
		permissionsMap[permissionFeatures.agilityRisk.key][permissionFeatures.agilityRisk.actions.risk] = false;

		// Agility Exercise Manager
		permissionsMap[permissionFeatures.exerciseManagement.key] = {};
		permissionsMap[permissionFeatures.exerciseManagement.key][permissionFeatures.exerciseManagement.actions.exerciseManager] = false;

		return permissionsMap;
	}

	private initModules() {
		const modulesMap: any = {};
		modulesMap[securityModuleKeys.agilityAlerts] = false;
		modulesMap[securityModuleKeys.documents] = false;
		modulesMap[securityModuleKeys.training] = false;
		modulesMap[securityModuleKeys.resources] = false;
		modulesMap[securityModuleKeys.reports] = false;
		modulesMap[securityModuleKeys.manage] = false;
		modulesMap[securityModuleKeys.help] = false;
		modulesMap[securityModuleKeys.myProfile] = false;
		modulesMap[securityModuleKeys.myPreparis] = false;
		modulesMap[securityModuleKeys.administration] = false;
		modulesMap[securityModuleKeys.iQ] = false;
		modulesMap[securityModuleKeys.panic] = false;
		modulesMap[securityModuleKeys.liteMessaging] = false;
		modulesMap[securityModuleKeys.recoveryProfiles] = false;
		modulesMap[securityModuleKeys.agilityPlanner] = false;
		modulesMap[securityModuleKeys.agilitySafetyPass] = false;
		modulesMap[securityModuleKeys.agilityRisk] = false;
		modulesMap[securityModuleKeys.agilityExerciseManager] = false;

		return modulesMap;
	}

	private initFeatures() {
		const featuresMap: any = {};
		return featuresMap;
	}
}
