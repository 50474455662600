import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormErrorMessageModel } from '@app/shared/form-error-message.model';
import { Utility } from '@app/providers/utility';
import * as moment from 'moment';

@Component({
	selector: 'app-form-error-messages',
	templateUrl: './form-error-messages.component.html',
	styleUrls: ['./form-error-messages.component.scss']
})
export class FormErrorMessagesComponent implements OnChanges, OnDestroy {
	@Input() form: UntypedFormGroup;
	@Input() submitted: boolean;
	@Input() customErrorMessages: any = [];
	alert = 'danger';

	errors: string[];
	subscription: any = null;
	requiredAdded: boolean;

	constructor(private translator: TranslateService,
		private utility: Utility) {
	}

	ngOnChanges(changes) {
		if (!this.submitted) {
			this.unsubscribe();
			return;
		}

		if (this.form) {
			this.subscription = this.form.valueChanges.subscribe(() => this.getErrors());

			this.getErrors();
		}
	}

	ngOnDestroy() {
		this.unsubscribe();
	}

	unsubscribe() {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	getErrors() {
		this.errors = [];
		this.requiredAdded = false;
		this.getControlErrors(this.form, '');
	}

	getControlErrors(ctrl: AbstractControl, controlName: string) {
		if (ctrl.errors) {
			const errKeys: string[] = Object.keys(ctrl.errors);

			errKeys.forEach((ek) => {
				const msg = this.getErrorMessage(ctrl, ek, controlName);

				if (msg) {
					this.translator.get(msg.resourceKey, msg.interpolateParams).subscribe((res: any) => this.errors.push(res));
				}
			});
		}

		if (ctrl['controls']) {
			const ctrls: any = ctrl['controls'];
			const keys: string[] = Object.keys(ctrls);
			keys.forEach(k => this.getControlErrors(ctrls[k], k));
		}
	}

	getErrorMessage(ctrl: AbstractControl, propertyName: string, controlName: string): FormErrorMessageModel {
		if (ctrl.errors.hasOwnProperty(propertyName)) {
			switch (propertyName) {
				case 'required':
					if (this.requiredAdded) {
						return null;
					}

					this.requiredAdded = true;
					return new FormErrorMessageModel('errorMessages.msgValidateRequired');
					// form.$setValidity(errorType, false);
					// errorObj.$setTouched();
				case 'email':
					return new FormErrorMessageModel('errorMessages.msgValidateEmailFormat');
				case 'maxlength':
					return new FormErrorMessageModel('errorMessages.msgValidateMaxLength', {
						0: controlName,
						1: ctrl.errors.maxlength.requiredLength
					});
				case 'max':
					return new FormErrorMessageModel('errorMessages.msgValidateMaxValue', {
						0: controlName,
						1: ctrl.errors.max.max
					});
				case 'minlength':
					return new FormErrorMessageModel('errorMessages.msgValidateMinLength', {
						0: controlName,
						1: ctrl.errors.minlength.requiredLength
					});
				case 'min':
					return new FormErrorMessageModel('errorMessages.msgValidateMinValue', {
						0: controlName,
						1: ctrl.errors.min.min
					});
				case 'ngbDate':
					if (ctrl.errors.ngbDate.requiredBefore) {
						return new FormErrorMessageModel('errorMessages.msgValidateRequiredBefore', {
							0: controlName,
							1: moment(this.utility.fromBootstrapDate(ctrl.errors.ngbDate.requiredBefore)).format('MM/DD/YYYY')
						});
					}
					else {
						return new FormErrorMessageModel('errorMessages.invalidDate');
					}
				case 'isInvalidPhoneNumber':
					return new FormErrorMessageModel('errorMessages.isInvalidPhoneNumber', {});
				case 'dateRangeMax':
					return new FormErrorMessageModel('errorMessages.dateRangeMax', {});
				case 'fromDateIsAfterToDate':
					return new FormErrorMessageModel('errorMessages.fromDateIsAfterToDate', {});
				default:
					return ctrl.errors[propertyName];
			}
		}
	}
}
