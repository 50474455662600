import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TranslationWidth } from '@angular/common';

@Injectable()
export class CustomDatePickerI18n extends NgbDatepickerI18n {
	I18N_VALUES: any;

	constructor(translateService: TranslateService) {
		super();
		this.I18N_VALUES = {
			weekdays: [translateService.instant('layout.calendar.dayNamesMin.monday'),
				translateService.instant('layout.calendar.dayNamesMin.tuesday'),
				translateService.instant('layout.calendar.dayNamesMin.wednesday'),
				translateService.instant('layout.calendar.dayNamesMin.thursday'),
				translateService.instant('layout.calendar.dayNamesMin.friday'),
				translateService.instant('layout.calendar.dayNamesMin.saturday'),
				translateService.instant('layout.calendar.dayNamesMin.sunday')],
			months: [
				translateService.instant('layout.calendar.monthNamesShort.january'),
				translateService.instant('layout.calendar.monthNamesShort.february'),
				translateService.instant('layout.calendar.monthNamesShort.march'),
				translateService.instant('layout.calendar.monthNamesShort.april'),
				translateService.instant('layout.calendar.monthNamesShort.may'),
				translateService.instant('layout.calendar.monthNamesShort.june'),
				translateService.instant('layout.calendar.monthNamesShort.july'),
				translateService.instant('layout.calendar.monthNamesShort.august'),
				translateService.instant('layout.calendar.monthNamesShort.september'),
				translateService.instant('layout.calendar.monthNamesShort.october'),
				translateService.instant('layout.calendar.monthNamesShort.november'),
				translateService.instant('layout.calendar.monthNamesShort.december')
			]
		};
	}

	getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
		return this.I18N_VALUES.weekdays[weekday - 1];
	}

	getWeekdayShortName(weekday: number): string {
		return this.I18N_VALUES.weekdays[weekday - 1];
	}

	getMonthShortName(month: number): string {
		return this.I18N_VALUES.months[month - 1];
	}

	getMonthFullName(month: number): string {
		return this.getMonthShortName(month);
	}

	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}-${date.month}-${date.year}`;
	}
}
