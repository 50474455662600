export class FormErrorMessageModel {
	resourceKey: string;
	interpolateParams?: { [key: string]: string };

	constructor(resourceKey: string, interpolateParams?: { [key: string]: string }) {
		this.resourceKey = resourceKey;
		this.interpolateParams = interpolateParams;
	}
}

export class FormErrorModel {
	[key: string]: FormErrorMessageModel;
}
