import { Injectable } from '@angular/core';

import { LocalStorageService } from '@app/providers/local-storage.service';
import { Endpoint } from '@app/shared/endpoint.model';
import { env, storageKeys } from '@app/app.constants';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class EndpointService {
	private _endpoints: Array<Endpoint> = [];

	constructor(private storage: LocalStorageService) {
		this._endpoints = this.storage.get(storageKeys.endpoint) as Array<Endpoint>;
	}

	init() {
		const endpoints = _.cloneDeep(env.endpoints);
		this.refresh();
		if (this._endpoints == null || this._endpoints.length === 0) {
			this._endpoints = [];
			Object.assign(this._endpoints, endpoints);
		}
		else {
			this._endpoints.forEach((e: any) => {
				const endpoint = endpoints.find(ep => ep.endpointName.toLowerCase() === e.endpointName.toLowerCase());
				if (endpoint) {
					e.endpointURL = endpoint.endpointURL;
				}
			});
		}
		this.storage.set(storageKeys.endpoint, this._endpoints);
	}

	find(endpointName: string) {
		if (this._endpoints == null) {
			this.init();
		}
		let endpoint: Endpoint = null;

		try {
			endpoint = this._endpoints.find(item => item.endpointName.toLowerCase() === endpointName.toLowerCase());
		}
		catch (error) {
			// failed to get endpoint, rerun initialize and try again
			console.log(`Failed to get endpoint "${error}`);
			this.init();
			endpoint = this._endpoints.find(item => item.endpointName.toLowerCase() === endpointName.toLowerCase());
		}

		if (endpoint) {
			return endpoint.endpointURL;
		}
		else {
			return '';
		}
	}

	set(newEndpoints: Array<Endpoint>) {
		this._endpoints = newEndpoints;
		this.storage.set(storageKeys.endpoint, newEndpoints);
	}

	add(newEndpoints: Array<Endpoint>) {
		try {
			newEndpoints.forEach((e: any) => {
				const endpoint = this._endpoints.find(ep => ep.endpointName.toLowerCase() === e.endpointName.toLowerCase());
				if (endpoint) {
					endpoint.endpointURL = e.endpointURL;
				}
				else {
					this._endpoints.push(e);
				}
			});
		}
		catch (error) {
			// failed to use endpoint, rerun initialize and try again
			console.log(`Failed to get endpoint "${error}`);
			this.init();
			this._endpoints = this._endpoints.concat(newEndpoints);
		}
		this.storage.set(storageKeys.endpoint, this._endpoints);
	}

	refresh() {
		this._endpoints = this.storage.get(storageKeys.endpoint) as Array<Endpoint>;
	}
}
