export class AuthResultModel {
	valid: boolean;
	pendingMultifactorAuthentication: boolean;
	mfaPinExpirationMinutes: number;
	error: AuthResultErrorModel;
	userRights: any;
	agreementContent: AuthResultAgreementContentModel;
	ssoCustomerRedirectUrl: string;
	languageCode: string;
}

export class AuthResultErrorModel {
	statusCode: number;
	message: string;
	userMessage: string;
}

export class AuthResultAgreementContentModel {
	termsAndConditionsId: string = null;
	termsAndConditionsBody: string = null;
	termsAndConditionsBodyAsBase64: string = null;
	userHasAcceptedTnc: boolean = false;

	constructor() {
	}
}
