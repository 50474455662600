import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { AutoResume, DocumentInterruptSource, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@app/providers/api.service';
import { IdleTimeoutModalComponent } from '@app/components/modals/idle-timeout-modal/idle.timeout.modal';
import { idleTimeoutSettings, storageKeys } from '@app/app.constants';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class SessionService {
	private modalRef?: NgbModalRef = null;

	constructor(private idle: Idle,
		private keepalive: Keepalive,
		private localStorage: LocalStorageService,
		private api: ApiService,
		private modalService: NgbModal,
		private router: Router) {
		// sets an idle timeout
		idle.setIdle(idleTimeoutSettings.idlePeriodInSeconds);
		// sets a timeout period that the user will be considered timed out after going idle
		idle.setTimeout(idleTimeoutSettings.idleTimeoutInSeconds);
		// sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
		idle.setInterrupts([new DocumentInterruptSource(idleTimeoutSettings.interruptEvents)]);
		// when in warning mode don't allow interrupts to refresh idle timer
		idle.setAutoResume(AutoResume.notIdle);

		idle.onIdleEnd.subscribe(() => {

		});
		idle.onTimeout.subscribe(() => {
			this.signOut();
		});
		idle.onIdleStart.subscribe(() => {
			// Show warning dialog
			this.modalRef = this.modalService.open(IdleTimeoutModalComponent, {
				backdrop: 'static',
				keyboard: false
			});
			this.modalRef.componentInstance.maxVal = idleTimeoutSettings.idleTimeoutInSeconds;
			this.modalRef.result
				.then((extendSession: any) => {
					if (extendSession) {
						console.log('session extended');
						this.idle.stop();
						this.idle.watch();
					}
					else {
						console.log('session ended');
						this.signOut();
					}
				})
				.catch((err) => {
					// triggered by modal dismiss in signout(), don't signout again
				});
		});
		idle.onTimeoutWarning.subscribe((countdown) => {
			// Update state in warning dialog
			this.modalRef.componentInstance.timeRemaining = countdown;
			console.log(`Session will time out in ${countdown} seconds!`);
		});

		// sets the ping interval to keep alive interval
		keepalive.interval(idleTimeoutSettings.keepAliveInterval);

		keepalive.onPing.subscribe(() => {
			this.checkServersideSession()
				.then((resp) => {

				})
				.catch((error) => {
					console.log('Unable to extend session, signing out user.');
					this.signOut();
				});
			console.log('keepalive.onPing');
		});
	}

	public watch() {
		this.idle.watch();
	}

	public unwatch() {
		this.idle.stop();
	}

	public setTimeout(timeout) {
		this.idle.setIdle(timeout);
	}

	public isRunning() {
		// idle.watch() sets it to running
		// done here and in RouteGuard
		return this.idle.isRunning();
	}

	public checkServersideSession() {
		return this.api.post('checktoken', {});
	}

	private signOut() {
		this.unwatch();
		// If the warning modal is visible, close it
		if (this.modalRef) {
			this.modalRef.dismiss();
		}
		// Set to idle and transfer to login page
		this.localStorage.set(storageKeys.idle, true);
		if (this.localStorage.get(storageKeys.currentPortalId) === this.localStorage.get(storageKeys.rootPortalId)) {
			// Save current location so it can be returned to later
			this.localStorage.set(storageKeys.originalDestination, { name: window.location.pathname });
		}
		// Go to the login page
		this.router.navigateByUrl('/logout');
	}
}
