import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { defaultImageAddress, serviceErrorCodes, storageKeys, userActivityFlagTypes } from '@app/app.constants';
import { UserService } from '@app/providers/user.service';
import { AuthService } from '@app/providers/auth.service';
import { PasswordPoliciesModel } from '@app/shared/login/password-policies.model';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
	errorMessages: Array<string>;
	authorizationToken: string;
	isNewUser: boolean;
	passwordPoliciesSatisfied: boolean;
	passwordPolicies: PasswordPoliciesModel = new PasswordPoliciesModel();
	newPassword: string;
	confirmNewPassword: string;
	loading: boolean;
	displayPasswordPolicies: boolean;
	public defaultImageAddress = defaultImageAddress;
	public passwordIsExpired: boolean = false;

	constructor(private route: ActivatedRoute,
		private router: Router,
		private localStorage: LocalStorageService,
		private authSvc: AuthService,
		private userSvc: UserService,
		private translateSvc: TranslateService) {
	}

	ngOnInit() {
		this.authorizationToken = this.route.snapshot.queryParams.token;
		this.isNewUser = this.route.snapshot.queryParams.newUser;
		this.passwordPoliciesSatisfied = false;
		this.requestPasswordResetTokenAndResolvePasswordPolicies();
		this.errorMessages = [];
		this.passwordIsExpired = this.route?.snapshot?.queryParams?.passwordIsExpired.toLowerCase() === 'true';
	}

	validate() {
		this.errorMessages = [];

		if (!this.passwordPoliciesSatisfied) {
			this.errorMessages.push(this.translateSvc.instant('passwordPolicies.msgPasswordPolicyFail'));
		}

		if (!this.passwordsAreTheSame()) {
			this.errorMessages.push(this.translateSvc.instant('passwordPolicies.msgPasswordsMustMatch'));
		}

		if (this.passwordPoliciesSatisfied
		  && this.errorMessages.length === 0) {
			this.errorMessages = null;
			this.changePassword();
		}
	}

	passwordsAreTheSame(): boolean {
		return this.newPassword === this.confirmNewPassword;
	}

	requestPasswordResetTokenAndResolvePasswordPolicies() {
		this.loading = true;
		this.displayPasswordPolicies = false;
		if (!this.localStorage.get(storageKeys.endpoint)) {
			this.authSvc.initEndpoints();
		}

		this.authSvc.validateResetToken(this.route.snapshot.queryParams.token)
			.then((result: any) => {
				if (result.passwordPolicy) {
					this.passwordPolicies = result.passwordPolicy;
					this.displayPasswordPolicies = true;
				}
			})
			.catch((error: any) => {
				this.router.navigate(['/forgot-password'],
					{ state: { errorCode: error.errorCode } });
			})
			.then((_) => {
				this.loading = false;
			});
	}

	changePassword() {
		this.loading = true;
		this.authSvc.changePassword(this.newPassword, this.authorizationToken)
			.then((result: any) => {
				if (result.statusCode === 0 && result.errorMessage === 'OK') {
					if (this.isNewUser) {
						// First login
						this.userSvc.updateUserActivityFlag(userActivityFlagTypes.userFirstLogin, null, null);
					}
					this.router.navigate(['/login'], { queryParams: { changePasswordSuccess: true } });
				}
			})
			.catch((error) => {
				switch (error.errorCode) {
					case serviceErrorCodes.passwordPolicyFailureCode:
						this.errorMessages = ['passwordPolicies.msgPasswordPolicyFail'];
						this.passwordPoliciesSatisfied = false;
						break;
					case serviceErrorCodes.invalidOrExpiredToken:
						this.router.navigate(['/forgot-password'], { state: { errorCode: error.errorCode } });
						break;
					case serviceErrorCodes.passwordReuse:
						this.errorMessages = [this.translateSvc.instant('passwordPolicies.passwordReuseError')];
						break;
					default:
						this.errorMessages = [error.errorMessage];
						break;
				}
			})
			.then((_) => {
				this.loading = false;
			});
	}

	onPasswordPoliciesSatisfied(isSatisfied: boolean) {
		this.passwordPoliciesSatisfied = isSatisfied;
	}

	isChangePasswordDisabled() {
		return !this.passwordPoliciesSatisfied || !this.passwordsAreTheSame();
	}

	onCancel() {
		this.router.navigateByUrl('/login');
	}
}
