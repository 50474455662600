import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Team } from '@app/shared/team.model';
import {
	applicationVerticalTypes,
	complianceScheduleFrequencySettings,
	daysInWeek,
	monthsInQuarter,
	monthsInSemiYear,
	planTemplateTypeIds,
	roleBundleTypes,
	storageKeys, timeDurationUnit
} from '@app/app.constants';
import { Buffer } from 'buffer';
import { ConfirmationModalComponent } from '@app/components/modals/confirmation-modal/confirmation-modal.component';
import { ConfirmationCommentModalComponent } from '@app/components/modals/confirmation-comment-modal/confirmation-comment-modal.component';
import * as uuid from 'uuid';
import * as moment from 'moment-timezone';
import { Moment } from 'moment';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { iQTeamTreeTypes } from '@app/app.enum';
import { AbsoluteDateModel } from '@app/shared/business-continuity/process-critical-date.model';
import { PortalIndustry } from '@app/shared/business-continuity/portal-industry.model';
import { UntypedFormControl } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';

@Injectable({
	providedIn: 'root'
})
export class Utility {
	constructor(public storage: LocalStorageService,
		private translateService: TranslateService,
		private modalService: NgbModal,
		private dialogS: DialogService) {
	}

	get isSelfRegisteredUser(): boolean {
		return this.storage.get(storageKeys.isSelfRegistered) === true;
	}

	get hasBusinessContinuityAssignments(): boolean {
		return this.storage.get(storageKeys.hasBusinessContinuityAssignments) === true;
	}

	get isBusinessContinuityTeamLead(): boolean {
		return this.storage.get(storageKeys.isBusinessContinuityTeamLead) === true;
	}

	get isBusinessContinuitySME(): boolean {
		return this.storage.get(storageKeys.isBusinessContinuitySME) === true;
	}

	get isBusinessContinuityAnnexLead(): boolean {
		return this.storage.get(storageKeys.isBusinessContinuityAnnexLead) === true;
	}

	get isBusinessContinuityTechnologyOwner(): boolean {
		return this.storage.get(storageKeys.isBusinessContinuityTechnologyOwner) === true;
	}

	get privacyUrl(): string {
		return this.storage.get(storageKeys.privacyUrl);
	}

	get termOfServiceUrl(): string {
		return this.storage.get(storageKeys.termOfServiceUrl);
	}

	get primeNgCalendarLocale(): any {
		return {
			firstDayOfWeek: 0,
			dayNamesMin: [this.translateService.instant('layout.calendar.dayNamesMin.sunday'),
				this.translateService.instant('layout.calendar.dayNamesMin.monday'),
				this.translateService.instant('layout.calendar.dayNamesMin.tuesday'),
				this.translateService.instant('layout.calendar.dayNamesMin.wednesday'),
				this.translateService.instant('layout.calendar.dayNamesMin.thursday'),
				this.translateService.instant('layout.calendar.dayNamesMin.friday'),
				this.translateService.instant('layout.calendar.dayNamesMin.saturday')],
			monthNames: [this.translateService.instant('layout.calendar.monthNames.january'),
				this.translateService.instant('layout.calendar.monthNames.february'),
				this.translateService.instant('layout.calendar.monthNames.march'),
				this.translateService.instant('layout.calendar.monthNames.april'),
				this.translateService.instant('layout.calendar.monthNames.may'),
				this.translateService.instant('layout.calendar.monthNames.june'),
				this.translateService.instant('layout.calendar.monthNames.july'),
				this.translateService.instant('layout.calendar.monthNames.august'),
				this.translateService.instant('layout.calendar.monthNames.september'),
				this.translateService.instant('layout.calendar.monthNames.october'),
				this.translateService.instant('layout.calendar.monthNames.november'),
				this.translateService.instant('layout.calendar.monthNames.december')]
		};
	}

	get currentIndustryId(): string {
		return <string>(<PortalIndustry> this.storage.get(storageKeys.portalIndustry))?.industryId;
	}

	getUserId(): string {
		return <string> this.storage.get(storageKeys.userId);
	}

	getUserProfileName(): string {
		return <string> this.storage.get(storageKeys.userProfileName);
	}

	getCurrentPortalId(): string {
		return <string> this.storage.get(storageKeys.currentPortalId);
	}

	getCurrentPortalName(): string {
		return <string> this.storage.get(storageKeys.currentPortalName);
	}

	getRootPortalId(): string {
		return <string> this.storage.get(storageKeys.rootPortalId);
	}

	getRootPortalName(): string {
		return <string> this.storage.get(storageKeys.rootPortalName);
	}

	getToken(): string {
		return <string> this.storage.get(storageKeys.token);
	}

	getVerticalTypeId(): string {
		return <string> this.storage.get(storageKeys.verticalTypeId);
	}

	getLanguageCode(): string {
		return <string> this.storage.get(storageKeys.preferredLanguage) || 'en';
	}

	getPortalPlanTypeId(planTemplateTypeId?: string): string {
		const portalPlanTypes: any[] = this.storage.get(storageKeys.portalPlanTypes);

		let planType = null;

		if (planTemplateTypeId) {
			planType = portalPlanTypes.find(ppt => ppt.planTypeId === planTemplateTypeId);
		}
		else {
			planType = portalPlanTypes.find(ppt => ppt.planTypeId === planTemplateTypeIds.businessContinuity);
			if (!planType) {
				planType = portalPlanTypes.find(ppt => ppt.planTypeId === planTemplateTypeIds.continuityOfOperationPlanning);
			}
		}

		return planType?.portalPlanTypeId;
	}

	toBootstrapDate(inDate: Moment): NgbDateStruct {
		if (!inDate) {
			return null;
		}

		return {
			day: inDate.date(),
			year: inDate.year(),
			month: inDate.month() + 1
		} as NgbDateStruct;
	}

	fromBootstrapDate(bsDate: NgbDateStruct, time?: any, timeZone?: string, toUtc?: boolean): Date {
		// e.g., time = {hour: x, minute: x}
		if (!bsDate) {
			return null;
		}

		const newDate: NgbDateStruct = {
			day: bsDate.day,
			year: bsDate.year,
			month: bsDate.month - 1
		};

		if (time) {
			let momentDate = moment.tz(newDate, timeZone);
			momentDate = momentDate.set(time);
			if (toUtc) {
				return momentDate.utc().toDate();
			}
			else {
				return momentDate.toDate();
			}
		}
		else {
			return moment(newDate).toDate();
		}
	}

	fromBootstrapDateToUTC(bsDate: NgbDateStruct): Date {
		// e.g., time = {hour: x, minute: x}
		if (!bsDate) {
			return null;
		}

		const newDate: NgbDateStruct = {
			day: bsDate.day,
			year: bsDate.year,
			month: bsDate.month - 1
		};
		/* to test the difference:
        console.log('valueOf: ' + moment.utc(newDate).valueOf());
        console.log('utc.toDate.valueOf: ' + moment.utc(newDate).toDate().valueOf());
        console.log('toDate.valueOf: ' +  moment(newDate).toDate().valueOf());
        */
		return moment.utc(newDate).toDate();
	}

	getBootstrapDateNow() {
		return this.toBootstrapDate(moment.utc());
	}

	formatBootstrapDate(date: NgbDateStruct, format: string): string {
		return moment(this.fromBootstrapDate(date)).format(format);
	}

	parseTeamNodes(flatTeams: Array<Team>, selectedTeamId?: string) {
		const flatNodes: TreeNode[] = flatTeams.map((team: Team) => {
			return <TreeNode>{
				label: team.teamName,
				data: {
					id: team.teamId,
					name: team.teamName,
					label: team.teamName,
					parentId: team.parentTeamId,
					scopeId: team.scopeId,
					teamId: team.teamId,
					type: iQTeamTreeTypes.team
				},
				children: []
			};
		});

		const treeNodes = new Array<TreeNode>();
		flatNodes.forEach((node: TreeNode) => {
			if (node.data.parentId) {
				const parentNode = flatNodes.find((parentNode: TreeNode) => parentNode.data.id === node.data.parentId);
				if (parentNode) {
					parentNode.children.push(node);
				}
				else {
					treeNodes.push(node);
				}
			}
			else {
				treeNodes.push(node);
			}
		});

		return treeNodes;
	}

	getTreeNodeById(nodeArray: TreeNode[], id: string) {
		let node = nodeArray.find((treeNode: TreeNode) => treeNode.data.id === id);
		if (!node) {
			nodeArray.forEach((treeNode: TreeNode) => {
				if (treeNode.children) {
					node = this.getTreeNodeById(treeNode.children, id);
					if (node) {
						return node;
					}
				}
			});
		}
		return node;
	}

	translateObject(from: any) {
		const props = Object.keys(from);
		props.forEach((p) => {
			from[p] = from[p] ? this.translateService.instant(from[p]) : from[p];
		});
	}

	createTranslatedSaveToastrMessageConfig(labelKey: string): { successMsg: string, errorMsg: string } {
		const label = this.translateService.instant(labelKey);
		const successMsg = this.translateService.instant('successMessages.saveSuccess', { name: label });
		const errorMsg = this.translateService.instant('errorMessages.saveError', { name: label });
		return { successMsg: successMsg, errorMsg: errorMsg };
	}

	createTranslatedRemoveToastrMessageConfig(labelKey: string): { successMsg: string, errorMsg: string } {
		let label = '';
		this.translateService.get(labelKey).subscribe((res: string) => label = res);
		let successMsg = '';
		this.translateService.get('successMessages.removeSuccess', { name: label }).subscribe((res: string) => successMsg = res);
		let errorMsg = '';
		this.translateService.get('errorMessages.removeError', { name: label }).subscribe((res: string) => errorMsg = res);
		return { successMsg: successMsg, errorMsg: errorMsg };
	}

	createTranslatedArchivedToastrMessageConfig(labelKey: string, isCurrentlyArchived: boolean): { successMsg: string, errorMsg: string } {
		let label = '';
		this.translateService.get(labelKey).subscribe((res: string) => label = res);

		return this.createTranslatedToastrMessageConfig(
			isCurrentlyArchived ? 'successMessages.unArchiveSuccess' : 'successMessages.archiveSuccess',
			isCurrentlyArchived ? 'errorMessages.unArchiveError' : 'errorMessages.archiveError',
			{ name: label });
	}

	createTranslatedToastrMessageConfig(successMsgKey: string, errorMsgKey: string, interpolateParams: { [key: string]: string }): { successMsg: string, errorMsg: string } {
		let successMsg = '';
		this.translateService.get(successMsgKey, interpolateParams).subscribe((res: string) => successMsg = res);
		let errorMsg = '';
		this.translateService.get(errorMsgKey, interpolateParams).subscribe((res: string) => errorMsg = res);
		return { successMsg: successMsg, errorMsg: errorMsg };
	}

	createTranslatedVerifyToastrMessageConfig(labelKey: string): { successMsg: string, errorMsg: string } {
		let label = '';
		this.translateService.get(labelKey).subscribe((res: string) => label = res);
		let successMsg = '';
		this.translateService.get('successMessages.verifySuccess', { name: label }).subscribe((res: string) => successMsg = res);
		let errorMsg = '';
		this.translateService.get('errorMessages.verifyError', { name: label }).subscribe((res: string) => errorMsg = res);
		return { successMsg: successMsg, errorMsg: errorMsg };
	}

	createTranslatedCheckInToastrMessageConfig(labelKey: string): { successMsg: string, errorMsg: string } {
		let label = '';
		this.translateService.get(labelKey).subscribe((res: string) => label = res);
		let successMsg = '';
		this.translateService.get('successMessages.checkInSuccess', { name: label }).subscribe((res: string) => successMsg = res);
		let errorMsg = '';
		this.translateService.get('errorMessages.checkInError', { name: label }).subscribe((res: string) => errorMsg = res);
		return { successMsg: successMsg, errorMsg: errorMsg };
	}

	createTranslatedDeclineToastrMessageConfig(labelKey: string): { successMsg: string, errorMsg: string } {
		let label = '';
		this.translateService.get(labelKey).subscribe((res: string) => label = res);
		let successMsg = '';
		this.translateService.get('successMessages.declineSuccess', { name: label }).subscribe((res: string) => successMsg = res);
		let errorMsg = '';
		this.translateService.get('errorMessages.declineError', { name: label }).subscribe((res: string) => errorMsg = res);
		return { successMsg: successMsg, errorMsg: errorMsg };
	}

	createTranslatedApproveToastrMessageConfig(labelKey: string): { successMsg: string, errorMsg: string } {
		let label = '';
		this.translateService.get(labelKey).subscribe((res: string) => label = res);
		let successMsg = '';
		this.translateService.get('successMessages.approveSuccess', { name: label }).subscribe((res: string) => successMsg = res);
		let errorMsg = '';
		this.translateService.get('errorMessages.approveError', { name: label }).subscribe((res: string) => errorMsg = res);
		return { successMsg: successMsg, errorMsg: errorMsg };
	}

	getFileExtension(fileName: string): string {
		const extension = fileName.substring(fileName.lastIndexOf('.'));
		return (extension !== fileName) ? extension : '';
	}

	getFileIcon(document): string {
		const isIQ = (document.isIQDocument === true);
		const isBC = (document.isBCDocument === true);
		let icon = (isIQ) ? 'pp-file-text-o' : (isBC) ? 'pp-ap-file-text-o' : 'fa fa-file-text-o';
		const extension = this.getFileExtension(document.documentFileName).toLowerCase();
		switch (extension) {
			case '.pdf':
				icon = (isIQ) ? 'pp-file-pdf-o' : (isBC) ? 'pp-ap-file-pdf-o' : 'fa fa-file-pdf-o';
				break;
			case '.jpg':
			case '.jpeg':
			case '.jpe':
			case '.tif':
			case '.tiff':
			case '.bmp':
			case '.gif':
			case '.png':
			case '.svg':
				icon = (isIQ) ? 'pp-file-image-o' : (isBC) ? 'pp-ap-file-image-o' : 'fa fa-file-image-o';
				break;
			case '.wav':
			case '.mp3':
				icon = (isIQ) ? 'pp-file-sound-o' : (isBC) ? 'pp-ap-file-sound-o' : 'fa fa-file-sound-o';
				break;
			case '.zip':
				icon = (isIQ) ? 'pp-file-zip-o' : (isBC) ? 'pp-ap-file-zip-o' : 'fa fa-file-zip-o';
				break;
			case '.xls':
			case '.xlsx':
			case '.xlt':
			case '.xlm':
			case '.xlc':
			case '.csv':
				icon = (isIQ) ? 'pp-file-excel-o' : (isBC) ? 'pp-ap-file-excel-o' : 'fa fa-file-excel-o';
				break;
			case '.doc':
			case '.docx':
				icon = (isIQ) ? 'pp-file-word-o' : (isBC) ? 'pp-ap-file-word-o' : 'fa fa-file-word-o';
				break;
			case '.ppt':
				icon = (isIQ) ? 'pp-file-powerpoint-o' : (isBC) ? 'pp-ap-file-powerpoint-o' : 'fa fa-file-powerpoint-o';
				break;
			case '.js':
			case '.css':
			case '.htm':
			case '.html':
			case '.htmls':
				icon = (isIQ) ? 'pp-file-code-o' : (isBC) ? 'pp-ap-file-code-o' : 'fa fa-file-code-o';
				break;
		}
		return icon;
	}

	getFileCategory(document): string {
		const isIQ = (document.isIQDocument === true);
		const isBC = (document.isBCDocument === true);
		let category = this.translateService.instant('documents.itemsCategories.text');
		const extension = this.getFileExtension(document.documentFileName).toLowerCase();
		switch (extension) {
			case '.pdf':
				category = 'PDF';
				break;
			case '.jpg':
			case '.jpeg':
			case '.jpe':
			case '.tif':
			case '.tiff':
			case '.bmp':
			case '.gif':
			case '.png':
			case '.svg':
				category = this.translateService.instant('documents.itemsCategories.image');
				break;
			case '.wav':
			case '.mp3':
				category = this.translateService.instant('documents.itemsCategories.audio');
				break;
			case '.zip':
				category = 'ZIP';
				break;
			case '.rar':
				category = 'RAR';
				break;
			case '.xls':
			case '.xlsx':
			case '.xlt':
			case '.xlm':
			case '.xlc':
			case '.csv':
				category = this.translateService.instant('documents.itemsCategories.spreadsheet');
				break;
			case '.doc':
			case '.docx':
				category = this.translateService.instant('documents.itemsCategories.wordDocument');
				break;
			case '.ppt':
				category = 'PPT';
				break;
			case '.js':
			case '.css':
			case '.htm':
			case '.html':
			case '.htmls':
				category = 'HTML';
				break;
			case '.exe':
				category = this.translateService.instant('documents.fileType.EXE');
		}
		return category;
	}

	calculateNextValidation(lastValidated: Date, validationFrequency: number): Date {
		if (!lastValidated
		  || isNaN(validationFrequency)
		  || !validationFrequency) {
			return null;
		}

		const nextValidation: Date = new Date(lastValidated);

		switch (validationFrequency) {
			case complianceScheduleFrequencySettings.weekly:
				nextValidation.setDate(lastValidated.getDate() + daysInWeek);
				break;
			case complianceScheduleFrequencySettings.monthly:
				nextValidation.setMonth(lastValidated.getMonth() + 1);
				break;
			case complianceScheduleFrequencySettings.quarterly:
				nextValidation.setMonth(lastValidated.getMonth() + monthsInQuarter);
				break;
			case complianceScheduleFrequencySettings.semiannually:
				nextValidation.setMonth(lastValidated.getMonth() + monthsInSemiYear);
				break;
			case complianceScheduleFrequencySettings.annually:
				nextValidation.setFullYear(lastValidated.getFullYear() + 1);
				break;
			default:
				nextValidation.setDate(lastValidated.getDate() + validationFrequency);
				break;
		}

		return nextValidation;
	}

	isBase64Encoded(value: string): boolean {
		const base64Matcher = new RegExp('^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})([=]{1,2})?$');
		return base64Matcher.test(value);
	}

	base64Encode(value: string): string {
		if (value == null || value === '') {
			return null;
		}
		else {
			return Buffer.from(value).toString('base64');
		}
	}

	base64Decode(value: string): string {
		if (this.isBase64Encoded(value)) {
			try {
				return Buffer.from(value, 'base64').toString();
			}
			catch (e) {
				// failed to decode so just return as is
				return value;
			}
		}
		else {
			return value;
		}
	}

	sortByStringFieldAlphabetically(array: Array<any>, fieldName: string, asc: boolean = true) {
		if (!array
		  || array.length === 0) {
			return;
		}

		const i: number = asc ? 1 : -1;

		array.sort((i1: any, i2: any): number => {
			return i * (i1[fieldName].localeCompare(i2[fieldName], 'kn', { sensitivity: 'base' }));
		});
	}

	confirmAndExecute(baseResourcePath: string, confirmButtonClass: string, parent: any, callback: (any) => any, translateParams: any, callbackArgs: any, undoCallback?: (any) => any) {
		const boundCallback = callback ? callback.bind(parent, callbackArgs) : null;
		const boundUndoCallback = undoCallback ? undoCallback.bind(parent, callbackArgs) : null;

		const modalRef = this.modalService.open(ConfirmationModalComponent);

		modalRef.componentInstance.init(
			this.translateService.instant(baseResourcePath + '.header', translateParams),
			this.translateService.instant(baseResourcePath + '.body', translateParams),
			this.translateService.instant(baseResourcePath + '.text'),
			confirmButtonClass
		);

		// handle modal result
		modalRef.result.then((res) => {
			if (res
			  && boundCallback) {
				// modal confirmed
				boundCallback();
			}
		}).catch((_) => {
			if (boundUndoCallback) {
				boundUndoCallback();
			}
		});
	}

	confirmWithCommentAndExecute(baseResourcePath: string, confirmButtonClass: string, parent: any, callback: (any, string) => any, translateParams: any, callbackArgs: any, requireComment?: boolean) {
		const boundCallback = callback ? callback.bind(parent, callbackArgs) : null;

		const modalRef = this.modalService.open(ConfirmationCommentModalComponent);

		modalRef.componentInstance.init(
			this.translateService.instant(baseResourcePath + '.header', translateParams),
			this.translateService.instant(baseResourcePath + '.body', translateParams),
			this.translateService.instant(baseResourcePath + '.text'),
			confirmButtonClass,
			requireComment
		);

		// handle modal result
		modalRef.result.then((res) => {
			if (boundCallback) {
				// modal confirmed
				boundCallback(res);
			}
		}).catch((_) => {
			// modal dismissed
		});
	}

	splitCamelCase(value: string): string {
		return value.replace(/([A-Z])/g, ' $1');
	}

	convertToUTCDate(localDateTime: Date): Date {
		const dteb = moment(localDateTime).utc();
		return new Date(dteb.year(), dteb.month(), dteb.date());
	}

	isBeforeUTCToday(dt: NgbDateStruct): boolean {
		const newDate = moment(this.fromBootstrapDate(dt));
		const minDate = moment.utc();
		if (newDate.isBefore(minDate, 'day')) {
			return true;
		}
		return false;
	}

	isToday(dt: NgbDateStruct): boolean {
		const newDate = moment(this.fromBootstrapDate(dt));
		const today = moment().startOf('day');

		return newDate.isSame(today, 'day');
	}

	isRootPortal(): boolean {
		return this.storage.get(storageKeys.currentPortalId) === this.storage.get(storageKeys.rootPortalId);
	}

	hasSubPortals(): boolean {
		return this.storage.get(storageKeys.hasSubPortals) === true;
	}

	getGuid(): string {
		return uuid.v4();
	}

	isRealEstatePortal(): boolean {
		return this.storage.get(storageKeys.verticalTypeId) === applicationVerticalTypes.realEstate;
	}

	isNumberOnly(testString: string): boolean {
		const isNumber = /^\d+$/.test(testString);

		return isNumber;
	}

	isSafari(): boolean {
		return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	}

	pushToArrayAndTriggerChangeDetection(array: Array<any>, newElement: any) {
		array.push(newElement);
		// have to create new array instance to trigger change detection
		array = array.map(e => e);
	}

	calculateSizeForDisplay(size): string {
		const uom = 1024;
		let sizeForDisplay = '0';
		if (!isNaN(size)) {
			const sizeInBytes = parseInt(size, 10);
			if (sizeInBytes < uom) {
				sizeForDisplay = Math.round(sizeInBytes) + ' Bytes';
			}
			else {
				const sizeInKBytes = sizeInBytes / uom;
				if (sizeInKBytes < uom) {
					sizeForDisplay = Math.round(sizeInKBytes) + ' Kb';
				}
				else {
					const sizeInMBytes = sizeInKBytes / uom;
					sizeForDisplay = Math.round(sizeInMBytes) + ' Mb';
				}
			}
		}
		return sizeForDisplay;
	}

	getFileType(fileName): string {
		const extension = this.getFileExtension(fileName);
		if (!extension) {
			return this.translateService.instant('documents.fileType.unknown');
		}

		const fileTypeResourceKey: string = 'documents.fileType' + extension.toUpperCase();
		const resString = this.translateService.instant(fileTypeResourceKey);
		return (resString && resString !== fileTypeResourceKey) ? resString : this.translateService.instant('documents.fileType.unknown');
	}

	setAllowMobileAccess(value: boolean) {
		return this.storage.set(storageKeys.allowMobileAccess, value);
	}

	setCurrentPortalId(portalId: string) {
		return this.storage.set(storageKeys.currentPortalId, portalId);
	}

	setCurrentPortalName(portalName: string) {
		return this.storage.set(storageKeys.currentPortalName, portalName);
	}

	getUserRoleBundle() {
		const userRights = this.storage.get(storageKeys.userRights) as any;

		return userRights.roleBundles[0];
	}

	isSuperAdminRole(roleBundleTypeId: string): boolean {
		return (roleBundleTypeId === roleBundleTypes.superAdmin
		  || roleBundleTypeId === roleBundleTypes.propertySuperAdmin);
	}

	isMessageOnlyRole(roleBundleTypeId: string): boolean {
		return (roleBundleTypeId === roleBundleTypes.messageOnly
		  || roleBundleTypeId === roleBundleTypes.propertyMessageOnly
		  || roleBundleTypeId === roleBundleTypes.tenantMessageOnly);
	}

	isTenantRole(roleBundleTypeId) {
		return (roleBundleTypeId === roleBundleTypes.tenantAdministrator
		  || roleBundleTypeId === roleBundleTypes.tenantCrisisTeam
		  || roleBundleTypeId === roleBundleTypes.tenantEmployee
		  || roleBundleTypeId === roleBundleTypes.tenantMessageOnly);
	}

	getUserEntity(entityTypeId: string) {
		const entities = (<any> this.storage.get(storageKeys.userRights)).entities.filter((entity: any) => {
			return entity.entityTypeId === entityTypeId;
		});
		return entities;
	}

	getCacheBustingValue() {
		let cacheBustingValue = this.storage.get(storageKeys.cacheBustingValue);
		if (cacheBustingValue == null) {
			cacheBustingValue = this.storage.set(storageKeys.cacheBustingValue, new Date().getTime());
		}
		return cacheBustingValue;
	}

	getResolvedUrl(route: ActivatedRouteSnapshot): string {
		return route?.pathFromRoot
			.filter(v => v.routeConfig == null || v.routeConfig.path !== '')
			.map(v => v.url.map(segment => segment.toString()).join('/'))
			.join('/');
	}

	getRouteParameterFromParent(route: ActivatedRouteSnapshot, paramName: string): string {
		if (route) {
			const portalPlanTypeId = route.params[paramName];
			if (portalPlanTypeId == null) {
				return this.getRouteParameterFromParent(route.parent, paramName);
			}
			else {
				return portalPlanTypeId;
			}
		}
		else {
			return null;
		}
	}

	getRouteParameterFromChild(route: ActivatedRouteSnapshot, paramName: string): string {
		if (route) {
			const param = route.params[paramName];
			if (param == null) {
				return this.getRouteParameterFromChild(route.firstChild, paramName);
			}
			else {
				return param;
			}
		}
		else {
			return null;
		}
	}

	formatString(formatString: string, args: any[]): string {
		let res: string = formatString;
		if (!formatString
		  || !args) {
			return res;
		}

		let regex;
		args.forEach((v, i) => {
			regex = new RegExp(`{(${i})}`, 'gi');
			if (regex.test(formatString)) {
				res = res.replace(regex, v);
			}
		});

		return res;
	}

	isValidEmailFormat(testEmailStr: string): boolean {
		const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		return regex.test(testEmailStr);
	}

	getKeyByValue(object, value) {
		return Object.keys(object).find(key => object[key] === value);
	}

	toAbsoluteDateModel(from: Date | Moment): AbsoluteDateModel {
		if (from instanceof Date) {
			return new AbsoluteDateModel(from.getFullYear(), from.getMonth() + 1, from.getDate());
		}

		if (moment.isMoment(from)) {
			return new AbsoluteDateModel(from.year(), from.month() + 1, from.date());
		}

		return null;
	}

	fromAbsoluteDateModel(from: AbsoluteDateModel): Date {
		if (!from) {
			return null;
		}
		return moment({ year: from.year, month: from.month - 1, day: from.day }).toDate();
	}

	clearPortalStorageInfo() {
		this.storage.remove(storageKeys.headerInfo);
		this.storage.remove(storageKeys.teamId);
		this.storage.remove(storageKeys.scopeId);
		this.storage.remove(storageKeys.brandingTerms);
		this.storage.remove(storageKeys.portalSite);
		this.storage.remove(storageKeys.portalIndustry);
		this.storage.remove(storageKeys.usersTableState);
		this.storage.remove(storageKeys.impactTimeSpans);
		this.storage.remove(storageKeys.impactCategories);
		this.storage.remove(storageKeys.impactSeverities);
	}

	noWhitespaceValidator(control: UntypedFormControl) {
		return String.isNullOrEmpty(control.value) ? { whitespace: true } : null;
	}

	translateWithOptions(keys: Array<string>) {
		let res = '';
		for (const k of keys) {
			console.log(k);
			res = this.translateService.instant(k);
			console.log(res);
			if (res != k) {
				break;
			}
		}
		return res;
	}

	// use this instead of String augmented base class since angular can`t used in the jasmine tests
	isNullOrEmpty(val: any): boolean {
		return (val === undefined || val === null || val.trim() === '');
	};

	timeDurationUnitToText(timeDurationUnitId: string): string {
		switch (timeDurationUnitId) {
			case timeDurationUnit.second:
				return this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.seconds');
			case timeDurationUnit.minute:
				return this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.minutes');
			case timeDurationUnit.hour:
				return this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.hours');
			case timeDurationUnit.day:
				return this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.days');
			case timeDurationUnit.month:
				return this.translateService.instant('complianceCalendar.month');
			case timeDurationUnit.year:
				return this.translateService.instant('complianceCalendar.year');
			default:
				return '';
		}
	}
}
