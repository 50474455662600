import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
	private prefix: string = 'preparisPortal.';

	set(key: string, value: any) {
		localStorage.setItem(this.prefix.concat(key), JSON.stringify(value));
	}

	get(key: string): any {
		try {
			return JSON.parse(localStorage.getItem(this.prefix.concat(key)));
		}
		catch (e: any) {
			return null;
		}
	}

	remove(key: string) {
		localStorage.removeItem(this.prefix.concat(key));
	}

	clear(): void {
		localStorage.clear();
	}

	keys(): string[] {
		return Object.keys(localStorage).map((key: string) => {
			return key.replace(this.prefix, '');
		});
	}
}
