import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { alertResponseSetTypeIds, alertResponseTypeIds } from '@app/app.constants';

@Component({
	selector: 'app-splash-message-dialog',
	templateUrl: './splash-message-dialog.component.html',
	styleUrls: ['./splash-message-dialog.component.scss']
})
export class SplashMessageDialogComponent implements OnInit {
	splashMessage: any;
	alertName: string;
	alertBody: string;
	alertResponseSetTypeId: string;
	hasAgreed: boolean;
	acceptedClicked: boolean;
	alertResponseSetTypes: any;

	constructor(private activeModal: NgbActiveModal,
		private translateSvc: TranslateService) {
		this.alertResponseSetTypes = alertResponseSetTypeIds;
	}

	ngOnInit() {
		this.alertName = this.splashMessage.alertName || this.translateSvc.instant('msgDefaultSplashTitle');
		this.alertBody = this.splashMessage.alertBody || '';
		this.alertResponseSetTypeId = this.splashMessage.alertResponseSetTypeId;
	}

	isResponseSetType(currentType) {
		return currentType.id === this.alertResponseSetTypeId;
	}

	accept() {
		this.acceptedClicked = true;

		if (this.isResponseSetType(alertResponseSetTypeIds.checkBox)) {
			/* Answer: Check */
			if (this.hasAgreed) {
				this.activeModal.close({
					alert: this.splashMessage,
					responseType: alertResponseTypeIds.check
				});
			}
			/* Answer: Ok */
		}
		else if (this.isResponseSetType(alertResponseSetTypeIds.ok)) {
			this.activeModal.close({
				alert: this.splashMessage,
				responseType: alertResponseTypeIds.ok
			});
			/* Answer: Yes (yesNo) */
		}
		else if (this.isResponseSetType(alertResponseSetTypeIds.yesNo)) {
			this.activeModal.close({
				alert: this.splashMessage,
				responseType: alertResponseTypeIds.yes
			});
		}
	}

	// Answer: No (yesNo)
	decline() {
		this.activeModal.dismiss({
			alert: this.splashMessage,
			responseType: alertResponseTypeIds.no
		});
	}

	// Answer: Cancel (i.e. user hit close button on modal)
	close() {
		this.activeModal.dismiss({
			alert: this.splashMessage,
			responseType: alertResponseTypeIds.cancel
		});
	}
}
