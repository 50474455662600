import { Injectable } from '@angular/core';
import { defaultLocale } from '@app/app.constants';
import * as _ from 'lodash';
import { ApiService } from '@app/providers/api.service';
import { PhoneNumberFormat as PNF, PhoneNumberUtil } from 'google-libphonenumber';

@Injectable({
	providedIn: 'root'
})
export class PhoneNumberService {
	private phoneNumberUtil = PhoneNumberUtil.getInstance();

	constructor(private api?: ApiService) {
	}

	public isValidNumber(phoneNumber: string): boolean {
		if (!phoneNumber) {
			return false;
		}

		try {
			const parsed = this.phoneNumberUtil.parse(phoneNumber, this.getCountryCodeFromLocale());

			if (_.startsWith(phoneNumber, '+')) {
				return this.phoneNumberUtil.isValidNumber(parsed);
			}
			else {
				const countryCode = this.getCountryCodeFromLocale(defaultLocale);
				return this.isValidNumberForRegion(phoneNumber, countryCode);
			}
		}
		catch (e) {
			return false;
		}
	}

	isValidNumberForRegion(phoneNumber: string, region: string) {
		const parsed = this.phoneNumberUtil.parse(phoneNumber, region);

		return this.phoneNumberUtil.isValidNumberForRegion(parsed, region);
	}

	public toE164Format(phoneNumber: string) {
		if (phoneNumber?.length) {
			const parsed = this.phoneNumberUtil.parse(phoneNumber, this.getCountryCodeFromLocale());
			return this.phoneNumberUtil.format(parsed, PNF.E164);
		}
	}

	public toDisplayFormat(phoneNumber: string) {
		if (!phoneNumber?.length) {
			return;
		}

		try {
			const parsed = this.phoneNumberUtil.parse(phoneNumber, this.getCountryCodeFromLocale());

			if (this.phoneNumberUtil.getRegionCodeForNumber(parsed) === 'US') {
				return this.phoneNumberUtil.format(parsed, PNF.NATIONAL);
			}
			else {
				return this.phoneNumberUtil.format(parsed, PNF.INTERNATIONAL);
			}
		}
		catch (e) {
			return phoneNumber;
		}
	}

	public getCountryCodeFromLocale(localeId?) {
		if (!localeId) {
			return this.getCountryCodeFromLocale(defaultLocale);
		}

		return localeId.split('-')[1];
	}

	postValidatePhoneNumber(phoneNumber: string): Promise<any> {
		return this.api.post('PostValidatePhoneNumber', { phoneNumber });
	}
}
