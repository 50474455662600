import { Component, OnInit } from '@angular/core';
import { SsoService } from '@app/providers/sso.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-sso-response',
	template: ''
})
export class SsoResponseComponent implements OnInit {
	constructor(private ssoService: SsoService,
		private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.ssoService.handleResponse(this.route.snapshot.params);
	}
}
