export class Credentials {
	userName: string;
	userNameAsBase64: string = '';
	password: string;
	passwordAsBase64: string = '';
	multifactorAuthPin: string;
	referrerSite: string;
	referrerSiteAsBase64: string = '';
	jwtToken: string;

	constructor(userName: string,
		password: string,
		multifactorAuthPin: string,
		referrerSite: string,
		jwtToken: string) {
		this.userName = userName;
		this.password = password;
		this.multifactorAuthPin = multifactorAuthPin;
		this.referrerSite = referrerSite;
		this.jwtToken = jwtToken;
	}
}
