import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseFormComponent } from '@app/components/base-form/base-form.component';

@Component({
	selector: 'app-confirmation-comment--modal',
	templateUrl: './confirmation-comment-modal.component.html',
	styleUrls: ['./confirmation-comment-modal.component.scss']
})
export class ConfirmationCommentModalComponent extends BaseFormComponent implements OnInit {
	@Input() header: string;
	@Input() body: string;
	@Input() confirmButtonText: string;
	@Input() confirmButtonClass: string;
	@Input() requireComment?: boolean = true;

	confirmationForm: UntypedFormGroup;
	comment: string = '';

	constructor(public activeModal: NgbActiveModal,
		private formBuilder: UntypedFormBuilder) {
		super();
		this.confirmButtonClass = this.confirmButtonClass ? this.confirmButtonClass : 'btn-primary';
	}

	ngOnInit() {
	}

	close() {
		if (!this.canSubmit(this.confirmationForm)) {
			return;
		}
		this.activeModal.close(this.comment);
	}

	public init(header: string, body: string, confirmButtonText: string, confirmButtonClass: string, requireComment?: boolean) {
		this.header = header;
		this.body = body;
		this.confirmButtonText = confirmButtonText;
		this.confirmButtonClass = confirmButtonClass;
		if (requireComment === false) {
			this.requireComment = requireComment;
		}
		this.confirmationForm = this.formBuilder.group({
			comment: [this.comment, this.requireComment === true ? Validators.required : null]
		});
	}
}
