import * as en from './en.json';
import * as es from './es.json';
import * as fr from './fr.json';
import * as pt from './pt.json';
import * as businessContinuity_en from './business-continuity/en.json';
import * as businessContinuity_es from './business-continuity/es.json';
import * as businessContinuity_fr from './business-continuity/fr.json';
import * as businessContinuity_pt from './business-continuity/pt.json';
import * as risk_en from './risk/en.json';
import * as risk_es from './risk/es.json';
import * as risk_fr from './risk/fr.json';
import * as risk_pt from './risk/pt.json';
import * as incidentManagement_en from './incident-management/en.json';
import * as incidentManagement_es from './incident-management/es.json';
import * as incidentManagement_fr from './incident-management/fr.json';
import * as incidentManagement_pt from './incident-management/pt.json';

import * as en_cre from './en.cre.json';
import * as es_cre from './es.cre.json';
import * as fr_cre from './fr.cre.json';
import * as pt_cre from './pt.cre.json';

export class Locales {
	getLocales(): LocalesModel {
		return {
			en: {
				...(en as any).default,
				...(businessContinuity_en as any).default,
				...(risk_en as any).default,
				...(incidentManagement_en as any).default
			},
			en_cre: (en_cre as any).default,
			es: {
				...(es as any).default,
				...(businessContinuity_es as any).default,
				...(incidentManagement_es as any).default,
				...(risk_es as any).default
			},
			es_cre: (es_cre as any).default,
			fr: {
				...(fr as any).default,
				...(businessContinuity_fr as any).default,
				...(incidentManagement_fr as any).default,
				...(risk_fr as any).default
			},
			fr_cre: (fr_cre as any).default,
			pt: {
				...(pt as any).default,
				...(businessContinuity_pt as any).default,
				...(incidentManagement_pt as any).default,
				...(risk_pt as any).default
			},
			pt_cre: (pt_cre as any).default
		};
	}
}

export class LocalesModel {
	en: any;
	en_cre: any;
	es: any;
	es_cre: any;
	fr: any;
	fr_cre: any;
	pt: any;
	pt_cre: any;
}
