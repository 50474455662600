export const environment = {
    production: false,
    id: 'prod',
    name: 'Production',
    baseApiUrl: 'https://prod-main-core.preparisapp.com/API/',
    baseLocalUrl: 'http://localhost:7057/v10/API/',
    identityServerBaseUrl: 'https://identity.preparisapp.com',
    identityServerAcsUrl: 'https://identity.preparisapp.com/identity/saml/{0}/Acs',
    ssoLoginUrl: 'https://www.preparisapp.com/sso/{0}',
    ssoApplicationUrl: 'https://sso.preparisapp.com',
    applicationId: '15871a25-1ab0-4c98-9a29-0f2e076c9adc',
    versionId: '39fd8b5d-5151-46d7-aca1-6c9f8f4cd153',
    googleMapsUrl: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCBy2L4EIXmz9kq6GOmFaDP5GfTh3P0rZk&libraries=drawing,places,geometry',
    productionMode: true,
    isDefault: true,
    endpoints: [
        {
            endpointName: 'selectendpoints',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/GetEndpoints'
        },
        {
            endpointName: 'getpasswordsecuritypolicy',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/getpasswordsecuritypolicy'
        },
        {
            endpointName: 'login',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/login'
        },
        {
            endpointName: 'getserviceconfig',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/getserviceconfig'
        },
        {
            endpointName: 'getentityresource',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/getentityresource'
        },
        {
            endpointName: 'gettrainingcourseurl',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/gettrainingcourseurl'
        },
        {
            endpointName: 'generateguids',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/GenerateGuids'
        },
        {
            endpointName: 'checktermsandconditions',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/checktermsandconditions'
        },
        {
            endpointName: 'requestresetpasswordtoken',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/requestresetpasswordtoken'
        },
        {
            endpointName: 'resetuserpassword',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/resetuserpassword'
        },
        {
            endpointName: 'sendportalslinks',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/sendportalsurls'
        },
        {
            endpointName: 'getcountries',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/getcountries'
        },
        {
            endpointName: 'getportalselfregistrationbycode',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/getportalselfregistrationbycode'
        },
        {
            endpointName: 'getportalselfregistrationbyportalid',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/getportalselfregistrationbyportalid'
        },
        {
            endpointName: 'isvalidusername',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/isvalidusername'
        },
        {
            endpointName: 'saveselfregisteruser',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/saveselfregisteruser'
        },
        {
            endpointName: 'gettermsandconditionsbyportal',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/gettermsandconditionsbyportal'
        },
        {
            endpointName: 'updateuseractivityflag',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/updateuseractivityflag'
        },
        {
            endpointName: 'getselfregisteruser',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/getselfregisteruser'
        },
        {
            endpointName: 'deleteselfregisteruser',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/deleteselfregisteruser'
        },
        {
            endpointName: 'getfeatureflagdefaultsettings',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/getfeatureflagdefaultsettings'
        },
        {
            endpointName: 'validaterecaptchatoken',
            endpointURL: 'https://prod-main-core.preparisapp.com/API/validaterecaptchatoken'
        }
    ],
    APPINSIGHTS_INSTRUMENTATIONKEY: 'f370bb20-8b9a-4648-9694-9e3bef68a4c9',
    launchDarklyClientSideId: '5e558d3de430bc07b38ebc93',
    openWeatherMapForecastUrl: 'https://api.openweathermap.org/data/2.5/forecast',
    adpIntegrationAuthorizationURLEndpoint: 'https://prod-sf.preparisapp.com/api/adp/authorization',
    useRawErrorMessage: false,
    isFullstoryDevMode: false,
    tinyApiKey: 'mgafa663xe1grxcxscmtkg894rrofigspkv76jte0qx2z9je',
    recaptchaV2SiteKey: '6Le_4ZgpAAAAAPz9oexj4rATFQTz50bEPIJQnxKT'
};
