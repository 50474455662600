import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { TermsAndConditionsService } from '@app/providers/terms-and-conditions.service';
import { defaultImageAddress, serviceErrorCodes, storageKeys, userActivityFlagTypes } from '@app/app.constants';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Utility } from '@app/providers/utility';
import { MfaDialogComponent } from '../mfa-dialog/mfa-dialog.component';
import { TacDialogComponent } from '../tac-dialog/tac-dialog.component';
import { UserService } from '@app/providers/user.service';
import { AuthService } from '@app/providers/auth.service';
import { TranslationService } from '@app/providers/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { SplashMessagePopupService } from '@app/providers/splash-message-popup.service';
import { AuthResultErrorModel, AuthResultModel } from '@app/shared/login/auth-result.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '@app/providers/session.service';
import { FeatureService } from '@app/providers/feature.service';
import { environment } from '@env/environment';
import { RecaptchaVerificationResponse } from '@app/shared/login/recaptcha.model';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	passwordVisibility: boolean;
	loginForm: UntypedFormGroup;
	sessionTimeoutWarning: boolean;
	changePasswordSuccess: boolean;
	forgotSubdomains: boolean;
	loading: boolean;
	authenticated: boolean;
	sso: boolean;
	id_token: string;
	username: string;
	password: string;
	hasAcceptedTerms: boolean;
	securityCode: string;
	referrerId: string;
	authToken: string;
	errorMessages: Array<string>;
	isSelfRegistered: boolean = false;
	loginFormSubmitted: boolean;
	captchaRequired: boolean = false;
	public defaultImageAddress = defaultImageAddress;
	public recaptchaV2SiteKey = environment.recaptchaV2SiteKey;
	private serviceErrorCodes = serviceErrorCodes;

	constructor(private formBuilder: UntypedFormBuilder,
		private localStorage: LocalStorageService,
		private termsAndConditions: TermsAndConditionsService,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		private toasterSvc: ToastrService,
		private utility: Utility,
		private translateSvc: TranslateService,
		private translationSvc: TranslationService,
		private userSvc: UserService,
		private authSvc: AuthService,
		private splashMessageSvc: SplashMessagePopupService,
		private sessionService: SessionService,
		private router: Router,
		private authService: AuthService) {
	}

	ngOnInit() {
		const isIdle = this.localStorage.get(storageKeys.idle);
		const test = this.translateSvc.instant('login.msgSessionTimeout');
		if (isIdle) {
			this.sessionTimeoutWarning = true;
			this.localStorage.remove(storageKeys.idle);
		}

		this.changePasswordSuccess = this.route.snapshot.queryParams.changePasswordSuccess;

		this.referrerId = window.location.hostname;

		this.id_token = this.route.snapshot.queryParams.id_token;
		this.sso = this.id_token != null;
		this.buildControls(!this.sso);

		if (this.sso) {
			// Go straight to login, since this is an SSO request
			// if SSO initiated from ADP, validate state
			if (this.route.snapshot.queryParams.iss === 'ADP') {
				const adpSSOState = this.localStorage.get(storageKeys.adpSSOState);
				this.localStorage.remove(storageKeys.adpSSOState);

				if (this.route.snapshot.queryParams.state !== adpSSOState) {
					const res = new AuthResultModel();
					res.valid = false;
					res.error = new AuthResultErrorModel();
					res.error.message = 'Invalid status for ADP SSO';
					res.error.userMessage = 'Unauthorized';
					this.onAuthRejected(res);
					return;
				}
			}
			this.onLogin();
		}
	}

	buildControls(isRequired: boolean) {
		this.loginForm = this.formBuilder.group({
			username: ['', isRequired ? Validators.required : null],
			password: ['', isRequired ? Validators.required : null]
		});
	}

	onForgotPassword() {
		this.router.navigate(['forgot-password', { username: this.username }]);
	}

	onLogin(): any {
		// clear all messages
		this.errorMessages = [];
		this.sessionTimeoutWarning = false;
		this.changePasswordSuccess = false;

		this.loginFormSubmitted = true;
		this.loading = true;
		this.authenticated = false;
		this.hasAcceptedTerms = false;
		this.changePasswordSuccess = null;
		this.utility.setCurrentPortalId(null);

		this.authSvc.authenticateUser(this.username, this.password, this.securityCode, this.referrerId, this.id_token)
			.then((authResult: any) => {
				this.onAuthFulfilled(authResult);
			})
			.catch((errorRes) => {
				if (!errorRes?.errorCodes?.length) {
					this.onAuthRejected(errorRes);
				}
				else {
					// look for passwordExpiration errorCode
					if (errorRes.errorCodes.find((errorCode: number) => errorCode === this.serviceErrorCodes.passwordExpiration)) {
						// pw has expired. take user to change their pw.
						this.router.navigate(['/change-password'], {
							queryParams: {
								passwordIsExpired: true,
								token: errorRes?.token
							}
						});
					}
				}
			})
			.then((_) => {
				this.loading = false;
			});
	}

	onAuthFulfilled(authResult: AuthResultModel) {
		if (!authResult.valid) {
			if (authResult.pendingMultifactorAuthentication) {
				// Display dialog to await the security code
				this.displayMFAModel(authResult.mfaPinExpirationMinutes);
				return;
			}

			this.loading = false;
			this.setErrorFromResult(authResult.error.statusCode);
			return;
		}

		this.isSelfRegistered = this.utility.isSelfRegisteredUser;
		if (this.sso) {
			this.localStorage.set('ssoAuthentication', true);
			this.localStorage.set('ssoLogoutRedirectUrl', authResult.ssoCustomerRedirectUrl);
		}

		// successfully authenticated
		this.authToken = this.localStorage.get(storageKeys.token);

		// reset security code to blank
		this.securityCode = '';

		// Set this as to hide login form.
		this.authenticated = true;

		if (this.isSelfRegistered) {
			this.redirectAfterLogin(false);
		}
		else {
			if (!authResult.agreementContent.userHasAcceptedTnc) {
				this.displayTACDialog(authResult.agreementContent.termsAndConditionsId, authResult.agreementContent.termsAndConditionsBody);
			}
			else {
				this.splashMessageSvc.processSplashMessages();

				this.redirectAfterLogin(false);
				this.hasAcceptedTerms = true;
			}
		}
	}

	onAuthRejected(response: AuthResultModel) {
		this.loading = false;

		if (response.error.statusCode === serviceErrorCodes.mustLoginUsingPortalSubdomain) {
			this.forgotSubdomains = true;
		}
		// reset security code to blank
		this.securityCode = '';
		// in the case sso is true set it to false since there was an error
		this.sso = false;
		this.setErrorFromResult(response.error.statusCode);
	}

	redirectAfterLogin(isInitialLogin) {
		this.userSvc.updateUserActivityFlag(userActivityFlagTypes.userLatestLogin, null, null);
		const lastLoggedInUser = this.localStorage.get(storageKeys.lastLoggedInUserId);
		this.localStorage.remove(storageKeys.lastLoggedInUserId);

		// reload ngx languages after getting verticalTypeId
		window.setTimeout(this.onRedirectAfterLoginTimeout.bind(this, isInitialLogin, lastLoggedInUser), 0);
	}

	onRedirectAfterLoginTimeout(isInitialLogin, lastLoggedInUser) {
		this.translationSvc.reloadLang();
		if (this.isSelfRegistered) {
			// self registered user, got to user account profile page
			this.sessionService.watch();
			this.router.navigate(['optin', this.utility.getRootPortalId(), 'profile']);
		}
		else {
			this.translationSvc.mergePortalTranslations(null)
				.then(this.onLangReloaded.bind(this, isInitialLogin, lastLoggedInUser));
		}
	}

	onLangReloaded(isInitialLogin, lastLoggedInUser) {
		// redirect after resources in ngx are reloaded
		if (isInitialLogin) {
			if (this.utility.hasBusinessContinuityAssignments) {
				this.router.navigate(['/home'], { queryParams: { isInitialLogin: isInitialLogin } });
			}
			else {
				this.router.navigate(['/profile'], { queryParams: { isInitialLogin: isInitialLogin } });
			}
			return;
		}

		const originalDestination: any = this.localStorage.get(storageKeys.originalDestination);
		if (originalDestination) {
			this.localStorage.remove(storageKeys.originalDestination);
			if (lastLoggedInUser === this.utility.getUserId() || !!this.utility.getUserId()) {
				// the way originalDestination is set, it's already a fully qualified url with all the params built into, so just navigateByUrl. if you navigate by state name, it will break
				this.router.navigateByUrl(originalDestination.name);
			}
			else {
				this.router.navigateByUrl('/home');
			}
		}
		else {
			this.router.navigateByUrl('/home');
		}
	}

	sendPortalsUrls() {
		const toastrConfig = this.utility.createTranslatedToastrMessageConfig('login.msgRequestSendPortalsLinksSuccess', 'login.msgRequestSendPortalsLinksFailed', null);
		this.authSvc.sendPortalsUrls(this.username)
			.then((_) => {
				this.toasterSvc.success(toastrConfig.successMsg);
			})
			.catch((_) => {
				this.toasterSvc.error(toastrConfig.errorMsg);
			})
			.then();
	}

	displayMFAModel(mfaPinExpirationMinutes: number) {
		const modalOptions: NgbModalOptions = {};
		modalOptions.backdrop = 'static';
		modalOptions.keyboard = false;
		const modalInstance = this.modalService.open(MfaDialogComponent, modalOptions);
		modalInstance.componentInstance.mfaPinExpirationMinutes = mfaPinExpirationMinutes;

		modalInstance.result
			.then((response) => {
				this.securityCode = response.mfaSecurityCode;
				// Capture security code, try to login again
				this.onLogin();
			})
			.catch((_) => {
				this.setErrorFromResult('msgMFAFailed');
			})
			.then();
	}

	displayTACDialog(termsAndConditionsId: string, termsAndConditionsBody: string) {
		const modalOptions: NgbModalOptions = {};
		modalOptions.size = 'lg';
		modalOptions.backdrop = 'static';
		modalOptions.keyboard = false;
		modalOptions.windowClass = 'termsAndConditionsModal';
		const modalInstance = this.modalService.open(TacDialogComponent, modalOptions);

		modalInstance.componentInstance.termsAndConditions = termsAndConditionsBody;

		modalInstance.result
			.then((response) => {
				if (!response.accepted) {
					this.setErrorFromResult('tacDeclined');
					this.authenticated = false;
					this.authSvc.logout();
					return;
				}
				this.termsAndConditions.acceptTermsAndConditions(termsAndConditionsId)
					.then((response) => {
						if (response) {
							this.termsAndConditions.setStatus(true);

							// Update t&c's accepted activity
							this.userSvc.updateUserActivityFlag(userActivityFlagTypes.termsAccepted, null, null);
							this.hasAcceptedTerms = true;

							// If the user has to accept Terms and Conditions, it is user's first time logging in - show initial splash message
							this.splashMessageSvc.processSplashMessages()
								.then((_) => {
									this.redirectAfterLogin(true);
								})
								.catch(); // ignore error?
						}
						else {
							const error = {
								field: undefined,
								error: {
									message: 'Something went wrong when signing T&C agreement.',
									userMessage: 'System Error. Please try again later.'
								}
							};
							// Logger.error(error.message);
							this.setErrorFromResult(error);
							this.authSvc.logout();
						}
					});
			})
			.catch((error) => {
				// error === 1 if esc key pressed on the tac dialog
				this.setErrorFromResult(error === 1 ? 'tacDeclined' : 'msgTACFailed');

				this.authenticated = false;
				this.authSvc.logout();
				return;
			})
			.then();
	}

	setErrorFromResult(errorMessage) {
		this.errorMessages = [];
		let error = '';
		switch (errorMessage) {
			case serviceErrorCodes.lockedOut:
			case serviceErrorCodes.authenticationPinInvalid:
			case serviceErrorCodes.mfaUserDoesNotHavePrimaryPhone:
			case 'tacDeclined':
				error = errorMessage;
				break;
			case serviceErrorCodes.portalUrlOrSso:
				error = '1005';
				this.forgotSubdomains = true;
				break;
			case serviceErrorCodes.mustLoginUsingPortalSubdomain:
				error = '1005';
				this.forgotSubdomains = true;
				break;
			case serviceErrorCodes.maxLoginAttemptsCaptchaRequired:
				error = '1005';
				this.captchaRequired = true;
				break;
			default:
				error = '1005';
				break;
		}

		this.errorMessages.push(
			this.translateSvc.instant(`login.errors.${error}`)
		);
	}

	showHidePassword() {
		this.passwordVisibility = !this.passwordVisibility;
	}

	support() {
		this.authService.support();
	}

	handleResolvedCaptcha(token: string) {
		if (token) {
			this.authService.validateRecaptcha(token)
				.then((res: RecaptchaVerificationResponse) => {
					if (res.Success) {
						this.captchaRequired = false;
					}
				});
		}
	}
}
