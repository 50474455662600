<div class="resetPasswordWrapper">
    <form class="content" [formGroup]="requestResetPasswordTokenForm"
          *ngIf="!requestResetPasswordResetTokenSuccess">
        <img [src]="defaultImageAddress.login.logo" class="img-responsive agility-primary-logo"
             alt="Preparis Logo">
        <h4>{{ 'login.lblResetPassword' | translate }}</h4>
        <p>{{ 'login.msgResetPasswordInstructions' | translate }}</p>
        <app-form-error-messages [submitted]="requestResetPasswordTokenFormSubmitted"
                                 [customErrorMessages]="errorMessages">
        </app-form-error-messages>
        <div class="mb-3 has-feedback has-feedback-left">
            <div class="input-group">
                <div class="input-group-text"><i class="fa fa-envelope-o"></i></div>
                <input id="userName"
                       name="userName"
                       formControlName="userName"
                       type="email"
                       email="true"
                       class="form-control"
                       [(ngModel)]="userName"
                       required
                       placeholder="{{ 'login.phUsername' | translate }}"/>
            </div>
        </div>
        <button id="requestResetPasswordResetTokenButton" class="btn btn-primary btn-primary" [disabled]="loading"
                (click)="requestResetPasswordResetToken()">
            <span *ngIf="loading">
                    <i class="fa fa-spinner fa-spin"
                       *ngIf="loading">
                    </i>
                </span>
            <span *ngIf="!loading">{{ 'login.btnRequestPasswordReset' | translate }}</span>
        </button>
        <a routerLink="/login" class="btn btn-secondary">{{ 'common.cancel' | translate }}</a>
    </form>
    <div class="content" *ngIf="requestResetPasswordResetTokenSuccess">
        <img [src]="defaultImageAddress.login.logo" class="img-responsive agility-primary-logo"
             alt="Preparis Logo">
        <h4>{{ 'login.lblResetPassword' | translate }}</h4>
        <p>{{ 'login.msgForgotPassword1' | translate }}</p>
        <p>{{ 'login.msgForgotPassword2' | translate }} <b>
            <a
                (click)="requestResetPasswordResetTokenSuccess = false">{{ 'login.msgForgotPassword3' | translate}}</a></b>
            {{ 'login.msgForgotPassword4' | translate }}</p>
        <p><a routerLink="/login">&laquo; {{ 'login.btnBackToLogin' | translate }}</a></p>
    </div>
</div>
