import { Injectable } from '@angular/core';
import { ApiService } from '@app/providers/api.service';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { storageKeys } from '@app/app.constants';

@Injectable({
	providedIn: 'root'
})
export class TermsAndConditionsService {
	constructor(private api: ApiService,
		private storageSvc: LocalStorageService) {
	}

	setStatus(status) {
		this.storageSvc.set(storageKeys.termsAndConditionsAccepted, status);
	}

	getStatus() {
		return this.storageSvc.get(storageKeys.termsAndConditionsAccepted);
	}

	acceptTermsAndConditions(termsAndConditionsId) {
		return this.api.post('signtermsandconditions', { termsAndConditionsId: termsAndConditionsId });
	}

	public getTermsAndConditionsByPortal(portalId: string) {
		return this.api.post('gettermsandconditionsbyportal', { portalId: portalId });
	}
}
