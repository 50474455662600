import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { ApiService } from '@app/providers/api.service';
import { Utility } from '@app/providers/utility';
import { GetUsersRequest } from '@app/shared/get-users-request.model';
import { addressTypes, entityTypeIds, storageKeys, userEmailAddressTypeIds, userPhoneNumberSubTypeIds, userPhoneNumberTypeIds, userStateTypes } from '@app/app.constants';
import { PermissionService } from '@app/providers/permission.service';
import { entityResourceTypes } from '@app/app.enum';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	constructor(public storage: LocalStorageService,
		public api: ApiService,
		private utility: Utility,
		private permissionService: PermissionService) {
	}

	getUsers(request?: GetUsersRequest) {
		request = request || <GetUsersRequest>{};
		if (request.searchPhrase) {
			Object.assign(request, {
				searchPhraseAsBase64: this.utility.base64Encode(request.searchPhrase)
			});
		}
		return this.api.post('getlistusersstatusrolesentitieslightweight', request);
	}

	getCurrentUserId(): string {
		return this.storage.get(storageKeys.userId) as string;
	}

	getCurrentUsername(): string {
		return this.storage.get(storageKeys.userName);
	}

	getUserAssignments(portalId: string, userId: string) {
		const request = {
			portalId: portalId,
			userId: userId,
			canOverrideUserId: true
		};
		return this.api.post('getuserassignments', request);
	}

	getComplianceCalendarEvents(portalId: string, scopeId: string, teamId: string, complianceCategoryId: string, startDate: string, endDate: string, userAssignmentsOnly: boolean) {
		const request = {
			portalId: portalId,
			scopeId: scopeId,
			teamId: teamId,
			complianceCategoryId: complianceCategoryId,
			startDate: startDate,
			endDate: endDate,
			userAssignmentsOnly: userAssignmentsOnly
		};
		return this.api.post('getcompliancecalendarevents', request);
	}

	getUserRoles(selectedUserId: string) {
		const request = {
			selectedUserId: selectedUserId
		};
		return this.api.post('getuserroles', request);
	}

	getUserAssignmentTypes() {
		return this.api.post('getuserassignmenttypes', {});
	}

	getUserProfile(userId) {
		return this.api.post('getuserprofile', { assignedUserId: userId });
	}

	searchForUsers(searchString?: string, searchUserId?: string, isPersonalNumberIncluded?: boolean, userIds?: Array<string>) {
		return this.api.post('searchforusers', {
			searchStringAsBase64: this.utility.base64Encode(searchString),
			searchUserId: searchUserId,
			includePersonal: isPersonalNumberIncluded,
			userIds: userIds
		});
	}

	searchForMessageUsers(searchString?: string, searchUserId?: string, isPersonalNumberIncluded?: boolean) {
		return this.api.post('searchformessageusers', {
			searchStringAsBase64: this.utility.base64Encode(searchString),
			searchUserId: searchUserId,
			includePersonal: isPersonalNumberIncluded
		});
	}

	searchActionPlanMessageUsers(userIds: Array<string>) {
		return this.api.post('searchActionPlanMessageUsers', {
			userIds: userIds
		});
	}

	getUserProfileImage(userId) {
		return this.api.post('getuserprofileimage', { assignedUserId: userId });
	}

	getCountUsersByStatus() {
		return this.api.post('countusersbystatus', {});
	}

	sendPendingUsersInvitation(userIds: Array<string>) {
		return this.api.post('sendpendingusersinvitation', { userIds: userIds });
	}

	sendUserInvitation(userId: string) {
		return this.api.post('senduserinvitation', { inviteUserId: userId });
	}

	exportUsersCsvFile(userIds?: Array<string>) {
		return this.api.post('exportuserscsvfile', {
			userIds: userIds
		}, true);
	}

	exportHeadersForUsersCsvFile() {
		return this.api.post('exportheadersforuserscsvfile', {}, true);
	}

	uploadBulkUserFile(fileAsBase64: any, fileNameAsBase64: string) {
		const request = {
			csvFileNameAsBase64: fileNameAsBase64,
			csvFileAsBase64: fileAsBase64
		};
		return this.api.post('uploadbulkuserfile', request);
	}

	getUserBulkUploadList(startDate: any, endDate: any) {
		const request = {
			startDate: startDate,
			endDate: endDate
		};
		return this.api.post('getuserbulkuploadlist', request);
	}

	downloadUserBulkuploadFile(processingId: string, fileType: any) {
		const request = {
			processingId: processingId,
			fileType: fileType
		};
		return this.api.post('downloaduserbulkuploadfile', request, true);
	}

	getUserBookmarks() {
		return this.api.post('getuserbookmarks', {});
	}

	addBookmark(document: any) {
		return this.api.post('addbookmark', { item: document });
	}

	updateBookmark(document: any) {
		return this.api.post('updatebookmark', { item: document });
	}

	deleteBookmark(bookmarkId: string, entityId: string) {
		return this.api.post('deletebookmark', {
			item: {
				documentBookmarkId: bookmarkId,
				entityId: entityId
			}
		});
	}

	getUserPrimaryLocationId(): string {
		return this.storage.get(storageKeys.primaryLocationId) as string;
	}

	getUsersAddress(locationId: string) {
		return this.api.post('getusersaddress', { locationId: locationId });
	}

	updateUserActivityFlag(flagTypeId: string, userId: string, flagId) {
		const request = {
			item: {
				activityFlagId: flagId,
				userId: (userId) ? userId : this.utility.getUserId(),
				flagValue: true,
				activityFlagTypeId: flagTypeId
			}
		};
		return this.api.post('updateuseractivityflag', request);
	}

	setUserRights(userRights) {
		this.storage.set(storageKeys.userRights, userRights);
	}

	getUserStatusCountsByEntity() {
		return this.api.post('getuserstatuscountsbyentity', {});
	}

	getHeaderInfo() {
		const request: any = {};

		request.generateGuidsRequest = {};
		request.generateGuidsRequest.amount = 500;
		request.getEntityResourceRequests = [
			{
				entityId: this.utility.getCurrentPortalId(),
				entityResourceTypeId: entityResourceTypes.portalLogo
			},
			{
				entityId: this.utility.getCurrentPortalId(),
				entityResourceTypeId: entityResourceTypes.portalFooterLogo
			},
			{
				entityId: this.utility.getCurrentPortalId(),
				entityResourceTypeId: entityResourceTypes.portalFooterPoweredByLogo
			}
		];
		request.getContentRequests = [{
			viewTypeNameAsBase64: this.utility.base64Encode('ResourcesLandingNew')
		}, {
			viewTypeNameAsBase64: this.utility.base64Encode('FeaturedResources')
		}];

		return this.api.post('getindexpageinfo', request);
	}

	getUserProfileSettings() {
		return this.api.post('getuserprofilesettings', {});
	}

	getListUsersStatusRolesEntities(userDetailId: string) {
		return this.api.post('getlistusersstatusrolesentities', { userDetailId: userDetailId });
	}

	uploadUserProfilePic(imageAsBase64: string, userId: string) {
		const request = {
			userPicAsBase64: imageAsBase64,
			assignedUserId: userId
		};
		return this.api.post('uploaduserprofilepic', request);
	}

	saveUser(user: any, profileOnly: boolean) {
		return new Promise((resolve, reject) => {
			const instance = this;
			const promises = [];
			const isNewUser: boolean = !(user.userId);
			if (isNewUser) {
				user.userId = this.utility.getGuid();
			}

			// Save User, first
			const uuRequest = {
				updatedUserId: user.userId,
				userStateId: user.userStateId,
				userNameAsBase64: this.utility.base64Encode(user.userName),
				lat: user.lat,
				lng: user.lng,
				externalSystemId: user.externalSystemId,
				timeZoneId: user.timeZoneId,
				isSelfRegistered: user.isSelfRegistered,
				managerUserId: user.managerUserId
			};

			this.api.post('updateuser', uuRequest).then((_) => {
				if (!user.userWorkPhoneId || user.userWorkPhoneId.length === 0) {
					user.userWorkPhoneId = instance.utility.getGuid();
				}

				if (!profileOnly) {
					// Save User Role
					const rbRequest = {
						portalId: this.utility.getCurrentPortalId(),
						userDetailId: user.userId,
						roleBundleIds: [
							user.role.roleBundleId
						]
					};
					promises.push(this.api.post('setuserrolebundles', rbRequest));
				}

				// Update work phone
				const workPhoneUpdateRequest = {
					phoneNumber: {
						userPhoneId: (user.userWorkPhoneId) ? user.userWorkPhoneId : instance.utility.getGuid(),
						userId: user.userId,
						phoneNumberTypeId: userPhoneNumberTypeIds.office,
						phoneNumberSubTypeId: userPhoneNumberSubTypeIds.standardUS,
						phoneNumberAsBase64: this.utility.base64Encode(user.workPhoneNumber),
						extensionAsBase64: this.utility.base64Encode(user.workPhoneExtension),
						optOutSms: !user.communicationPreferences.workPhoneOptInSms,
						optOutVoice: !user.communicationPreferences.workPhoneOptInVoice
					}
				};
				promises.push(instance.api.post('updateuserphonenumber', workPhoneUpdateRequest));

				// Update mobile phone
				if (!user.userMobilePhoneId || user.userMobilePhoneId.length === 0) {
					user.userMobilePhoneId = instance.utility.getGuid();
				}
				const mobilePhoneUpdateRequest = {
					phoneNumber: {
						userPhoneId: (user.userMobilePhoneId) ? user.userMobilePhoneId : instance.utility.getGuid(),
						userId: user.userId,
						phoneNumberTypeId: userPhoneNumberTypeIds.mobile,
						phoneNumberSubTypeId: userPhoneNumberSubTypeIds.standardUS,
						phoneNumberAsBase64: this.utility.base64Encode(user.mobilePhoneNumber),
						optOutSms: !user.communicationPreferences.mobilePhoneOptInSms,
						optOutVoice: !user.communicationPreferences.mobilePhoneOptInVoice
					}
				};
				promises.push(instance.api.post('updateuserphonenumber', mobilePhoneUpdateRequest));

				// Update personal phone
				if (!user.userPersonalPhoneId || user.userPersonalPhoneId.length === 0) {
					user.userPersonalPhoneId = instance.utility.getGuid();
				}
				const personalPhoneUpdateRequest = {
					phoneNumber: {
						userPhoneId: (user.userPersonalPhoneId) ? user.userPersonalPhoneId : instance.utility.getGuid(),
						userId: user.userId,
						phoneNumberTypeId: userPhoneNumberTypeIds.personal,
						phoneNumberSubTypeId: userPhoneNumberSubTypeIds.standardUS,
						phoneNumberAsBase64: this.utility.base64Encode(user.personalPhoneNumber),
						optOutSms: !user.communicationPreferences.personalPhoneOptInSms,
						optOutVoice: !user.communicationPreferences.personalPhoneOptInVoice
					}
				};
				promises.push(instance.api.post('updateuserphonenumber', personalPhoneUpdateRequest));

				// Update additional personal phone
				if (!user.userAdditionalPersonalPhoneId || user.userAdditionalPersonalPhoneId.length === 0) {
					user.userAdditionalPersonalPhoneId = instance.utility.getGuid();
				}
				const additionalPersonalPhoneUpdateRequest = {
					phoneNumber: {
						userPhoneId: (user.userAdditionalPersonalPhoneId) ? user.userAdditionalPersonalPhoneId : instance.utility.getGuid(),
						userId: user.userId,
						phoneNumberTypeId: userPhoneNumberTypeIds.additionalPersonal,
						phoneNumberSubTypeId: userPhoneNumberSubTypeIds.standardUS,
						phoneNumberAsBase64: this.utility.base64Encode(user.additionalPersonalPhoneNumber),
						optOutSms: !user.communicationPreferences.additionalPersonalPhoneOptInSms,
						optOutVoice: !user.communicationPreferences.additionalPersonalPhoneOptInVoice
					}
				};
				promises.push(instance.api.post('updateuserphoneNumber', additionalPersonalPhoneUpdateRequest));

				// Update Personal Email
				user.userPersonalEmailId = (user.userPersonalEmailId) ? user.userPersonalEmailId : this.utility.getGuid();
				const personalEmailUpdateRequest = {
					email: {
						userEMailId: user.userPersonalEmailId,
						userId: user.userId,
						emailTypeId: userEmailAddressTypeIds.personal,
						emailAddressAsBase64: this.utility.base64Encode(user.personalEmail),
						optOutEmail: !user.communicationPreferences.personalOptInEmail
					}
				};
				promises.push(instance.api.post('updateuseremail', personalEmailUpdateRequest));

				// Update Business Email
				user.businessEmailId = (user.businessEmailId) ? user.businessEmailId : this.utility.getGuid();
				const businessEmailUpdateRequest = {
					email: {
						userEMailId: user.businessEmailId,
						userId: user.userId,
						emailTypeId: userEmailAddressTypeIds.business,
						emailAddressAsBase64: this.utility.base64Encode(user.businessEmail),
						optOutEmail: !user.communicationPreferences.businessOptInEmail
					}
				};
				promises.push(instance.api.post('updateuseremail', businessEmailUpdateRequest));

				// Update User Profile
				if (!user.userProfileId || user.userProfileId.length === 0) {
					user.userProfileId = this.utility.getGuid();
				}
				const upRequest = {
					updatedUserId: user.userId,
					userProfileId: user.userProfileId,
					userStringLanguageId: user.userStringLanguageId,
					firstNameAsBase64: this.utility.base64Encode(user.firstName),
					lastNameAsBase64: this.utility.base64Encode(user.lastName),
					pinAsBase64: this.utility.base64Encode(user.pin),
					titleAsBase64: this.utility.base64Encode(user.title),
					floorAsBase64: this.utility.base64Encode(user.floors)
				};
				promises.push(this.api.post('updateuserprofile', upRequest));

				// Save Selected User Locations
				const locRequest = {
					parentEntityId: this.utility.getCurrentPortalId(),
					assignedUserId: user.userId,
					entityTypeId: entityTypeIds.location,
					entityUsersItems: []
				};
				user.locations.forEach((item: any) => {
					locRequest.entityUsersItems.push({
						entityUserItemId: this.utility.getGuid(),
						entityId: item.entityId,
						IsPrimaryRelationship: item.IsPrimaryRelationship,
						CreatedById: this.utility.getUserId(),
						LastModifiedById: this.utility.getUserId()
					});
				});
				promises.push(this.api.post('deleteandupdateentityuserbytype', locRequest));

				if (!profileOnly) {
					// Save Selected User Groups
					const grpRequest = {
						parentEntityId: this.utility.getCurrentPortalId(),
						assignedUserId: user.userId,
						entityTypeId: entityTypeIds.group,
						entityUsersItems: []
					};
					user.groups.forEach((item: any) => {
						grpRequest.entityUsersItems.push({
							entityUserItemId: this.utility.getGuid(),
							entityId: item.entityId,
							IsPrimaryRelationship: false,
							CreatedById: this.utility.getUserId(),
							LastModifiedById: this.utility.getUserId()
						});
					});
					promises.push(this.api.post('deleteandupdateentityuserbytype', grpRequest));

					// Save Selected User Departments
					const deptRequest = {
						parentEntityId: this.utility.getCurrentPortalId(),
						assignedUserId: user.userId,
						entityTypeId: entityTypeIds.department,
						entityUsersItems: []
					};
					user.depts.forEach((item: any) => {
						deptRequest.entityUsersItems.push({
							entityUserItemId: this.utility.getGuid(),
							entityId: item.entityId,
							IsPrimaryRelationship: false,
							CreatedById: this.utility.getUserId(),
							LastModifiedById: this.utility.getUserId()
						});
					});
					promises.push(this.api.post('deleteandupdateentityuserbytype', deptRequest));

					// Save Selected User Departments
					const tenantRequest = {
						parentEntityId: this.utility.getCurrentPortalId(),
						assignedUserId: user.userId,
						entityTypeId: entityTypeIds.tenant,
						entityUsersItems: []
					};
					user.tenants.forEach((item: any) => {
						tenantRequest.entityUsersItems.push({
							entityUserItemId: this.utility.getGuid(),
							entityId: item.entityId,
							IsPrimaryRelationship: item.IsPrimaryRelationship,
							CreatedById: this.utility.getUserId(),
							LastModifiedById: this.utility.getUserId()
						});
					});
					promises.push(this.api.post('deleteandupdateentityuserbytype', tenantRequest));
				}

				// Update user address
				const userFullAddress = (user.addressMainAddress1 || '')
				  + ((user.addressMainCity) ? ' ' + user.addressMainCity : '')
				  + ((user.addressMainState) ? ', ' + user.addressMainState : '')
				  + ((user.addressMainZip) ? ' ' + user.addressMainZip : '')
				  + ((user.addressMainCountry) ? ', ' + user.addressMainCountry : '');

				if (userFullAddress !== null && userFullAddress !== '') {
					if (!user.addressMainId || user.addressMainId.length === 0) {
						user.addressMainId = this.utility.getGuid();
					}
					if (!user.addressMainTypeId || user.addressMainTypeId.length === 0) {
						user.addressMainTypeId = addressTypes.main;
					}
					const addressRequest = {
						address: {
							userAddressId: user.addressMainId,
							userId: user.userId,
							userAddressTypeId: user.addressMainTypeId,
							countryTypeId: user.addressMainCountryTypeId,
							address1AsBase64: this.utility.base64Encode(user.addressMainAddress1),
							address2AsBase64: this.utility.base64Encode(user.addressMainAddress2),
							cityAsBase64: this.utility.base64Encode(user.addressMainCity),
							stateAsBase64: this.utility.base64Encode(user.addressMainState),
							zipAsBase64: this.utility.base64Encode(user.addressMainZip),
							lat: user.addressMainLat,
							lng: user.addressMainLng
						}
					};
					promises.push(this.api.post('updateuseraddress', addressRequest));
				}
				else {
					if (user.addressMainId !== null) {
						promises.push(this.api.post('deleteuseraddress', { priamryKey: user.addressMainId }));
					}
				}
				Promise.all(promises).then((requests?: any) => {
					// fire and forget
					this.api.post('updateuserintegration', {
						userId: user.userId,
						isEssentialContact: user.isEssentialContact,
						canOverrideUserId: true
					})
						.then()
						.catch()
						.then(_ => resolve(true));

					resolve(requests);
				}).catch((requests: any) => {
					reject(requests);
				});
			}).catch((err: any) => {
				reject(err);
			});
		});
	}

	deleteUser(user: any) {
		return new Promise((resolve, reject) => {
			const promises = [];

			const uuRequest = {
				updatedUserId: user.userId,
				userNameAsBase64: this.utility.base64Encode(user.userName),
				userStateId: userStateTypes.Deleted,
				lat: user.lat,
				lng: user.lng,
				externalSystemId: user.externalSystemId,
				timeZoneId: user.timeZoneId
			};
			this.api.post('updateuser', uuRequest).then((result: any) => {
				// Delete all entity relationships

				// Leave user's location info intact to enable the report of deleted users with enhanced security

				// Delete User Groups
				const grpRequest = {
					parentEntityId: this.utility.getCurrentPortalId(),
					assignedUserId: user.userId,
					entityTypeId: entityTypeIds.group,
					entityUsersItems: []
				};
				promises.push(this.api.post('deleteandupdateentityuserbytype', grpRequest));

				// Delete User Departments
				const deptRequest = {
					parentEntityId: this.utility.getCurrentPortalId(),
					assignedUserId: user.userId,
					entityTypeId: entityTypeIds.department,
					entityUsersItems: []
				};
				promises.push(this.api.post('deleteandupdateentityuserbytype', deptRequest));

				// Delete User Tenants
				const tenantRequest = {
					parentEntityId: this.utility.getCurrentPortalId(),
					assignedUserId: user.userId,
					entityTypeId: entityTypeIds.tenant,
					entityUsersItems: []
				};
				promises.push(this.api.post('deleteandupdateentityuserbytype', tenantRequest));

				Promise.all(promises).then((result: any) => {
					resolve(result);
				}).catch((errorResult: any) => {
					reject(errorResult);
				});
			}).catch((errorResult: any) => {
				reject(errorResult);
			});
		});
	}

	getPasswordSecurityPolicy(userId: string, canOverrideUserId: boolean) {
		const request = {
			userId: userId,
			CanOverrideUserId: !!canOverrideUserId,
			entityId: this.utility.getCurrentPortalId()
		};

		return this.api.post('getpasswordsecuritypolicy', request);
	}

	changeUserPassword(userId: string, userName: string, newPassword: string, canOverrideUserId: boolean) {
		const request = {
			passwordAsBase64: this.utility.base64Encode(newPassword),
			userNameAsBase64: this.utility.base64Encode(userName),
			userId: userId,
			CanOverrideUserId: !!canOverrideUserId
		};

		return this.api.post('changeuserpassword', request);
	}

	unlockUser(userId: string) {
		return this.api.post('unlockuser', { unlockUserId: userId });
	}

	updateUserLanguage(userId: string, languageId: string) {
		return this.api.post('UpdateUserLanguage', { UpdateUserId: userId, languageId: languageId });
	}
}
