export class ChangePasswordRequestModel {
	newPassword: string;
	newPasswordAsBase64: string = '';
	authorizationToken: string;

	constructor(newPassword: string, authorizationToken: string) {
		this.newPassword = newPassword;
		this.authorizationToken = authorizationToken;
	}
}
