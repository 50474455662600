import { Injectable } from '@angular/core';
import { EndpointService } from '@app/providers/endpoint.service';
import { Utility } from '@app/providers/utility';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { ApiService } from '@app/providers/api.service';
import { Credentials } from '@app/shared/login/credentials.model';
import { SessionService } from '@app/providers/session.service';
import { UserService } from '@app/providers/user.service';
import { SsoService } from '@app/providers/sso.service';
import { PermissionService } from '@app/providers/permission.service';
import { ResetPasswordRequestModel } from '@app/shared/login/reset-password-request.model';
import { ChangePasswordRequestModel } from '@app/shared/login/change-password-request.model';
import { AuthResultAgreementContentModel, AuthResultModel } from '@app/shared/login/auth-result.model';
import { Subject } from 'rxjs';
import { FeatureService } from '@app/providers/feature.service';
import { storageKeys } from '@app/app.constants';
import { environment } from '@env/environment';
import { FullstoryService } from '@app/providers/fulllstory/fullstory.service';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SupportModalComponent } from '@app/components/modals/support-modal/support-modal.component';
import { RecaptchaVerificationResponse, ValidateRecaptchaRequest } from '@app/shared/login/recaptcha.model';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private clearCachedConstantsSubject = new Subject<string>();

	constructor(private endpointSvc: EndpointService,
		private utility: Utility,
		private localStorageSvc: LocalStorageService,
		private apiSvc: ApiService,
		private sessionSvc: SessionService,
		private userSvc: UserService,
		private permissionSvc: PermissionService,
		private ssoSvc: SsoService,
		private featureService: FeatureService,
		private fullstoryService: FullstoryService,
		private businessContinuityService: BusinessContinuityService,
		private router: Router,
		private modalService: NgbModal,
		private translateService: TranslateService) {
	}

	onClearCachedConstants() {
		return this.clearCachedConstantsSubject.asObservable();
	}

	logout() {
		this.sessionSvc.unwatch();
		const originalDestination = this.localStorageSvc.get(storageKeys.originalDestination);
		const lastLoggedInUserId = (this.localStorageSvc.get(storageKeys.lastLoggedInUserId)) ? this.localStorageSvc.get(storageKeys.lastLoggedInUserId) : this.utility.getUserId();
		const isIdle = this.localStorageSvc.get(storageKeys.idle);
		const isLoggedInViaSSO = this.localStorageSvc.get(storageKeys.ssoAuthentication);
		const preferredLanguage = this.localStorageSvc.get((storageKeys.preferredLanguage));

		// keep table settings - don't wipe it out from storage
		const tableSettingsKeys = this.localStorageSvc.keys().filter(x => x.indexOf('Table') > -1);
		const tableSettings: Array<{
			tableName: string
			setting: string
		}> = [];
		if (tableSettingsKeys) {
			tableSettingsKeys.forEach((key: string) => {
				tableSettings.push({ tableName: key, setting: this.localStorageSvc.get(key) });
			});
		}

		// NOTE: do this BEFORE this.clearApplicationData() below
		this.logOff();

		// clearing out the local storage, and then setting the essentails back
		this.clearApplicationData();
		if (originalDestination) {
			this.localStorageSvc.set(storageKeys.originalDestination, originalDestination);
		}
		if (lastLoggedInUserId) {
			this.localStorageSvc.set(storageKeys.lastLoggedInUserId, lastLoggedInUserId);
		}
		if (isIdle) {
			this.localStorageSvc.set(storageKeys.idle, isIdle);
		}
		if (preferredLanguage) {
			this.localStorageSvc.set(storageKeys.preferredLanguage, preferredLanguage);
		}
		if (tableSettings) {
			tableSettings.forEach((setting: any) => {
				this.localStorageSvc.set(setting.tableName, setting.setting);
			});
		}

		// SSO Signout, if necessary
		if (isLoggedInViaSSO) {
			this.ssoSvc.signOut();
		}

		// reinitialize launchdarkly w/ anonomyous user
		this.featureService.logOutUser();

		// ultimately redirect to login page
		this.router.navigate(['login']);
	}

	initEndpoints() {
		this.endpointSvc.init();
	}

	clearApplicationData() {
		// Reset all cached information to prevent issues when user login with different credentials
		this.clearCachedConstantsSubject.next();
		this.localStorageSvc.clear();
	}

	isAuthenticated(): boolean {
		let isAuth: boolean = false;
		if (this.localStorageSvc.get(storageKeys.token) && this.sessionSvc.isRunning()) {
			isAuth = true;
		}
		return isAuth;
	}

	validateToken() {
		if (!this.localStorageSvc.get(storageKeys.token)) {
			return Promise.resolve(false);
		}
		else {
			return this.apiSvc.post('checktokennottouch', {})
				.then(function (result: any) {
					return result.statusCode === 0 && result.errorMessage === 'OK';
				}).catch((error: any) => {
					return false;
				});
		}
	}

	authenticateUser(username, password, securityCode, referrerId, id_token): Promise<any> {
		const credentials: Credentials = new Credentials(
			username,
			password,
			securityCode,
			referrerId,
			id_token);

		return this.apiSvc.post('login', credentials)
			.then((authResponse: any) => {
				if (!authResponse.errorCodes?.length) {
					// no errors, proceed with login
					return Promise.resolve(this.onAuthFulfilled(authResponse));
				}
				else {
					// there are errorCodes. reject promise.
					return Promise.reject(authResponse);
				}
			})
			.catch((errorRes: any) => {
				if (!errorRes?.errorCodes?.length) {
					// parse the error object like we used to. other error. not an error thrown.
					return Promise.reject(this.onAuthRejected(errorRes));
				}
				else {
					// reject with the object sent to server
					return Promise.reject(errorRes);
				}
			});
	}

	validateRecaptcha(token: string): Promise<RecaptchaVerificationResponse> {
		const request = new ValidateRecaptchaRequest();
		request.recaptchaToken = token;

		return this.apiSvc.post('validaterecaptchatoken', request) as Promise<RecaptchaVerificationResponse>;
	}

	onAuthFulfilled(authResponse) {
		const authResult = this.parseAuthResponse(authResponse);
		const lastLoggedInUserId = this.localStorageSvc.get(storageKeys.lastLoggedInUserId);
		const tableSettingsKeys = this.localStorageSvc.keys().filter(x => x.indexOf('Table') > -1);

		if (!authResult.valid) {
			return authResult;
		}

		if (authResponse.termsAndConditionsBodyAsBase64) {
			this.localStorageSvc.set(storageKeys.termsAndConditionsAccepted, false);
		}
		else {
			this.localStorageSvc.set(storageKeys.termsAndConditionsAccepted, true);
		}

		if (authResponse.languageCode) {
			this.localStorageSvc.set(storageKeys.preferredLanguage, String(authResponse.languageCode).toLowerCase());
		}

		this.storeToken(authResponse);
		this.setIdleTimeout(authResponse);
		this.storePortalData(authResponse);

		// Do NOT comment this out, it is for FullStory, c'mon man!
		this.fullstoryService.identify({
			displayName: authResponse.userName,
			email: authResponse.userName,
			token_str: authResponse.token,
			selectedPortalId: authResponse.currentPortalId,
			selectedPortalName: authResponse.currentPortalName,
			portalId: authResponse.rootPortalId,
			portalName: authResponse.rootPortalName,
			subdomain: window.location.host,
			environment: environment.name
		});

		// clear table settings if userId is different from lastLoggedInUserId
		if (lastLoggedInUserId !== authResponse.userId && tableSettingsKeys) {
			tableSettingsKeys.forEach((key: string) => {
				this.localStorageSvc.remove(key);
			});
		}

		this.userSvc.setUserRights(authResponse.userRights);
		this.permissionSvc.setPermissions();
		this.permissionSvc.setFeatures(authResponse.features);
		this.initEndpoints();
		this.endpointSvc.add(authResponse.userRights.endpoints);

		// fullstory indentify: set fullstory portalSalesforceAccountId
		this.fullstoryService.identify({ portalSalesforceAccountId: authResponse.currentPortalSalesforceId || '' });

		// fullstory event: business continuity manager logged in
		if (this.businessContinuityService.isBusinessContinuityManager) {
			const firstName: string = this.utility.getUserProfileName()?.split(' ')[0] || '';
			const lastName: string = this.utility.getUserProfileName()?.split(' ')[1] || '';

			this.fullstoryService.event('Planner: Business Continuity Manager logged in', {
				portalId: this.utility.getRootPortalId(),
				portalName: this.utility.getRootPortalName(),
				selectedPortalId: authResponse.currentPortalId,
				selectedPortalName: authResponse.currentPortalName,
				firstName: firstName,
				lastName: lastName,
				subdomain: window.location.host,
				portalSalesforceAccountId: authResponse.currentPortalSalesforceId || ''
			});
		}

		if (!authResponse.isSelfRegistered) {
			// reinitialize LaunchDarkly w/ user data
			this.featureService.setUserInformation(authResponse);
			this.featureService.initializeFeatureService();
		}

		// store isSuperAdmin in storage
		this.localStorageSvc.set(storageKeys.isSuperAdmin, this.permissionSvc.isSuperAdmin);

		return authResult;
	}

	onAuthRejected(reason) {
		return this.parseErrorResponse(reason);
	}

	validateResetToken(token) {
		return this.apiSvc.post('getpasswordsecuritypolicy', { authorizationToken: token });
	}

	sendPortalsUrls(username) {
		const credentials: Credentials = new Credentials(
			username,
			null,
			null,
			null,
			null);
		return this.apiSvc.post('sendportalslinks', credentials);
	}

	requestResetPasswordResetToken(username, referrerId) {
		const request = new ResetPasswordRequestModel(username.trim(), referrerId);
		return this.apiSvc.post('requestresetpasswordtoken', request);
	}

	changePassword(password, authorizationToken) {
		return this.apiSvc.post('resetuserpassword', new ChangePasswordRequestModel(password, authorizationToken));
	}

	getPasswordPolicyByPortal(portalId?: string) {
		return this.apiSvc.post('getpasswordsecuritypolicy', { portalId: (portalId) ? portalId : this.utility.getCurrentPortalId() });
	}

	logOff(): void {
		try {
			if (this.isAuthenticated()) {
				this.apiSvc.post('logoff', {});
			}
		}
		catch (e) {
			// does not need to do anything
		}
	}

	public support() {
		const openSupportModal = () => {
			this.modalService.open(SupportModalComponent, {
				backdrop: true,
				centered: true
			});
		};
		if (this.isAuthenticated()) {
			this.apiSvc.post('zendeskauthentication', {}).then((res: any) => {
				if (res && res.redirectUrl) {
					window.open(res.redirectUrl);
				}
				else {
					openSupportModal();
				}
			}).catch((err) => {
				openSupportModal();
			});
		}
		else {
			openSupportModal();
		}
	}

	private storeToken(authResponse) {
		this.localStorageSvc.set(storageKeys.token, authResponse.token);
	}

	private storePortalData(authResponse) {
		this.localStorageSvc.set(storageKeys.userId, authResponse.userId);
		this.localStorageSvc.set(storageKeys.userName, authResponse.userName);
		this.localStorageSvc.set(storageKeys.userProfileName, authResponse.userProfileName);
		this.localStorageSvc.set(storageKeys.isSelfRegistered, authResponse.isSelfRegistered);
		this.localStorageSvc.set(storageKeys.hasBusinessContinuityAssignments, authResponse.hasBusinessContinuityAssignments);
		this.localStorageSvc.set(storageKeys.hasBusinessContinuityManagers, authResponse.hasBusinessContinuityManagers);
		this.localStorageSvc.set(storageKeys.isBusinessContinuityTeamLead, authResponse.isBusinessContinuityTeamLead);
		this.localStorageSvc.set(storageKeys.isBusinessContinuitySME, authResponse.isBusinessContinuitySME);
		this.localStorageSvc.set(storageKeys.isBusinessContinuityAnnexLead, authResponse.isBusinessContinuityAnnexLead);
		this.localStorageSvc.set(storageKeys.isBusinessContinuityTechnologyOwner, authResponse.isBusinessContinuityTechnologyOwner);
		this.localStorageSvc.set(storageKeys.currentPortalId, authResponse.currentPortalId);
		this.localStorageSvc.set(storageKeys.rootPortalId, authResponse.rootPortalId);
		this.localStorageSvc.set(storageKeys.primaryLocationId, authResponse.primaryLocationId);
		this.localStorageSvc.set(storageKeys.rootPortalName, authResponse.rootPortalName);
		this.localStorageSvc.set(storageKeys.dateTimeRootPortalCreated, authResponse.dateTimeRootPortalCreated);
		this.localStorageSvc.set(storageKeys.currentPortalName, authResponse.currentPortalName);
		this.localStorageSvc.set(storageKeys.verticalTypeId, authResponse.verticalTypeId);
		this.localStorageSvc.set(storageKeys.hasSubPortals, authResponse.hasSubPortals);
		this.localStorageSvc.set(storageKeys.allowMobileAccess, authResponse.allowMobileAccess);
		this.localStorageSvc.set(storageKeys.privacyUrl, authResponse.privacyUrl);
		this.localStorageSvc.set(storageKeys.termOfServiceUrl, authResponse.termOfServiceUrl);
		this.localStorageSvc.set(storageKeys.aboutUsUrl, authResponse.aboutUsUrl);
		this.localStorageSvc.set(storageKeys.supportUrl, authResponse.supportUrl);
		this.localStorageSvc.set(storageKeys.currentPortalSalesforceId, authResponse.currentPortalSalesforceId);
		this.localStorageSvc.set(storageKeys.portalIndustry, { industryId: authResponse.industryId });
		this.localStorageSvc.set(storageKeys.revisionItemTypes, authResponse.revisionItemTypes);
	}

	private parseAuthResponse(response) {
		const parsedResult = new AuthResultModel();

		if (response) {
			parsedResult.valid = (response.statusCode === 0) && (!response.pendingMultifactorAuthentication);
			parsedResult.pendingMultifactorAuthentication = response.pendingMultifactorAuthentication;
			parsedResult.mfaPinExpirationMinutes = response.mfaPinExpirationMinutes;
			parsedResult.error = {
				statusCode: response.statusCode,
				message: this.utility.base64Decode(response.errorMessage),
				userMessage: this.utility.base64Decode(response.errorMessageToUser)
			};
			parsedResult.userRights = response.userRights;
			parsedResult.agreementContent = {
				termsAndConditionsId: response.termsAndConditionsId,
				termsAndConditionsBody: this.utility.base64Decode(response.termsAndConditionsBodyAsBase64),
				userHasAcceptedTnc: response.userHasAcceptedTnc
			} as AuthResultAgreementContentModel;
			parsedResult.ssoCustomerRedirectUrl = response.ssoCustomerRedirectUrl;

			if (!response.userRights || !response.userRights.endpoints) {
				parsedResult.valid = false;
				parsedResult.error = {
					statusCode: response.statusCode,
					message: 'Unable to locate endpoints for user account.',
					userMessage: 'System Error. Please try again later.'
				};
				// !! Logger.error(authResult.error.message);
			}
		}

		return parsedResult;
	}

	private parseErrorResponse(errorResponse) {
		const parsedResult = new AuthResultModel();

		if (errorResponse.errorCode) {
			parsedResult.error = {
				statusCode: errorResponse.errorCode,
				message: errorResponse.errorMessage,
				userMessage: ''
			};
		}
		else {
			parsedResult.error = {
				statusCode: 999,
				message: '',
				userMessage: ''
			};
		}
		parsedResult.valid = false;
		return parsedResult;
	}

	private setIdleTimeout(response) {
		const SECONDS_PER_MINUTE = 60;
		if (response && response.sessionTimeoutMinutes) {
			// Subtract 1 minute from session timeout so countdown timer doesn't go beyond session time out
			// timout is expressed in in seconds for the Idle API
			const timeout = (response.sessionTimeoutMinutes - 1) * SECONDS_PER_MINUTE;
			this.sessionSvc.setTimeout(timeout); // in seconds
		}
	}
}
