<div class="modal-header">
    <h3>{{ 'mfa.lblMultifactorTitle' | translate }}</h3>
</div>

<div class="modal-body">
    <form id="mfaForm"
          [formGroup]="mfaForm"
          (submit)="accept()">
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-label">{{ 'mfa.lblMfaSecurityCode' | translate }}</label>
                    <input id="mfaSecurityCode"
                           type="text"
                           class="form-control"
                           formControlName="mfaSecurityCode"
                           placeholder="{{ 'mfa.lblRequired' | translate }}"
                           [(ngModel)]="mfaSecurityCode"
                           required
                           autofocus>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <span>{{ msgMultifactorInstructions }}</span>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button id="cancelButton" class="btn btn-secondary" type="button"
            (click)="cancel()">{{ 'common.cancel' | translate }}</button>
    <button id="acceptButton" class="btn btn-primary" type="button"
            (click)="accept()">{{ 'common.accept' | translate }}</button>
</div>
