import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { env } from '@app/app.constants';
import { LoggerService } from '@app/providers/logger.service';

@Injectable()
export class AppInsightsService implements LoggerService {
	private appInsights = new ApplicationInsights({
		config: {
			instrumentationKey: env.APPINSIGHTS_INSTRUMENTATIONKEY,
			loggingLevelTelemetry: 1, // Send internal critical errors only
			disableAjaxTracking: true
		}
	});

	constructor() {
		this.appInsights.loadAppInsights();
	}

	// parameters:
	// message: Diagnostic data. Can be much longer than a name.
	// properties: Map of string to string: Additional data used to filter exceptions in the portal. Defaults to empty.
	// severityLevel: severity level

	logDebug(message: string, properties?: any) {
		this.appInsights.trackTrace({ message: message, properties: properties, severityLevel: SeverityLevel.Verbose });
	}

	logInfo(message: string, properties?: any) {
		this.appInsights.trackTrace({
			message: message,
			properties: properties,
			severityLevel: SeverityLevel.Information
		});
	}

	logError(message: string, properties?: any) {
		this.appInsights.trackTrace({ message: message, properties: properties, severityLevel: SeverityLevel.Error });
	}

	logWarning(message: string, properties?: any) {
		this.appInsights.trackTrace({ message: message, properties: properties, severityLevel: SeverityLevel.Warning });
	}

	logCritical(message: string, properties?: any) {
		this.appInsights.trackTrace({ message: message, properties: properties, severityLevel: SeverityLevel.Critical });
	}
}
