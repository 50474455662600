<div class="modal-header">
    <h4 class="modal-title">{{header}}</h4>
    <input style="display: none" />
    <button *ngIf="!closeWithDismiss;else withDismis" id="dismissButton" type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
        <i class="fa fa-times"></i>
    </button>
    <ng-template #withDismis>
        <button id="dismissButton" type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <i class="fa fa-times"></i>
        </button>
    </ng-template>
</div>
<div class="modal-body">
    <p [innerHTML]="body"></p>
</div>
<div class="modal-footer">
    <button id="cancelButton" type="button" class="btn btn-secondary" [hidden]="hideCancelButton" (click)="activeModal.dismiss(false)">{{cancelButtonText}}</button>
    <button id="confirmButton" type="button" class="btn {{confirmButtonClass}}"
            (click)="activeModal.close(true)">{{ confirmButtonText }}</button>
</div>
