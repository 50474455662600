import { Injectable } from '@angular/core';
import { UserService } from '@app/providers/user.service';
import { LoggerService } from '@app/providers/logger.service';
import * as FullStory from '@fullstory/browser';
import { UserVars } from '@fullstory/browser';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class FullstoryService {
	private sessionUrl: string;

	constructor(private userService: UserService,
		private logger: LoggerService) {
		// initialize fullstory
		this.init();
	}

	init() {
		FullStory.init({
			orgId: 'KfO',
			devMode: environment?.isFullstoryDevMode || false
		}, (result: { sessionUrl: string }) => {
			this.sessionUrl = result.sessionUrl;
		});
	}

	identify(identify: any) {
		try {
			FullStory.identify(this.userService.getCurrentUserId(), identify);
		}
		catch (e: any) {
			// log error
			this.logger.logError(`fullstory.identify error, userId: ${this.userService.getCurrentUserId()}, ${e}`);
		}
	}

	setUserVars(customVars: UserVars) {
		try {
			FullStory.setUserVars(customVars);
		}
		catch (e: any) {
			// log error
			this.logger.logError(`fullstory.setUserVars error, userId: ${this.userService.getCurrentUserId()}, ${e}`);
		}
	}

	event(eventName: string, eventProperties?: any) {
		try {
			FullStory.event(eventName, eventProperties);
		}
		catch (e: any) {
			// log error
			this.logger.logError(`fullstory.event error, userId: ${this.userService.getCurrentUserId()}, ${e}`);
		}
	}
}
