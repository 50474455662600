<h5>{{ 'passwordPolicies.lblPasswordPolicyHeader' | translate }}</h5>
<ul class="list-unstyled passwordPoliciesList">
    <li *ngIf="loading">
        <span>{{ 'passwordPolicies.msgPasswordPolicyLoading' | translate }}</span>
    </li>
    <li *ngIf="!loading && passwordPolicies.length === 0">
        <span id="msgPasswordPolicyNoneFound">{{ 'passwordPolicies.msgPasswordPolicyNoneFound' | translate }}</span>
    </li>
    <li *ngIf="passwordPolicies.length > 0">
        <i class="fa" aria-hidden="true" [ngClass]="getStyleProps('length')"></i>
        <span
            id="msgPasswordPolicyMinCharacters">{{ 'passwordPolicies.msgPasswordPolicyMinCharacters' | translate : {number: passwordPolicies.length}
            }}
        </span>
    </li>
    <li *ngIf="passwordPolicies.uppercaseLetters > 0">
        <i class="fa" aria-hidden="true" [ngClass]="getStyleProps('uppercase')"></i>
        <span id="msgPasswordPolicyMinUppercaseCharacters">
            {{
            'passwordPolicies.msgPasswordPolicyMinUppercaseCharacters' | translate : {number: passwordPolicies.uppercaseLetters}
            }}
        </span>
    </li>
    <li *ngIf="passwordPolicies.lowercaseLetters > 0">
        <i class="fa" aria-hidden="true" [ngClass]="getStyleProps('lowercase')"></i>
        <span id="msgPasswordPolicyMinLowercaseCharacters">
            {{
            'passwordPolicies.msgPasswordPolicyMinLowercaseCharacters' | translate : {number: passwordPolicies.lowercaseLetters}
            }}
        </span>
    </li>

    <li *ngIf="passwordPolicies.numbers > 0">
        <i class="fa" aria-hidden="true" [ngClass]="getStyleProps('numbers')"></i>
        <span id="msgPasswordPolicyMinNumberCharacters">
            {{
            'passwordPolicies.msgPasswordPolicyMinNumberCharacters' | translate : {number: passwordPolicies.numbers}
            }}
        </span>
    </li>

    <li *ngIf="passwordPolicies.symbols > 0">
        <i class="fa" aria-hidden="true" [ngClass]="getStyleProps('symbols')"></i>
        <span id="msgPasswordPolicySpecialCharacters">
            {{
            'passwordPolicies.msgPasswordPolicySpecialCharacters' | translate : {number: passwordPolicies.symbols}
            }}
        </span>
    </li>
</ul>
