import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-confirmation-modal',
	templateUrl: './confirmation-modal.component.html',
	styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
	@Input() header: string;
	@Input() body: string;
	@Input() confirmButtonText: string;
	@Input() confirmButtonClass: string;
	@Input() cancelButtonText: string;
	@Input() hideCancelButton: boolean = false;

	@Input() closeWithDismiss: boolean = false;

	constructor(public activeModal: NgbActiveModal,
		public translateService: TranslateService) {
		// defaults to btn-primary
		this.confirmButtonClass = this.confirmButtonClass ? this.confirmButtonClass : 'btn-primary';

		this.cancelButtonText = this.cancelButtonText ? this.cancelButtonText : this.translateService.instant('common.cancel');

		this.hideCancelButton = this.hideCancelButton === true;
	}

	ngOnInit() {
	}

	public init(header: string, body: string, confirmButtonText: string, confirmButtonClass: string, cancelButtonText?: string) {
		this.header = header;
		this.body = body;
		this.confirmButtonText = confirmButtonText;
		this.confirmButtonClass = confirmButtonClass;
		this.cancelButtonText = cancelButtonText ? cancelButtonText : this.translateService.instant('common.cancel');
	}
}
