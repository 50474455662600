import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SplashMessageDialogComponent } from '@app/components/modals/splash-message-dialog/splash-message-dialog.component';
import { IncidentService } from '@app/providers/incident.service';

@Injectable({
	providedIn: 'root'
})
export class SplashMessagePopupService {
	constructor(private modalService: NgbModal,
		private incidentSvc: IncidentService) {
	}

	processSplashMessages() {
		return new Promise((resolve, reject) => {
			this.incidentSvc.getActiveAlerts()
				.then((result: Array<any>) => {
					if (result
					  && result.length > 0) {
						const msgs = [];
						result.forEach((sp) => {
							msgs.push(this.showSplashMessage(sp));
						});
						Promise.all(msgs)
							.then((_) => {
								resolve(true);
							});
					}
					else {
						resolve(true);
					}
				})
				.catch((_) => {
					reject(false);
				});
		});
	}

	showSplashMessage(alert: any) {
		this.splashMessage(alert, false);
	}

	previewSplashMessage(alert: any) {
		this.splashMessage(alert, true);
	}

	private splashMessage(alert: any, isPreview: boolean) {
		const modalOptions: NgbModalOptions = {};
		modalOptions.size = 'lg';
		modalOptions.backdrop = 'static';
		modalOptions.windowClass = 'splashMessageModal';
		const modalInstance = this.modalService.open(SplashMessageDialogComponent, modalOptions);

		modalInstance.componentInstance.splashMessage = alert;

		let alertId = '';
		let alertResponseTypeId = '';
		return modalInstance.result
			.then((response) => {
				alertId = response.alert.alertId;
				alertResponseTypeId = response.responseType;
			})
			.catch((errorRes) => {
				alertId = errorRes.alert.alertId;
				alertResponseTypeId = errorRes.responseType;
			})
			.then((_) => {
				if (!isPreview) {
					this.incidentSvc.respondToAlert(alertId, alertResponseTypeId);
				}
			});
	}
}
