import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TermsAndConditionsService } from '@app/providers/terms-and-conditions.service';

@Component({
	selector: 'app-tac-dialog',
	templateUrl: './tac-dialog.component.html',
	styleUrls: ['./tac-dialog.component.scss']
})
export class TacDialogComponent implements OnInit {
	termsAndConditions: string;

	constructor(private activeModal: NgbActiveModal,
		private formBuilder: UntypedFormBuilder,
		private termsAndConditionsSVC: TermsAndConditionsService) {
	}

	ngOnInit() {
	}

	accept() {
		this.activeModal.close({
			accepted: true
		});
	}

	cancel() {
		this.activeModal.close({
			accepted: false
		});
	}
}
