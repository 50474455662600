import { Injectable } from '@angular/core';
import { ApiService } from '@app/providers/api.service';
import { Utility } from '@app/providers/utility';
import { CreateNewPortalRequest } from '@app/shared/createNewPortalRequest.model';
import { Entity } from '@app/shared/manage/entity.model';
import { PhoneNumber } from '@app/shared/phone-number.model';
import { User } from '@app/shared/user.model';
import { Building } from '@app/shared/manage/building.model';
import { PermissionService } from '@app/providers/permission.service';
import { SecurityService } from '@app/providers/security.service';
import { PhoneNumberService } from '@app/providers/phoneNumber.service';
import { permissionFeatures, storageKeys } from '@app/app.constants';
import { PortalModule } from '@app/shared/portal-module.model';
import { PortalIndustry } from '@app/shared/business-continuity/portal-industry.model';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { EntityGroupType } from '@app/shared/entity-group-type.model';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { ModelGetLocationTypesResponse, ModelLocationType } from '@app/shared/business-continuity/location-types';
import { ModelEmergencyReadiness, ModelGetEmergencyReadinessListResponse } from '@app/shared/business-continuity/emergency-readiness';

@Injectable({
	providedIn: 'root'
})
export class EntityService {
	constructor(public api: ApiService,
		private utility: Utility,
		private permissionService: PermissionService,
		private securityService: SecurityService,
		private phoneNumberService: PhoneNumberService,
		private bcService: BusinessContinuityService,
		private storage: LocalStorageService) {
	}

	getEntities() {
		return this.api.post('getentitydetaillisting', {});
	}

	getEntity(entityId: string, entityTypeId?: string, logForAuditing?: boolean) {
		return this.api.post('getentitydetails', {
			entityId: entityId,
			entityTypeId: entityTypeId,
			logForAuditing: logForAuditing
		});
	}

	getEntityDetailList(entityTypeId?: string, logForAuditing?: boolean, includeRiskInfo?: boolean) {
		return this.api.post('getentitydetaillisting', {
			entityTypeId: entityTypeId,
			logForAuditing: logForAuditing,
			includeRiskInfo: includeRiskInfo
		});
	}

	getEntitiesCountByEntityType(entityTypeId?: string, excludeEntityIds?: Array<string>, country?: string) {
		return this.api.post('getentitiescountbyentitytype', {
			entityTypeId: entityTypeId,
			excludeEntityIds: excludeEntityIds,
			country: country
		});
	}

	getMessageEntitiesCountByEntityType(entityTypeId?: string, excludeEntityIds?: Array<string>, country?: string) {
		return this.api.post('getmessageentitiescountbyentitytype', {
			entityTypeId: entityTypeId,
			excludeEntityIds: excludeEntityIds,
			country: country
		});
	}

	getFilteredEntities(entityTypeId?: string, letter?: string, searchPhrase?: string, country?: string, excludeEntityIds?: Array<string>) {
		const request = {
			entityTypeId: entityTypeId,
			letterAsBase64: this.utility.base64Encode(letter),
			searchPhraseAsBase64: this.utility.base64Encode(searchPhrase),
			countryAsBase64: this.utility.base64Encode(country),
			excludeEntityIds: excludeEntityIds
		};
		return this.api.post('getFilteredEntities', request);
	}

	getMessageFilteredEntities(entityTypeId?: string, letter?: string, searchPhrase?: string, country?: string, excludeEntityIds?: Array<string>) {
		const request = {
			entityTypeId: entityTypeId,
			letterAsBase64: this.utility.base64Encode(letter),
			searchPhraseAsBase64: this.utility.base64Encode(searchPhrase),
			countryAsBase64: this.utility.base64Encode(country),
			excludeEntityIds: excludeEntityIds
		};
		return this.api.post('getMessageFilteredEntities', request);
	}

	getFilteredMessageUsers(params) {
		// entities consist of roleId, departmentId, locationId, tenantId, groupId
		return this.api.post('getfilteredmessageusers', params);
	}

	getEntitySettingsList() {
		return this.api.post('getentitysettingslist', {});
	}

	getSystemEmails() {
		return this.api.post('getsystememails', {});
	}

	getEntitySetting(entitySettingTypeId: string) {
		return this.api.post('getentitysettingslist', { entitySettingTypeId: entitySettingTypeId });
	}

	getEntitySettings(entitySettingTypeIds: string[]) {
		return this.api.post('getentitysettings', { settingTypeIds: entitySettingTypeIds });
	}

	getLocationTypes(): Promise<ModelGetLocationTypesResponse> {
		return this.api.post('GetLocationTypes', {}) as Promise<ModelGetLocationTypesResponse>;
	}

	savePortalLocationType(locationType: ModelLocationType): Promise<ModelGetLocationTypesResponse> {
		return this.api.post('SavePortalLocationType',
			{ locationType: locationType }) as Promise<ModelGetLocationTypesResponse>;
	}

	getEmergencyReadinessList(): Promise<ModelGetEmergencyReadinessListResponse> {
		return this.api.post('GetEmergencyReadinessList', {}) as Promise<ModelGetEmergencyReadinessListResponse>;
	}

	savePortalEmergencyReadiness(emergencyReadiness: ModelEmergencyReadiness): Promise<ModelGetEmergencyReadinessListResponse> {
		return this.api.post('SavePortalEmergencyReadiness',
			{ emergencyReadiness: emergencyReadiness }) as Promise<ModelGetEmergencyReadinessListResponse>;
	}

	setEntitySettings(entitySettings) {
		return this.api.post('setentitysettings', entitySettings);
	}

	saveSystemEmails(entitySettings) {
		return this.api.post('savesystememails', entitySettings);
	}

	uploadSystemEmailImage(settingTypeId: string, systemEmailImageAsBase64: string) {
		const modelUploadSystemEmailImageRequest: any = {
			settingTypeId: settingTypeId,
			systemEmailImageAsBase64: systemEmailImageAsBase64
		};

		return this.api.post('uploadsystememailimage', modelUploadSystemEmailImageRequest);
	}

	getPortalHierarchyByPortalId(searchPhrase: string) {
		return this.api.post('getportalhierarchybyportalid', { searchPhraseAsBase64: this.utility.base64Encode(searchPhrase) });
	}

	switchPortal(portalId: string) {
		return this.api.post('switchportal', { portalId: portalId });
	}

	getPortals(searchPhrase?: string) {
		return this.api.post('getportals', { searchPhraseAsBase64: this.utility.base64Encode(searchPhrase) });
	}

	getPortalDetails(portalId: string) {
		return this.api.post('getportaldetails', { entityId: portalId });
	}

	createNewPortal(request: CreateNewPortalRequest) {
		return this.api.post('createnewportal', request);
	}

	getEntityDetails(entityId?: string) {
		// NOTE: empty object for companyProfile
		const request = entityId ? { entityId: entityId } : {};

		return this.api.post('getentitydetails', request);
	}

	updateUsersByEntity(entity: Entity, entityUsers: Array<User>) {
		const request = {
			entityId: entity.entityId,
			entityUsersItems: this.mapEntityUsers(entity.entityId, entityUsers)
		};

		return this.api.post('updateentityusersforanentity', request);
	}

	mapEntityUsers(entityId: string, entityUsers) {
		return entityUsers.map((user) => {
			return {
				entityUserItemId: this.utility.getGuid(),
				entityId: entityId,
				userId: user.userId,
				IsPrimaryRelationship: false
			};
		});
	}

	deleteEntity(entityId: string) {
		const request = { entityId: entityId };

		return this.api.post('deleteentity', request);
	}

	deletePortal(entityId: string) {
		const request = { entityId: entityId };

		return this.api.post('deleteportal', request);
	}

	getEntityAssociationsCountBySecondaryEntityId(entityId: string) {
		const request = { entityId: entityId };

		return this.api.post('GetEntityAssociationsCountBySecondaryEntityId', request);
	}

	checkEntityNameForDuplication(entityId: string, entityTypeId: string, parentEntityId: string, entityName: string) {
		const request = {
			entityId: entityId,
			entityTypeId: entityTypeId,
			parentEntityId: parentEntityId,
			entityNameAsBase64: this.utility.base64Encode(entityName)
		};

		return this.api.post('checkentitynameforduplication', request);
	}

	saveLocation(entity: Entity, isNewEntity?: boolean): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const promises = [];

			// must do BEFORE 'updateentity'. based on replication of ng1 way of doing it...
			entity.parentEntityId = entity.parentEntityId ? entity.parentEntityId : entity.parentEntityItems[0].entityId;
			entity.entityNameAsBase64 = this.utility.base64Encode(entity.entityName);
			entity.descriptionAsBase64 = this.utility.base64Encode(entity.description);

			// phoneNumberAsBase64
			if (entity.entityAddressItems
			  && entity.entityAddressItems.length) {
				entity.entityAddressItems[0].entityId = entity.entityId;

				if (entity.entityAddressItems[0].phoneNumbers) {
					entity.entityAddressItems[0].phoneNumbers.forEach((phoneNumber: PhoneNumber) => {
						phoneNumber.phoneNumberAsBase64 = this.utility.base64Encode(phoneNumber.phoneNumber);
					});
				}
			}

			if (isNewEntity) {
				this.api.post('updateentity', entity)
					.then((res: any) => {
						this.api.post('updateentityhierarchy', {
							ParentEntityId: this.utility.getCurrentPortalId(),
							ChildEntityId: entity.entityId
						})
							.then((res: any) => {
								this.api.post('updateentityaddress', { item: entity.entityAddressItems[0] })
									.then((res: any) => {
										if (entity.entitySettingsItems.length > 0) {
											this.api.post('UpdateEntityPlannerSettings', { entityId: entity.entityId, settings: entity.entitySettingsItems })
												.then((res: any) => {
													resolve(true);
												});
										}
										resolve(true);
									})
									.catch((err: any) => {
										reject(false);
									});
							})
							.catch((err: any) => {
								reject(false);
							});
					})
					.catch((err: any) => {
						reject(false);
					});
			}
			else {
				promises.push(this.api.post('updateentity', entity));
				promises.push(this.api.post('updateentityaddress', { item: entity.entityAddressItems[0] }));
				if (entity.entitySettingsItems.length > 0) {
					promises.push(this.api.post('UpdateEntityPlannerSettings', { entityId: entity.entityId, settings: entity.entitySettingsItems }));
				}
				Promise.all(promises)
					.then((res: any) => {
						resolve(true);
					})
					.catch((err: any) => {
						reject(false);
					});
			}
		});
	}

	/// BULK UPLOAD
	uploadBulkEntityFile(entityTypeId: string, fileAsBase64: string, fileName: string) {
		const request = {
			entityId: this.utility.getCurrentPortalId(),
			entityTypeId: entityTypeId,
			csvFileNameAsBase64: this.utility.base64Encode(fileName),
			csvFileAsBase64: fileAsBase64
		};

		return this.api.post('uploadbulkentityfile', request);
	}

	exportHeadersForEntityCsvFile(entityTypeId: string) {
		return this.api.post('exportheadersforentitycsvfile', {
			parentEntityId: this.utility.getCurrentPortalId(),
			entityTypeId: entityTypeId
		}, true);
	}

	exportEntityCsvFile(entityTypeId: string) {
		return this.api.post('exportentitycsvfile', {
			parentEntityId: this.utility.getCurrentPortalId(),
			entityTypeId: entityTypeId
		}, true);
	}

	// END BULK UPLOAD

	// MANAGE
	saveGroup(entity: Entity, isNewEntity?: boolean): Promise<boolean> {
		return new Promise((resolve, reject) => {
			// must be done before the const below
			if (entity.groupType) {
				// convert settingValue to valueAsBase64 which is used by service to save
				entity.groupType.valueAsBase64 = this.utility.base64Encode(entity.groupType.settingValue);
				entity.groupType.value = entity.groupType.settingValue;
			}
			entity.entityNameAsBase64 = this.utility.base64Encode(entity.entityName);
			entity.descriptionAsBase64 = this.utility.base64Encode(entity.description);

			const promises = [];
			const entitySettingRequest = { entityId: entity.entityId, items: [entity.groupType] };

			// must do BEFORE 'updateentity'. based on replication of ng1 way of doing it...
			entity.parentEntityId = entity.parentEntityId ? entity.parentEntityId : entity.parentEntityItems[0].entityId;

			if (isNewEntity) {
				this.api.post('updateentity', entity)
					.then((res: any) => {
						this.api.post('updateentityhierarchy', {
							ParentEntityId: this.utility.getCurrentPortalId(),
							ChildEntityId: entity.entityId
						})
							.then((res: any) => {
								this.api.post('setentitysettings', entitySettingRequest)
									.then((res: any) => {
										resolve(true);
									})
									.catch((err: any) => {
										reject(false);
									});
							})
							.catch((err: any) => {
								reject(false);
							});
					})
					.catch((err: any) => {
						reject(false);
					});
			}
			else {
				promises.push(this.api.post('updateentity', entity));
				promises.push(this.api.post('setentitysettings', entitySettingRequest));

				Promise.all(promises)
					.then((res: any) => {
						resolve(true);
					})
					.catch((err: any) => {
						reject(false);
					});
			}
		});
	}

	saveDepartment(entity: Entity): Promise<any> {
		entity.entityNameAsBase64 = this.utility.base64Encode(entity.entityName);
		entity.descriptionAsBase64 = this.utility.base64Encode(entity.description);

		return this.api.post('updateentity', entity);
	}

	saveTenant(entity: Entity, isNewEntity?: boolean): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const promises = [];

			// must do BEFORE 'updateentity'. based on replication of ng1 way of doing it...
			entity.parentEntityId = entity.parentEntityId ? entity.parentEntityId : entity.parentEntityItems[0].entityId;
			entity.entityNameAsBase64 = this.utility.base64Encode(entity.entityName);
			entity.descriptionAsBase64 = this.utility.base64Encode(entity.description);

			// phoneNumberAsBase64
			if (entity.entityAddressItems
			  && entity.entityAddressItems.length
			  && entity.entityAddressItems[0].phoneNumbers) {
				entity.entityAddressItems[0].phoneNumbers.forEach((phoneNumber: PhoneNumber) => {
					phoneNumber.phoneNumberAsBase64 = this.utility.base64Encode(phoneNumber.phoneNumber);
				});
			}

			if (isNewEntity) {
				this.api.post('updateentity', entity)
					.then((res: any) => {
						this.api.post('updateentityhierarchy', {
							ParentEntityId: this.utility.getCurrentPortalId(),
							ChildEntityId: entity.entityId
						})
							.then((res: any) => {
								this.api.post('updateentityaddress', { item: entity.entityAddressItems[0] })
									.then((res: any) => {
										resolve(true);
									})
									.catch((err: any) => {
										reject(false);
									});
							})
							.catch((err: any) => {
								reject(false);
							});
					})
					.catch((err: any) => {
						reject(false);
					});
			}
			else {
				promises.push(this.api.post('updateentity', entity));
				promises.push(this.api.post('updateentityaddress', { item: entity.entityAddressItems[0] }));

				Promise.all(promises)
					.then((res: any) => {
						resolve(true);
					})
					.catch((err: any) => {
						reject(false);
					});
			}
		});
	}

	getEntityAssociationsByPrimaryEntityId(entityId: string): Promise<any> {
		return this.api.post('getentityassociationsbyprimaryentityid', { entityId: entityId });
	}

	getEntityListing(): Promise<any> {
		return this.api.post('getentitylisting', null);
	}

	createEntityAssociation(building: Building): Promise<any> {
		return this.api.post('createentityassociation', building);
	}

	checkTenantLocationAssociatedUsers(tenantId: string, locationId: string): Promise<any> {
		const request = {
			tenantId: tenantId,
			locationId: locationId
		};

		return this.api.post('checktenantlocationassociatedusers', request);
	}

	removeEntityAssociation(building: Building): Promise<any> {
		return this.api.post('removeentityassociation', building);
	}

	// logo, etc.
	getEntityResource(entityId?: string, entityResourceTypeId?: any): Promise<any> {
		return this.api.post('getentityresource', {
			entityId: entityId ? entityId : null,
			entityResourceTypeId: entityResourceTypeId
		});
	}

	saveCompanyProfile(companyProfile: Entity, emailFromName: any, voiceDisplayNumber: any, portalSubdomainName: any, portalIndustry: PortalIndustry): Promise<any> {
		// base64
		companyProfile.entityNameAsBase64 = this.utility.base64Encode(companyProfile.entityName);

		if (!emailFromName.settingId) {
			emailFromName.settingId = this.utility.getGuid();
		}
		emailFromName.valueAsBase64 = this.utility.base64Encode(emailFromName.value);

		if (!voiceDisplayNumber.settingId) {
			voiceDisplayNumber.settingId = this.utility.getGuid();
		}
		voiceDisplayNumber.valueAsBase64 = this.utility.base64Encode(this.phoneNumberService.toE164Format(voiceDisplayNumber.value));

		const entitySettingRequest = {
			entityId: companyProfile.entityId,
			items: [
				emailFromName,
				voiceDisplayNumber
			]
		};

		if (this.permissionService.isSuperAdmin) {
			if (!portalSubdomainName.settingId) {
				portalSubdomainName.settingId = this.utility.getGuid();
			}
			portalSubdomainName.valueAsBase64 = this.utility.base64Encode(portalSubdomainName.value);
			entitySettingRequest.items.push(portalSubdomainName);
		}

		const promises = [];

		return new Promise((resolve, reject) => {
			promises.push(this.api.post('updateentity', companyProfile));
			promises.push(this.api.post('setentitysettings', entitySettingRequest));
			promises.push(this.api.post('updateentityaddress', { item: companyProfile.entityAddressItems[0] }));

			if (this.permissionService.hasPermission(permissionFeatures.administration.key, [permissionFeatures.administration.actions.managePortal]) && companyProfile.ssoSettings) {
				const isDelete = (!companyProfile.ssoSettings.useSso);
				if (isDelete || companyProfile.ssoSettings.allowNonSSOLogin) {
					companyProfile.ssoSettings.allowNonSSOLoginRoleBundles = [];
				}
				promises.push(this.securityService.saveSsoConfig(companyProfile.ssoSettings, isDelete));

				if (portalIndustry != null && portalIndustry.industryId != null) {
					portalIndustry.portalId = portalIndustry.portalId || companyProfile.entityId;
					promises.push(this.savePortalIndustry(portalIndustry));
				}
			}

			if (this.permissionService.hasPermission(permissionFeatures.manage.key, [permissionFeatures.manage.actions.companyApi]) && companyProfile.apiSettings) {
				promises.push(this.securityService.saveApiConfig(companyProfile.apiSettings));
			}

			if (this.permissionService.hasPermission(permissionFeatures.administration.key, [permissionFeatures.administration.actions.managePortal]) && companyProfile.ldapSettings) {
				promises.push(this.securityService.saveLDAPConfig(companyProfile.ldapSettings));
			}

			Promise.all(promises)
				.then((res: any) => {
					resolve(res);
				})
				.catch((err: any) => {
					reject(err);
				});
		});
	}

	savePortalIndustry(portalIndustry: PortalIndustry) {
		return new Promise<void>((resolve, reject) => {
			this.bcService.savePortalIndustry(portalIndustry)
				.then((res: any) => {
					this.storage.set(storageKeys.portalIndustry, { industryId: portalIndustry.industryId });
					// set the portal plan type features
					this.permissionService.setFeatures(res);
					resolve();
				}).catch((_) => {
					reject();
				});
		});
	}

	uploadPortalLogo(portalLogo: string): Promise<any> {
		const request = {
			portalLogoAsBase64: portalLogo
		};

		return this.api.post('uploadportallogopic', request);
	}

	resetPortalLogo(entityResourceTypeId: string): Promise<any> {
		return this.api.post('ResetPortalLogo', { entityResourceTypeId: entityResourceTypeId });
	}

	uploadPortalFooterLogo(portalFooterLogo: string, isPoweredByLogo?: boolean): Promise<any> {
		const request = {
			portalLogoAsBase64: portalFooterLogo,
			isPoweredByLogo: isPoweredByLogo
		};

		return this.api.post('UploadPortalFooterLogo', request);
	}

	getPortalModules(portalId: string) {
		return this.api.post('getportalmodules', { portalId: portalId });
	}

	savePortalModules(portalId: string, portalModules: Array<PortalModule>) {
		return this.api.post('saveportalmodules', { portalId: portalId, modules: portalModules });
	}

	deleteEntitySettings(settingIds: Array<string>) {
		return this.api.post('deleteentitysettings', { settingIds: settingIds });
	}

	deleteSystemEmails(settingIds: Array<string>) {
		return this.api.post('deleteentitysettings', { settingIds: settingIds });
	}

	getNotificationOptOutSettings() {
		return this.api.post('getnotificationoptoutsettings', {});
	}

	// END MANAGE

	// get the default values for the features flags
	getFeatureFlagDefaultSettings(customAttributes: any) {
		return this.api.post('getfeatureflagdefaultsettings', {
			customAttributes: customAttributes
		});
	}

	getADPSubscriptionCode() {
		return this.api.post('selectadpsubscriptioncode', {});
	}

	generateADPSubscriptionCode() {
		return this.api.post('generateadpsubscriptioncode', {});
	}

	// Entity Group Type
	getEntityGroupTypes(logForAuditing?: boolean) {
		return this.api.post('GetEntityGroupTypes', {
			logForAuditing: logForAuditing
		});
	}

	upsertEntityGroupType(entityGroupType: EntityGroupType) {
		return this.api.post('UpsertEntityGroupType', { entityGroupType: entityGroupType });
	}

	deleteEntityGroupType(entityGroupType: EntityGroupType) {
		return this.api.post('DeleteEntityGroupType', { entityGroupType: entityGroupType });
	}

	// END Entity Group Type

	isMaxPortalLocationLicensesExceeded(entityId?: string) {
		return this.api.post('validateportallocationlicense', { entityId: entityId });
	}

	// get list of countries
	getCountries(filterByPortalLocations?: boolean) {
		return this.api.post('getusercountrylist', { filterByPortalLocations: filterByPortalLocations });
	}

	getPortalAddressAndPhoneNumbers() {
		return this.api.post('GetPortalAddressAndPhoneNumbers', {});
	}
}
