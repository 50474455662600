export class BreadCrumb {
	name: string;
	state: string;
	routeState?: any;
	queryParams?: any;

	constructor(name: string, state: string, routeState: any = null, queryParams: any = null) {
		this.name = name;
		this.state = state;
		this.routeState = routeState;
		this.queryParams = queryParams;
	}
}
