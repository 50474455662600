import { TranslateLoader } from '@ngx-translate/core';
import { storageKeys, verticalTypeIds } from '@app/app.constants';
import { Locales, LocalesModel } from '../../assets/i18n/locales';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { Observable, of } from 'rxjs';
import { brandingTranslationDefaults } from '../../assets/i18n/branding-terms';

export class CustomTranslateLoader implements TranslateLoader {
	private brandingRegex: RegExp = /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g;
	private locales: LocalesModel = new Locales().getLocales();

	constructor(private storageSvc: LocalStorageService) {
	}

	public getTranslation(lang: string): Observable<any> {
		return this.getTranslations(lang);
	}

	public mergeTranslationAndPortalBrandingTerms(translations: any, lang: string) {
		// termKey & termValue provided by service
		const brandingTerms: { termKey: string, termValue: string }[] | any = this.storageSvc.get(storageKeys.brandingTerms) as { termKey: string, termValue: string }[] || [];
		const defaultBrandingKeys: string[] = Array.from(brandingTranslationDefaults.keys());
		let transStr: string = JSON.stringify(translations);

		brandingTerms?.forEach((pt) => {
			const pattern = this.escapeRegExp(`[[${pt.termKey}]]`);
			const regex: RegExp = new RegExp(pattern, 'g');
			transStr = transStr.replace(regex, pt.termValue);
		});

		// fallback/failsafe - if [[]] remnants
		if ((transStr.includes('[[') || transStr.includes(']]'))) {
			console.error(`brandingTerms are missing ${brandingTerms?.length}`);

			defaultBrandingKeys.forEach((defaultBrandingKey: string) => {
				const pattern = this.escapeRegExp(defaultBrandingKey);
				const regex: RegExp = new RegExp(pattern, 'g');
				const value: string = brandingTranslationDefaults.get(defaultBrandingKey)[lang || 'en'];
				transStr = transStr.replace(regex, value);
			});

			// last line of defense
			transStr.replace('[[', '');
			transStr.replace(']]', '');
		}

		return JSON.parse(transStr);
	}

	public escapeRegExp(str) {
		return str.replace(this.brandingRegex, '\\$&');
	}

	private getTranslations(lang: string): Observable<any> {
		const verticalTypeId = JSON.parse(localStorage.getItem('preparisPortal.verticalTypeId'));
		let translations = JSON.parse(JSON.stringify(this.locales[lang]));

		if (verticalTypeId === verticalTypeIds.realEstate) {
			const verticalTranslations = this.locales[lang + '_cre'];

			Object.keys(verticalTranslations).forEach((key) => {
				this.mergeKey(verticalTranslations, translations, key);
			});
		}

		translations = this.mergeTranslationAndPortalBrandingTerms(translations, lang);
		return of(translations);
	}

	private mergeKey(source: any, target: any, propertyName: string) {
		if (this.isObject(source[propertyName])) {
			if (target[propertyName] === 'undefined') {
				target[propertyName] = {};
			}
			Object.keys(source[propertyName]).forEach((key) => {
				this.mergeKey(source[propertyName], target[propertyName], key);
			});
		}
		else {
			target[propertyName] = source[propertyName];
		}
	}

	private isObject(obj) {
		return obj === Object(obj);
	}
}
