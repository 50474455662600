import { Injectable } from '@angular/core';
import { ApiService } from '@app/providers/api.service';
import { PortalIndustry } from '@app/shared/business-continuity/portal-industry.model';
import { Utility } from '@app/providers/utility';
import { ModelGetPortalFunctionalAreaResponse, PortalFunctionalArea, PortalFunctionalAreaPlan } from '@app/shared/business-continuity/portal-functional-area.model';
import { PortalProcess, PortalProcessDependency, PortalProcessPlan } from '@app/shared/business-continuity/portal-process.model';
import {
	bcCriticalDateFrequencyTypes,
	bcImpactCategories,
	bcImpactSeverity,
	bcRevisionItemTypes,
	bcRevisionStateType,
	bcStatusStyle,
	bcStrategyTypes,
	entitySettingsTypes,
	featureTypes,
	guid,
	impactTimespanStatusTypes,
	industries,
	messageResponseTypes,
	permissionFeatures,
	planTemplateTargetTypeIds,
	planTemplateTypeIds,
	requestForUserResponseNumeric,
	revisionScopes,
	serviceErrorCodes,
	storageKeys,
	timeDurationUnit
} from '@app/app.constants';
import { PermissionService } from '@app/providers/permission.service';
import { PortalSite } from '@app/shared/business-continuity/portal-site.model';
import { Subject } from 'rxjs';
import { NavigationModel } from '@app/shared/business-continuity/navigation.model';
import { ModelGetCurrentRevisionPeriodResponse, RevisionPeriod } from '@app/shared/business-continuity/revision-period.model';
import { RevisionItem } from '@app/shared/business-continuity/revision-item.model';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { RevisionStatusStyle } from '@app/shared/business-continuity/revistion-status-style.model';
import { EquipmentType } from '@app/shared/business-continuity/equipment-type.model';
import { PortalProcessPlanNote, PortalProcessPlanStrategy, PortalProcessStrategy } from '@app/shared/business-continuity/portal-process-strategy.model';
import { DRVendor, Vendor } from '@app/shared/business-continuity/vendor.model';
import { BCIssue } from '@app/shared/business-continuity/bc-issue.model';
import { Process } from '@app/shared/business-continuity/process.model';
import { ProcessStrategy } from '@app/shared/business-continuity/process-strategy.model';
import {
	ModelGetPortalTechnologiesProcessesResponse,
	ModelGetPortalTechnologiesRPOsResponse, ModelGetPortalTechnologiesVendorsResponse,
	ModelGetTechnologyTiersResponse,
	Technology,
	TechnologyRequestFilter, TechnologyWithDetailsRequestFilter
} from '@app/shared/business-continuity/technology.model';
import { PointOfInterestModel } from '@app/shared/point-of-interest.model';
import { BcCriticalDatesOverviewEvent } from '@app/components/business-continuity/bc-dashboard/bc-critical-dates-overview/bc-critical-dates-overview.component';
import { AbsoluteDateModel, ProcessCriticalDate } from '@app/shared/business-continuity/process-critical-date.model';
import { FunctionalArea } from '@app/shared/business-continuity/functional-area.model';
import { Industry } from '@app/shared/business-continuity/industry.model';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { BcImpactSeverityDescriptionModel } from '@app/shared/business-continuity/bc-impact-severity-description.model';
import { Document, ModelGetPortalPlanTechnologyDocumentsResponse } from '@app/shared/document.model';
import { BcPlanActivationIssueCommentModel } from '@app/shared/business-continuity/bc-plan-activation-issue-comment.model';
import { SendMessage } from '@app/shared/incidents/sendMessage.model';
import { ConferenceCall } from '@app/shared/incidents/conferenceCall.model';
import { User } from '@app/shared/user.model';
import { TranslateService } from '@ngx-translate/core';
import { ImpactTimespan } from '@app/shared/business-continuity/impact-timespan.model';
import { ImpactSeverity } from '@app/shared/business-continuity/impact-severity.model';
import { ImpactCategory } from '@app/shared/business-continuity/impact-category.model';
import { CustomFieldValue } from '@app/shared/custom-field-value.model';
import { PortalProcessLocation } from '@app/shared/business-continuity/bc-portal-process-location.model';
import { BCStrategySettings } from '@app/shared/business-continuity/bc-strategy-settings.model';
import { PortalLocationPlan } from '@app/shared/business-continuity/portal-location-plan.model';
import { ExerciseScenarioModel } from '@app/shared/exercise-manager/exercise-scenario.model';
import { ExerciseTypeModel } from '@app/shared/exercise-manager/exercise-type.model';
import { ThreadHazardModel } from '@app/shared/exercise-manager/thread-hazard.model';
import { ExerciseModel } from '@app/shared/exercise-manager/exercise.model';
import { ExerciseTargetModel } from '@app/shared/exercise-manager/exercise-target-model';
import { PortalOrdersOfSuccession } from '@app/shared/business-continuity/portal-orders-of-succession.model';
import { PortalDelegationOfAuthority } from '@app/shared/business-continuity/portal-delegation-of-authority';
import { PlanSectionNode } from '@app/shared/business-continuity/plan-builder/plan-section-node.model';
import { PlanTemplate } from '@app/shared/business-continuity/plan-builder/plan-template.model';
import { PlanTemplateAssignToTargetsModel } from '@app/shared/business-continuity/plan-builder/plan-template-assign.model';
import { NewCopyPlanTemplate } from '@app/shared/business-continuity/plan-builder/plan-copy-plan.model';
import { DRTeam, ModelGetPortalTeamsResponse, PortalTeam } from '@app/shared/business-continuity/portal-team.model';
import { ModelBCTeam } from '@app/shared/business-continuity/bc-team.model';
import { ModelGetBCTeamResponse, ModelGetBCTeamsResponse } from '@app/shared/business-continuity/default-teams-response.model';
import { BaseResponse } from '@app/shared/base-response.model';
import { DelegationOfAuthority } from '@app/shared/business-continuity/delegation-of-authority';
import { OrderOfSuccession } from '@app/shared/business-continuity/plan-builder/order-of-succession';
import { PortalRevisionItemType } from '@app/shared/business-continuity/portal-revision-item-type.model';
import { Router } from '@angular/router';
import { PortalAnnex } from '@app/shared/business-continuity/annexes/bc-annex.model';
import { Agency } from '@app/shared/business-continuity/agency.model';
import { ModelGetPortalAnnexRecipientsResponse } from '@app/shared/business-continuity/annexes/bc-annex-email-recipient.model';
import { ModelBaseModel } from '@app/shared/base-model.model';
import { BCPlanGeneralQuestion } from '@app/shared/business-continuity/bc-plan-general-question';
import { AnnexSection } from '@app/shared/business-continuity/annexes/bc-annex-section.model';
import { ActionPhase } from '@app/shared/business-continuity/bc-action-phase.model';
import { PortalAnnexAction } from '@app/shared/business-continuity/annexes/bc-annex-action.model';
import { PortalAnnexAgency, PortalAnnexAgencyRole } from '@app/shared/business-continuity/annexes/bc-annex-agency.model';
import { RevisionItemType } from '@app/shared/business-continuity/bc-planner-steps-by-plan-type.model';
import { ModelGetTechnologyTypeResponse, TechnologyType } from '@app/shared/business-continuity/technology-type.model';
import { EntitySetting } from '@app/shared/entity-setting.model';
import { ConfirmationCode } from '@app/shared/confirmationCode';
import { DialogService } from 'primeng/dynamicdialog';
import { BcPlanVendor } from '@app/shared/business-continuity/technologies/bc-plan-vendor';
import { GetPortalPlanTechnologyMessageRecipientsResponse } from '@app/shared/business-continuity/technologies/tech-message-recipient.model';
import { PortalPlanTechnologyDependency, PortalPlanTechnologyForDependencies } from '@app/shared/business-continuity/technologies/bc-plan-technology-dependency';
import {
	ModelGetPortalPlanTechnologiesResponse,
	ModelGetPortalPlanTechnologiesTiersResponse,
	ModelGetPortalPlanTechnologiesWithDetailsResponse,
	ModelGetPortalPlanTechnologyResponse,
	PlanTechnology
} from '@app/shared/business-continuity/technologies/bc-plan-technology';
import { TechnologyTask, TechnologyTaskSet } from '@app/shared/business-continuity/technologies/technology-task-set';
import { BcPortalPlanTechnologyGap } from '@app/shared/business-continuity/bc-portal-plan-technology-gap.model';

@Injectable({
	providedIn: 'root'
})
export class BusinessContinuityService {
	public isBcStrategySettingsLoaded: boolean = false;
	private navigationChangedSubject = new Subject<NavigationModel>();
	private issueSelectedSource = new Subject<BCIssue>();
	private issueSavedSource = new Subject<BCIssue>();
	private allowLocationBasedStrategies: boolean = false;
	private allowScenarioBasedStrategies: boolean = false;
	private resizeExerciseFrameSubject = new Subject();
	private _revisionItemTypes: Array<PortalRevisionItemType> = [];
	private industryPlanTypeFeatures: Array<any> = [];
	hasChangePlanSubscriptions$: Subject<boolean> = new Subject<boolean>();

	constructor(private api: ApiService,
		private utility: Utility,
		private dialogS: DialogService,
		private localStorageService: LocalStorageService,
		private permissionService: PermissionService,
		public translateService: TranslateService,
		private router: Router) {
	}

	get isBusinessContinuityManager(): boolean {
		return this.permissionService.hasPermission(permissionFeatures.agilityPlanner.key, permissionFeatures.agilityPlanner.actions.businessContinuityManagement);
	}

	get isBusinessContinuityAuditor(): boolean {
		return this.permissionService.hasPermission(permissionFeatures.agilityPlanner.key, permissionFeatures.agilityPlanner.actions.businessContinuityAudit);
	}

	get isActionPlanManager(): boolean {
		return this.permissionService.hasPermission(permissionFeatures.incidentManagement.key, permissionFeatures.incidentManagement.actions.managePlans);
	}

	get isActionPlanViewer(): boolean {
		return this.permissionService.hasPermission(permissionFeatures.incidentManagement.key, permissionFeatures.incidentManagement.actions.viewPlans);
	}

	get isAnnexLead(): boolean {
		return this.localStorageService.get(storageKeys.isBusinessContinuityAnnexLead);
	}

	get isTechnologyOwner(): boolean {
		return this.localStorageService.get(storageKeys.isBusinessContinuityTechnologyOwner);
	}

	get canManageIncident(): boolean {
		return this.permissionService.hasPermission(permissionFeatures.incidentManagement.key, permissionFeatures.incidentManagement.actions.incidentManagement);
	}

	get canViewActionPlans(): boolean {
		return this.isActionPlanManager || this.isActionPlanViewer || this.isBusinessContinuityManager || this.isBusinessContinuityAuditor || this.utility.isBusinessContinuityTeamLead;
	}

	get canManageActionPlans(): boolean {
		return this.isActionPlanManager || this.isBusinessContinuityManager || this.utility.isBusinessContinuityTeamLead;
	}

	get actionPlanIncidentIdInStorage(): string {
		return this.localStorageService.get(storageKeys.actionPlanIncidentId);
	}

	get portalImpactTimeSpans(): Array<ImpactTimespan> {
		const timeSpans = this.localStorageService.get(storageKeys.impactTimeSpans) as Array<ImpactTimespan>;
		if (timeSpans) {
			return timeSpans.filter(ts => ts.impactTimespanStatusTypeId === impactTimespanStatusTypes.active);
		}
		else {
			return [];
		}
	}

	get defaultImpactTimeSpans(): Array<ImpactTimespan> {
		const timeSpans = this.localStorageService.get(storageKeys.impactTimeSpans) as Array<ImpactTimespan>;
		if (timeSpans) {
			return timeSpans.filter(ts => String.isNullOrEmpty(ts.portalId));
		}
		else {
			return [];
		}
	}

	get allPortalImpactTimeSpans(): Array<ImpactTimespan> {
		const timeSpans = this.localStorageService.get(storageKeys.impactTimeSpans) as Array<ImpactTimespan>;
		return timeSpans || [];
	}

	get portalImpactCategories(): Array<ImpactCategory> {
		const impactCategories = this.localStorageService.get(storageKeys.impactCategories) as Array<ImpactCategory>;
		if (impactCategories) {
			return impactCategories.filter(ts => ts.isEnabled);
		}
		else {
			return [];
		}
	}

	get defaultImpactCategories(): Array<ImpactCategory> {
		const impactCategories = this.localStorageService.get(storageKeys.impactCategories) as Array<ImpactCategory>;
		if (impactCategories) {
			return impactCategories.filter(ts => ts.isDefault);
		}
		else {
			return [];
		}
	}

	get portalImpactSeverities(): Array<ImpactSeverity> {
		const impactSeverities = this.localStorageService.get(storageKeys.impactSeverities) as Array<ImpactSeverity>;
		return impactSeverities || [];
	}

	get isExercise(): boolean {
		const isExercise: boolean = this.localStorageService.get(storageKeys.isExercise);
		return isExercise === true || !String.isNullOrEmpty(this.localStorageService.get(storageKeys.exerciseId));
	}

	get exerciseId(): string {
		const exerciseId: string = this.localStorageService.get(storageKeys.exerciseId);
		return String.isNullOrEmpty(exerciseId) ? null : exerciseId;
	}

	get isLocationBasedStrategy(): boolean {
		return this.allowLocationBasedStrategies;
	}

	getRevisionItemTypes(revisionScope: string, revisionCategory?: string): Array<any> {
		if (this._revisionItemTypes == null || this._revisionItemTypes.length === 0) {
			this._revisionItemTypes = this.localStorageService.get(storageKeys.revisionItemTypes) as Array<PortalRevisionItemType>;
		}

		return this._revisionItemTypes
			.filter(r => r.revisionScope === revisionScope && (revisionCategory == null || r.revisionCategory === revisionCategory))
			.map((r) => {
				return r.revisionItemTypeId;
			});
	}

	setRevisionItemTypes(revisionItemTypes: Array<PortalRevisionItemType>) {
		if (revisionItemTypes && revisionItemTypes.length > 0) {
			this._revisionItemTypes = revisionItemTypes;
			this.localStorageService.set(storageKeys.revisionItemTypes, revisionItemTypes);
		}
	}

	getPlanTargetTypeName(plan: PlanTemplate) {
		return _.findKey(planTemplateTargetTypeIds, e => e == plan.planTemplateTargetTypeId);
	}

	getPlanTypeName(type) {
		return _.findKey(planTemplateTypeIds, e => e == type);
	}

	enterExerciseMode(exerciseId?: string) {
		this.localStorageService.set(storageKeys.isExercise, true);
		this.localStorageService.set(storageKeys.actionPlanIncidentId, null);
		if (!String.isNullOrEmpty(exerciseId)) {
			this.localStorageService.set(storageKeys.exerciseId, exerciseId);
		}
	}

	exitExerciseMode() {
		this.localStorageService.set(storageKeys.isExercise, false);
		this.localStorageService.set(storageKeys.exerciseId, null);
		this.localStorageService.set(storageKeys.actionPlanIncidentId, null);
	}

	setExerciseId(exerciseId: string) {
		if (String.isNullOrEmpty(exerciseId)) {
			exerciseId = null;
		}
		this.localStorageService.set(storageKeys.exerciseId, exerciseId);
	}

	canManageActionPlan(plan: PortalFunctionalAreaPlan) {
		return this.isActionPlanManager || this.isBusinessContinuityManager || this.isDepartmentLead((plan));
	}

	canManageProcessPlan(plan: PortalProcessPlan) {
		return this.isActionPlanManager || this.isBusinessContinuityManager || this.isDepartmentLead(plan.portalFunctionalArea);
	}

	canManageDepartment(pfa: PortalFunctionalArea) {
		return pfa ? this.isBusinessContinuityManager || this.isDepartmentLead(pfa) : false;
	}

	canAccessDepartment(pfa: PortalFunctionalArea) {
		return (this.isBusinessContinuityAuditor || this.hasAssignments(pfa)) && pfa?.style.status === bcRevisionStateType.complete;
	}

	canManageProcess(p: PortalProcess) {
		const userId = this.utility.getUserId();
		return p ? this.isBusinessContinuityManager || (this.isProcessSME(p) || this.isDepartmentLead(p.portalFunctionalArea)) : false;
	}

	canManageAnnex(a: PortalAnnex) {
		const userId = this.utility.getUserId();
		return a ? this.isBusinessContinuityManager || (a && a.assignees[0]?.assignedUserId === userId) : false;
	}

	canManageTechnology(t: PlanTechnology) {
		const userId = this.utility.getUserId();
		return t ? this.isBusinessContinuityManager || (t && (t.owner.userId === userId || t.alternateOwners.map(al => al.userId).includes(userId))) : false;
	}

	isDepartmentLead(pfa: PortalFunctionalArea) {
		const userId = this.utility.getUserId();
		return pfa && (pfa.leader?.userId === userId || pfa.alternateLeaders?.map(u => u.userId).includes(userId));
	}

	isProcessSME(p: PortalProcess) {
		const userId = this.utility.getUserId();
		return p && (p.responsibleUser?.userId === userId || p.alternateResponsibleUsers?.map(user => user.userId).includes(userId));
	}

	hasAssignments(pfa: PortalFunctionalArea): boolean {
		return pfa.portalProcesses.findIndex(p => this.isProcessSME(p)) !== -1;
	}

	onNavigationChanged() {
		return this.navigationChangedSubject.asObservable();
	}

	setNavigation(navigation: NavigationModel) {
		this.navigationChangedSubject.next(navigation);
	}

	onResizeExerciseFrame() {
		return this.resizeExerciseFrameSubject.asObservable();
	}

	resizeExerciseFrame() {
		this.resizeExerciseFrameSubject.next();
	}

	getRTOTimespanName(impactTimespanId: string): string {
		const languageCode = this.utility.getLanguageCode();
		const timespans: Array<ImpactTimespan> = this.allPortalImpactTimeSpans;
		let timespanName: string = null;
		if (timespans) {
			timespanName = timespans.find(ts => ts.impactTimespanId === impactTimespanId)?.timespanNameTranslations
				.find(t => t.languageCode === languageCode)?.translatedTimespanName;
		}
		return timespanName;
	}

	getImpactCategoryName(impactCategoryId: string) {
		const languageCode = this.utility.getLanguageCode();
		const categories: Array<ImpactCategory> = this.portalImpactCategories;
		let categoryName: string = null;
		if (categories) {
			categoryName = categories.find(ts => ts.impactCategoryId === impactCategoryId)?.categoryTranslations
				.find(t => t.languageCode === languageCode)?.translatedCategoryName;
		}
		return categoryName;
	}

	getImpactCategoryDescription(impactCategoryId: string) {
		const languageCode = this.utility.getLanguageCode();
		const categories: Array<ImpactCategory> = this.portalImpactCategories;
		let categoryDescription: string = null;
		if (categories) {
			const category: ImpactCategory = categories.find(ts => ts.impactCategoryId === impactCategoryId);
			categoryDescription = category?.categoryTranslations
				.find(t => t.languageCode === languageCode)?.translatedCategoryDescription || category?.categoryDescription;
		}
		return categoryDescription;
	}

	getImpactSeverityName(impactSeverityId: string) {
		const languageCode = this.utility.getLanguageCode();
		const severities: Array<ImpactSeverity> = this.portalImpactSeverities;
		let severityName: string = null;
		if (severities) {
			severityName = severities.find(ts => ts.impactSeverityId === impactSeverityId)?.severityNameTranslations
				.find(t => t.languageCode === languageCode)?.translatedSeverityName;
		}
		return severityName;
	}

	saveIndustry(industry: Industry) {
		return this.api.post('saveIndustry', { industry: industry });
	}

	copyBcIndustry(industryName: string, copyIndustryId: string) {
		return this.api.post('copyBcIndustry', {
			industryNameAsBase64: this.utility.base64Encode(industryName),
			copyIndustryId: copyIndustryId
		});
	}

	exportBcIndustry(industryId: string, includeProcesses: boolean = false, includeProcessImpacts: boolean = false, includeProcessStrategies: boolean = false) {
		return this.api.post('exportBcIndustry', {
			industryId: industryId,
			includeProcesses: includeProcesses,
			includeProcessImpacts: includeProcessImpacts,
			includeProcessStrategies: includeProcessStrategies
		}, true);
	}

	uploadIndustryFile(fileAsBase64: string, fileName: string) {
		const request = {
			fileNameAsBase64: this.utility.base64Encode(fileName),
			fileAsBase64: fileAsBase64
		};

		return this.api.post('importBcIndustry', request);
	}

	getIndustries() {
		return this.api.post('getindustries', {});
	}

	getIndustry(industryId: string, includeProcesses: boolean = false, includeProcessImpacts: boolean = false, includeProcessStrategies: boolean = false) {
		return this.api.post('getindustry', {
			industryId: industryId,
			includeProcesses: includeProcesses,
			includeProcessImpacts: includeProcessImpacts,
			includeProcessStrategies: includeProcessStrategies
		});
	}

	getPortalIndustry(portalId?: string) {
		portalId = portalId || this.utility.getCurrentPortalId();
		return this.api.post('getportalindustry', { portalId: portalId });
	}

	savePortalIndustry(portalIndustry: PortalIndustry) {
		return this.api.post('savePortalIndustry', { portalIndustry: portalIndustry });
	}

	getPortalSites() {
		return this.api.post('getPortalSites', {});
	}

	getPortalSite(siteId: string) {
		return this.api.post('getPortalSite', { siteId: siteId });
	}

	savePortalSite(portalSite: PortalSite, portalPlanTypeId: string) {
		return this.api.post('savePortalSite', { portalSite: portalSite, portalPlanTypeId: portalPlanTypeId });
	}

	resetPortalSite() {
		return this.api.post('resetPortalSite', {});
	}

	getFunctionalAreas(industryId: string) {
		return this.api.post('getFunctionalAreas', { industryId: industryId });
	}

	saveTemplateFunctionalArea(functionalArea: FunctionalArea) {
		return this.api.post('saveBcTemplateFunctionalArea', { functionalArea: functionalArea });
	}

	getFunctionalArea(functionalAreaId: string) {
		return this.api.post('getFunctionalArea', { functionalAreaId: functionalAreaId });
	}

	getPortalFunctionalAreas(siteId?: string, portalPlanTypeId?: string, assignedOnly: boolean = false, headerDataOnly: boolean = true, includeStrategyInfo: boolean = false, includeImpactInfo: boolean = false, includeDependencies = false) {
		return this.api.post('getPortalFunctionalAreas', {
			siteId: siteId,
			portalPlanTypeId: portalPlanTypeId,
			assignedOnly: assignedOnly,
			headerDataOnly: headerDataOnly,
			includeStrategyInfo: includeStrategyInfo,
			includeImpactInfo: includeImpactInfo,
			includeDependencies: includeDependencies
		});
	}

	getPortalFunctionalAreasList(siteId?: string, assignedOnly: boolean = false, headerDataOnly: boolean = true, includeStrategyInfo: boolean = false, includeImpactInfo: boolean = false, includeDependencies = false) {
		return this.api.post('getPortalFunctionalAreaList', {
			siteId: siteId,
			assignedOnly: assignedOnly,
			headerDataOnly: headerDataOnly,
			includeStrategyInfo: includeStrategyInfo,
			includeImpactInfo: includeImpactInfo,
			includeDependencies: includeDependencies
		});
	}

	getPortalFunctionalArea(portalFunctionalAreaId: string, includeStrategyInfo: boolean = false, includeImpactInfo: boolean = false): Promise<ModelGetPortalFunctionalAreaResponse> {
		return this.api.post('getportalfunctionalarea', {
			portalFunctionalAreaId: portalFunctionalAreaId,
			includeStrategyInfo: includeStrategyInfo,
			includeImpactInfo: includeImpactInfo
		}) as Promise<ModelGetPortalFunctionalAreaResponse>;
	}

	getPortalFunctionalAreasByLocation(locationIds: Array<string>) {
		return this.api.post('getportalfunctionalareasbylocation', {
			locationIds: locationIds
		});
	}

	getPortalFunctionalAreasByUser(siteId?: string) {
		return this.api.post('getPortalFunctionalAreasByUser', { siteId: siteId });
	}

	renamePortalFunctionalArea(portalFunctionalArea: PortalFunctionalArea) {
		return this.api.post('renamePortalFunctionalArea', {
			portalFunctionalArea: portalFunctionalArea
		});
	}

	showHidePortalFunctionalArea(portalFunctionalArea: PortalFunctionalArea) {
		return this.api.post('showHidePortalFunctionalArea', {
			portalFunctionalArea: portalFunctionalArea
		});
	}

	savePortalFunctionalArea(portalFunctionalArea: PortalFunctionalArea, processes: Array<PortalProcess> = [], updateProcesses: boolean = false) {
		// make sure it's not hidden if it is hidden if has leader assigned
		if (portalFunctionalArea.leader) {
			portalFunctionalArea.isHidden = false;
		}

		return this.api.post('savePortalFunctionalArea', {
			portalFunctionalArea: portalFunctionalArea,
			processes: processes,
			updateProcesses: updateProcesses
		});
	}

	getPortalFunctionalAreaMessageRecipients(siteId?: string, portalFunctionalAreaId?: string) {
		return this.api.post('getPortalFunctionalAreaMessageRecipients', {
			siteId: siteId,
			portalFunctionalAreaId: portalFunctionalAreaId
		});
	}

	updatePortalFunctionalAreaDescription(portalFunctionalArea: PortalFunctionalArea) {
		return this.api.post('UpdatePortalFunctionalAreaDescription', {
			portalFunctionalArea: portalFunctionalArea
		});
	}

	sendTeamLeadIntroductoryEmails(portalFunctionalAreaIds: Array<string>, emailTemplateAsBase64: string, emailTemplateNewUserAsBase64: string, portalPlanTypeId: string) {
		return this.api.post('sendTeamLeadIntroductoryEmails', {
			portalFunctionalAreaIds: portalFunctionalAreaIds,
			emailTemplateAsBase64: emailTemplateAsBase64,
			emailTemplateNewUserAsBase64: emailTemplateNewUserAsBase64,
			portalPlanTypeId: portalPlanTypeId
		});
	}

	searchUsersForTeamLead(searchPhrase: string, maxRecords: number, locationIds: Array<string> = null, groupIds: Array<string> = null, departmentIds: Array<string> = null, roleBundleIds: Array<string> = null) {
		const request = {
			searchPhraseAsBase64: this.utility.base64Encode(searchPhrase),
			locationIds: locationIds,
			groupIds: groupIds,
			departmentIds: departmentIds,
			roleBundleIds: roleBundleIds,
			maxRecords: maxRecords
		};
		return this.api.post('searchusersforteamlead', request);
	}

	searchUsersForBCMessaging(searchPhrase: string, maxRecords: number, locationIds: Array<string> = null, groupIds: Array<string> = null, departmentIds: Array<string> = null, roleBundleIds: Array<string> = null) {
		const request = {
			searchPhraseAsBase64: this.utility.base64Encode(searchPhrase),
			locationIds: locationIds,
			groupIds: groupIds,
			departmentIds: departmentIds,
			roleBundleIds: roleBundleIds,
			maxRecords: maxRecords
		};
		return this.api.post('searchusersforbcmessaging', request);
	}

	getPortalProcess(portalProcessId: string) {
		return this.api.post('getBcPortalProcess', { portalProcessId: portalProcessId });
	}

	saveTemplateProcess(process: Process) {
		return this.api.post('saveBcTemplateProcess', { process: process });
	}

	getProcesses(functionalAreaId: string) {
		return this.api.post('getBcProcesses', {
			functionalAreaId: functionalAreaId
		});
	}

	getPortalProcesses(siteId: string, portalFunctionalAreaId?: string, assignedOnly: boolean = false) {
		return this.api.post('getBcPortalProcesses', {
			siteId: siteId,
			portalFunctionalAreaId: portalFunctionalAreaId,
			assignedOnly: assignedOnly
		});
	}

	getPortalProcessesByUser(siteId?: string) {
		return this.api.post('getPortalProcessesByUser', { siteId: siteId });
	}

	savePortalProcess(portalProcess: PortalProcess) {
		// make sure it's not hidden if has responsibleUser assigned
		if (portalProcess.responsibleUser) {
			portalProcess.isHidden = false;
		}

		return this.api.post('saveBcPortalProcess', { portalProcess: portalProcess });
	}

	renamePortalProcess(portalProcess: PortalProcess) {
		return this.api.post('renameBcPortalProcess', { portalProcess: portalProcess });
	}

	showHidePortalProcess(portalProcess: PortalProcess) {
		return this.api.post('showHideBcPortalProcess', { portalProcess: portalProcess });
	}

	getPortalProcessMessageRecipients(siteId?: string, portalFunctionalAreaId?: string, portalProcessId?: string) {
		return this.api.post('getPortalProcessMessageRecipients', {
			siteId: siteId,
			portalFunctionalAreaId: portalFunctionalAreaId,
			portalProcessId: portalProcessId
		});
	}

	sendTeamMemberIntroductoryEmails(portalFunctionalAreaId: string, portalProcessIds: Array<string>, emailTemplateAsBase64: string, emailTemplateNewUserAsBase64: string, portalPlanTypeId: string) {
		return this.api.post('sendTeamMemberIntroductoryEmails', {
			portalFunctionalAreaId: portalFunctionalAreaId,
			portalProcessIds: portalProcessIds,
			emailTemplateAsBase64: emailTemplateAsBase64,
			emailTemplateNewUserAsBase64: emailTemplateNewUserAsBase64,
			portalPlanTypeId: portalPlanTypeId
		});
	}

	getTemplateProcessImpacts(processId: string) {
		return this.api.post('getBcTemplateProcessImpacts', { processId: processId });
	}

	saveTemplateProcessImpacts(process: Process) {
		return this.api.post('saveBcTemplateProcessImpacts', { process: process });
	}

	getPortalProcessImpacts(portalProcessId: string) {
		return this.api.post('getBcPortalProcessImpacts', { portalProcessId: portalProcessId });
	}

	savePortalProcessImpacts(portalProcess: PortalProcess) {
		return this.api.post('saveBcPortalProcessImpacts', { portalProcess: portalProcess });
	}

	getPortalProcessCriticalDates(portalProcessId: string) {
		return this.api.post('getPortalProcessCriticalDates', { portalProcessId: portalProcessId });
	}

	savePortalProcessCriticalDates(portalProcess: PortalProcess) {
		return this.api.post('savePortalProcessCriticalDates', { portalProcess: portalProcess });
	}

	getCurrentPortalRevisions(siteId: string, portalPlanTypeId: string): Promise<ModelGetCurrentRevisionPeriodResponse> {
		return this.api.post('getCurrentRevisionPeriodBySiteId', { siteId: siteId, portalPlanTypeId: portalPlanTypeId }) as Promise<ModelGetCurrentRevisionPeriodResponse>;
	}

	getCurrentPortalRevisionsByPortalFunctionalArea(portalFunctionalAreaId: string, portalPlanTypeId: string) {
		return this.api.post('getCurrentRevisionPeriodByPortalFunctionalArea', {
			portalFunctionalAreaId: portalFunctionalAreaId,
			portalPlanTypeId: portalPlanTypeId
		});
	}

	getCurrentRevisionPeriodByPortalPlanTechnology(portalPlanTechnologyId: string, portalPlanTypeId: string) {
		return this.api.post('getCurrentRevisionPeriodByPortalPlanTechnology', {
			portalPlanTechnologyId,
			portalPlanTypeId
		});
	}

	getCurrentRevisionPeriodByPortalAnnex(portalAnnexId: string, portalPlanTypeId: string) {
		return this.api.post('GetCurrentRevisionPeriodByPortalAnnex', {
			portalAnnexId,
			portalPlanTypeId
		});
	}

	getCurrentPortalRevisionsByPortalProcess(portalProcessId: string, portalPlanTypeId: string) {
		return this.api.post('getCurrentRevisionPeriodByPortalProcess', { portalProcessId: portalProcessId, portalPlanTypeId: portalPlanTypeId });
	}

	getPortalRevisionItemTypes(portalPlanTypeId?: string) {
		return this.api.post('getPortalRevisionItemTypes', { portalPlanTypeId: portalPlanTypeId });
	}

	getRevisionPeriodsForSite(siteId: string, portalPlanTypeId: string) {
		return this.api.post('SelectRevisionPeriodsBySiteId', { siteId: siteId, portalPlanTypeId: portalPlanTypeId });
	}

	saveRevisionPeriod(revision: RevisionPeriod, portalPlanTypeId: string) {
		return this.api.post('saveRevisionPeriod', { revisionPeriod: revision, portalPlanTypeId: portalPlanTypeId });
	}

	startNewRevisionPeriod(revision: RevisionPeriod) {
		return this.api.post('StartNewRevisionPeriod', { revisionPeriod: revision });
	}

	setSiteRevisionItem(revisionItem: RevisionItem, portalPlanTypeId: string) {
		return this.api.post('setSiteRevisionItem', { revisionItem: revisionItem, portalPlanTypeId: portalPlanTypeId });
	}

	getPortalEquipmentTypes(getAssignedPortalProcess: boolean = false) {
		return this.api.post('getportalequipmenttypes', { getAssignedPortalProcess: getAssignedPortalProcess });
	}

	savePortalEquipmentType(equipmentType: EquipmentType) {
		return this.api.post('saveportalequipmenttype', { equipmentType: equipmentType });
	}

	saveExcludedSystemEquipmentType(equipmentTypeId: string, portalId: string) {
		return this.api.post('SaveExcludedSystemEquipmentTypes', { equipmentTypeId, portalId });
	}

	getPortalVendors(getAssignedPortalProcess: boolean = false) {
		return this.api.post('getportalvendors', { getAssignedPortalProcess: getAssignedPortalProcess });
	}

	savePortalVendor(vendor: Vendor) {
		return this.api.post('saveportalvendor', { vendor: vendor });
	}

	uploadPortalVendorDocument(vendorId: string, document: Document) {
		return this.api.post('uploadportalvendordocument', {
			vendorId: vendorId,
			document: document
		});
	}

	getPortalVendorDocuments(vendorId: string) {
		return this.api.post('getportalvendordocuments', {
			vendorId: vendorId
		});
	}

	getPortalVendorDocumentAsStream(documentId: string) {
		return this.api.post('GetPortalVendorDocumentAsStream', { documentId: documentId }, true);
	}

	deletePortalVendorDocuments(documentId: string) {
		return this.api.post('deleteportalvendordocument', {
			documentId: documentId
		});
	}

	getPortalAgencies(getAssignedAnnexes?: boolean, searchPhraseAsBase64?: string, AgencyTypeIds?: Array<string>) {
		return this.api.post('getportalagencies', { getAssignedAnnexes, searchPhraseAsBase64, AgencyTypeIds });
	}

	savePortalAgency(agency: Agency) {
		return this.api.post('saveportalagency', { agency });
	}

	uploadPortalAgencyDocument(agencyId: string, document: Document) {
		return this.api.post('uploadportalagencydocument', {
			agencyId,
			document
		});
	}

	getPortalAgencyDocuments(agencyId: string) {
		return this.api.post('getportalagencydocuments', {
			agencyId
		});
	}

	getPortalAgencyDocumentAsStream(documentId: string) {
		return this.api.post('GetPortalagencyDocumentAsStream', { documentId: documentId }, true);
	}

	deletePortalAgencyDocuments(documentId: string) {
		return this.api.post('deleteportalagencydocument', {
			documentId: documentId
		});
	}

	getTechnologies(getAssignedPortalProcess: boolean = false, filters?: TechnologyRequestFilter, portalPlanTypeId?: string) {
		return this.api.post('gettechnologies', { portalPlanTypeId, getAssignedPortalProcess: getAssignedPortalProcess, ...filters });
	}

	saveTechnology(technology: Technology) {
		return this.api.post('savetechnology', { technology: technology });
	}

	getPortalPlanTechnology(portalTPlanTechnologyId: string): Promise<ModelGetPortalPlanTechnologyResponse> {
		return this.api.post('getportalplantechnology', { portalPlanTechnologyId: portalTPlanTechnologyId }) as Promise<ModelGetPortalPlanTechnologyResponse>;
	}

	getPortalProcessStrategy(portalProcessStrategyId: string) {
		return this.api.post('getportalprocessstrategy', { portalProcessStrategyId: portalProcessStrategyId });
	}

	getPortalProcessStrategies(portalProcessId: string) {
		return this.api.post('getPortalProcessStrategies', { portalProcessId: portalProcessId });
	}

	savePortalProcessStrategy(processStrategy: PortalProcessStrategy, removeAllResources: boolean = false) {
		return this.api.post('saveportalprocessstrategy', {
			processStrategy: processStrategy,
			removeAllResources: removeAllResources
		});
	}

	deletePortalProcessStrategy(portalProcessStrategyId: string) {
		return this.api.post('deleteportalprocessstrategy', {
			portalProcessStrategyId: portalProcessStrategyId
		});
	}

	getTemplateProcessStrategy(processId: string) {
		return this.api.post('gettemplateprocessstrategy', { processId: processId });
	}

	saveTemplateProcessStrategy(processStrategy: ProcessStrategy) {
		return this.api.post('savetemplateprocessstrategy', { processStrategy: processStrategy });
	}

	getBcEmailTemplates(): Promise<EntitySetting[]> {
		return this.api.post('getbcemailtemplates', {}) as Promise<EntitySetting[]>;
	}

	calcRevisionScopeStyle(revision: RevisionPeriod | PortalProcess, revisionScope: string, portalFunctionalAreaId: string = null, portalProcessId: string = null, portalAnnexId: string = null, portalPlanTechnologyId: string = null): RevisionStatusStyle {
		return this.calcRevisionStyle(revision, this.getRevisionItemTypes(revisionScope), portalFunctionalAreaId, portalProcessId, portalAnnexId, portalPlanTechnologyId);
	}

	calcRevisionCategoryStyle(revision: RevisionPeriod | PortalProcess, revisionScope: string, revisionCategory: string, portalFunctionalAreaId: string = null, portalProcessId: string = null, portalAnnexId: string = null, portalPlanTechnologyId: string = null): RevisionStatusStyle {
		return this.calcRevisionStyle(revision, this.getRevisionItemTypes(revisionScope, revisionCategory), portalFunctionalAreaId, portalProcessId, portalAnnexId, portalPlanTechnologyId);
	}

	calcRevisionItemStyle(revision: RevisionPeriod | PortalProcess, revisionItemTypeId: string, portalFunctionalAreaId: string = null, portalProcessId: string = null, portalAnnexId: string = null, portalPlanTechnologyId: string = null): RevisionStatusStyle {
		// return this.calcRevisionStyle(revision, this.getRevisionItemType(revisionItemTypeId), portalFunctionalAreaId, portalProcessId, portalAnnexId);
		const revisionItem: RevisionItem = revision.revisionItems.find(rri =>
			rri.revisionItemTypeId === revisionItemTypeId
			&& rri.portalFunctionalAreaId === portalFunctionalAreaId
			&& rri.portalProcessId === portalProcessId
			&& rri.portalAnnexId === portalAnnexId
			&& rri.portalPlanTechnologyId === portalPlanTechnologyId);
		const status: string = revisionItem?.revisionStateTypeId ?? bcRevisionStateType.notStarted;
		let style = bcStatusStyle.none;
		switch (status) {
			case bcRevisionStateType.notStarted:
				style = bcStatusStyle.none;
				break;
			case bcRevisionStateType.inProcess:
				style = bcStatusStyle.inProgress;
				break;
			case bcRevisionStateType.complete:
				style = bcStatusStyle.done;
				break;
			case bcRevisionStateType.new:
				style = bcStatusStyle.new;
				break;
		}
		style = _.cloneDeep(style);
		style.revisionItemType = revisionItemTypeId;

		return style;
	}

	calcRevisionStyle(revision: RevisionPeriod | PortalProcess, revisionItemTypes: Array<any>, portalFunctionalAreaId: string = null, portalProcessId: string = null, portalAnnexId: string = null, portalPlanTechnologyId: string = null): RevisionStatusStyle {
		let status: string = bcRevisionStateType.notStarted;
		let revisionItemType: string = null;
		if (revisionItemTypes) {
			revisionItemTypes.forEach((ri) => {
				const revisionItem: RevisionItem = revision.revisionItems.find(rri =>
					rri.revisionItemTypeId === ri
					&& rri.portalFunctionalAreaId === portalFunctionalAreaId
					&& rri.portalProcessId === portalProcessId
					&& rri.portalAnnexId === portalAnnexId
					&& rri.portalPlanTechnologyId === portalPlanTechnologyId);
				const currItemStatus: string = revisionItem?.revisionStateTypeId ?? bcRevisionStateType.notStarted;
				if (status === bcRevisionStateType.notStarted) {
					status = currItemStatus;
				}
				else if (status === bcRevisionStateType.inProcess) {
					// leave as inProcess regardless of other items.
					// if one is inProcess it means the whole category is in progress
				}
				else if (status === bcRevisionStateType.new) {
					// if one is inProcess it means the whole category is in progress
					status = bcRevisionStateType.new;
				}
				else if (status === bcRevisionStateType.complete) {
					// If status is complete but we found and incomplete item, set it back to in progress
					status = (currItemStatus === bcRevisionStateType.complete || currItemStatus === bcRevisionStateType.new) ? bcRevisionStateType.complete : bcRevisionStateType.inProcess;
				}
				if (status === bcRevisionStateType.inProcess && revisionItemType == null) {
					revisionItemType = ri;
				}
			});
		}

		let style = bcStatusStyle.none;
		switch (status) {
			case bcRevisionStateType.notStarted:
				style = bcStatusStyle.none;
				break;
			case bcRevisionStateType.inProcess:
				style = bcStatusStyle.inProgress;
				break;
			case bcRevisionStateType.complete:
				style = bcStatusStyle.done;
				break;
		}
		style = _.cloneDeep(style);
		style.revisionItemType = revisionItemType;

		return style;
	}

	getProcessProgressStyle(process: PortalProcess): RevisionStatusStyle {
		let style = bcStatusStyle.none;

		switch (process.portalProcessProgressId) {
			case bcRevisionStateType.notStarted:
				style = bcStatusStyle.none;
				break;
			case bcRevisionStateType.inProcess:
				style = bcStatusStyle.inProgress;
				break;
			case bcRevisionStateType.complete:
				style = bcStatusStyle.done;
				break;
		}

		style = _.cloneDeep(style);

		style.revisionItemType = ((!process.firstNotCompleteRevisionItemTypeId) || process.firstNotCompleteRevisionItemTypeId == guid.empty) ? null : process.firstNotCompleteRevisionItemTypeId;

		return style;
	}

	getCalculatedDate(date: AbsoluteDateModel, criticalDateFrequencyTypeId: string) {
		let calculatedDate;
		const dateWrapper = moment({ year: date.year, month: date.month - 1, day: date.day });
		if (criticalDateFrequencyTypeId === bcCriticalDateFrequencyTypes.semiMonthly) {
			calculatedDate = this.getTwiceAMonthValidDate(dateWrapper);
		}
		else {
			calculatedDate = dateWrapper;
		}
		return calculatedDate;
	}

	getTwiceAMonthValidDate(date: any) {
		const fixedDate = moment(date);
		const sunday = fixedDate.weekday() === 0;
		const saturday = fixedDate.weekday() === 6;
		if (saturday) {
			fixedDate.add(-1, 'days');
		}
		else if (sunday) {
			fixedDate.add(1, 'days');
		}
		return fixedDate;
	}

	getRevisionItemDueDate(revision: RevisionPeriod,
		revisionScope: string,
		portalFunctionalAreaId: string = null,
		portalProcessId: string = null,
		portalAnnexId: string = null,
		portalPlanTechnologyId: string = null): Date {
		let dueDate: Date = null;
		let revisionItem = null;
		switch (revisionScope) {
			case revisionScopes.department.key:
				revisionItem = revision.revisionItems.find(i =>
					i.revisionItemTypeId === bcRevisionItemTypes.validateProcess
					&& i.portalFunctionalAreaId === portalFunctionalAreaId
				);
				break;
			case revisionScopes.annex.key:
				revisionItem = revision.revisionItems.find(i =>
					i.revisionItemTypeId === bcRevisionItemTypes.annexSections
					&& i.portalAnnexId === portalAnnexId
				);
				break;
			case revisionScopes.process.key:
				revisionItem = revision.revisionItems.find(i =>
					i.revisionItemTypeId === bcRevisionItemTypes.impactAssessment
					&& i.portalFunctionalAreaId === portalFunctionalAreaId
					&& i.portalProcessId === portalProcessId
				);
				break;
			case revisionScopes.technology.key:
				revisionItem = revision.revisionItems.find(i =>
					i.revisionItemTypeId === bcRevisionItemTypes.technologyVendors
					&& i.portalPlanTechnologyId === portalPlanTechnologyId
				);
				break;
		}
		if (revisionItem && revisionItem.revisionItemDueDate) {
			dueDate = new Date(moment.utc(revisionItem.revisionItemDueDate).format('L'));
		}
		return dueDate;
	}

	onIssueSelected() {
		return this.issueSelectedSource.asObservable();
	}

	onIssueSaved() {
		return this.issueSavedSource.asObservable();
	}

	issueSelected(issue: BCIssue) {
		this.issueSelectedSource.next(issue);
	}

	issueSaved(issue: BCIssue) {
		this.issueSavedSource.next(issue);
	}

	getBCIssuesList(portalPlanTypeId: string, revisionItemTypeId: string, siteId: string, portalFunctionalAreaId: string, portalProcessId: string, portalAnnexId: string, portalTechnologyId: string) {
		return this.api.post('SelectBCIssuesList', {
			revisionItemTypeId: revisionItemTypeId,
			siteId: siteId,
			portalFunctionalAreaId: portalFunctionalAreaId,
			portalProcessId: portalProcessId,
			portalAnnexId: portalAnnexId,
			portalTechnologyId: portalTechnologyId,
			portalPlanTypeId: portalPlanTypeId
		});
	}

	saveBCIssue(issue: BCIssue) {
		return this.api.post('SaveBCIssue', { issue: issue });
	}

	getBCIssueComments(issueId: string) {
		return this.api.post('SelectBCIssueComments', { issueId: issueId });
	}

	saveBCIssueComment(issueComment: any) {
		return this.api.post('SaveBCIssueComment', {
			comment: issueComment,
			incidentId: this.actionPlanIncidentIdInStorage
		});
	}

	deleteBCIssueComment(issueCommentId: string) {
		return this.api.post('DeleteBCIssueComment', {
			issueCommentId: issueCommentId,
			incidentId: this.actionPlanIncidentIdInStorage
		});
	}

	getFrequencyTypeName(typeId: string): string {
		let typeName: string;
		switch (typeId) {
			case bcCriticalDateFrequencyTypes.weekly:
				typeName = 'weekly';
				break;
			case bcCriticalDateFrequencyTypes.biWeekly:
				typeName = 'biWeekly';
				break;
			case bcCriticalDateFrequencyTypes.monthly:
				typeName = 'monthly';
				break;
			case bcCriticalDateFrequencyTypes.quarterly:
				typeName = 'quarterly';
				break;
			case bcCriticalDateFrequencyTypes.semiAnnually:
				typeName = 'semiAnnually';
				break;
			case bcCriticalDateFrequencyTypes.annually:
				typeName = 'annually';
				break;
			case bcCriticalDateFrequencyTypes.oneTimeEvent:
				typeName = 'oneTimeEvent';
				break;
			case bcCriticalDateFrequencyTypes.semiMonthly:
				typeName = 'semiMonthly';
				break;
			default:
				typeName = '';
		}
		return typeName;
	}

	getDatePartDesc(typeId: string, date: AbsoluteDateModel): string {
		let datePart: string;
		const dateWrapper = moment({ year: date.year, month: date.month - 1, day: date.day });
		switch (typeId) {
			case bcCriticalDateFrequencyTypes.weekly:
				datePart = dateWrapper.format('dddd');
				break;
			case bcCriticalDateFrequencyTypes.biWeekly:
				datePart = dateWrapper.format('dddd');
				break;
			case bcCriticalDateFrequencyTypes.monthly:
				datePart = dateWrapper.format('Do');
				break;
			case bcCriticalDateFrequencyTypes.quarterly:
				datePart = dateWrapper.format('Do');
				break;
			case bcCriticalDateFrequencyTypes.semiAnnually:
				datePart = dateWrapper.format('Do');
				break;
			case bcCriticalDateFrequencyTypes.annually:
				datePart = dateWrapper.format('MMMM Do');
				break;
			case bcCriticalDateFrequencyTypes.oneTimeEvent:
				datePart = dateWrapper.format('MMMM Do, YYYY');
				break;
			default:
				datePart = '';
		}
		return datePart;
	}

	getContinuityStrategyTypeNameKey(strategyTypeId: string): string {
		let key: string = '';
		switch (strategyTypeId) {
			case bcStrategyTypes.workFromHome:
				key = 'businessContinuity.continuityStrategy.strategyTypes.workFromAnywhere';
				break;
			case bcStrategyTypes.temporaryLocation:
				key = 'businessContinuity.continuityStrategy.strategyTypes.temporaryLocation';
				break;
			case bcStrategyTypes.moveTheWork:
				key = 'businessContinuity.continuityStrategy.strategyTypes.moveTheWork';
				break;
			case bcStrategyTypes.suspend:
				key = 'businessContinuity.continuityStrategy.strategyTypes.suspend';
				break;
			default:
				key = 'common._emptyString';
		}
		return key;
	}

	getContinuityStrategyNames(strategies: Array<PortalProcessStrategy>) {
		const strategyTypeNames: string[] = [...new Set(strategies.map(s => this.translateService.instant(this.getContinuityStrategyTypeNameKey(s.strategyTypeId))))];
		return strategyTypeNames.join(', ');
	}

	getRevisionStateTypeKey(revisionStateTypeId: string): string {
		let key: string = '';
		switch (revisionStateTypeId) {
			case bcRevisionStateType.inProcess:
				key = 'businessContinuity.status.inProcess';
				break;
			case bcRevisionStateType.notStarted:
				key = 'businessContinuity.status.none';
				break;
			case bcRevisionStateType.complete:
				key = 'businessContinuity.status.complete';
				break;
			default:
				key = 'common._emptyString';
		}
		return key;
	}

	getImpactSeverityKey(impactSeverityId: string): string {
		let key: string = '';
		switch (impactSeverityId) {
			case bcImpactSeverity.none:
				key = 'businessContinuity.impact.severity.none';
				break;
			case bcImpactSeverity.insignificant:
				key = 'businessContinuity.impact.severity.insignificant';
				break;
			case bcImpactSeverity.significant:
				key = 'businessContinuity.impact.severity.significant';
				break;
			case bcImpactSeverity.catastrophic:
				key = 'businessContinuity.impact.severity.catastrophic';
				break;
			default:
				key = 'common._emptyString';
		}
		return key;
	}

	getImpactCategoryKey(industryId: string, impactCategoryId: string): string {
		let key: string = '';
		switch (impactCategoryId) {
			case bcImpactCategories.financial:
				key = 'businessContinuity.impact.category.financial';
				break;
			case bcImpactCategories.customer:
				switch (industryId) {
					case industries.banking:
						key = 'businessContinuity.impact.category.memberImpact';
						break;
					case industries.legal:
						key = 'businessContinuity.impact.category.clientImpact';
						break;
					default:
						key = 'businessContinuity.impact.category.customerImpact';
				}
				break;
			case bcImpactCategories.employee:
				key = 'businessContinuity.impact.category.employee';
				break;
			case bcImpactCategories.reputational:
				key = 'businessContinuity.impact.category.reputational';
				break;
			case bcImpactCategories.operational:
				key = 'businessContinuity.impact.category.operational';
				break;
			case bcImpactCategories.compliance:
				key = 'businessContinuity.impact.category.compliance';
				break;
		}
		return key;
	}

	// Plans
	saveDepartmentPlanTemplateAsStream(templateFileAsBase64: string, portalId?: string) {
		return this.api.post('SaveDepartmentPlanTemplateAsStream', {
			templateFileAsBase64: templateFileAsBase64,
			portalId: portalId
		}, false);
	}

	getDepartmentPlanTemplateAsStream(portalId?: string) {
		return this.api.post('GetDepartmentPlanTemplateAsStream', { portalId: portalId }, true);
	}

	deleteDepartmentPlanTemplate() {
		return this.api.post('DeletePortalDepartmentPlanTemplate', {}, false);
	}

	getBCPlanGenerationStatus(planGenJobId: string) {
		return this.api.post('GetBCPlanGenerationStatus', { planGenJobId: planGenJobId }, false);
	}

	cancelBCPlanGeneration(planGenJobId: string) {
		return this.api.post('CancelBCPlanGeneration', { planGenJobId: planGenJobId }, false);
	}

	generateBCPlan(planGenJobTypeId: string, targetId: string, planTypeId: string, utcOffset: number, timeZoneAbbr: string) {
		const portalId = this.utility.getCurrentPortalId();

		return this.api.post('GenerateBCPlan', {
			planGenJobTypeId: planGenJobTypeId,
			id: targetId,
			planTypeId,
			timeZoneInfo: {
				utcOffset,
				timeZoneAbbr
			}
		}, false);
	}

	getBcPlanGeneratedDocument(planGenJobTypeId: string, targetId: string, planTypeId: string, isZip: boolean) {
		return this.api.post('getBcPlanGeneratedDocument', {
			planGenJobTypeId: planGenJobTypeId,
			targetId,
			planTypeId,
			zip: isZip
		}, true, true);
	}

	/*
	   downloadBcPlanGeneratedStrategyAttachments(planGenJobTypeId: string, targetId: string, planTypeId: string) {
		   return this.api.post('getBcPlanGeneratedDocument', {
			   planGenJobTypeId: planGenJobTypeId,
			   id: targetId,
			   planTypeId,
			   zip: true
		   }, true, true);
	   }
   */

	generateBCPlanAttachmentsZip(planGenJobTypeId: string, targetId: string, planTypeId: string, utcOffset: number, timeZoneAbbr: string) {
		return this.api.post('generatebcplanattachmentszip', {
			planGenJobTypeId: planGenJobTypeId,
			id: targetId,
			planTypeId,
			timeZoneInfo: {
				utcOffset,
				timeZoneAbbr
			}
		}, false);
	}

	getBcPlanGeneratedPreviewDocument(planGenJobId: string) {
		return this.api.post('getbcplanpreviewdocument', {
			planGenJobId: planGenJobId
		}, true);
	}

	getBcStrategyAttachments(portalFunctionalAreaId: string, notPDFConvertibleDocumentTypesOnly: boolean = false, locationId?: string) {
		return this.api.post('getbcstrategyattachmentsasstream', {
			portalFunctionalAreaId: portalFunctionalAreaId,
			notPDFConvertibleDocumentTypesOnly: notPDFConvertibleDocumentTypesOnly,
			locationId: locationId
		}, true);
	}

	saveBcPointOfInterest(pointOfInterest: PointOfInterestModel) {
		return this.api.post('saveBcPointOfInterest', { pointOfInterest: pointOfInterest });
	}

	getBcProcessStrategyDocuments(processStrategyId: string) {
		return this.api.post('getbcprocessstrategydocuments', { processStrategyId: processStrategyId });
	}

	uploadBcProcessStrategyDocument(
		processStrategyId: string,
		portalProcessId: string,
		impactTimespanId: string,
		strategyTypeId: string,
		document: Document) {
		return this.api.post('uploadbcprocessstrategydocument', {
			processStrategyId: processStrategyId,
			portalProcessId: portalProcessId,
			impactTimespanId: impactTimespanId,
			strategyTypeId: strategyTypeId,
			document: document
		});
	}

	deleteBcProcessStrategyDocument(documentId: string) {
		return this.api.post('deletebcprocessstrategydocument', { documentId: documentId });
	}

	getBcProcessStrategyDocumentAsStream(documentId: string) {
		return this.api.post('getbcprocessstrategydocumentasstream', { documentId: documentId }, true);
	}

	filterFunctionalAreasByResponsibility(portalFunctionalAreas: Array<PortalFunctionalArea>): Array<PortalFunctionalArea> {
		const assignedPortalFunctionalAreas = new Array<PortalFunctionalArea>();
		portalFunctionalAreas.forEach((pfa: PortalFunctionalArea) => {
			if (pfa.portalProcesses.length > 0) {
				pfa.portalProcesses.map((pp: PortalProcess) => {
					pp.portalFunctionalArea = <PortalFunctionalArea>{
						portalFunctionalAreaId: pfa.portalFunctionalAreaId,
						portalFunctionalAreaName: pfa.portalFunctionalAreaName,
						leader: pfa.leader,
						alternateLeaders: pfa.alternateLeaders
					};
					return pp;
				});
			}
			if (this.isBusinessContinuityManager || this.isBusinessContinuityAuditor || this.isDepartmentLead(pfa)) {
				assignedPortalFunctionalAreas.push(pfa);
			}
			else {
				pfa.portalProcesses = pfa.portalProcesses.filter((pp: PortalProcess) => this.isProcessSME(pp));
				if (pfa.portalProcesses.length > 0) {
					assignedPortalFunctionalAreas.push(pfa);
				}
			}
		});
		return assignedPortalFunctionalAreas;
	}

	getRelatedIssues(issues: Array<BCIssue>, assignedPortalFunctionalAreas: Array<PortalFunctionalArea>, annexes: Array<PortalAnnex>, technologies?: PlanTechnology[]): Array<BCIssue> {
		const relatedIssues: BCIssue[] = new Array<BCIssue>();
		const currentUserId: string = this.utility.getUserId();
		const assignedProcesses: PortalProcess[] = assignedPortalFunctionalAreas?.reduce((pps: Array<PortalProcess>, pfa: PortalFunctionalArea) => pps = pps
			.concat(pfa.portalProcesses
				.map((pp: PortalProcess) => pp)), new Array<PortalProcess>());
		issues.forEach((i: BCIssue) => {
			const dept: PortalFunctionalArea = assignedPortalFunctionalAreas?.find(pfa => pfa.portalFunctionalAreaId === i.portalFunctionalAreaId);
			const proc: PortalProcess = assignedProcesses?.find((pp: PortalProcess) => pp.portalProcessId === i.portalProcessId);
			const annex: PortalAnnex = annexes?.find((annex => annex.portalAnnexId === i.portalAnnexId));

			if (this.isBusinessContinuityManager
			  || this.isBusinessContinuityAuditor
			  || i.ownerUserId === currentUserId
			  || (dept && dept.leader && dept.leader.userId === currentUserId)
			  || (annex && annex.assignees.length && annex.assignees.findIndex((assigned => assigned.assignedUserId === currentUserId)))
			  || (proc)
			  || technologies?.find((t: PlanTechnology) => t.owner.userId === currentUserId)) {
				i.portalFunctionalAreaName = dept?.portalFunctionalAreaName || proc?.portalFunctionalArea.portalFunctionalAreaName;
				i.portalProcessName = proc?.portalProcessName;
				relatedIssues.push(i);
			}
		});
		return relatedIssues.sort((a: BCIssue, b: BCIssue) => (a.dateTimeCreated < b.dateTimeCreated) ? 1 : -1);
	}

	getCriticalDates(assignedPortalFunctionalAreas: Array<PortalFunctionalArea>) {
		return new Array<BcCriticalDatesOverviewEvent>()
			.concat(assignedPortalFunctionalAreas
				.reduce((current: Array<BcCriticalDatesOverviewEvent>, pfa: PortalFunctionalArea) => current
					.concat(pfa.portalProcesses
						.reduce((current: Array<BcCriticalDatesOverviewEvent>, pp: PortalProcess) => current
							.concat(pp.criticalDates
								.map((pcd: ProcessCriticalDate) => {
									pp.portalFunctionalArea = pfa;
									return <BcCriticalDatesOverviewEvent>{ criticalDate: pcd, portalProcess: pp };
								})), []), []), []));
	}

	getCurrentPortalIndustry() {
		return new Promise((resolve, reject) => {
			let portalIndustry: PortalIndustry = this.localStorageService.get(storageKeys.portalIndustry) as PortalIndustry;
			if (portalIndustry == null || String.isNullOrEmpty(portalIndustry.industryId)) {
				this.getPortalIndustry().then((response: any) => {
					if (response.portalIndustry) {
						portalIndustry = response.portalIndustry;
						this.localStorageService.set(storageKeys.portalIndustry, portalIndustry);
						resolve(portalIndustry);
					}
					else {
						resolve(null);
					}
				}).catch((_) => {
					reject();
				});
			}
			else {
				resolve(portalIndustry);
			}
		});
	}

	getCurrentPortalSite() {
		return new Promise((resolve, reject) => {
			let portalSite: PortalSite = this.localStorageService.get(storageKeys.portalSite) as PortalSite;
			if (portalSite == null || portalSite.portalId !== this.utility.getCurrentPortalId()) {
				this.getPortalSites().then((response: any) => {
					if (response.portalSites && response.portalSites.length > 0) {
						portalSite = response.portalSites[0];
						this.localStorageService.set(storageKeys.portalSite, portalSite);
						resolve(portalSite);
					}
					else {
						resolve(null);
					}
				}).catch((_) => {
					reject();
				});
			}
			else {
				resolve(portalSite);
			}
		});
	}

	hasBCManagers(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			let hasBCManagers: boolean = this.localStorageService.get(storageKeys.hasBusinessContinuityManagers) as boolean;
			if (hasBCManagers == null || hasBCManagers !== true) {
				this.api.post('HasBusinessContinuityManagers', {}).then((response: any) => {
					hasBCManagers = response.hasBusinessContinuityManagers;
					this.localStorageService.set(storageKeys.hasBusinessContinuityManagers, hasBCManagers);
					resolve(hasBCManagers);
				}).catch((_) => {
					// errorCode 60003 - go ahead resolve false. no reason to be rejecting the promise. causes code issues.
					if (_.errorCode === serviceErrorCodes.bcProcessPermissionError) {
						resolve(false);
					}

					reject();
				});
			}
			else {
				resolve(hasBCManagers);
			}
		});
	}

	// IMPACT SEVERITY DESCRIPTIONS
	getBCImpactSeverityDescriptions() {
		return this.api.post('GetBCImpactSeverityDescriptions', null, false);
	}

	saveBCImpactSeverityDescriptions(impactSeverityDescriptions: Array<BcImpactSeverityDescriptionModel>) {
		return this.api.post('SaveBCImpactSeverityDescriptions',
			{
				impactSeverityDescriptions: impactSeverityDescriptions
			},
			false);
	}

	// INCIDENT COMMUNICATIONS
	getBCIncidentCommunicationSetting() {
		return this.api.post('GetBCIncidentCommunicationGroups', {}, false);
	}

	saveBCIncidentCommunicationSetting(items) {
		return this.api.post('SaveBCIncidentCommunicationGroups', { items: items }, false);
	}

	getBCProcessCriticalDates(portalProcessId: string, fromDate: any, toDate: any, filterForActivation: any) {
		return this.api.post('GetBCProcessCriticalDates', {
			processId: portalProcessId,
			fromDate: fromDate,
			toDate: toDate,
			filterForActivation: filterForActivation
		}, false);
	}

	// get all critical dates - not just by process
	getBCProcessesCriticalDates(fromDate: Date, toDate: Date, filterForActivation: boolean) {
		return this.api.post('GetBCProcessesCriticalDates', {
			fromDate: fromDate,
			toDate: toDate,
			filterForActivation: filterForActivation
		}, false);
	}

	getPrimaryActionPlanIncidentId(portalProcessId: string) {
		return this.api.post('getPrimaryActionPlanIncidentId', {
			portalProcessId: portalProcessId,
			forExercise: this.isExercise
		});
	}

	savePlanActivationIssue(issue: any) {
		return this.api.post('upsertPlanActivationIssue', {
			issue: issue,
			incidentId: this.actionPlanIncidentIdInStorage
		});
	}

	selectPlanActivationIssues(planActivationId?: string, planActivationProcessId?: string, excludeResolvedIssues: boolean = false, isExercise: boolean = null) {
		return this.api.post('SelectBCPlanActivationIssues', {
			planActivationId: planActivationId,
			planActivationProcessId: planActivationProcessId,
			excludeResolvedIssues: excludeResolvedIssues,
			forExercise: isExercise == null ? this.isExercise : isExercise
		});
	}

	getBCIncidentCommunicationGroups() {
		return this.api.post('getbcincidentcommunicationgroups', {});
	}

	getPortalFunctionalAreaPlans(siteId: string, isExercise: boolean = null): Promise<{ departmentPlans: Array<PortalFunctionalAreaPlan> }> {
		return this.api.post('getportalfunctionalareaplans', {
			siteId: siteId,
			forExercise: isExercise == null ? this.isExercise : isExercise
		}) as any;
	}

	getPortalLocationPlans(siteId: string, isExercise: boolean = null): Promise<{ departmentPlans: Array<PortalLocationPlan> }> {
		return this.api.post('getportallocationplans', {
			siteId: siteId,
			forExercise: isExercise == null ? this.isExercise : isExercise
		}) as any;
	}

	setDepartmentPlanStatus(statusTypeId: string, departmentPlans: Array<PortalFunctionalAreaPlan>) {
		return this.api.post('setdepartmentplanstatus', {
			activationStateTypeId: statusTypeId,
			portalFunctionalAreaPlans: departmentPlans,
			incidentId: this.actionPlanIncidentIdInStorage
		});
	}

	setLocationPlanStatus(statusTypeId: string, locationPlans: Array<PortalLocationPlan>) {
		return this.api.post('setlocationplanstatus', {
			activationStateTypeId: statusTypeId,
			portalLocationPlans: locationPlans,
			incidentId: this.actionPlanIncidentIdInStorage
		});
	}

	getPortalProcessPlanStrategy(portalProcessStrategyId: string): Promise<{
		portalProcessPlan: PortalProcessPlan
		portalProcessPlanStrategy: PortalProcessPlanStrategy
		portalProcessStrategies: Array<PortalProcessStrategy>
	}> {
		return this.api.post('getportalprocessplanstrategy', {
			portalProcessStrategyId: portalProcessStrategyId,
			forExercise: this.isExercise
		}) as any;
	}

	getPortalProcessPlans(isExercise: boolean = null): Promise<{ portalProcessPlans: Array<PortalProcessPlan> }> {
		return this.api.post('getportalprocessplans', { forExercise: isExercise == null ? this.isExercise : isExercise }) as any;
	}

	savePortalProcessPlan(portalProcessPlan: PortalProcessPlan): Promise<{ portalProcessPlan: PortalProcessPlan }> {
		return this.api.post('saveportalprocessplan', {
			portalProcessPlan: portalProcessPlan,
			incidentId: this.actionPlanIncidentIdInStorage,
			forExercise: this.isExercise
		}) as any;
	}

	getPortalProcessPlanNotes(planActivationProcessId: string): Promise<{ notes: Array<PortalProcessPlanNote> }> {
		return this.api.post('getportalprocessplannotes', { planActivationProcessId: planActivationProcessId }) as any;
	}

	deletePortalProcessPlanNote(planActivationProcessNoteId: string, planActivationProcessId: string): Promise<{ notes: Array<PortalProcessPlanNote> }> {
		return this.api.post('deleteportalprocessplannote', {
			planActivationProcessNoteId: planActivationProcessNoteId,
			planActivationProcessId: planActivationProcessId,
			incidentId: this.actionPlanIncidentIdInStorage
		}) as any;
	}

	addPortalProcessPlanNote(portalProcessPlanNote: PortalProcessPlanNote): Promise<{ portalProcessPlans: PortalProcessPlan }> {
		return this.api.post('addportalprocessplannote', {
			note: portalProcessPlanNote,
			incidentId: this.actionPlanIncidentIdInStorage
		}) as any;
	}

	// INCIDENT MANAGER
	sendActionPlanMessage(sendMessage: SendMessage, planActivationIds: Array<string> = []): Promise<boolean> {
		return new Promise((resolve, reject) => {
			// Generate incidentId if no incidentId. This is 2nd line of defense.
			if (!sendMessage.incidentId || sendMessage.incidentId === guid.empty) {
				sendMessage.incidentId = this.utility.getGuid();
			}

			if (sendMessage.voiceMailTextToSpeech) {
				sendMessage.voiceMailRecordingId = null;
			}

			if (sendMessage.isFollowUpMessage) {
				switch (sendMessage.responseType) {
					case messageResponseTypes.everyone:
						sendMessage.followUpParams = this.translateService.instant('sendMessage.everyoneInThisEvent');
						break;
					case messageResponseTypes.limited:
						sendMessage.followUpParams = '<div>' + this.translateService.instant('sendMessage.everyoneWhose') + '<div/>';

						sendMessage.responseOptions.forEach((response: any) => {
							if (response.checked) {
								let htmlString = '<div>' + response.responseKey;

								// if has responseDefinition
								if (response.responseDefinition) {
									htmlString += ' = ' + response.responseDefinition;
								}

								// needs to be done after if statement above
								htmlString += '</div>';

								sendMessage.followUpParams += htmlString;
							}
						});
						break;
					case messageResponseTypes.incidentCreator:
						sendMessage.followUpParams = '<div>' + this.translateService.instant('sendMessage.incidentCreator') + ' (' + sendMessage.createdByName + ')</div>';
						break;
				}
			}

			// NOTE: clear out the customResponses here or backend. don't do it on options step - or can do it on next(). causes issue when message is a follow if you do it on options step.
			// don't need customResponses when response is not required or using system default responses.
			if (!sendMessage.requestForUserResponse
			  || sendMessage.requestForUserResponseNumeric === requestForUserResponseNumeric.yes) {
				sendMessage.customResponses = [];
			}

			if (!sendMessage.autoResend) {
				sendMessage.resendOptions = null;
			}

			if (!sendMessage.automaticFollowUp) {
				sendMessage.automaticFollowUpMessages = [];
			}

			// include additional personal contact phone if personal contact phone is included
			sendMessage.includeAdditionalPersonalContactPhone = sendMessage.includePersonalContactPhone;

			this.api.post('sendActionPlanMessage', sendMessage)
				.then((res: any) => {
					if (res.statusCode === 0) {
						resolve(true);
					}
					else {
						reject(false);
					}
				})
				.catch((res: any) => {
					reject(false);
				});
		});
	}

	startActionPlanConferenceCall(conferenceCall: ConferenceCall): Promise<boolean> {
		return new Promise((resolve, reject) => {
			// convert the user objects to just user ids
			conferenceCall.recipientUserIds = conferenceCall.manuallyAddedUsers.map((u: User) => u.userId);

			conferenceCall.sending = true;

			// Do this ONLY here. Doing this so it's consistent with sendMessage.
			conferenceCall.participantDepartmentIds = conferenceCall.recipientDepartmentIds;
			conferenceCall.participantTenantIds = conferenceCall.recipientTenantIds;
			conferenceCall.participantGroupIds = conferenceCall.recipientGroupIds;
			conferenceCall.participantLocationIds = conferenceCall.recipientLocationIds;
			conferenceCall.participantUserIds = conferenceCall.recipientUserIds;
			conferenceCall.participantRoleBundleIds = conferenceCall.recipientRoleBundleIds;
			conferenceCall.participantUserOverride = conferenceCall.recipientUserOverride;
			conferenceCall.participantNonUsers = conferenceCall.recipientNonUsers;
			//

			conferenceCall.smsNotificationJobId = (conferenceCall.sendDetailToSMS) ? this.utility.getGuid() : null;
			conferenceCall.emailNotificationJobId = (conferenceCall.sendDetailToEmail) ? this.utility.getGuid() : null;
			// delete voicePhoneNumberTypeId if Guid.empty
			conferenceCall.voicePhoneNumberTypeId = conferenceCall.voicePhoneNumberTypeId === guid.empty ? '' : conferenceCall.voicePhoneNumberTypeId;
			conferenceCall.voiceJobId = (conferenceCall.voicePhoneNumberTypeId) ? this.utility.getGuid() : null;

			if (conferenceCall.isFollowUpConferenceCall) {
				switch (conferenceCall.responseType) {
					case messageResponseTypes.everyone:
						conferenceCall.followUpParams = this.translateService.instant('sendMessage.everyoneInThisEvent');
						break;
					case messageResponseTypes.limited:
						conferenceCall.followUpParams = '<div>' + this.translateService.instant('sendMessage.everyoneWhose') + '<div/>';

						conferenceCall.responseOptions.forEach((response: any) => {
							if (response.checked) {
								let htmlString = '<div>' + response.responseKey;

								// if has responseDefinition
								if (response.responseDefinition) {
									htmlString += ' = ' + response.responseDefinition;
								}

								// needs to be done after if statement above
								htmlString += '</div>';

								conferenceCall.followUpParams += htmlString;
							}
						});
						break;
					case messageResponseTypes.incidentCreator:
						conferenceCall.followUpParams = '<div>' + this.translateService.instant('sendMessage.incidentCreator') + ' (' + conferenceCall.createdByName + ')</div>';
						break;
				}
			}

			// include additional personal contact phone if personal contact phone is included
			conferenceCall.includeAdditionalPersonalContactPhone = conferenceCall.includePersonalContactPhone;

			conferenceCall.parentEntityId = this.utility.getCurrentPortalId();

			// additionalMessage encode
			if (conferenceCall.sendDetailToSMS && conferenceCall.additionalSmsMessage) {
				conferenceCall.additionalSmsMessageAsBase64 = this.utility.base64Encode(conferenceCall.additionalSmsMessage);
			}
			else {
				delete conferenceCall.additionalSmsMessageAsBase64;
				delete conferenceCall.additionalSmsMessage;
			}
			if (conferenceCall.sendDetailToEmail && conferenceCall.additionalEmailBody) {
				conferenceCall.additionalEmailBodyAsBase64 = this.utility.base64Encode(conferenceCall.additionalEmailBody);
			}
			else {
				delete conferenceCall.additionalEmailBodyAsBase64;
				delete conferenceCall.additionalEmailBody;
			}

			this.api.post('startActionPlanConferenceCall', conferenceCall)
				.then((res: any) => {
					resolve(true);
				})
				.catch((err: any) => {
					reject(false);
				});
		});
	}

	savePlanActivationIssueComment(comment: BcPlanActivationIssueCommentModel) {
		return this.api.post('saveBCPlanActivationIssueComment', {
			comment: comment,
			incidentId: this.actionPlanIncidentIdInStorage
		});
	}

	selectPlanActivationIssueComments(planActivationIssueId: string) {
		return this.api.post('selectBCPlanActivationIssueComments', { issueId: planActivationIssueId });
	}

	deletePlanActivationIssueComment(planActivationIssueCommentId: string) {
		return this.api.post('deleteBCPlanActivationIssueComment', {
			issueCommentId: planActivationIssueCommentId,
			incidentId: this.actionPlanIncidentIdInStorage
		});
	}

	getPortalImpactMetaData() {
		return new Promise<void>((resolve, reject) => {
			const promises: any[] = [
				this.getPortalImpactTimeSpans(),
				this.getPortalImpactCategories(),
				this.getPortalImpactSeverities()
			];
			Promise.all(promises).then((_) => {
				resolve();
			}).catch((_) => {
				reject();
			});
		});
	}

	getPortalImpactTimeSpans(): Promise<Array<ImpactTimespan>> {
		return new Promise((resolve, reject) => {
			this.api.post('getportalimpacttimespans', {}).then((res: any) => {
				this.localStorageService.set(storageKeys.impactTimeSpans, res.impactTimeSpans);
				resolve(res.impactTimeSpans);
			}).catch((_) => {
				reject();
			});
		}) as any;
	}

	savePortalImpactTimeSpans(timespans: Array<ImpactTimespan>): Promise<Array<ImpactTimespan>> {
		return new Promise((resolve, reject) => {
			this.api.post('saveportalimpacttimespans', { impactTimeSpans: timespans }).then((res: any) => {
				this.localStorageService.set(storageKeys.impactTimeSpans, res.impactTimeSpans);
				resolve(res.impactTimeSpans);
			}).catch((_) => {
				reject();
			});
		}) as any;
	}

	resetPortalImpactTimeSpans(): Promise<Array<ImpactTimespan>> {
		return new Promise((resolve, reject) => {
			this.api.post('resetportalimpacttimespans', {}).then((res: any) => {
				this.localStorageService.set(storageKeys.impactTimeSpans, res.impactTimeSpans);
				resolve(res.impactTimeSpans);
			}).catch((_) => {
				reject();
			});
		}) as any;
	}

	getPortalImpactSeverities(): Promise<Array<ImpactSeverity>> {
		return new Promise((resolve, reject) => {
			this.api.post('getportalimpactseverities', {}).then((res: any) => {
				this.localStorageService.set(storageKeys.impactSeverities, res.impactSeverities);
				resolve(res.impactSeverities);
			}).catch((_) => {
				reject();
			});
		}) as any;
	}

	savePortalImpactSeverities(severities: Array<ImpactSeverity>): Promise<Array<ImpactSeverity>> {
		return new Promise((resolve, reject) => {
			this.api.post('saveportalimpactseverities', { impactSeverities: severities }).then((res: any) => {
				this.localStorageService.set(storageKeys.impactSeverities, res.impactSeverities);
				resolve(res.impactSeverities);
			}).catch((_) => {
				reject();
			});
		}) as any;
	}

	resetPortalImpactSeverities(): Promise<Array<ImpactSeverity>> {
		return new Promise((resolve, reject) => {
			this.api.post('resetportalimpactseverities', {}).then((res: any) => {
				this.localStorageService.set(storageKeys.impactSeverities, res.impactSeverities);
				resolve(res.impactSeverities);
			}).catch((_) => {
				reject();
			});
		}) as any;
	}

	getPortalImpactCategories(): Promise<Array<ImpactCategory>> {
		return new Promise((resolve, reject) => {
			this.api.post('getportalimpactcategories', {}).then((res: any) => {
				this.localStorageService.set(storageKeys.impactCategories, res.impactCategories);
				resolve(res.impactCategories);
			}).catch((_) => {
				reject();
			});
		}) as any;
	}

	savePortalImpactCategories(categories: Array<ImpactCategory>): Promise<Array<ImpactCategory>> {
		return new Promise((resolve, reject) => {
			this.api.post('saveportalimpactcategories', { ImpactCategories: categories }).then((res: any) => {
				this.localStorageService.set(storageKeys.impactCategories, res.impactCategories);
				resolve(res.impactCategories);
			}).catch((_) => {
				reject();
			});
		}) as any;
	}

	resetPortalImpactCategories(): Promise<Array<ImpactCategory>> {
		return new Promise((resolve, reject) => {
			this.api.post('resetportalimpactcategories', {}).then((res: any) => {
				this.localStorageService.set(storageKeys.impactCategories, res.impactCategories);
				resolve(res.impactCategories);
			}).catch((_) => {
				reject();
			});
		}) as any;
	}

	getAllowConfigureMTDSetting(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.api.post('getallowconfiguremtdsetting', {}).then((res: any) => {
				resolve(res.allowConfigureMTD);
			}).catch((_) => {
				reject();
			});
		}) as any;
	}

	saveAllowConfigureMTDSetting(allowConfigureMTD: boolean) {
		return this.api.post('saveallowconfiguremtdsetting', { allowConfigureMTD: allowConfigureMTD });
	}

	isMaxTeamLeadLicenseOverage(userId: string, excludeUserId: string = null, excludePortalFunctionalAreaId: string = null): Promise<any> {
		return this.api.post('validateteamleadlicense', {
			userId: userId,
			excludeUserId: excludeUserId,
			excludePortalFunctionalAreaId: excludePortalFunctionalAreaId
		});
	}

	isMaxTeamMemberLicenseOverage(userId: string, excludeUserId: string = null, excludePortalProcessId: string = null): Promise<any> {
		return this.api.post('validateteammemberlicense', {
			userId: userId,
			excludeUserId: excludeUserId,
			excludePortalProcessId: excludePortalProcessId
		});
	}

	isMaxBCMLicenseOverage(userId: any, roleBundleId: string): Promise<any> {
		return this.api.post('validatebusinesscontinuitymanagerlicense', {
			selectedUserId: userId,
			roleBundleId: roleBundleId
		});
	}

	getDurationTypeName(unitId: string, isSingular: boolean): string {
		let durationUnitName: string = '';

		switch (unitId) {
			case timeDurationUnit.hour:
				durationUnitName = this.translateService.instant(isSingular ? 'common.hour' : 'common.hours');
				break;
			case timeDurationUnit.day:
				durationUnitName = this.translateService.instant(isSingular ? 'common.day' : 'common.days');
				break;
		}

		return durationUnitName;
	}

	getBCStrategyCustomFields(portalProcessStrategyId: string) {
		return this.api.post('getportalprocessstrategycustomfields', { portalProcessStrategyId: portalProcessStrategyId });
	}

	saveBcStrategyCustomFields(portalProcessStrategy: PortalProcessStrategy, customFieldValues: Array<CustomFieldValue>) {
		return this.api.post('saveportalprocessstrategycustomfields', {
			portalProcessStrategy: portalProcessStrategy,
			customFieldValues: customFieldValues
		});
	}

	getPortalProcessStrategyCustomFieldValues(portalProcessStrategyId?: string) {
		return this.api.post('getportalprocessstrategycustomfieldvalues', { portalProcessStrategyId: portalProcessStrategyId });
	}

	getContinuityStrategySettings(): Promise<BCStrategySettings> {
		return new Promise<BCStrategySettings>((resolve, reject) => {
			this.api.post('getcontinuitystrategysettings', {}).then((res: any) => {
				this.allowLocationBasedStrategies = res.allowLocationBasedStrategies;
				this.allowScenarioBasedStrategies = res.allowScenarioBasedStrategies;
				resolve(res);
			}).catch((err) => {
				reject(err);
			});
		});
	}

	setContinuityStrategySetting(settingId: string, settingValue: boolean, locationId: string = null) {
		return new Promise<unknown>((resolve, reject) => {
			this.api.post('setcontinuitystrategysetting', {
				settingId: settingId,
				settingValue: settingValue,
				locationId: locationId
			}).then((res: any) => {
				switch (settingId) {
					case entitySettingsTypes.BCAllowScenarioBasedContinuityStrategies:
						this.allowScenarioBasedStrategies = settingValue;
						break;
					case entitySettingsTypes.BCAllowLocationBasedContinuityStrategies:
						this.allowLocationBasedStrategies = settingValue;
						break;
				}
				resolve(res);
			}).catch((err) => {
				reject(err);
			});
		});
	}

	getPortalProcessLocations(portalProcessId: string): Promise<{ portalProcessLocations: Array<PortalProcessLocation> }> {
		return this.api.post('getportalprocesslocations', { portalProcessId: portalProcessId }) as any;
	}

	savePortalProcessLocation(portalProcessLocation: PortalProcessLocation) {
		return this.api.post('saveportalprocesslocation', { portalProcessLocation: portalProcessLocation });
	}

	deletePortalProcessLocation(portalProcessLocationId: string) {
		return this.api.post('deleteportalprocesslocation', { portalProcessLocationId: portalProcessLocationId });
	}

	exportHeadersForBcVendorCsvFile() {
		return this.api.post('exportheadersforbcvendorcsvfile', {}, true);
	}

	exportBcVendorCsvFile() {
		return this.api.post('exportbcvendorcsvfile', {}, true);
	}

	uploadBulkVendorFile(fileAsBase64: string, fileName: string) {
		const request = {
			csvFileNameAsBase64: this.utility.base64Encode(fileName),
			csvFileAsBase64: fileAsBase64
		};

		return this.api.post('uploadbulkvendorfile', request);
	}

	exportHeadersForBcAgencyCsvFile() {
		return this.api.post('exportheadersforbcagencycsvfile', {}, true);
	}

	exportBcAgencyCsvFile() {
		return this.api.post('exportbcagencycsvfile', {}, true);
	}

	uploadBulkAgencyFile(fileAsBase64: string, fileName: string) {
		const request = {
			csvFileNameAsBase64: this.utility.base64Encode(fileName),
			csvFileAsBase64: fileAsBase64
		};

		return this.api.post('uploadbulkagencyfile', request);
	}

	GetAgencyTypes() {
		return this.api.post('getagencytypes', {});
	}

	exportHeadersForTechnologyCsvFile() {
		return this.api.post('exportheadersforbctechnologycsvFile', {}, true);
	}

	exportTechnologyCsvFile() {
		return this.api.post('exportbctechnologycsvFile', {}, true);
	}

	uploadBulkTechnologyFile(fileAsBase64: string, fileName: string) {
		const request = {
			csvFileNameAsBase64: this.utility.base64Encode(fileName),
			csvFileAsBase64: fileAsBase64
		};

		return this.api.post('uploadbulktechnologyfile'.toLowerCase(), request);
	}

	exportHeadersForBcVendorContactCsvFile() {
		return this.api.post('exportheadersforbcvendorcontactcsvfile', {}, true);
	}

	exportBcVendorContactCsvFile() {
		return this.api.post('exportbcvendorcontactcsvfile', {}, true);
	}

	uploadBulkVendorContactFile(fileAsBase64: string, fileName: string) {
		const request = {
			csvFileNameAsBase64: this.utility.base64Encode(fileName),
			csvFileAsBase64: fileAsBase64
		};

		return this.api.post('uploadbulkvendorcontactfile', request);
	}

	exportHeadersForBcAgencyContactCsvFile() {
		return this.api.post('exportheadersforbcagencycontactcsvfile', {}, true);
	}

	exportBcAgencyContactCsvFile() {
		return this.api.post('exportbcagencycontactcsvfile', {}, true);
	}

	uploadBulkAgencyContactFile(fileAsBase64: string, fileName: string) {
		const request = {
			csvFileNameAsBase64: this.utility.base64Encode(fileName),
			csvFileAsBase64: fileAsBase64
		};

		return this.api.post('uploadbulkagencycontactfile', request);
	}

	getProcessDependencies(portalProcessId: string) {
		return this.api.post('GetPortalProcessDependencies', {
			portalProcessId: portalProcessId
		});
	}

	postProcessDependencies(portalProcessId: string, portalProcessDependencies: Array<PortalProcessDependency>) {
		return this.api.post('PostPortalProcessDependencies', {
			portalProcessId: portalProcessId,
			portalProcessDependencies: portalProcessDependencies
		});
	}

	deleteProcessDependencies(portalProcessDependencyId: string) {
		return this.api.post('DeletePortalProcessDependency', {
			portalProcessDependencyId
		});
	}

	getAllProcessesForDependencies(siteId: string, portalProcessId: string) {
		return this.api.post('GetBcPortalProcessesForDependencies', {
			siteId,
			portalProcessId
		});
	}

	getSystemExerciseScenarios(): Promise<Array<ExerciseScenarioModel>> {
		return this.api.post('getsystemexercisescenarios', {}) as any;
	}

	saveSystemExerciseScenario(exerciseScenario: ExerciseScenarioModel, originalExerciseScenarioId?: string) {
		return this.api.post('savesystemexercisescenario', {
			exerciseScenario: exerciseScenario,
			originalExerciseScenarioId: originalExerciseScenarioId
		});
	}

	deleteSystemExerciseScenario(exerciseScenarioId: string) {
		return this.api.post('deletesystemexercisescenario', { exerciseScenarioId: exerciseScenarioId });
	}

	getPortalExerciseScenarios(): Promise<Array<ExerciseScenarioModel>> {
		return this.api.post('getportalexercisescenarios', {}) as any;
	}

	savePortalExerciseScenario(exerciseScenario: ExerciseScenarioModel, originalExerciseScenarioId?: string) {
		return this.api.post('saveportalexercisescenario', {
			exerciseScenario: exerciseScenario,
			originalExerciseScenarioId: originalExerciseScenarioId
		});
	}

	deletePortalExerciseScenario(exerciseScenarioId: string) {
		return this.api.post('deleteportalexercisescenario', { exerciseScenarioId: exerciseScenarioId });
	}

	getPortalExerciseTypes(getAssignedExerciseScenarios?: boolean): Promise<Array<ExerciseTypeModel>> {
		return this.api.post('getportalexercisetypes', { getAssignedExerciseScenarios: getAssignedExerciseScenarios }) as any;
	}

	savePortalExerciseType(exerciseType: ExerciseTypeModel) {
		return this.api.post('saveportalexercisetype', { exerciseType: exerciseType });
	}

	saveSystemExerciseType(exerciseType: ExerciseTypeModel) {
		return this.api.post('savesystemexercisetype', { exerciseType: exerciseType });
	}

	saveExcludedSystemExerciseType(exerciseTypeId: string) {
		return this.api.post('saveexcludedsystemexercisetype', { exerciseTypeId: exerciseTypeId });
	}

	getPortalThreadHazard(getAssignedExerciseScenarios?: boolean): Promise<Array<ThreadHazardModel>> {
		return this.api.post('getportalthreadhazard', { getAssignedExerciseScenarios: getAssignedExerciseScenarios }) as any;
	}

	savePortalThreadHazard(threadHazard: ThreadHazardModel) {
		return this.api.post('saveportalthreadhazard', { threadHazard: threadHazard });
	}

	saveSystemThreadHazard(threadHazard: ThreadHazardModel) {
		return this.api.post('savesystemthreadhazard', { threadHazard: threadHazard });
	}

	saveExcludedSystemThreadHazard(threadHazardId: string) {
		return this.api.post('saveexcludedsystemthreadhazard', { threadHazardId: threadHazardId });
	}

	checkExerciseScenarioAttachmentStatus(exerciseScenarioId: string, fileNameAsBase64: string) {
		return this.api.post('checkexercisescenariodocumentstatus', {
			exerciseScenarioId: exerciseScenarioId,
			fileNameAsBase64: fileNameAsBase64
		});
	}

	getExerciseScenarioAttachment(exerciseScenarioId: string): Promise<Array<string>> {
		return this.api.post('getexercisescenariodocuments', { exerciseScenarioId: exerciseScenarioId }) as any;
	}

	getExerciseScenarioAttachmentAsStream(exerciseScenarioId: string, document: Document) {
		return this.api.post('getexercisescenariodocumentasstream', {
			exerciseScenarioId: exerciseScenarioId,
			document: document
		}, true);
	}

	saveExerciseScenarioAttachment(exerciseScenarioId: string, document: Document) {
		return this.api.post('createorupdateexercisescenariodocument', {
			exerciseScenarioId: exerciseScenarioId,
			document: document
		});
	}

	removeExerciseScenarioAttachment(exerciseScenarioId: string, document: Document) {
		return this.api.post('deleteexercisescenariodocument', {
			exerciseScenarioId: exerciseScenarioId,
			document: document
		});
	}

	getPortalExercises(startDate?: Date, endDate?: Date): Promise<Array<ExerciseModel>> {
		return this.api.post('getportalexercises', {
			startDate: startDate,
			endDate: endDate
		}) as any;
	}

	getPortalExercise(exerciseId: string): Promise<ExerciseModel> {
		return this.api.post('getportalexercise', {
			exerciseId: exerciseId
		}) as any;
	}

	savePortalExercise(exercise: ExerciseModel, exerciseScenarioId: string = null) {
		return this.api.post('saveportalexercise', { exercise: exercise, exerciseScenarioId: exerciseScenarioId });
	}

	deletePortalExercise(exerciseId: string) {
		return this.api.post('deleteportalexercise', { exerciseId: exerciseId });
	}

	checkExerciseAttachmentStatus(exerciseId: string, fileNameAsBase64: string) {
		return this.api.post('checkexercisedocumentstatus', {
			exerciseId: exerciseId,
			fileNameAsBase64: fileNameAsBase64
		});
	}

	getExerciseAttachment(exerciseId: string): Promise<Array<string>> {
		return this.api.post('getexercisedocuments', { exerciseId: exerciseId }) as any;
	}

	getExerciseAttachmentAsStream(exerciseId: string, document: Document) {
		return this.api.post('getexercisedocumentasstream', {
			exerciseId: exerciseId,
			document: document
		}, true);
	}

	saveExerciseAttachment(exerciseId: string, document: Document) {
		return this.api.post('createorupdateexercisedocument', {
			exerciseId: exerciseId,
			document: document
		});
	}

	removeExerciseAttachment(exerciseId: string, document: Document) {
		return this.api.post('deleteexesrcisedocument', {
			exerciseId: exerciseId,
			document: document
		});
	}

	addPortalExerciseTesters(exerciseId: string, testUsers: Array<any>) {
		return this.api.post('addportalexercisetesters', { exerciseId: exerciseId, testUsers: testUsers });
	}

	deletePortalExerciseTester(exerciseId: string, testUserId: string) {
		return this.api.post('deleteportalexercisetester', { exerciseId: exerciseId, testUserId: testUserId });
	}

	getExerciseTargetsForExerciseManager(): Promise<Array<ExerciseTargetModel>> {
		return this.api.post('getexercisetargetsforexercisemanager', {}) as Promise<Array<ExerciseTargetModel>>;
	}

	copyPortalProcess(processId: string, destinationDepartmentId: string, userId: string) {
		return this.api.post('CopyPortalProcess',
			{
				processId: processId,
				destinationPortalFunctionalAreaId: destinationDepartmentId,
				newResponsibleUserId: userId
			});
	}

	movePortalProcess(processId: string, destinationDepartmentId: string, userId: string) {
		return this.api.post('MovePortalProcess',
			{
				processId: processId,
				destinationPortalFunctionalAreaId: destinationDepartmentId,
				newResponsibleUserId: userId
			});
	}

	CopyPortalProcessValidateProcessNameCollision(processId: string, destinationDepartmentId: string, userId: string) {
		return this.api.post('CopyPortalProcessValidateProcessNameCollision',
			{
				processId: processId,
				destinationPortalFunctionalAreaId: destinationDepartmentId,
				newResponsibleUserId: userId
			});
	}

	getPortalFunctionalAreasForDepartmentAssociation() {
		return this.api.post('GetPFAForDepartmentAssociation', {});
	}

	getPortalOrderOfSuccessions(portalPlanTypeId: string, portalFunctionalAreaId?: string) {
		return this.api.post('GetPortalOrderOfSuccessions', { portalPlanTypeId, portalFunctionalAreaId });
	}

	getPortalOrderOfSuccession(portalOrderOfSuccessionId: string, portalPlanTypeId: string, portalFunctionalAreaId?: string) {
		return this.api.post('GetPortalOrderOfSuccession', { portalOrderOfSuccessionId, portalPlanTypeId, portalFunctionalAreaId });
	}

	savePortalOrderOfSuccession(portalOrderOfSuccession: PortalOrdersOfSuccession) {
		return this.api.post('SavePortalOrderOfSuccession', { portalOrderOfSuccession });
	}

	deletePortalOrderOfSuccession(portalOrderOfSuccessionId: string, portalPlanTypeId: string, portalFunctionalAreaId?: string) {
		return this.api.post('DeletePortalOrderOfSuccession', { portalOrderOfSuccessionId, portalPlanTypeId, portalFunctionalAreaId });
	}

	getPortalDelegationOfAuthorities(portalPlanTypeId: string, portalFunctionalAreaId?: string) {
		return this.api.post('getPortalDelegationOfAuthorities', { portalPlanTypeId, portalFunctionalAreaId });
	}

	getPortalDelegationOfAuthority(portalDelegationOfAuthorityId: string, portalPlanTypeId: string, portalFunctionalAreaId?: string) {
		return this.api.post('getPortalDelegationOfAuthority', { portalDelegationOfAuthorityId, portalPlanTypeId, portalFunctionalAreaId });
	}

	savePortalDelegationOfAuthority(portalDelegationOfAuthority: PortalDelegationOfAuthority) {
		return this.api.post('savePortalDelegationOfAuthority', { portalDelegationOfAuthority });
	}

	deletePortalDelegationOfAuthority(portalDelegationOfAuthorityId: string, portalPlanTypeId: string, portalFunctionalAreaId?: string) {
		return this.api.post('deletePortalDelegationOfAuthority', { portalDelegationOfAuthorityId, portalPlanTypeId, portalFunctionalAreaId });
	}

	// PLAN BUILDER

	getBCPlanTemplateTargetTypes(portalPlanTypeId?: string) {
		return this.api.post('getbcplantemplatetargettypes', { portalPlanTypeId: portalPlanTypeId });
	}

	getPlanTemplates() {
		return this.api.post('GetPlanTemplates', {});
	}

	getPlanTemplate(planTemplateId: string) {
		return this.api.post('GetPlanTemplate', { planTemplateId });
	}

	savePlanTemplateSectionsTree(planTemplate: PlanTemplate) {
		return this.api.post('SavePlanTemplateSectionsTree', { planTemplate });
	}

	uploadPlanTemplateSectionImage(planTemplateSectionId: string, planTemplateSectionImageAsBase64: string) {
		return this.api.post('UploadPlanTemplateSectionImage', {
			planTemplateSectionId: planTemplateSectionId,
			planTemplateSectionImageAsBase64: planTemplateSectionImageAsBase64
		});
	}

	getPlanTemplateSection(planTemplateSectionId: string) {
		return this.api.post('GetPlanTemplateSection', {
			planTemplateSectionId: planTemplateSectionId
		});
	}

	getAssignedTemplateTargets(planTemplateId: string) {
		return this.api.post('GetAssignedPlanTemplateTargets', { planTemplateId: planTemplateId });
	}

	assignPlanTemplateToTargets(targetToAssign: PlanTemplateAssignToTargetsModel) {
		return this.api.post('AssignPlanTemplateToTargets', {
			planTemplateId: targetToAssign.planTemplateId,
			planTemplateTargetTypeId: targetToAssign.planTemplateTargetTypeId,
			targetIds: targetToAssign.targetIds,
			makeDefault: targetToAssign.makeDefault
		});
	}

	updatePlanTemplateSection(planTemplateSection: PlanSectionNode) {
		return this.api.post('UpdatePlanTemplateSection', {
			planTemplateSection: planTemplateSection
		});
	}

	savePlanTemplatePublish(planTemplateId: string) {
		return this.api.post('SavePlanTemplatePublish', {
			originalPlanTemplateId: planTemplateId
		});
	}

	savePlanTemplateRollback(planTemplateId: string) {
		return this.api.post('SavePlanTemplateRollback', {
			originalPlanTemplateId: planTemplateId
		});
	}

	deletePlanTemplate(planTemplate: PlanTemplate) {
		return this.api.post('DeletePlanTemplate', {
			planTemplate
		});
	}

	savePlanTemplate(planTemplate: PlanTemplate) {
		return this.api.post('SavePlanTemplate', {
			planTemplate
		});
	}

	savePlanTemplateAsSystemDefault(planTemplateId: string) {
		return this.api.post('SavePlanTemplateAsSystem', {
			planTemplateId
		});
	}

	getPortalPlanTypes() {
		return this.api.post('GetPortalPlanTypes', {});
	}

	getPortalPlanTypeFeatures() {
		return this.api.post('GetPortalPlanTypeFeatures', {});
	}

	copyPlanTemplateFromPublished(copyTemplate: NewCopyPlanTemplate) {
		return this.api.post('CopyPlanTemplateFromPublished', {
			...copyTemplate
		});
	}

	copyPlanTemplateFromUnPublished(copyTemplate: NewCopyPlanTemplate): Promise<BaseResponse> {
		return this.api.post('CopyPlanTemplateFromUnPublished', {
			...copyTemplate
		}) as Promise<BaseResponse>;
	}

	getBCPlanDataElements(planTemplateId: string, planDataElementTypeId: string): Promise<BaseResponse> {
		return this.api.post('GetBCPlanDataElements', { planTemplateId: planTemplateId, planDataElementTypeId: planDataElementTypeId }) as Promise<BaseResponse>;
	}

	generateBCPlanPreview(planTemplateId: string, planTemplateTargetTypeId: string, id: string, utcOffset: number, timeZoneAbbr: string) {
		return this.api.post('GenerateBCPlanPreview', {
			planTemplateId: planTemplateId,
			planGenJobTypeId: planTemplateTargetTypeId,
			id: id,
			isPreview: true,
			timeZoneInfo: {
				utcOffset,
				timeZoneAbbr
			}
		});
	}

	// END PLAN BUILDER

	// TEAMS

	getPortalTeams(portalPlanTypeId: string, portalFunctionalAreaId?: string): Promise<ModelGetPortalTeamsResponse> {
		return this.api.post('getportalteams', { portalPlanTypeId, portalFunctionalAreaId }) as Promise<ModelGetPortalTeamsResponse>;
	}

	getDefaultTeams(functionalAreaId: string): Promise<ModelGetBCTeamsResponse> {
		return this.api.post('GetDefaultTeams', {
			functionalAreaId
		}) as Promise<ModelGetBCTeamsResponse>;
	}

	getPortalTeam(portalTeamId: string, portalPlanTypeId: string, portalFunctionalAreaId?: string) {
		return this.api.post('getportalteam', { portalPlanTypeId, portalFunctionalAreaId, portalTeamId });
	}

	getDefaultTeam(teamId: string, functionalAreaId: string): Promise<ModelGetBCTeamResponse> {
		return this.api.post('GetDefaultTeam', {
			teamId,
			functionalAreaId
		}) as Promise<ModelGetBCTeamResponse>;
	}

	savePortalTeam(portalTeam: PortalTeam) {
		return this.api.post('savePortalTeam', { portalteam: portalTeam });
	}

	saveDefaultTeam(modelBCTeam: ModelBCTeam): Promise<ModelGetBCTeamResponse> {
		return this.api.post('SaveDefaultTeam',
			{
				bcTeam: modelBCTeam
			}) as Promise<ModelGetBCTeamResponse>;
	}

	deletePortalTeam(portalTeamId: string, portalPlanTypeId: string, portalFunctionalAreaId?: string) {
		return this.api.post('deleteportalteam', { portalTeamId, portalPlanTypeId, portalFunctionalAreaId });
	}

	deleteDefaultTeam(teamId: string, functionalAreaId: string): Promise<BaseResponse> {
		return this.api.post('DeleteDefaultTeam', {
			teamId, functionalAreaId
		}) as Promise<BaseResponse>;
	}

	linkPortalTeams(portalFunctionalAreaIds: Array<string>, portalTeamId: string, portalPlanTypeId: string) {
		return this.api.post('LinkPortalTeams', { portalFunctionalAreaIds, portalTeamId, portalPlanTypeId });
	}

	unlinkPortalTeam(portalFunctionalAreaId: string, portalTeamId: string, portalPlanTypeId: string) {
		return this.api.post('UnlinkPortalTeam', { portalFunctionalAreaId, portalTeamId, portalPlanTypeId });
	}

	getIndustryPlanTypes(industryId: string) {
		return this.api.post('getindustryplantypes', {
			industryId: industryId
		});
	}

	getIndustryPlanTypeFeatures(industryId: string) {
		return new Promise((resolve, reject) => {
			if (this.industryPlanTypeFeatures == null || this.industryPlanTypeFeatures.length === 0) {
				this.api.post('getindustryplantypefeatures', {
					industryId: industryId
				}).then((res) => {
					this.industryPlanTypeFeatures = res as any;
					resolve(this.industryPlanTypeFeatures);
				}).catch((_) => {
					reject();
				});
			}
			else {
				resolve(this.industryPlanTypeFeatures);
			}
		});
	}

	addPortalPlanType(planTypeId: string) {
		return this.api.post('addportalplantype', {
			planTypeId: planTypeId
		});
	}

	deletePortalPlanType(planTypeId: string) {
		return this.api.post('deleteportalplantype', {
			planTypeId: planTypeId
		});
	}

	saveDelegationOfAuthority(delegationOfAuthority: DelegationOfAuthority) {
		return this.api.post('saveDelegationOfAuthority', { delegationOfAuthority: delegationOfAuthority });
	}

	deleteDelegationOfAuthority(delegationOfAuthorityId: string) {
		return this.api.post('deleteDelegationOfAuthority', { delegationOfAuthorityId });
	}

	getDelegationOfAuthority(delegationOfAuthorityId: string) {
		return this.api.post('getDelegationOfAuthority', { delegationOfAuthorityId });
	}

	getDelegationOfAuthorities(functionalAreaId: string) {
		return this.api.post('getDelegationOfAuthorities', { functionalAreaId });
	}

	saveOrderOfSuccession(orderOfSuccession: OrderOfSuccession) {
		return this.api.post('saveOrderOfSuccession', { orderOfSuccession: orderOfSuccession });
	}

	deleteOrderOfSuccession(orderOfSuccessionId: string) {
		return this.api.post('deleteOrderOfSuccession', { orderOfSuccessionId });
	}

	getOrderOfSuccession(orderOfSuccessionId: string) {
		return this.api.post('getOrderOfSuccession', { orderOfSuccessionId });
	}

	getOrderOfSuccessions(functionalAreaId: string) {
		return this.api.post('getOrderOfSuccessions', { functionalAreaId });
	}

	getBCPlanGenTargetsList(planTypeId: string) {
		return this.api.post('getbcplangentargetslist', {
			planTypeId
		});
	}

	// END TEAMS

	hasPlanTypes(planTypes: string[]): boolean {
		const portalPlanTypes: string[] = this.localStorageService.get(storageKeys.portalPlanTypes);
		const pt: string[] = portalPlanTypes.map((planTypes: any) => {
			return planTypes;
		});

		return !!planTypes.filter((planType: string) => pt.includes(planType));
	}

	navigateToDepartmentFlow(portalPlanTypeId: string, siteId: string, portalFunctionalAreaId, isTeamLead?: boolean) {
		this.getCurrentPortalRevisionsByPortalFunctionalArea(portalFunctionalAreaId, portalPlanTypeId)
			.then((res: any) => {
				// get the revisionItemTypes from storage, but filter it down to just this portalPlanTypeId (revisionScope)
				const revisionItemTypesFromStorage: RevisionItemType[] = JSON.parse(localStorage?.getItem('preparisPortal.revisionItemTypes')).filter((ritfs: RevisionItemType) => {
					return ritfs.portalPlanTypeId == portalPlanTypeId
					  && ritfs.revisionScope === revisionScopes.department.key;
				}) as any;
				const revisionPeriod: RevisionPeriod = res.revisionPeriod;
				// all possible "department" pages /w routes
				let revisions: Array<{ revisionItemTypeId: string, route: string[], revisionStateTypeId: string, featureType: string }> = [
					{
						revisionItemTypeId: bcRevisionItemTypes.departmentDescription,
						route: ['/business-continuity/planner', portalPlanTypeId, siteId, portalFunctionalAreaId, 'department-description'],
						revisionStateTypeId: '',
						featureType: featureTypes.manageDepartments
					},
					{
						revisionItemTypeId: bcRevisionItemTypes.departmentAlternateLeader,
						route: ['/business-continuity/planner', portalPlanTypeId, siteId, portalFunctionalAreaId, 'department-alternate'],
						revisionStateTypeId: '',
						featureType: featureTypes.manageDepartments
					},
					{
						revisionItemTypeId: bcRevisionItemTypes.departmentOOS,
						route: ['/business-continuity/planner', portalPlanTypeId, siteId, portalFunctionalAreaId, 'orders-of-succession'],
						revisionStateTypeId: '',
						featureType: featureTypes.departmentOOS
					},
					{
						revisionItemTypeId: bcRevisionItemTypes.departmentDOA,
						route: ['/business-continuity/planner', portalPlanTypeId, siteId, portalFunctionalAreaId, 'authority-delegations'],
						revisionStateTypeId: '',
						featureType: featureTypes.departmentDOA
					},
					{
						revisionItemTypeId: bcRevisionItemTypes.departmentTeams,
						route: ['/business-continuity/planner', portalPlanTypeId, siteId, portalFunctionalAreaId, 'response-teams'],
						revisionStateTypeId: '',
						featureType: featureTypes.departmentTeams
					},
					{
						revisionItemTypeId: bcRevisionItemTypes.validateProcess,
						route: ['/business-continuity/planner', portalPlanTypeId, 'validate-process', siteId, 'select-process', portalFunctionalAreaId],
						revisionStateTypeId: '',
						featureType: featureTypes.manageProcess
					},
					{
						revisionItemTypeId: bcRevisionItemTypes.sendAssignmentEmail,
						route: ['/business-continuity/planner', portalPlanTypeId, 'validate-process', siteId, 'introductory-email', portalFunctionalAreaId],
						revisionStateTypeId: '',
						featureType: featureTypes.manageProcess
					}
				];
				let route: string[] = [];

				// filter revision to available revisionItemTypesFromStorage
				revisions = revisions.filter((r: { revisionItemTypeId: string, route: string[], revisionStateTypeId: string, featureType: string }) => {
					return !!revisionItemTypesFromStorage.find((ritfs: RevisionItemType) => r.revisionItemTypeId === ritfs.revisionItemTypeId);
				});

				// find 1st revision item is in process or not started and go to it.
				revisions.forEach((revision: any) => {
					const revisionItem: RevisionItem = revisionPeriod.revisionItems.find((ri: RevisionItem) => ri.revisionItemTypeId === revision.revisionItemTypeId);
					revision.revisionStateTypeId = revisionItem?.revisionStateTypeId || bcRevisionStateType.notStarted;

					// if the 1st step not completed is the department description, then go to introduction page instead
					if (this.permissionService.hasFeature(featureTypes.manageDepartments, portalPlanTypeId)
					  && (this.permissionService.hasPermission(permissionFeatures.agilityPlanner.key, [permissionFeatures.agilityPlanner.actions.businessContinuityManagement, permissionFeatures.agilityPlanner.actions.businessContinuityAudit])
					  || isTeamLead)
					  && revision.revisionItemTypeId === bcRevisionItemTypes.departmentDescription
					  && revision.revisionStateTypeId === bcRevisionStateType.notStarted
					  && !route?.length
					) {
						route = ['/business-continuity/planner', portalPlanTypeId, siteId, portalFunctionalAreaId, 'introduction'];
					}

					if (this.permissionService.hasFeature(revision.featureType, portalPlanTypeId)
					  && (this.permissionService.hasPermission(permissionFeatures.agilityPlanner.key, [permissionFeatures.agilityPlanner.actions.businessContinuityManagement, permissionFeatures.agilityPlanner.actions.businessContinuityAudit])
					  || isTeamLead)
					  && (
					  	revisionItem?.revisionStateTypeId === bcRevisionStateType.inProcess
					  	|| revisionItem?.revisionStateTypeId === bcRevisionStateType.notStarted
					  	|| !revisionItem
					  )
					  && !route?.length
					) {
						this.router.navigate(revision.route);
						route = revision.route;
					}
				});

				if (!revisions.find(r => r.revisionStateTypeId === bcRevisionStateType.notStarted || r.revisionStateTypeId === bcRevisionStateType.inProcess)
				  && !route?.length) {
					// all steps completed, go to track
					route = ['/business-continuity/planner', portalPlanTypeId, siteId, portalFunctionalAreaId, 'track', 'processes'];
				}

				// at this point if route is still empty go to introduction
				if (!route?.length) {
					route = ['/business-continuity/planner', portalPlanTypeId, siteId, portalFunctionalAreaId, 'introduction'];
				}

				this.router.navigate(route);
			})
			.catch((err: any) => {
			});
	}

	navigateToTechnologyFlow(portalPlanTypeId: string, siteId: string, portalPlanTechnologyId: string, isTechnologyOwner?: boolean) {
		this.getCurrentRevisionPeriodByPortalPlanTechnology(portalPlanTechnologyId, portalPlanTypeId)
			.then((res: any) => {
				// get the revisionItemTypes from storage, but filter it down to just this portalPlanTypeId (revisionScope)
				const revisionItemTypesFromStorage: RevisionItemType[] = JSON.parse(localStorage?.getItem('preparisPortal.revisionItemTypes')).filter((ritfs: RevisionItemType) => {
					return ritfs.portalPlanTypeId == portalPlanTypeId
					  && ritfs.revisionScope === revisionScopes.technology.key;
				}) as any;
				const revisionPeriod: RevisionPeriod = res.revisionPeriod;
				// all possible "technology" pages /w routes
				let revisions: Array<{ revisionItemTypeId: string, route: string[], revisionStateTypeId: string, featureType: string }> = [
					{
						// technologyVendors
						revisionItemTypeId: bcRevisionItemTypes.technologyVendors,
						route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'technology', portalPlanTechnologyId, 'vendors'],
						revisionStateTypeId: '',
						featureType: featureTypes.manageTechnologies
					},
					{
						// technologyDependencies
						revisionItemTypeId: bcRevisionItemTypes.technologyDependencies,
						route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'technology', portalPlanTechnologyId, 'upstream-dependencies'],
						revisionStateTypeId: '',
						featureType: featureTypes.manageTechnologies
					},
					{
						// technology Details and Attachments
						revisionItemTypeId: bcRevisionItemTypes.technologyApplicationDetails,
						route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'technology', portalPlanTechnologyId, 'details-and-attachments'],
						revisionStateTypeId: '',
						featureType: featureTypes.manageTechnologies
					},
					{
						// technologyTasks
						revisionItemTypeId: bcRevisionItemTypes.technologyTasks,
						route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'technology', portalPlanTechnologyId, 'tasks-sets'],
						revisionStateTypeId: '',
						featureType: featureTypes.manageTechnologies
					},
					{
						// technologyRecoveryTime
						revisionItemTypeId: bcRevisionItemTypes.technologyRecoveryTime,
						route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'technology', portalPlanTechnologyId, 'recovery-time'],
						revisionStateTypeId: '',
						featureType: featureTypes.manageTechnologies
					}
				];
				let route: string[] = [];

				// filter revision to available revisionItemTypesFromStorage
				revisions = revisions.filter((r: { revisionItemTypeId: string, route: string[], revisionStateTypeId: string, featureType: string }) => {
					return !!revisionItemTypesFromStorage.find((ritfs: RevisionItemType) => r.revisionItemTypeId === ritfs.revisionItemTypeId);
				});

				// find 1st revision item is in technology or not started and go to it.
				revisions.forEach((revision: any) => {
					const revisionItem: RevisionItem = revisionPeriod.revisionItems.find((ri: RevisionItem) => ri.revisionItemTypeId === revision.revisionItemTypeId);
					revision.revisionStateTypeId = revisionItem?.revisionStateTypeId || bcRevisionStateType.notStarted;
					// if the 1st step not completed is the vendors, then go to introduction page instead
					if (this.permissionService.hasFeature(featureTypes.manageTechnologies, portalPlanTypeId)
					  && (this.permissionService.hasPermission(permissionFeatures.agilityPlanner.key, [permissionFeatures.agilityPlanner.actions.businessContinuityManagement, permissionFeatures.agilityPlanner.actions.businessContinuityAudit])
					  || isTechnologyOwner)
					  && revision.revisionItemTypeId === bcRevisionItemTypes.technologyVendors
					  && revision.revisionStateTypeId === bcRevisionStateType.notStarted
					  && !route?.length
					) {
						route = ['/business-continuity/planner', portalPlanTypeId, siteId, 'technology', portalPlanTechnologyId, 'introduction'];
					}

					if (this.permissionService.hasFeature(revision.featureType, portalPlanTypeId)
					  && (this.permissionService.hasPermission(permissionFeatures.agilityPlanner.key, [permissionFeatures.agilityPlanner.actions.businessContinuityManagement, permissionFeatures.agilityPlanner.actions.businessContinuityAudit])
					  || isTechnologyOwner)
					  && (
					  	revisionItem?.revisionStateTypeId === bcRevisionStateType.inProcess
					  	|| revisionItem?.revisionStateTypeId === bcRevisionStateType.notStarted
					  	|| !revisionItem
					  )
					  && !route?.length
					) {
						this.router.navigate(revision.route);
						route = revision.route;
					}
				});

				if (!revisions.find(r => r.revisionStateTypeId === bcRevisionStateType.notStarted || r.revisionStateTypeId === bcRevisionStateType.inProcess)
				  && !route?.length) {
					// all steps completed, go to track
					route = ['/business-continuity/planner', portalPlanTypeId, siteId, 'technology', portalPlanTechnologyId, 'recovery-time'];
				}

				// at this point if route is still empty go to introduction
				if (!route?.length) {
					route = ['/business-continuity/planner', portalPlanTypeId, siteId, 'technology', portalPlanTechnologyId, 'introduction'];
				}

				this.router.navigate(route);
			})
			.catch((err: any) => {
			});
	}

	navigateToAnnexFlow(portalPlanTypeId: string, siteId: string, portalAnnexId: string, isAnnexLead?: boolean) {
		this.getCurrentRevisionPeriodByPortalAnnex(portalAnnexId, portalPlanTypeId)
			.then((res: any) => {
				console.log(res);
				// get the revisionItemTypes from storage, but filter it down to just this portalPlanTypeId (revisionScope)
				const revisionItemTypesFromStorage: RevisionItemType[] = JSON.parse(localStorage?.getItem('preparisPortal.revisionItemTypes')).filter((ritfs: RevisionItemType) => {
					return ritfs.portalPlanTypeId == portalPlanTypeId
					  && ritfs.revisionScope === revisionScopes.annex.key;
				}) as any;
				const revisionPeriod: RevisionPeriod = res.revisionPeriod;
				// all possible "annex" pages /w routes
				let revisions: Array<{ revisionItemTypeId: string, route: string[], revisionStateTypeId: string, featureType: string }> = [
					{
						// annexSections
						revisionItemTypeId: bcRevisionItemTypes.annexSections,
						route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'annex', portalAnnexId, 'sections'],
						revisionStateTypeId: '',
						featureType: featureTypes.manageAnnex
					},
					{
						// annexAgencies
						revisionItemTypeId: bcRevisionItemTypes.annexAgencies,
						route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'annex', portalAnnexId, 'tasked-agencies'],
						revisionStateTypeId: '',
						featureType: featureTypes.manageAnnex
					},
					{
						// annexActionList
						revisionItemTypeId: bcRevisionItemTypes.annexActionList,
						route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'annex', portalAnnexId, 'responsibilities'],
						revisionStateTypeId: '',
						featureType: featureTypes.manageAnnex
					}
				];
				let route: string[] = [];

				// filter revision to available revisionItemTypesFromStorage
				revisions = revisions.filter((r: { revisionItemTypeId: string, route: string[], revisionStateTypeId: string, featureType: string }) => {
					return !!revisionItemTypesFromStorage.find((ritfs: RevisionItemType) => r.revisionItemTypeId === ritfs.revisionItemTypeId);
				});

				// find 1st revision item is in technology or not started and go to it.
				revisions.forEach((revision: any) => {
					const revisionItem: RevisionItem = revisionPeriod.revisionItems.find((ri: RevisionItem) => ri.revisionItemTypeId === revision.revisionItemTypeId);
					revision.revisionStateTypeId = revisionItem?.revisionStateTypeId || bcRevisionStateType.notStarted;
					// if the 1st step not completed is the sections, then go to introduction page instead
					if (this.permissionService.hasFeature(featureTypes.manageAnnexes, portalPlanTypeId)
					  && (this.permissionService.hasPermission(permissionFeatures.agilityPlanner.key, [permissionFeatures.agilityPlanner.actions.businessContinuityManagement, permissionFeatures.agilityPlanner.actions.businessContinuityAudit])
					  || isAnnexLead)
					  && revision.revisionItemTypeId === bcRevisionItemTypes.annexSections
					  && revision.revisionStateTypeId === bcRevisionStateType.notStarted
					  && !route?.length
					) {
						route = ['/business-continuity/planner', portalPlanTypeId, siteId, 'annex', portalAnnexId, 'introduction'];
					}

					if (this.permissionService.hasFeature(revision.featureType, portalPlanTypeId)
					  && (this.permissionService.hasPermission(permissionFeatures.agilityPlanner.key, [permissionFeatures.agilityPlanner.actions.businessContinuityManagement, permissionFeatures.agilityPlanner.actions.businessContinuityAudit])
					  || isAnnexLead)
					  && (
					  	revisionItem?.revisionStateTypeId === bcRevisionStateType.inProcess
					  	|| revisionItem?.revisionStateTypeId === bcRevisionStateType.notStarted
					  	|| !revisionItem
					  )
					  && !route?.length
					) {
						this.router.navigate(revision.route);
						route = revision.route;
					}
				});

				if (!revisions.find(r => r.revisionStateTypeId === bcRevisionStateType.notStarted || r.revisionStateTypeId === bcRevisionStateType.inProcess)
				  && !route?.length) {
					// all steps completed, go to track
					route = ['/business-continuity/planner', portalPlanTypeId, siteId, 'annex', portalAnnexId, 'responsibilities'];
				}

				// at this point if route is still empty go to introduction
				if (!route?.length) {
					route = ['/business-continuity/planner', portalPlanTypeId, siteId, 'annex', portalAnnexId, 'introduction'];
				}

				this.router.navigate(route);
			})
			.catch((err: any) => {
			});
	}

	/* ANNEXES */
	getSystemAnnexes() {
		return this.api.post('GetSystemAnnexes', {});
	}

	getPortalAnnexes() {
		return this.api.post('GetPortalAnnexes', {});
	}

	getPortalAnnex(portalAnnexId: string) {
		return this.api.post('GetPortalAnnex', { portalAnnexId });
	}

	savePortalAnnexes(annexes: PortalAnnex[], portalPlanTypeId: string, copyFromLibrary: boolean = false) {
		return this.api.post('SavePortalAnnexes', {
			items: annexes,
			portalPlanTypeId,
			copyFromLibrary
		});
	}

	savePortalAnnexAssignee(annex: PortalAnnex) {
		return this.api.post('SavePortalAnnexAssignee', {
			portalAnnexId: annex.portalAnnexId,
			assignedUserId: annex.assignees[0].assignedUserId,
			portalAnnexAssigneeId: annex.assignees[0].portalAnnexAssigneeId
		});
	}

	savePortalAnnexToLibrary(portalAnnexId: string) {
		return this.api.post('savePortalAnnexToLibrary', { portalAnnexId });
	}

	deleteSystemAnnexes(annexIdList: Array<string>) {
		return this.api.post('deleteSystemAnnexes', { annexIdList });
	}

	sendAnnexLeadIntroductoryEmails(portalAnnexIds: string[],
		emailTemplateAsBase64: string,
		emailTemplateNewUserAsBase64: string,
		portalPlanTypeId: string): Promise<ModelBaseModel> {
		return this.api.post('SendAnnexLeadIntroductoryEmails', {
			portalAnnexIds,
			emailTemplateAsBase64,
			emailTemplateNewUserAsBase64,
			portalPlanTypeId
		}) as Promise<ModelBaseModel>;
	};

	sendTechnologyLeadIntroductoryEmails(portalPlanTechnologyIds: string[],
		emailTemplateAsBase64: string,
		emailTemplateNewUserAsBase64: string,
		portalPlanTypeId: string): Promise<ModelBaseModel> {
		return this.api.post('SendTechnologyLeadIntroductoryEmails', {
			portalPlanTechnologyIds,
			emailTemplateAsBase64,
			emailTemplateNewUserAsBase64,
			portalPlanTypeId
		}) as Promise<ModelBaseModel>;
	}

	getPortalAnnexMessageRecipients(siteId?: string, portalAnnexId?: string): Promise<ModelGetPortalAnnexRecipientsResponse> {
		return this.api.post('GetPortalAnnexMessageRecipients', {
			siteId,
			portalAnnexId
		}) as Promise<ModelGetPortalAnnexRecipientsResponse>;
	}

	/* END ANNEXES */

	getPlanTypeIdByPortalPlanTypeId(portalPlanTypeId: string): string {
		const portalPlanTypes: any = this.localStorageService.get(storageKeys.portalPlanTypes);

		return portalPlanTypes.find((ppt: any) => ppt.portalPlanTypeId === portalPlanTypeId)?.planTypeId;
	}

	getPortalSupersession(portalPlanTypeId: string) {
		return this.api.post('getPortalSupersession', { portalPlanTypeId });
	}

	savePortalSupersession(supersessionText: string, portalPlanTypeId: string) {
		return this.api.post('savePortalSupersession', { supersessionText, portalPlanTypeId, supersessionTextAsBase64: '' });
	}

	getBCPortalGeneralQuestions(portalPlanTypeId: string) {
		return this.api.post('getbcportalgeneralquestions', { portalPlanTypeId });
	}

	saveBCPortalGeneralQuestions(portalPlanTypeId: string, whatQuestions: BCPlanGeneralQuestion[], whereQuestions: BCPlanGeneralQuestion[]) {
		return this.api.post('savebcportalgeneralquestionsvalues', { portalPlanTypeId, whatQuestions, whereQuestions });
	}

	getPortalAnnexAgencyRoles(portalAnnexId?: string): Promise<{ roles: Array<PortalAnnexAgencyRole> }> {
		return this.api.post('getPortalAnnexAgencyRoles', { portalAnnexId }) as any;
	}

	getPortalAnnexAgencies(portalAnnexId?: string, searchPhraseAsBase64?: string, portalAnnexAgencyRoleIds?: Array<string>): Promise<{ agencies: Array<PortalAnnexAgency> }> {
		return this.api.post('getPortalAnnexAgencies', { portalAnnexId, searchPhraseAsBase64, portalAnnexAgencyRoleIds }) as any;
	}

	savePortalAnnexAgencies(portalAnnexId: string, portalPlanTypeId: string, agencies: Array<PortalAnnexAgency>) {
		return this.api.post('savePortalAnnexAgencies', { portalAnnexId, portalPlanTypeId, agencies });
	}

	deletePortalAnnexAgency(portalAnnexAgencyId: string) {
		return this.api.post('deletePortalAnnexAgency', { portalAnnexAgencyId });
	}

	getPortalAnnexSections(portalAnnexId: string, portalPlanTypeId: string): Promise<{ sections: Array<AnnexSection> }> {
		return this.api.post('getPortalAnnexSections', { portalAnnexId, portalPlanTypeId }) as any;
	}

	getPortalAnnexSection(portalAnnexSectionId: string, portalAnnexId: string): Promise<{ section: AnnexSection }> {
		return this.api.post('getPortalAnnexSection', { portalAnnexSectionId, portalAnnexId }) as any;
	}

	savePortalAnnexSection(section: AnnexSection): Promise<any> {
		return this.api.post('savePortalAnnexSection', { section });
	}

	orderPortalAnnexSections(portalAnnexId: string, portalPlanTypeId: string, sections: Array<AnnexSection>): Promise<{ sections: AnnexSection[] }> {
		return this.api.post('orderPortalAnnexSections', { portalAnnexId, portalPlanTypeId, sections }) as any;
	}

	getPortalAnnexActionPhases(portalAnnexId: string): Promise<{ phases: ActionPhase[] }> {
		return this.api.post('getPortalAnnexActionPhases', { portalAnnexId }) as any;
	}

	getPortalAnnexAction(portalAnnexId: string, portalAnnexActionId: string): Promise<{ action: PortalAnnexAction }> {
		return this.api.post('getPortalAnnexAction', { portalAnnexId, portalAnnexActionId }) as any;
	}

	savePortalAnnexAction(action: PortalAnnexAction) {
		return this.api.post('savePortalAnnexAction', { action }) as any;
	}

	deletePortalAnnexAction(portalAnnexId: string, portalAnnexActionId: string) {
		return this.api.post('deletePortalAnnexAction', { portalAnnexId, portalAnnexActionId }) as any;
	}

	getBCPlanDataElementsByPortalPlanType(portalPlanTypeId: string, planTemplateTargetTypeId: string, planDataElementTypeId: string) {
		return this.api.post('GetBCPlanDataElementsByPortalPlanType', { portalPlanTypeId, planTemplateTargetTypeId, planDataElementTypeId }) as any;
	}

	exportPlanTemplate(planTemplateId: string) {
		return this.api.post('exportPlanTemplate', { planTemplateId }, true, false);
	}

	importPlanTemplate(planTypeId: string, fileAsBase64: string, fileName: string, keepSameTemplateId: boolean = false) {
		const request = {
			planTypeId,
			fileAsBase64,
			fileNameAsBase64: this.utility.base64Encode(fileName),
			keepSameTemplateId
		};
		return this.api.post('importPlanTemplate', request);
	}

	getDataElementTranslatedName(planTemplateTargetTypeId: string, dataElementName: string) {
		const den = this.formatText(dataElementName);

		const key = 'businessContinuity.planBuilder.planTemplate.elements.';
		const keys = new Array<string>();
		Object.keys(planTemplateTargetTypeIds).forEach((k) => {
			if (planTemplateTargetTypeIds[k] === planTemplateTargetTypeId) {
				keys.push(`${key}${k}.${den}`);
			}
		});
		keys.push(`${key}${den}`);
		return this.utility.translateWithOptions(keys);
	}

	formatText(dataElementName: string) {
		const re = /\_/gi;
		return dataElementName[0].toLowerCase() + dataElementName.replace(re, '').slice(1);
	}

	getPortalFunctionalAreaAlternateLeaders(portalFunctionalAreaId: string) {
		return this.api.post('getPortalFunctionalAreaAlternateLeaders', { portalFunctionalAreaId });
	}

	savePortalFunctionalAreaAlternateLeaders(portalFunctionalAreaId: string, alternateLeaderUserIds: Array<string>) {
		const request = {
			portalFunctionalAreaId,
			alternateLeaderUserIds
		};
		return this.api.post('savePortalFunctionalAreaAlternateLeaders', request);
	}

	getPortalProcessAlternateResponsibleUsers(portalProcessId: string) {
		return this.api.post('getPortalProcessAlternateResponsibleUsers', { portalProcessId });
	}

	savePortalProcessAlternateResponsibleUsers(portalProcessId: string, alternateResponsibleUserIds: Array<string>) {
		const request = {
			portalProcessId,
			alternateResponsibleUserIds
		};
		return this.api.post('savePortalProcessAlternateResponsibleUsers', request);
	}

	getTechnologyTypes(): Promise<ModelGetTechnologyTypeResponse> {
		return this.api.post('GetTechnologyTypes', {}) as Promise<ModelGetTechnologyTypeResponse>;
	}

	saveTechnologyType(technologyType: TechnologyType) {
		return this.api.post('SaveTechnologyType', { technologyType });
	}

	deleteTechnologyType(TechnologyTypeId: string) {
		return this.api.post('DeleteTechnologyType', { TechnologyTypeId });
	}

	getTechnologyTiers() {
		return this.api.post('getTechnologyTiers', {});
	}

	getPortalPlanTechnologies(portalPlanTypeId: string, assignedOnly: boolean, filters: TechnologyRequestFilter): Promise<ModelGetPortalPlanTechnologiesResponse> {
		return this.api.post('getPortalPlanTechnologies', { portalPlanTypeId, assignedOnly, ...filters }) as Promise<ModelGetPortalPlanTechnologiesResponse>;
	}

	savePortalPlanTechnologies(portalPlanTypeId: string, technologyIds: string[]) {
		return this.api.post('savePortalPlanTechnologies', { portalPlanTypeId, technologyIds });
	}

	savePortalPlanTechnology(portalPlanTechnology: PlanTechnology) {
		return this.api.post('SavePortalPlanTechnology', { portalPlanTechnology: portalPlanTechnology });
	}

	savePortalPlanTechnologiesOwner(ownerUserId: string, portalPlanTechnologyIds: string[]) {
		return this.api.post('savePortalPlanTechnologiesOwner', { ownerUserId, portalPlanTechnologyIds });
	}

	savePortalPlanTechnologiesAlternateOwner(alternateOwnerUserId: string, portalPlanTechnologyIds: string[]) {
		return this.api.post('savePortalPlanTechnologiesAlternateOwner', { alternateOwnerUserId, portalPlanTechnologyIds });
	}

	deletePortalPlanTechnologies(portalPlanTypeId: string, technologyIds: string[]) {
		return this.api.post('deletePortalPlanTechnologies', { portalPlanTypeId, technologyIds });
	}

	validateSystemPlanTemplateAccess(code: ConfirmationCode) {
		return this.api.post('validateSystemPlanTemplateAccess', { ...code });
	}

	getPortalPlanTechnologyVendors(portalPlanTechnologyId: string): Promise<{ portalPlanTechnologyVendors: BcPlanVendor[] }> {
		return this.api.post('getPortalPlanTechnologyVendors', { portalPlanTechnologyId }) as Promise<{ portalPlanTechnologyVendors: BcPlanVendor[] }>;
	}

	getPortalPlanTechnologyVendor(portalPlanTechnologyVendorId: string, portalPlanTechnologyId: string): Promise<any> {
		return this.api.post('getPortalPlanTechnologyVendor', { portalPlanTechnologyVendorId, portalPlanTechnologyId });
	}

	savePortalPlanTechnologyVendor(vendor: BcPlanVendor): Promise<any> {
		return this.api.post('savePortalPlanTechnologyVendor', { vendor });
	}

	uploadPortalPlanTechnologyVendorDocument(document: Document, vendorId: string, portalPlanTechnologyId: string, portalPlanTechnologyVendorId: string): Promise<any> {
		return this.api.post('uploadPortalPlanTechnologyVendorDocument', { document, vendorId, portalPlanTechnologyId, portalPlanTechnologyVendorId });
	};

	deletePortalPlanTechnologyVendor(portalPlanTechnologyVendorId: string, portalPlanTechnologyId: string): Promise<any> {
		return this.api.post('deletePortalPlanTechnologyVendor', { portalPlanTechnologyVendorId, portalPlanTechnologyId });
	}

	getPortalPlanTechnologyVendorDocuments(portalPlanTechnologyVendorId: string, portalPlanTechnologyId: string): Promise<any> {
		return this.api.post('getPortalPlanTechnologyVendorDocuments', { portalPlanTechnologyVendorId, portalPlanTechnologyId });
	}

	getPortalPlanTechnologyDocuments(portalPlanTechnologyId: string): Promise<ModelGetPortalPlanTechnologyDocumentsResponse> {
		return this.api.post('GetPortalPlanTechnologyDocuments', { portalPlanTechnologyId }) as Promise<ModelGetPortalPlanTechnologyDocumentsResponse>;
	}

	uploadPortalPlanTechnologyDocument(portalPlanTechnologyId: string, document: Document): Promise<any> {
		return this.api.post('UploadPortalPlanTechnologyDocument', { portalPlanTechnologyId, document });
	}

	deletePortalPlanTechnologyVendorDocument(documentId: string): Promise<any> {
		return this.api.post('deletePortalPlanTechnologyVendorDocument', { documentId });
	}

	deletePortalPlanTechnologyDocument(documentId: string): Promise<BaseResponse> {
		return this.api.post('DeletePortalPlanTechnologyDocument', { documentId }) as Promise<BaseResponse>;
	}

	getPortalPlanTechnologyVendorDocumentAsStream(documentId: string): Promise<any> {
		return this.api.post('getPortalPlanTechnologyVendorDocumentAsStream', { documentId }, true);
	}

	getPortalPlanTechnologyDocumentAsStream(documentId: string): Promise<any> {
		return this.api.post('GetPortalPlanTechnologyDocumentAsStream', { documentId }, true);
	}

	getPortalTechnologyMessageRecipients(siteId?: string, portalPlanTechnologyId?: string): Promise<GetPortalPlanTechnologyMessageRecipientsResponse> {
		return this.api.post('getportalplantechnologymessagerecipients', { siteId, portalPlanTechnologyId }) as Promise<GetPortalPlanTechnologyMessageRecipientsResponse>;
	}

	getPortalPlanTechnologyDependencies(portalPlanTechnologyId: string): Promise<{ portalPlanTechnologyDependencies: PortalPlanTechnologyDependency[] }> {
		return this.api.post('getPortalPlanTechnologyDependencies', { portalPlanTechnologyId }) as Promise<{ portalPlanTechnologyDependencies: PortalPlanTechnologyDependency[] }>;
	}

	savePortalPlanTechnologyDependencies(portalPlanTechnologyId: string, portalPlanTechnologyDependencies: PortalPlanTechnologyDependency[]): Promise<any> {
		return this.api.post('savePortalPlanTechnologyDependencies', { portalPlanTechnologyId, portalPlanTechnologyDependencies });
	}

	getBcTechnologyForDependencies(portalPlanTechnologyId: string, filters: TechnologyRequestFilter): Promise<{ portalPlanTechnologies: PortalPlanTechnologyForDependencies[] }> {
		return this.api.post('GetBcTechnologiesForDependencies', { portalPlanTechnologyId, ...filters }) as Promise<{ portalPlanTechnologies: PortalPlanTechnologyForDependencies[] }>;
	}

	deletePortalPlanTechnologyDependency(portalPlanTechnologyDependencyId: string): Promise<any> {
		return this.api.post('deletePortalPlanTechnologyDependency', { portalPlanTechnologyDependencyId });
	}

	getPortalPlanTechnologyTaskSets(portalPlanTechnologyId: string): Promise<{ portalPlanTechnologyTaskSets: TechnologyTaskSet[] }> {
		return this.api.post('getPortalPlanTechnologyTaskSets', { portalPlanTechnologyId }) as Promise<{ portalPlanTechnologyTaskSets: TechnologyTaskSet[] }>;
	}

	savePortalPlanTechnologyTaskSet(technologyTaskSet: TechnologyTaskSet): Promise<{ portalPlanTechnologyTaskSets: TechnologyTaskSet[] }> {
		return this.api.post('savePortalPlanTechnologyTaskSet', { ...technologyTaskSet }) as Promise<{ portalPlanTechnologyTaskSets: TechnologyTaskSet[] }>;
	}

	deletePortalPlanTechnologyTaskSet(technologyTaskSet: TechnologyTaskSet): Promise<{ portalPlanTechnologyTaskSets: TechnologyTaskSet[] }> {
		return this.api.post('deletePortalPlanTechnologyTaskSet', { ...technologyTaskSet }) as Promise<{ portalPlanTechnologyTaskSets: TechnologyTaskSet[] }>;
	}

	savePortalPlanTechnologyTask(technologyTask: TechnologyTask, portalPlanTechnologyId: string): Promise<any> {
		return this.api.post('savePortalPlanTechnologyTask', { ...technologyTask, portalPlanTechnologyId });
	}

	deletePortalPlanTechnologyTask(technologyTask: TechnologyTask, portalPlanTechnologyId: string): Promise<any> {
		return this.api.post('deletePortalPlanTechnologyTask', { ...technologyTask, portalPlanTechnologyId });
	}

	orderPortalPlanTechnologyTaskSets(portalPlanTechnologyTaskSets: TechnologyTaskSet[], portalPlanTechnologyId: string): Promise<any> {
		return this.api.post('orderPortalPlanTechnologyTaskSets', { portalPlanTechnologyTaskSets, portalPlanTechnologyId }) as any;
	}

	orderPortalPlanTechnologyTasks(portalPlanTechnologyTasks: TechnologyTask[], portalPlanTechnologyTaskSetId: string, portalPlanTechnologyId: string): Promise<any> {
		return this.api.post('orderPortalPlanTechnologyTasks', { portalPlanTechnologyTasks, portalPlanTechnologyTaskSetId, portalPlanTechnologyId }) as any;
	}

	savePortalPlanTechnologyTaskSetMembers(members: string[], portalPlanTechnologyTaskSetId: string, portalPlanTechnologyId: string): Promise<any> {
		return this.api.post('savePortalPlanTechnologyTaskSetMembers', { members, portalPlanTechnologyTaskSetId, portalPlanTechnologyId }) as any;
	}

	savePortalPlanTechnologyTaskSetTeams(teams: string[], portalPlanTechnologyTaskSetId: string, portalPlanTechnologyId: string): Promise<any> {
		return this.api.post('savePortalPlanTechnologyTaskSetTeams', { teams, portalPlanTechnologyTaskSetId, portalPlanTechnologyId }) as any;
	}

	savePortalPlanTechnologyTaskSetTime(time: any, portalPlanTechnologyTaskSetId: string, portalPlanTechnologyId: string): Promise<any> {
		return this.api.post('savePortalPlanTechnologyTaskSetTime', { ...time, portalPlanTechnologyTaskSetId, portalPlanTechnologyId }) as any;
	}

	getPortalPlanTechnologyGaps(portalPlanTypeId: string): Promise<BcPortalPlanTechnologyGap> {
		return this.api.post('GetPortalPlanTechnologyGaps', { portalPlanTypeId: portalPlanTypeId }) as Promise<BcPortalPlanTechnologyGap>;
	}

	getPortalPlanTeamsWithTechnologies(portalPlanTypeId: string): Promise<{ teams: DRTeam[] }> {
		return this.api.post('GetPortalPlanTeamsWithTechnologies', { portalPlanTypeId: portalPlanTypeId }) as Promise<{ teams: DRTeam[] }>;
	}

	getPortalPlanVendorsWithTechnologies(portalPlanTypeId: string): Promise<{ vendors: DRVendor[] }> {
		return this.api.post('GetPortalPlanVendorsWithTechnologies', { portalPlanTypeId: portalPlanTypeId }) as Promise<{ vendors: DRVendor[] }>;
	}

	getPortalPlanTechnologiesWithDetails(portalPlanTypeId: string, filters: TechnologyWithDetailsRequestFilter): Promise<ModelGetPortalPlanTechnologiesWithDetailsResponse> {
		return this.api.post('getPortalPlanTechnologiesWithDetails', { portalPlanTypeId, ...filters }) as Promise<ModelGetPortalPlanTechnologiesWithDetailsResponse>;
	}

	exportPortalPlanTechnologyAnalyticsCsvFile(portalPlanTypeId: string, filters: TechnologyWithDetailsRequestFilter, excludedColumns: string[]): Promise<any> {
		return this.api.post('exportPortalPlanTechnologyAnalyticsCsvFile', { portalPlanTypeId, ...filters, excludedColumns }, true);
	}

	getPortalTechnologiesRPOs(portalPlanTypeId: string): Promise<ModelGetPortalTechnologiesRPOsResponse> {
		return this.api.post('getPortalTechnologiesRPOs', { portalPlanTypeId }) as Promise<ModelGetPortalTechnologiesRPOsResponse>;
	}

	getPortalPlanTechnologiesTiers(portalPlanTypeId: string): Promise<ModelGetPortalPlanTechnologiesTiersResponse> {
		return this.api.post('getPortalPlanTechnologiesTiers', { portalPlanTypeId }) as Promise<ModelGetPortalPlanTechnologiesTiersResponse>;
	}

	getPortalTechnologiesProcesses(portalPlanTypeId: string): Promise<ModelGetPortalTechnologiesProcessesResponse> {
		return this.api.post('getPortalTechnologiesProcesses', { portalPlanTypeId }) as Promise<ModelGetPortalTechnologiesProcessesResponse>;
	}

	getPortalTechnologiesVendors(portalPlanTypeId: string): Promise<ModelGetPortalTechnologiesVendorsResponse> {
		return this.api.post('getPortalTechnologiesVendors', { portalPlanTypeId }) as Promise<ModelGetPortalTechnologiesVendorsResponse>;
	}
}
