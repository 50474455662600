import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PasswordPoliciesModel } from '@app/shared/login/password-policies.model';
import { PasswordPoliciesCheckResultsModel } from '@app/shared/login/password-policies-check-results.model';

@Component({
	selector: 'app-password-policies',
	templateUrl: './password-policies.component.html',
	styleUrls: ['./password-policies.component.scss']
})
export class PasswordPoliciesComponent implements OnInit {
	@Input() passwordPolicies: PasswordPoliciesModel;
	@Input() loading: boolean;
	@Output() passwordPoliciesSatisfied: EventEmitter<boolean> = new EventEmitter<boolean>();
	policyCheckResults: PasswordPoliciesCheckResultsModel;
	readonly specialChars = /[^!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/gi;

	constructor() {
	}

	// use setter to validate password
	@Input()
	set password(value: string) {
		this.validatePassword(value);
	}

	ngOnInit() {
	}

	getStyleProps(policyType) {
		switch (policyType) {
			case 'length':
				return {
					'fa-circle-o': !this.policyCheckResults.length,
					'fa-check-circle-o': this.policyCheckResults.length,
					'passwordPolicySatisfied': this.policyCheckResults.length
				};
			case 'symbols':
				return {
					'fa-circle-o': !this.policyCheckResults.symbols,
					'fa-check-circle-o': this.policyCheckResults.symbols,
					'passwordPolicySatisfied': this.policyCheckResults.symbols
				};
			case 'uppercase':
				return {
					'fa-circle-o': !this.policyCheckResults.uppercaseLetters,
					'fa-check-circle-o': this.policyCheckResults.uppercaseLetters,
					'passwordPolicySatisfied': this.policyCheckResults.uppercaseLetters
				};
			case 'lowercase':
				return {
					'fa-circle-o': !this.policyCheckResults.lowercaseLetters,
					'fa-check-circle-o': this.policyCheckResults.lowercaseLetters,
					'passwordPolicySatisfied': this.policyCheckResults.lowercaseLetters
				};
			case 'numbers':
				return {
					'fa-circle-o': !this.policyCheckResults.numbers,
					'fa-check-circle-o': this.policyCheckResults.numbers,
					'passwordPolicySatisfied': this.policyCheckResults.numbers
				};
			default:
				return null;
		}
	}

	validatePassword(newValue) {
		this.policyCheckResults = new PasswordPoliciesCheckResultsModel();
		// this.passwordPoliciesSatisfied = false;

		if (newValue) {
			// check length
			this.policyCheckResults.length = this.passwordPolicies.length > 0
			&& newValue.length >= this.passwordPolicies.length;

			// check uppercase
			this.policyCheckResults.uppercaseLetters = this.passwordPolicies.uppercaseLetters > 0
			&& newValue.replace(/[^A-Z]/g, '').length >= this.passwordPolicies.uppercaseLetters;

			// check lowercase
			this.policyCheckResults.lowercaseLetters = this.passwordPolicies.lowercaseLetters > 0
			&& newValue.replace(/[^a-z]/g, '').length >= this.passwordPolicies.lowercaseLetters;

			// check numbers
			this.policyCheckResults.numbers = this.passwordPolicies.numbers > 0
			&& newValue.replace(/[^0-9]/g, '').length >= this.passwordPolicies.numbers;

			// check special characters
			this.policyCheckResults.symbols = this.passwordPolicies.symbols > 0
			&& newValue.replace(this.specialChars, '').length >= this.passwordPolicies.symbols;

			// check if all password policies have been satisfied
			let passwordPoliciesSatisfied = true;
			Object.keys(this.passwordPolicies).forEach((key) => {
				if (this.passwordPolicies[key] > 0
				  && !this.policyCheckResults[key]) {
					passwordPoliciesSatisfied = false;
				}
			});

			this.passwordPoliciesSatisfied.emit(passwordPoliciesSatisfied);
		}
	}
}
