export class ResetPasswordRequestModel {
	userName: string;
	userNameAsBase64: string = '';
	referrerId: string;
	referrerIdAsBase64: string = '';

	constructor(userName: string, reffererId: string) {
		this.userName = userName;
		this.referrerId = reffererId;
	}
}
