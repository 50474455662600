import { AfterViewChecked, Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { FeatureService } from '@app/providers/feature.service';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { storageKeys } from '@app/app.constants';
import { AuthService } from '@app/providers/auth.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {
	scrollToTopButtonIsVisible: boolean = false;
	private subscription: any;

	constructor(public translate: TranslateService,
		public localStorageService: LocalStorageService,
		private featureService: FeatureService,
		private ngbModalConfig: NgbModalConfig,
		private authService: AuthService) {
		this.initializeProtoTypes();
		this.subscription = this.featureService.featureFlagChangesSubject.subscribe(() => {
			if (localStorageService.get(storageKeys.preferredLanguage)) {
				translate.use(localStorageService.get(storageKeys.preferredLanguage));
			}
			else {
				translate.use((translate.getBrowserLang()));
			}
		});

		// NOTE: this is how they showed how to do per documentation https://ng-bootstrap.github.io/#/guides/animations. might be a better way to do it. refactor when known.
		// global ng-bootstrap modal config
		this.ngbModalConfig.backdrop = 'static';
	}

	initializeProtoTypes() {
		String.isNullOrEmpty = function (val: any): boolean {
			return (val === undefined || val === null || val.trim() === '');
		};
		String.isNullOrEmptyGuid = function (val: any): boolean {
			return (val === undefined || val === null || val.trim() === '') || val === '00000000-0000-0000-0000-000000000000';
		};
		Date.prototype.toUTC = function (): Date {
			return new Date(this.toUTCString().replace(' GMT', ''));
		};
		Date.prototype.addSeconds = function (seconds: number) {
			this.setSeconds(this.getSeconds() + seconds);
			return this;
		};
		Date.prototype.addMinutes = function (minutes: number) {
			this.setMinutes(this.getMinutes() + minutes);
			return this;
		};
		Date.prototype.addHours = function (hours: number) {
			this.setHours(this.getHours() + hours);
			return this;
		};
		Date.prototype.addDays = function (days: number) {
			this.setDate(this.getDate() + days);
			return this;
		};
		Date.prototype.addMonths = function (months: number) {
			this.setMonth(this.getMonth() + months);
			return this;
		};
		Date.prototype.addYears = function (years: number) {
			this.setFullYear(this.getFullYear() + years);
			return this;
		};
	}

	ngOnInit() {
	}

	ngAfterViewChecked() {
	}

	scrollToTop(): void {
		window.scrollTo(0, 0);
	}

	support() {
		this.authService.support();
	}

	@HostListener('window:scroll', ['$event'])
	checkScroll() {
		const headerHeight: number = 114;
		const scrollPosition: number = window.pageYOffset;

		// show/hide scrollToTopButton
		this.scrollToTopButtonIsVisible = scrollPosition > headerHeight;
	}
}
