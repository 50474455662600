import { Injectable } from '@angular/core';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { environment } from '@env/environment';
import { LocalStorageService } from '@app/providers/local-storage.service';
import {
	entityTypeIds,
	guid,
	launchDarklyFeatureFlags,
	roleBundleTypeNames,
	storageKeys,
	verticalTypeNames
} from '@app/app.constants';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { LoggerService } from '@app/providers/logger.service';
import { EntityService } from '@app/providers/entity.service';
import { EntitySetting } from '@app/shared/entity-setting.model';
import { EndpointService } from '@app/providers/endpoint.service';

@Injectable({
	providedIn: 'root'
})
export class FeatureService {
	public client: LDClient.LDClient;
	public launchDarklyFeatureFlags = launchDarklyFeatureFlags;
	// features observables
	public featureFlagChangesSubject: Subject<any> = new Subject<any>();
	// uses feature "key" for object keys - e.g. new-theme. it looks like this {'new-theme': true, 'new-header': false}.
	public featureKeysIsOn: any = {};

	// the "status" of each feature
	// system default values
	public defaultFeatureKeysIsOn: any = {};
	private user: LDClient.LDUser;
	private customAttributes: any;

	constructor(private localStorage: LocalStorageService,
		private loggerService: LoggerService,
		private entityService: EntityService,
		private endpointService: EndpointService) {
		this.setUserInformation();
		this.initializeFeatureService();
	}

	initializeFeatureService() {
		// get the default setting from system/appsettings/portalsettings 1st. and then initialize launchdarkly
		this.getSystemDefault();
	}

	// check to see if a feature is turned on or not - returns boolean
	// using the "main object" that was already checked once at initialization
	featureFlagIsOn(key: string): boolean {
		return (this.client) ? this.client.variation(key, this.defaultFeatureKeysIsOn[key]) : false;
	}

	getSystemDefault() {
		const features = Object.keys(this.launchDarklyFeatureFlags);

		// get the default setting from system/appsettings/portalsettings 1st
		this.entityService.getFeatureFlagDefaultSettings(this.customAttributes)
			.then((res: Array<EntitySetting>) => {
				features.forEach((feature: string) => {
					const flag: any = this.launchDarklyFeatureFlags[feature];
					const setting: EntitySetting = res.find((setting: any) => setting.settingTypeId === flag.entitySettingTypeId);
					this.defaultFeatureKeysIsOn[flag.key] = setting ? setting.value.toLowerCase() === 'true' : false;
				});
			})
			.catch((err: any) => {
				// error getting default values - default all to false;
				features.forEach((feature: string) => {
					const flag: any = this.launchDarklyFeatureFlags[feature];
					this.defaultFeatureKeysIsOn[flag.key] = false;
				});
			})
			.then(() => {
				// after done getting the system default, then initialize launchdarkly
				this.initializeLaunchDarkly().then((_) => {
					this.endpointService.init();
				});

				// trigger a change
				this.featureFlagChangesSubject.next();
			});
	}

	initializeLaunchDarkly() {
		return new Promise((resolve) => {
			const features = Object.keys(this.launchDarklyFeatureFlags);
			this.client = LDClient.initialize(environment.launchDarklyClientSideId, this.user);
			this.client.flush();

			this.client.waitForInitialization().then(() => {
				// don't need to do anything
			}).catch((err: any) => {
				// logs the initialization error IFF logged in
				if (this.localStorage.get(storageKeys.userId)) {
					this.loggerService.logError('LaunchDarkly initialization error - portal.', {
						launchDarklyClientSideId: environment.launchDarklyClientSideId,
						userId: this.localStorage.get(storageKeys.userId) || '',
						userName: this.localStorage.get(storageKeys.userName) || '',
						rootPortalId: this.localStorage.get(storageKeys.rootPortalId) || '',
						RootPortalName: this.localStorage.get(storageKeys.rootPortalName) || '',
						dateTimeRootPortalCreated: moment(this.localStorage.get(storageKeys.dateTimeRootPortalCreated)).toDate() || '',
						hasSubPortals: this.localStorage.get(storageKeys.hasSubPortals) || '',
						allowMobileAccess: this.localStorage.get(storageKeys.allowMobileAccess) || '',
						verticalTypeId: this.localStorage.get(storageKeys.verticalTypeId) || '',
						error: JSON.stringify(err)
					});
				}
			}).then((_) => {
				resolve(null);
			});

			// on LaunchDarkly change
			this.client.on('change', (settings: any) => {
				const featureKeys = Object.keys(settings);
				let reload: boolean = false;

				// check if a change that was made is even a recorded/valid/existing feature flag && the status has changed/updated
				// must use .variation() to check the status. the status returned from the client on change is not the correct status for "THIS" user
				featureKeys.forEach((key: string) => {
					const currentStatus = this.featureFlagIsOn(key);

					if (this.launchDarklyFeatureFlags[_.camelCase(key)] && this.featureKeysIsOn[key] !== currentStatus) {
						// the feature exists in app.constants && the status changed/updated

						// update the status
						this.featureKeysIsOn[key] = currentStatus;

						// set the reload to true so it can be reloaded
						reload = true;
					}
				});

				// if existing feature flag, reload the page
				if (reload) {
					location.reload();
				}
			});
		});
	}

	logOutUser() {
		this.setAnonymousUserInformation();
		this.initializeFeatureService();
	}

	setAnonymousUserInformation() {
		this.user = { key: guid.empty, anonymous: true };
	}

	setUserInformation(user?: any) {
		if (user == null) {
			const userName = this.localStorage.get(storageKeys.userName);
			if (userName) {
				user = {
					userId: this.localStorage.get(storageKeys.userId),
					userName: this.localStorage.get(storageKeys.userName),
					userRights: this.localStorage.get(storageKeys.userRights),
					rootPortalId: this.localStorage.get(storageKeys.rootPortalId),
					RootPortalName: this.localStorage.get(storageKeys.rootPortalName),
					dateTimeRootPortalCreated: moment(this.localStorage.get(storageKeys.dateTimeRootPortalCreated)).toDate(),
					hasSubPortals: this.localStorage.get(storageKeys.hasSubPortals),
					allowMobileAccess: this.localStorage.get(storageKeys.allowMobileAccess),
					verticalTypeId: this.localStorage.get(storageKeys.verticalTypeId)
				};
				this.customAttributes = user;
			}
			else {
				this.customAttributes = null;
				this.setAnonymousUserInformation();
				return;
			}
		}

		const locations = user.userRights.entities.filter((entity: any) => entity.entityTypeId === entityTypeIds.location);
		const primaryLocation = locations.find((l: any) => l.isPrimaryRelationship === true);
		this.customAttributes = {
			portalId: user.rootPortalId,
			portalName: user.RootPortalName,
			portalCreatedDate: user.dateTimeRootPortalCreated,
			vertical: verticalTypeNames.find((r: any) => r.id === user.verticalTypeId).name,
			hasSubPortals: user.hasSubPortals,
			allowMobileAccess: user.allowMobileAccess,
			primaryLocationId: primaryLocation.entityId,
			primaryLocationName: primaryLocation.entityName,
			roleId: user.userRights.roleBundles[0].roleBundleId,
			roleName: user.userRights.roleBundles[0].roleBundleName,
			roleTypeId: user.userRights.roleBundles[0].roleBundleTypeId,
			roleTypeName: roleBundleTypeNames.find((r: any) => r.id === user.userRights.roleBundles[0].roleBundleTypeId).name,
			permissions: user.userRights.roles.map((r: any) => r.roleName),
			locations: locations.map((e: any) => e.entityName),
			departments: user.userRights.entities.filter((entity: any) => entity.entityTypeId === entityTypeIds.department)
				.map((e: any) => e.entityName),
			groups: user.userRights.entities.filter((entity: any) => entity.entityTypeId === entityTypeIds.group)
				.map((e: any) => e.entityName),
			tenants: user.userRights.entities.filter((entity: any) => entity.entityTypeId === entityTypeIds.tenant)
				.map((e: any) => e.entityName)
		};
		// NOTE: custom is used on launchdarkly for target custom rules
		this.user = {
			key: user.userId,
			email: user.userName,
			custom: this.customAttributes
		};
	}
}
