import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { AuthService } from '@app/providers/auth.service';
import { Router } from '@angular/router';
import { FeatureService } from '@app/providers/feature.service';
import { storageKeys } from '@app/app.constants';

@Component({
	selector: 'app-logout',
	template: ''
})
export class LogoutComponent implements OnInit {
	constructor(private storage: LocalStorageService,
		private authService: AuthService,
		private router: Router,
		private featureService: FeatureService) {
	}

	ngOnInit() {
		const isSso = this.storage.get('ssoAuthentication');
		let ssoLogoutUrl = null;
		if (isSso) {
			ssoLogoutUrl = this.storage.get('ssoLogoutRedirectUrl');
		}

		if (isSso) {
			// Since going to an external site, we'll make idle: false so it doesn't
			// show on the login page if they come back in the same browser
			this.storage.set(storageKeys.idle, false);
			if (ssoLogoutUrl) {
				// checking by just http which should be a good catch all - *http*s
				if (ssoLogoutUrl.toLowerCase().indexOf('http') === -1) {
					ssoLogoutUrl = 'http://' + ssoLogoutUrl;
				}

				window.open(ssoLogoutUrl, '_self');
			}
		}
		else {
			this.authService.logout();
		}
	}
}
