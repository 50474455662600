import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-idle-timeout-modal',
	templateUrl: './idle.timeout.modal.html',
	styleUrls: ['./idle.timeout.modal.scss'],
	encapsulation: ViewEncapsulation.None
})
export class IdleTimeoutModalComponent {
	@Input() timeRemaining;
	@Input() maxVal;

	constructor(private activeModal: NgbActiveModal) {

	}

	public signOut() {
		console.log('Request to end session');
		this.activeModal.close(false);
	}

	public extendSession() {
		console.log('Request to extend session');
		this.activeModal.close(true);
	}
}
