<div class="loginWrapper p-3">
    <form class="content" id="loginForm" [formGroup]="loginForm">
        <div class="text-center mb-3">
            <img [src]="defaultImageAddress.login.logo"
                 class="img-responsive agility-primary-logo mb-3"
                 alt="Preparis">
        </div>
        <app-form-error-messages [form]="loginForm"
                                 [submitted]="loginFormSubmitted"
                                 [customErrorMessages]="errorMessages">
        </app-form-error-messages>
        <div class="alert alert-warning" role="alert" *ngIf="sessionTimeoutWarning">
            <span>{{'login.msgSessionTimeout' | translate}}</span>
        </div>
        <div class="alert alert-success" role="alert" *ngIf="changePasswordSuccess">
            <span class="fa fa-check-circle" aria-hidden="true"></span>
            <span>{{ 'login.msgChangedPasswordSuccess' | translate }}</span>
        </div>
        <div class="text-center" *ngIf="authenticated || sso">
            <h4 class="text-center"><i class="fa fa-spinner fa-spin me-3"></i>{{ 'login.lblLoggingIn' | translate }}
            </h4>
        </div>
        <div *ngIf="!authenticated && !sso">
            <div class="mb-3">
                <label class="form-label" for="username">{{ 'login.lblLoginId' | translate }}</label>
                <div class="input-group">
                    <div class="input-group-text">
                        <i class="fa fa-envelope-o"></i>
                    </div>
                    <input id="username"
                           name="username"
                           formControlName="username"
                           type="email"
                           class="form-control"
                           placeholder="{{ 'login.phUsername' | translate }}"
                           [(ngModel)]="username"
                           required/>
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label" for="password">{{ 'login.lblPassword' | translate }}</label>
                <div class="input-group">
                    <i *ngIf="password" (click)="showHidePassword()"
                       [ngClass]="passwordVisibility? 'far fa-eye': 'fa fa-eye-slash'" id="togglePassword"></i>
                    <div class="input-group-text"><i class="fa fa-key"></i></div>
                    <input id="password"
                           name="password"
                           formControlName="password"
                           [type]="passwordVisibility? 'text': 'password'"
                           class="form-control"
                           placeholder="{{ 'login.phPassword' | translate }}"
                           [(ngModel)]="password"
                           required/>
                </div>
            </div>
            <div class="forgotPassword text-end">
                <div>
                    <a routerLink="/forgot-password"
                       [queryParams]="{username: this.username}">{{ 'login.btnForgotPassword' | translate }}</a>
                </div>
                <div>
                    <a routerLink="/optin">{{ 'login.btnRegister' | translate }}</a>
                </div>
                <div *ngIf="forgotSubdomains">
                    <a (click)="sendPortalsUrls()">{{ 'login.btnForgotPortalUrl' | translate }}</a>
                </div>
            </div>
            <div class="pt-2 pb-3" *ngIf="captchaRequired">
                <re-captcha #captcha (resolved)="handleResolvedCaptcha($event)"></re-captcha>
            </div>
            <button id="loginButton"
                    type="submit"
                    class="btn btn-primary"
                    tabindex="3"
                    [disabled]="loading || captchaRequired"
                    (click)="onLogin()">
                <span *ngIf="loading">
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                </span>
                <span *ngIf="!loading">{{ 'login.btnLogin' | translate }}</span>
            </button>
        </div>
    </form>
    <div class="support">
        <span>{{'login.lblNeedHelp' | translate}}</span>
        <button (click)="support()" class="btn btn-link">{{ 'login.lblSupport' | translate }}</button>
    </div>
</div>
