import { NgModule } from '@angular/core';
/* Custom pipes */
import { Base64DecodePipe } from '@app/pipes/base64-decode.pipe';
import { DomSanitizedPipe } from '@app/pipes/dom-sanitized.pipe';
import { UrlTrustedPipe } from '@app/pipes/url-trusted.pipe';
import { FromBootstrapDatePipe } from '@app/pipes/from-bootstrap-date.pipe';
import { FileSizePipe } from '@app/pipes/file-size.pipe';
import { FilterRolesPipe } from '@app/pipes/filter-roles.pipe';
import { FilterByNamePipe } from '@app/pipes/filter-by-name';
import { FilterEntitiesPipe } from '@app/pipes/filter-entities.pipe';
import { FilterByAssessmentStatusPipe } from './filter-by-assessment-status.pipe';
import { FilterByTargetTypePipe } from './filter-by-target-type.pipe';

@NgModule({
	declarations: [
		Base64DecodePipe,
		DomSanitizedPipe,
		UrlTrustedPipe,
		FromBootstrapDatePipe,
		FileSizePipe,
		FilterRolesPipe,
		FilterByNamePipe,
		FilterEntitiesPipe,
		FilterByAssessmentStatusPipe,
		FilterByTargetTypePipe
	],
	imports: [],
	exports: [
		Base64DecodePipe,
		DomSanitizedPipe,
		UrlTrustedPipe,
		FromBootstrapDatePipe,
		FileSizePipe,
		FilterRolesPipe,
		FilterByNamePipe,
		FilterEntitiesPipe,
		FilterByAssessmentStatusPipe,
		FilterByTargetTypePipe
	]
})
export class PipesModule {
}
