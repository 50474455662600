<div class="changePasswordWrapper">
    <form class="content" name="changePasswordForm">
        <img [src]="defaultImageAddress.login.logo" class="img-responsive agility-primary-logo" alt="Preparis Logo">
        <h4>{{(isNewUser ? 'login.lblCreatePassword' : 'login.lblChangePassword') | translate}}</h4>
        <app-form-error-messages
            [submitted]="errorMessages && errorMessages.length > 0"
            [customErrorMessages]="errorMessages">
        </app-form-error-messages>
        <app-password-policies
            *ngIf="displayPasswordPolicies"
            [password]="newPassword"
            [loading]="loading"
            [passwordPolicies]="passwordPolicies"
            (passwordPoliciesSatisfied)="onPasswordPoliciesSatisfied($event)"></app-password-policies>
        <div class="alert alert-warning my-3" role="alert" *ngIf="passwordIsExpired">
            {{'login.errors.1025' | translate}}
        </div>
        <div class="mb-3">
            <label class="form-label">{{ 'login.lblNewPassword' | translate }}</label>
            <div class="input-group">
                <div class="input-group-text">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </div>
                <input id="newPassword"
                       name="newPassword"
                       type="password"
                       class="form-control"
                       [(ngModel)]="newPassword"
                       [disabled]="loading"
                       required/>
            </div>
        </div>
        <div class="mb-3 has-feedback has-feedback-left">
            <label class="form-label">{{ 'login.lblConfirmNewPassword' | translate }}</label>
            <div class="input-group">
                <div class="input-group-text">
                    <i class="fa fa-key" aria-hidden="true"></i>
                </div>
                <input id="confirmNewPassword"
                       name="confirmNewPassword"
                       type="password"
                       class="form-control"
                       pp-compare-to="newPassword"
                       [disabled]="loading"
                       [(ngModel)]="confirmNewPassword"
                       required/>
            </div>
        </div>
        <button type="submit"
                id="changePasswordButton"
                class="btn btn-primary btn-primary"
                [disabled]="isChangePasswordDisabled()"
                (click)="validate()">
            <span *ngIf="loading">
                <i class="fa fa-spinner fa-spin"></i>
            </span>
            <span
                *ngIf="!loading">{{ (isNewUser ? 'login.btnCreatePassword' : 'login.btnChangePassword') | translate}}</span>
        </button>
        <button class="btn btn-secondary" (click)="onCancel()">{{ 'common.cancel' | translate }}</button>
    </form>
</div>
