export class MessageFilter {
	incidentId?: string = null;
	messageId?: string = null;
	roleBundleId?: string = null;
	locationId?: string = null;
	departmentId?: string = null;
	groupId?: string = null;
	tenantId?: string = null;

	constructor() {
	}
}
