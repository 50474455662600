import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@app/pipes/pipes.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormErrorMessagesComponent } from '@app/components/form-error-messages/form-error-messages.component';
import { PasswordPoliciesComponent } from '@app/components/login/password-policies/password-policies.component';
import { SpinnerComponent } from '@app/components/widgets/spinner/spinner.component';
import { ApplyPermissionDirective } from '@app/directives/apply-permission/apply-permission.directive';
import { SidebarDirective } from '@app/directives/sidebar/sidebar.directive';
import { NgbActiveModal, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { TableModule } from 'primeng/table';
import { ToastrModule } from 'ngx-toastr';
import { LinkComponent } from '@app/components/table-cell-rendering-helper-components/link/link.component';
import { CommentsComponent } from '@app/components/comments/comments.component';
import { BreadcrumbsComponent } from '@app/components/breadcrumbs/breadcrumbs.component';
import { PortalReportsDashboardComponent } from '@app/components/reports/portal-reports-dashboard/portal-reports-dashboard.component';
import { UserAssignmentsComponent } from '@app/components/iQ/user-assignments/user-assignments.component';
import { MomentModule } from 'ngx-moment';
import { NamedListsPopoverComponent } from '@app/components/agility-alerts/incident-history/named-lists-popover/named-lists-popover.component';
import { LocationMapComponent } from '@app/components/location-map/location-map.component';
import { GoogleMapApiTsComponent } from '@app/components/google-map-api-ts/google-map-api-ts.component';
import { ProfileInfoComponent } from '@app/components/profile/profile-info/profile-info.component';
import { CustomFieldValuesComponent } from '@app/components/custom-field-values/custom-field-values.component';
import { IqNavComponent } from '@app/components/iQ/iq-nav/iq-nav.component';
import { RecipientSummaryBarComponent } from '@app/components/agility-alerts/shared/recipient-summary-bar/recipient-summary-bar.component';
import { EntitySelectionsBarComponent } from '@app/components/agility-alerts/shared/entity-selections-bar/entity-selections-bar.component';
import { FilterNoSelectionsComponent } from '@app/components/agility-alerts/shared/filters/filter-no-selections/filter-no-selections.component';
import { FilterRoleComponent } from '@app/components/agility-alerts/shared/filters/filter-role/filter-role.component';
import { FilterEntityComponent } from '@app/components/agility-alerts/shared/filters/filter-entity/filter-entity.component';
import { FilterGeolocationsComponent } from '@app/components/agility-alerts/shared/filters/filter-geolocations/filter-geolocations.component';
import { FilterManuallyAddComponent } from '@app/components/agility-alerts/shared/filters/filter-manually-add/filter-manually-add.component';
import { RecipientsWhoAreInComponent } from '@app/components/agility-alerts/shared/recipients-who-are-in/recipients-who-are-in.component';
import { ReadMoreComponent } from '@app/components/agility-alerts/shared/read-more/read-more.component';
import { ResourceContentSidebarComponent } from '@app/components/resources/resource-content-sidebar/resource-content-sidebar.component';
import { ManageRolesEntitiesModalComponent } from '@app/components/agility-alerts/shared/manage-roles-entities-modal/manage-roles-entities-modal.component';
import { UploadFileModalComponent } from '@app/components/modals/upload-file-modal/upload-file-modal.component';
import { UploadFileGenericModalComponent } from '@app/components/modals/upload-file-generic-modal/upload-file-generic-modal.component';
import { ScopeTeamModalComponent } from '@app/components/iQ/scope-team-modal/scope-team-modal.component';
import { SaveTableStateDirective } from '@app/directives/save-table-state/save-table-state.directive';
import { ErrorNotificationComponent } from '@app/components/error-notification/error-notification.component';
import { SplashMessageDialogComponent } from '@app/components/modals/splash-message-dialog/splash-message-dialog.component';
import { SelectDocumentModalComponent } from '@app/components/modals/select-document-modal/select-document-modal.component';
import { SelectDocumentGenericModalComponent } from '@app/components/modals/select-document-generic-modal/select-document-generic-modal.component';
import { GeolocationMapComponent } from '@app/components/agility-alerts/shared/geolocation-map/geolocation-map.component';
import { ApplyFeatureFlagDirective } from '@app/directives/apply-feature-flag/apply-feature-flag.directive';
import { ApplyFeatureFlagClassDirective } from '@app/directives/apply-feature-flag-class/apply-feature-flag-class.directive';
import { WeatherWidgetComponent } from '@app/components/widgets/weather-widget/weather-widget.component';
import { SendMessageContactMethodsComponent } from '@app/components/agility-alerts/send-message/send-message-contact-methods/send-message-contact-methods.component';
import { RowCountMessageComponent } from '@app/components/iQ/row-count-message/row-count-message.component';
import { SelectOnFocusDirective } from '@app/directives/select-on-focus/select-on-focus.directive';
import { AutofocusDirective } from '@app/directives/auto-focus/auto-focus.directive';
import { FooterComponent } from '@app/components/layout/footer/footer.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { HeaderComponent } from '@app/components/layout/header/header.component';
import { HeaderDesktopComponent } from '@app/components/layout/header/responsive/header-desktop/header-desktop.component';
import { HeaderMobileComponent } from '@app/components/layout/header/responsive/header-mobile/header-mobile.component';
import { HeaderDropdownIncidentsComponent } from '@app/components/layout/header/dropdowns/header-dropdown-incidents/header-dropdown-incidents.component';
import { HeaderDropdownIqComponent } from '@app/components/layout/header/dropdowns/header-dropdown-iq/header-dropdown-iq.component';
import { HeaderDropdownResourcesComponent } from '@app/components/layout/header/dropdowns/header-dropdown-resources/header-dropdown-resources.component';
import { HeaderDropdownManageComponent } from '@app/components/layout/header/dropdowns/header-dropdown-manage/header-dropdown-manage.component';
import { HeaderDropdownPortalsComponent } from '@app/components/layout/header/dropdowns/header-dropdown-portals/header-dropdown-portals.component';
import { HeaderDropdownPlanningComponent } from '@app/components/layout/header/dropdowns/header-dropdown-planning/header-dropdown-planning.component';
import { HeaderDropdownRiskComponent } from '@app/components/layout/header/dropdowns/header-dropdown-risk/header-dropdown-risk.component';
import { LocalStorageService } from '@app/providers/local-storage.service';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localePt from '@angular/common/locales/pt';
import { CustomDatePickerI18n } from '@app/providers/ngb-datepicker-locale';
import { FeatureService } from '@app/providers/feature.service';
import { BcNoDelegatesDirective } from '@app/directives/bc-no-delegates/bc-no-delegates.directive';
import { BcSelectUserModalComponent } from '@app/components/business-continuity/modals/bc-select-user/bc-select-user-modal.component';
import { IncidentManagementAssigneesComponent } from '@app/components/incident-management/incident-management-incident-details/incident-management-assignees/incident-management-assignees.component';
import { TabViewModule } from 'primeng/tabview';
import { PickListModule } from 'primeng/picklist';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ChartModule } from 'primeng/chart';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DialogService, DynamicDialogConfig, DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { OrderListModule } from 'primeng/orderlist';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { CalendarModule as PrimeNgCalendarModule } from 'primeng/calendar';
import { TreeModule } from 'primeng/tree';
import { RippleModule } from 'primeng/ripple';
import { TimelineModule } from 'primeng/timeline';
import { TooltipModule } from 'primeng/tooltip';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ResponseWatchersComponent } from '@app/components/agility-alerts/shared/response-watchers/response-watchers.component';
import { EditCustomFieldModalComponent } from '@app/components/modals/edit-custom-field-modal/edit-custom-field-modal.component';
import { CustomFieldsComponent } from '@app/components/custom-fields/custom-fields.component';
import { SelectEntityLocationsModalComponent } from '@app/components/modals/select-entity-locations-modal/select-entity-locations-modal.component';
import { HeaderDropdownExerciseComponent } from '@app/components/layout/header/dropdowns/header-dropdown-exercise/header-dropdown-exercise.component';
import { DocumentAttachmentsComponent } from '@app/components/document-attachments/document-attachments.component';
import { BadgeModule } from 'primeng/badge';
import { RangeDateComponent } from '@app/components/range-date/range-date.component';
import { PanelModule } from 'primeng/panel';
import { KeyFilterModule } from 'primeng/keyfilter';
import { SendMessageFiltersComponent } from '@app/components/agility-alerts/send-message/send-message-filters/send-message-filters.component';
import { MenuModule } from 'primeng/menu';
import { QRCodeModule } from 'angularx-qrcode';
import { HeaderDropdownSamplePagesComponent } from '@app/components/layout/header/dropdowns/header-dropdown-sample-pages/header-dropdown-sample-pages.component';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService, TreeDragDropService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageModule } from 'primeng/message';
import { ModalTextEditorComponent } from '@app/components/exercise-manager/scenario-manager/modal-text-editor/modal-text-editor.component';
import { CardModule } from 'primeng/card';
import { StepsModule } from 'primeng/steps';
import { SelectPortalFunctionalAreaForDepartmentModalComponent } from '@app/components/modals/select-portal-functional-area-for-department-modal/select-portal-functional-area-for-department-modal.component';
import { SkeletonModule } from 'primeng/skeleton';
import { MessagesModule } from 'primeng/messages';
import { ChipModule } from 'primeng/chip';
import { AvatarModule } from 'primeng/avatar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarWidgetComponent } from '@app/components/widgets/sidebar-widget/sidebar-widget.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BaseCardComponent } from '@app/components/widgets/base-card/base-card.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { BcSelectPlanTypeModalComponent } from '@app/components/home/modals/bc-select-plan-type-modal/bc-select-plan-type-modal.component';
import { ListboxModule } from 'primeng/listbox';
import { EmbeddedItemsListComponent } from '@app/components/embedded-items-list/embedded-items-list.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { GoogleMapsModule } from '@angular/google-maps';
import { DividerModule } from 'primeng/divider';
import { TabMenuModule } from 'primeng/tabmenu';
import { ConfirmationModalWithCodeComponent } from '@app/components/modals/confirmation-modal-with-code/confirmation-modal-with-code.component';
import { BaseTableComponent } from '@app/components/widgets/base-table/base-table.component';

@NgModule({
	// imports makes the exported declarations of other modules available in the current module
	imports: [
		TranslateModule.forChild(
			{
				isolate: false
			}
		),
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory
		}),
		AccordionModule,
		AutoCompleteModule,
		ChartModule,
		CheckboxModule,
		CommonModule,
		DialogModule,
		DragDropModule,
		DropdownModule,
		DynamicDialogModule,
		FileUploadModule,
		FormsModule,
		InputSwitchModule,
		InputTextModule,
		MomentModule,
		MultiSelectModule,
		NgIdleKeepaliveModule.forRoot(),
		NgbModule,
		OrderListModule,
		OverlayPanelModule,
		PanelMenuModule,
		PickListModule,
		PipesModule,
		PrimeNgCalendarModule,
		TreeModule,
		ReactiveFormsModule,
		SelectButtonModule,
		SliderModule,
		TabViewModule,
		TabMenuModule,
		TableModule,
		ToastrModule.forRoot(),
		RippleModule,
		TimelineModule,
		TooltipModule,
		ColorPickerModule,
		BadgeModule,
		PanelModule,
		KeyFilterModule,
		MenuModule,
		QRCodeModule,
		ConfirmPopupModule,
		ConfirmDialogModule,
		CardModule,
		StepsModule,
		SkeletonModule,
		ChipModule,
		AvatarModule,
		ProgressSpinnerModule,
		InputTextareaModule,
		EditorModule,
		ListboxModule,
		GoogleMapsModule,
		DividerModule,
		TreeModule
	],
	// declarations are to make directives (including components and pipes) from the current module available to other directives in the current module. Selectors of directives, components or pipes are only matched against the HTML if they are declared or imported.
	declarations: [
		ApplyFeatureFlagClassDirective,
		ApplyFeatureFlagDirective,
		ApplyPermissionDirective,
		AutofocusDirective,
		BcNoDelegatesDirective,
		BcSelectUserModalComponent,
		BreadcrumbsComponent,
		CommentsComponent,
		CustomFieldValuesComponent,
		CustomFieldsComponent,
		EditCustomFieldModalComponent,
		EntitySelectionsBarComponent,
		ErrorNotificationComponent,
		FilterGeolocationsComponent,
		SendMessageFiltersComponent,
		FilterEntityComponent,
		FilterManuallyAddComponent,
		FilterNoSelectionsComponent,
		FilterRoleComponent,
		FooterComponent,
		FormErrorMessagesComponent,
		GeolocationMapComponent,
		GoogleMapApiTsComponent,
		HeaderComponent,
		HeaderDesktopComponent,
		HeaderDropdownExerciseComponent,
		HeaderDropdownIncidentsComponent,
		HeaderDropdownIqComponent,
		HeaderDropdownManageComponent,
		HeaderDropdownPlanningComponent,
		HeaderDropdownPortalsComponent,
		HeaderDropdownResourcesComponent,
		HeaderMobileComponent,
		HeaderDropdownRiskComponent,
		IncidentManagementAssigneesComponent,
		IqNavComponent,
		LinkComponent,
		LocationMapComponent,
		ManageRolesEntitiesModalComponent,
		NamedListsPopoverComponent,
		PasswordPoliciesComponent,
		PortalReportsDashboardComponent,
		ProfileInfoComponent,
		ReadMoreComponent,
		RecipientSummaryBarComponent,
		RecipientsWhoAreInComponent,
		ResourceContentSidebarComponent,
		ResponseWatchersComponent,
		RowCountMessageComponent,
		SaveTableStateDirective,
		ScopeTeamModalComponent,
		SelectDocumentGenericModalComponent,
		SelectDocumentModalComponent,
		SelectEntityLocationsModalComponent,
		SelectOnFocusDirective,
		SendMessageContactMethodsComponent,
		SidebarDirective,
		SpinnerComponent,
		SplashMessageDialogComponent,
		UploadFileGenericModalComponent,
		UploadFileModalComponent,
		UserAssignmentsComponent,
		WeatherWidgetComponent,
		DocumentAttachmentsComponent,
		RangeDateComponent,
		HeaderDropdownSamplePagesComponent,
		ModalTextEditorComponent,
		SelectPortalFunctionalAreaForDepartmentModalComponent,
		SidebarWidgetComponent,
		BaseCardComponent,
		BcSelectPlanTypeModalComponent,
		EmbeddedItemsListComponent,
		ConfirmationModalWithCodeComponent,
		BaseTableComponent
	],
	// exports makes the components, directives, and pipes available in modules that add this module to imports. exports can also be used to re-export modules such as CommonModule and FormsModule, which is often done in shared modules.
	exports: [
		AccordionModule,
		ApplyFeatureFlagClassDirective,
		ApplyFeatureFlagDirective,
		ApplyPermissionDirective,
		AutoCompleteModule,
		AutoCompleteModule,
		AutofocusDirective,
		BcNoDelegatesDirective,
		BreadcrumbsComponent,
		CalendarModule,
		ChartModule,
		CheckboxModule,
		CheckboxModule,
		ColorPickerModule,
		CommentsComponent,
		CommonModule,
		CustomFieldValuesComponent,
		CustomFieldsComponent,
		DialogModule,
		DragDropModule,
		DropdownModule,
		DynamicDialogModule,
		EditCustomFieldModalComponent,
		EntitySelectionsBarComponent,
		ErrorNotificationComponent,
		FileUploadModule,
		FilterGeolocationsComponent,
		SendMessageFiltersComponent,
		FilterEntityComponent,
		FilterManuallyAddComponent,
		FilterNoSelectionsComponent,
		FilterNoSelectionsComponent,
		FilterRoleComponent,
		FilterRoleComponent,
		FooterComponent,
		FormErrorMessagesComponent,
		FormsModule,
		GeolocationMapComponent,
		GoogleMapApiTsComponent,
		HeaderComponent,
		HeaderDesktopComponent,
		HeaderDropdownExerciseComponent,
		HeaderDropdownIncidentsComponent,
		HeaderDropdownIqComponent,
		HeaderDropdownManageComponent,
		HeaderDropdownPlanningComponent,
		HeaderDropdownPortalsComponent,
		HeaderDropdownResourcesComponent,
		HeaderMobileComponent,
		HeaderDropdownRiskComponent,
		IncidentManagementAssigneesComponent,
		InputSwitchModule,
		InputTextModule,
		IqNavComponent,
		LinkComponent,
		LocationMapComponent,
		ManageRolesEntitiesModalComponent,
		MomentModule,
		MultiSelectModule,
		NamedListsPopoverComponent,
		NgIdleKeepaliveModule,
		NgbModule,
		OrderListModule,
		OverlayPanelModule,
		PanelMenuModule,
		PasswordPoliciesComponent,
		PickListModule,
		PipesModule,
		PortalReportsDashboardComponent,
		PrimeNgCalendarModule,
		TreeModule,
		ProfileInfoComponent,
		ReactiveFormsModule,
		ReadMoreComponent,
		RecipientSummaryBarComponent,
		RecipientsWhoAreInComponent,
		ResourceContentSidebarComponent,
		ResponseWatchersComponent,
		RowCountMessageComponent,
		SaveTableStateDirective,
		SelectButtonModule,
		SelectDocumentGenericModalComponent,
		SelectDocumentModalComponent,
		SelectEntityLocationsModalComponent,
		SelectOnFocusDirective,
		SendMessageContactMethodsComponent,
		SidebarDirective,
		SliderModule,
		SpinnerComponent,
		SplashMessageDialogComponent,
		TabViewModule,
		TabMenuModule,
		TableModule,
		TimelineModule,
		ToastrModule,
		ToggleButtonModule,
		TooltipModule,
		TranslateModule,
		UploadFileGenericModalComponent,
		UploadFileModalComponent,
		UserAssignmentsComponent,
		WeatherWidgetComponent,
		DocumentAttachmentsComponent,
		BadgeModule,
		RangeDateComponent,
		PanelModule,
		KeyFilterModule,
		MenuModule,
		QRCodeModule,
		MessageModule,
		CardModule,
		StepsModule,
		ConfirmDialogModule,
		SkeletonModule,
		MessagesModule,
		ChipModule,
		SidebarWidgetComponent,
		AvatarModule,
		InputTextareaModule,
		BaseCardComponent,
		TabViewModule,
		EditorModule,
		ListboxModule,
		EmbeddedItemsListComponent,
		GoogleMapsModule,
		DividerModule,
		TreeModule,
		BaseTableComponent
	],
	providers: [
		{ provide: NgbDatepickerI18n, useClass: CustomDatePickerI18n },
		DialogService, // a primeng service, used by DynamicDialogModule - not a custom made one
		NgbActiveModal,
		ConfirmationService,
		DynamicDialogConfig,
		TreeDragDropService,
		DatePipe
	]
})
export class SharedModule {
	constructor(public translate: TranslateService, public localStorageService: LocalStorageService, private featureService: FeatureService) {
		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang('en');
		registerLocaleData(localeEn);
		registerLocaleData(localeEs);
		registerLocaleData(localeFr);
		registerLocaleData(localePt);
	}
}
