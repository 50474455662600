import { Routes } from '@angular/router';
import { LoginComponent } from '@app/components/login/login/login.component';
import { LogoutComponent } from '@app/components/login/logout/logout.component';
import { ForgotPasswordComponent } from '@app/components/login/forgot-password/forgot-password.component';
import { SsoComponent } from '@app/components/login/sso/sso.component';
import { SsoResponseComponent } from '@app/components/login/sso/sso-response.component';
import { ChangePasswordComponent } from '@app/components/login/change-password/change-password.component';
import { RouteGuard } from '@app/providers/route-guard/route-guard.service';

export const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule)
	},
	{
		path: 'optin',
		loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule)
	},
	{
		path: 'business-continuity',
		loadChildren: () => import('./modules/business-continuity/business-continuity.module').then(m => m.BusinessContinuityModule)
	},
	{
		path: 'business-continuity/planner',
		loadChildren: () => import('./modules/business-continuity/planner/business-continuity-planner.module').then(m => m.BusinessContinuityPlannerModule)
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'logout',
		component: LogoutComponent
	},
	{
		path: 'forgot-password',
		component: ForgotPasswordComponent
	},
	{
		path: 'change-password',
		component: ChangePasswordComponent
	},
	{
		path: 'sso',
		component: SsoComponent
	},
	{
		path: 'sso/:id',
		component: SsoComponent
	},
	{
		path: 'ssoresponse',
		component: SsoResponseComponent
	},
	{
		path: '**',
		redirectTo: '/home'
	}
];
