export class ValidateRecaptchaRequest {
	recaptchaToken: string;
}

export class RecaptchaVerificationResponse {
	Success: boolean;
	challenge_ts: Date;
	hostname: string;
	'error-codes': string[];
}
