export class SsoSettings {
	useSso: boolean = false;
	ssoSettingId: string;
	portalId: string;
	idpUrl: string;
	idpMetadataUrl: string;
	idpCustomerRedirectUrl: string;
	allowNonSSOLogin: boolean = true;
	dateTimeCreated: string;
	createdById: string;
	dateTimeLastModified: string;
	lastModifiedById: string;
	idpMetadataXml: string;
	idpMetadataXmlAsBase64: string = '';
	isIdpMetadataUrlPublic: boolean = true;
}
