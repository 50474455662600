import { version } from '../../version';
import { environment } from '@env/environment';

export const env = environment;
export const appVersion = version;

export const environments = {
	LOCAL: 'local',
	DEV: 'dev',
	QA: 'qa',
	PROD: 'Prod'
};

export const verticalTypeIds = {
	standard: '3089683e-a1c9-4c91-9b87-1e3888a9cfcf',
	realEstate: 'f1d39814-6de9-481b-9abe-d655d396a64d'
};

export const verticalTypeNames = [
	{ id: verticalTypeIds.standard, name: 'Standard' },
	{ id: verticalTypeIds.realEstate, name: 'Real Estate' }
];

export const idleTimeoutSettings = {
	idlePeriodInSeconds: 1800,
	idleTimeoutInSeconds: 30,
	keepAliveInterval: 600,
	interruptEvents: 'keydown mousedown touchstart'
};

export const entityTypeIds = {
	location: '2067409a-be15-492c-8954-9d76e8d8a140',
	department: 'dfee690c-4176-48b0-852f-8a6860459345',
	group: 'c0df4767-bf7d-4df5-94bc-f2bf2e355e8a',
	portal: 'acb29efc-b113-47b0-808d-c3d366d873cd',
	myPreparis: 'e0b2c4b0-fd39-4404-92d5-ad68d9401cd2',
	account: '2791ea32-3bc5-4e71-9e9f-17242b4d04cc',
	tenant: '44ecdcea-70ec-4950-bce2-94647131e90d'
};

export const teamAssignmentTypesIds = {
	alternate: '806feeb4-6ac0-4fb9-aafb-14d7e705db5a',
	manager: '97642ad9-0c21-42bf-880c-42a8cecd96ed',
	teamMember: '72e8cc9c-9a03-4441-9566-a37429cbf0ce',
	supervisor: '239d87ee-7bb6-4c51-b5b3-caa769c173c4'
};

export const serviceErrorCodes = {
	cloudmersiveAPI: 6,
	lockedOut: 1010,
	invalidOrExpiredToken: 1006,
	passwordPolicyFailureCode: 1013,
	userNameExist: 1014,
	userNotFound: 1015,
	mfaUserDoesNotHavePrimaryPhone: 1017,
	authenticationPinInvalid: 1019,
	ssoOnlyLogin: 1022,
	mustLoginUsingPortalSubdomain: 1023,
	portalUrlOrSso: 1024,
	passwordExpiration: 1025,
	passwordReuse: 1026,
	maxLoginAttemptsCaptchaRequired: 1027,
	cannotDeleteScope: 8000,
	cannotDeleteTeam: 8001,
	scopeWithSameName: 8002,
	teamWithSameName: 8003,
	issueWithSameName: 8004,
	accountableTeamMember: 8005,
	externalResourceTypeWithSameName: 8006,
	complianceCategoryWithSameName: 8010,
	storageLocationWithSameName: 8011,
	itemWithSameName: 8012,
	assetWithSameName: 8013,
	notFound: 8014,
	externalResourceAssociatedToAsset: 8015,
	cannotRemoveRoleBundleRoles: 8018,
	cannotUpdateRoleBundleRoles: 8019,
	teamMemberWithAssignments: 8020,
	externalResourceTypeAssignedToResource: 8021,
	complianceCategoryWithDependents: 8022,
	compareDocumentsError: 8023,
	compareDocumentsInvalidType: 8024,
	notificationSettingWithDuplicatePrimaryKey: 8025,
	storageLocationInUse: 8026,
	invalidFirstTeamMember: 8030,
	cannotMassAssignIqTasksToFromUnknownUser: 8031,
	cannotMassAssignIqTasksToSameUndleryingUser: 8032,
	noIqTasksFoundToMassAssign: 8033,
	alertSubscriptionNotFound: 9000,
	alertSubscriptionNotDeleted: 9001,
	alertSubscriptionNotSaved: 9002,
	alertSubscriptionWithSameName: 9003,
	messagingIncidentTypeWithGroupDependencies: 4000,
	messagingIncidentTypeWithChannelDependencies: 4001,
	messagingIncidentTypeWithPublicAlertDependencies: 4002,
	messagingIncidentTypeCannotRemoveSystemDefault: 4003,
	customFieldWithSameName: 7008,
	customFieldOptionWithSameName: 7009,
	duplicateRoleName: 7002,
	duplicateSubdomain: 7007,
	duplicateRecoveryProfileName: 50000,
	assetMoveDuplicateName: 58013,
	issueMoveDuplicateName: 58004,
	itemMoveDuplicateName: 58012,
	externalResourceDuplicateName: 58007,
	bcFunctionalAreaDuplicateName: 60000,
	bcProcessDuplicateName: 60001,
	bcFunctionalAreaPermissionError: 60002,
	bcProcessPermissionError: 60003,
	bcPlanPermissionError: 60004,
	bcEquipmentTypeDuplicateName: 60005,
	bcDuplicateIndustryName: 60006,
	bcVendorDeleteInUse: 60009,
	bcAgencyDeleteInUse: 60040,
	bcDuplicateAgencyName: 60041,
	bcTechnologyDeleteInUse: 60010,
	bcEquipmentDeleteInUse: 60011,
	bcDuplicateVendorName: 60012,
	bcDuplicateTechnologyName: 60013,
	bcExceedMaxPortalProcessLocation: 60022,
	modifySystemExerciseScenarioNotAllowed: 60023,
	exerciseScenarioWithSameName: 60024,
	exerciseTypeInUse: 60025,
	exerciseTypeWithSameName: 60026,
	threadHazardInUse: 60027,
	threadHazardWithSameName: 60028,
	modifyPortalExerciseScenarioNotAllowed: 60029,
	portalExerciseAssociateToIncident: 60030,
	invalidBCPlanTemplate: 60032,
	cannotValidateBCPlanTemplate: 60034
};

export const documentStateIds = {
	readWrite: 'a615996c-640f-47d1-948a-4a3cb1bc7766',
	softDeleted: 'ffe3f90f-2bbd-4e16-a365-b1e211aff837',
	readOnly: 'a0a15cdc-ff13-4ac0-8040-b5bde41038cb'
};

export const documentAccessTypeIds = {
	view: '8650b146-a527-46a3-88ce-2e08d192b9e9',
	edit: 'a23ed95f-1371-4467-bc5c-bc756a02b6d3'
};

export const folderAccessTypeIds = {
	view: '0351ed1e-64df-48c2-9e87-fc19a1315744',
	edit: '8df9f4e8-9ecd-44af-bee1-b5ba454fe19e'
};

export const complianceScheduleFrequencySettings = {
	weekly: 7,
	monthly: 30,
	monthlyLastDay: 31,
	quarterly: 90,
	quarterlyLastDay: 91,
	semiannually: 180,
	semiannuallyLastDay: 181,
	annually: 365,
	na: 365000
};

export const iqItemTypes = {
	Document: '53e27db8-f119-4b73-9e84-a8b598fbd415',
	StorageItem: '92c579b0-36cf-46d2-b006-77d2f962c0ce',
	Task: '2b727081-d9b1-4f92-b8b4-4e275fe9a396',
	Other: '08b4edfc-1f95-425e-a24b-016ba5cad509'
};

export const iqPriorities = {
	critical: '086deec5-b113-4cc9-8024-d1f1ddb62e8d',
	high: '0b75aa95-3100-43b0-9cf7-560fb4607a47',
	medium: '31b51337-4a66-4cce-9c54-0daa8c6831c9',
	low: 'b7e4e27d-b0de-4936-858a-3fa07e0f3b68'
};

export const issueStatusTypeIds = {
	active: '375a0779-9797-40cf-bf21-22c5dbab5cd3',
	deleted: 'ef521e8a-7775-4f8b-833e-c09e164d8978',
	archived: 'fe1d12bd-3806-4416-b895-4e662294793d'
};

export const roleTypes = {
	administrators: '0fb2b650-0d1d-4a4f-b81b-1b03d074b51e',
	anonymousUsers: '9266477a-6c7c-4f2e-a46d-6f69bd2b9e07',
	bulkUpload: 'f6a400e1-aca9-444d-9188-394341ae7981',
	clientAdministrator: 'c4ca3253-10ab-423b-9d43-7e06f2cd786b',
	companySettings: '6a6a8d3c-d213-4030-aa43-e2ba0a28a3cb',
	core: '6618a2b0-04a0-4e3a-95f3-02eda8c95b1d',
	developer: '923add42-e5d1-4164-805b-8f7e3407f2ed',
	employees: '0ac501e4-c5f9-4dc7-ab71-43976871d067',
	incidentManagement: '0c671f10-a4e5-4b69-b22c-6f15887736a1',
	includeOnContacts: 'f44527b6-2475-496d-80ab-8f02e7fba4f2',
	login: 'a35ec6f8-b8c9-41f2-aa61-8585cff0da50',
	manageDocuments: '038b5e1a-6f01-4618-9a10-593049db0502',
	manageEntities: '359571be-3a1a-4b6d-b85b-23efa9fc049a',
	manageMessageSettings: 'e29d24c8-fa49-4a5d-8dc0-4f9f63327f53',
	manageSettings: '83e142d4-cbc0-4917-9e71-2232e30cab7f',
	manageUsers: '9fe8118c-a1b3-492c-a1fc-ad6aa83a127e',
	manageRoles: 'c8c7cb88-5e76-4e21-a0dc-d416baeefd02',
	preparisAdministrators: '3939b8d3-da64-4e07-98cc-0c64768501f1',
	sendMessages: '309f4066-4f0b-432e-8806-1887211ebf34',
	configureMessages: 'c77d1ba3-373f-4443-a497-da63d76a7fda',
	messageAdmin: '86cabffa-b078-46c2-98e0-395a419f7fd1',
	conferenceCall: '803cccc2-d10a-479d-a2e2-cbd591629154',
	callInAnnouncement: 'a6ece638-dab6-447d-a26c-5b7820e763b1',
	splashMessage: 'ff0deea0-f77f-4c46-a572-af50cfa9b245',
	userInitiateIncident: 'a4f4a86a-a517-4644-9bfa-b7e3e4ad6983',
	panic: 'f4a8b85d-1e89-486e-9110-513b7aa8473a',
	systemAdministrators: 'dac4c23c-e846-4705-848e-8baa4985ea5c',
	uploadDocuments: '814f5fc5-91ff-498d-afe4-ea54ebd3edc1',
	user: '0700fa7a-60aa-4b01-934c-ed1b8d4729a3',
	viewReports: '61013925-8231-4fc2-a3ea-3a4b81427e9a',
	viewAdminReports: '11e37906-031c-4bcb-943c-345452edf2c3',
	viewIncidentsReports: '706e8a54-7cd5-4997-8a1d-1565f2f7017f',
	viewTrainingReports: 'fe45a0c0-938b-463c-8cda-e0400d02e4b7',
	viewTraining: '96408d25-4615-40c4-bb17-a4516bd435db',
	messageSettings: 'e29d24c8-fa49-4a5d-8dc0-4f9f63327f53',
	navigatePortal: '21cb84d9-1c22-458b-92f5-f86e4ffde112',
	iQAdministration: 'fa5acc75-8f5a-4583-b9ea-e1d20d44fdc2',
	iQManagement: '09d95364-5bfe-48eb-89e0-a7dee5aa91fc',
	iQTeamAccess: '6d344200-8ff5-4c3f-9233-6576b45227d3',
	iQAssignmentAccess: 'f662ccd8-9580-40a9-a411-f31a83daa677',
	viewRecoveryProfile: '885f06cf-2f9b-4288-85e5-abd0a2b45db4',
	editRecoveryProfile: 'aea5644e-1060-458b-9be2-f46801875930',
	editRecoveryProfileContact: '356d5604-486e-4249-8675-bba7167ace80',
	editRecoveryProfileGenerator: '2f2df0fa-e300-4e0d-8c25-e7f5aae1bf01',
	editRecoveryProfileSite: '8f5702e3-c76f-48c5-adc3-749106205450',
	businessContinuityManagement: 'a90d9a09-75cd-44d0-8ece-a04d873520ad',
	businessContinuityAuditor: '90163ff8-3870-4e63-aa08-9caea86fb428',
	managePlans: '826325d4-f288-4235-b01c-1b4b9926a6b9',
	viewPlans: '33404d12-9f4d-44ba-9504-9968ac38bf5c',
	safetyPass: 'db574b9f-9b2a-4c0a-ae08-8e91a48e4ec6',
	viewIncidentManagementReports: '93e427e0-38bb-4f50-b30e-977d8a0486ec',
	manageMessages: 'af81d861-7f8b-4e56-976d-8d4ccf552d81'
};

export const moduleTypes = {
	Reporting: '954b30ba-f558-4bec-ad45-0b4935808295',
	DocumentManagement: '1f9df098-9435-4366-979e-468be5b75e51',
	AgilityAlerts: 'fe19f985-bf60-4a3f-a07f-58ffde743dad',
	Messaging: 'c30e9ca5-1df7-4a52-b245-7d10cdc4e219',
	Manage: '0d1fb0b8-f495-43b4-9f6f-8ec72c54432a',
	AgilityTrainingAndResource: 'a0e6845e-cdbd-4204-be71-9aabaee91ea5',
	MyPreparis: 'ff12a9f8-f454-4c2f-8f5b-9c0ff3301bb2',
	Help: 'a57f29c4-41d5-47f2-8202-a42a88e036ae',
	core: '3efc113e-20a1-4db0-a9df-acd41ae32a29',
	AgilityTraining: '8580e36b-b248-4503-86de-d0dbca89b4a4',
	MyProfile: 'fc924410-7b77-418b-8f54-e50b34a18628',
	Administration: 'b3b2327a-efaa-440f-9bd0-f81e884f1972',
	iQ: '08031ad0-8599-48e2-aaa3-15dd9bffefd7',
	Panic: '1415a4a9-49a0-4884-b62d-43cfe5a8079b',
	LiteMessaging: 'a525e8f1-a926-45b2-8c99-3621fa502cdd',
	RecoveryProfiles: '375e4f01-fa5e-47da-b26c-2eff19d23d14',
	AgilityPlanner: '5b4646af-d662-42af-9cde-e3f7e2c3ecb5',
	IncidentManagement: '6a55f235-61b7-40e5-bfe8-f6821309abab',
	AgilitySafetyPass: '3f0b5285-c53b-4643-a65a-9dc1d37671e6',
	AgilityRisk: '33999ddb-70b9-47dd-9ba8-664144fd50cc',
	AgilityExerciseManager: 'aed76c86-8fa2-48e0-b6cd-abc8ffa1f50a'
};

export const featureTypes = {
	departmentDOA: 'a4e7fe45-8252-4bb0-8e3a-c23e2129e330',
	departmentOOS: '70ca907b-15af-4b48-b77b-c7f4b4ddad5a',
	departmentTeams: 'aa98cc03-25ef-49e5-bc75-c564929dc93e',
	departmentLocations: 'cd0b46b4-1427-4bbe-b6bf-4583e8b5d9ac',
	manageAnnexes: '96a608c9-5253-463a-b0ec-cbab73077a68',
	manageDepartments: 'c75ab783-c650-4ce4-b3de-0db5e059daea',
	organizationDOA: 'a0c78301-cae8-4255-8b0c-450c70a82b90',
	organizationOOS: '0d21c375-b455-4d46-9bfe-6e172687b2f9',
	organizationTeams: '459da64d-180a-4b2e-8015-fd792dfe22dc',
	manageOrganization: '25ac82de-50a3-4971-8f7a-63e6063d05d8',
	manageProcess: '26d023ec-497e-4a95-9572-1e75c15acd23',
	processDependency: 'facfc7be-998c-4b87-ab2f-b0ae7f7154fc',
	processLocations: 'f4605128-5259-40c4-be68-70f69ee76f87',
	configureImpacts: '21213e31-1fe0-41f1-8719-fe27e8ede8ec',
	configureRTO: '99bab718-5b3c-43e7-add5-9ceb805cb744',
	configureStrategy: 'c7eb103a-4046-4ee6-b6d4-8c468aedb404',
	emergencyReadiness: 'acebcc14-2021-48c4-bcfc-1e8a8c1e0005',
	availableSeats: '812b560d-a9d8-41f1-8e6c-79ca081cf558',
	locationType: 'a01f3822-7b5b-486d-943e-a626911e0461',
	manageAgencies: '72f05021-d10b-4d95-aa90-786e456dd807',
	supersession: 'f74350b3-bcbd-4e80-ada3-239a7d85db4c',
	generalQuestions: '2baf7a38-5301-41cc-b8aa-5d2c36e95547',
	plannerDashboard: '640c65e2-9d91-4bc3-a664-ca9b8ba1e47c',
	plannerAnalytics: '88ee25bc-b3fd-4364-9a4b-587292a26a63',
	plannerReports: '12e718df-1782-4026-80a4-95a5d2fd4f96',
	manageVendors: '1032c347-8848-4fce-a6ff-a350025c9ad2',
	manageTechnologies: '01396616-37d2-4cfd-b7bf-9af43eece305',
	manageEquipment: '788d04c1-ad3e-437c-88c9-d3fe77dc429d',
	customQuestions: '0fad8508-0dd4-435e-acef-a82e4c3e01d3',
	manageAnnex: '02a77575-6c00-4d74-a2f2-d198adfde4b2',
	assignTeamToDepartments: 'd3fa3195-069b-4529-bde7-8cf6d162f91c',
	industryDOA: '52cfe5ee-6434-4c4b-aa78-842085ea4a14',
	industryOOS: '52352777-0081-4cbe-9e75-8e6cba52f498',
	industryTeams: 'dd2c75e3-e398-4dda-9b08-53419c10b9e8',
	departmentAlternateLeader: '6465641f-cc60-46b7-943a-3969b10d4a66',
	managePortalTechnologies: '6f6659bf-6d4c-4cf8-a917-bf8e13de1397',
	managePortalTechnology: '604a9b57-4dd8-4a28-87ec-14c39eb2e55d'
};

export const defaultLocale = 'en-US';

export const entitySettingsTypes = {
	iQSendTeamMemberReminderEmails: 'a86d4bc6-3a07-4a9c-aa62-1a96a0bd4962',
	iQSendTeamMemberReminderSummary: '25835a5e-4d36-4acf-9e85-2b1872eb58e3',
	iQTeamResourceRequireApproval: '7d955501-a611-4db3-a9d0-d3cd14af7ebe',
	iQAssignResourceToSelf: '8d6215ac-3ab2-420b-85ef-5bd932dc7a30',
	iQEmailFromName: '52a9fc1f-9b5d-420e-b95f-48100f49a561',
	iQVerificationFrequencyRule7: 'c6581d6b-1ce0-4932-ae5c-b17f97f648cd',
	iQVerificationFrequencyRule30: 'd42017bc-4261-4aa7-8af2-867bd2b055bf',
	iQVerificationFrequencyRule31: '99a22f45-d321-444d-a05d-2b81ad95eec6',
	iQVerificationFrequencyRule90: 'f7752815-3cf4-43f3-bbfa-b56d26565feb',
	iQVerificationFrequencyRule91: '1c5e4f9e-ea78-4675-b1b3-42067c22aaf9',
	iQVerificationFrequencyRule180: '8a44e7e0-2c7a-491b-a17c-15a7b81759b1',
	iQVerificationFrequencyRule181: '288be6ee-6138-44df-b901-d1d3acf178c6',
	iQVerificationFrequencyRule365: 'b208b2ca-e510-4fce-8832-84cc49eb5fe1',
	iQForceNextVerificationBasedOnLastVerified: 'ff7595bf-2797-49cb-b6a8-4a069ccdfff3',
	portalSubdomainName: 'd547b4ef-f63e-460f-97c2-cf5b8d3c09b1',
	callInAnnouncementDefaultWelcomeInstructions: 'ac084ecc-ad4b-4ae8-823b-dc8658314c82',
	callInAnnouncementDefaultChildInstructions: '9246f9c6-c25d-4fd9-9d42-502187c77ebc',
	bulkUploadRuleDisableBulkUploadEmailErrors: '462d591a-249e-4f65-bf1c-82c0fe40a01b',
	bulkUploadRuleDisableBulkUploadEmailAttachments: '8dd3c543-726e-407f-a36a-ae5624ba9541',
	bulkUploadRuleDefaultLocation: '13f9a090-56b6-40f9-bcab-bc52c2319668',
	bulkUploadRuleDefaultRole: '3e69c59b-617e-49a2-aaa3-fb434dc576e5',
	bulkUploadRuleNotificationsEmailAddresses: '78422d3f-306e-469b-814d-45b9edf242bb',
	BCAllowLocationBasedContinuityStrategies: '156b80fa-c403-466c-8989-0db70c3702fa',
	BCAllowScenarioBasedContinuityStrategies: 'af774882-0437-4539-a613-06ef19445ab9',
	portalMaliciousFileTypes: 'da6d451b-080a-40a8-8475-a533cd38b2ad',
	portalBlockMaliciousFileTypes: '78b43934-4b47-4f11-a9c1-00806b3d434b'
};

export const iqPlanItemTypes = {
	Document: 'c5ddeed5-986b-4321-bb55-922efd6c43d7',
	Report: '80831cbd-408a-494e-9bbb-a4cbc4f8b255',
	toc: '6ba51e5f-b579-4740-b6a2-d6667e954b52'
};

export const planGenJobStatusTypes = {
	failed: '5a500217-bba8-43bc-9da0-272bd57428cf',
	completed: 'a3a6f730-2803-480c-b15a-2b8d4914906d',
	inProcess: 'cf375d04-cef1-4d19-bc14-663b5e58a9b1',
	pending: '542def59-08f4-444e-8191-e0c9db6d945d',
	canceled: 'bd8f3bc7-9dd4-4ca2-8dbd-46f9ede8527c'
};
export const planGenOptions = {
	useLatest: 1,
	useVerified: 2
};

export const daysInWeek: number = 7;
export const daysInTwoWeeks: number = 14;
export const halfMonthDays: number = 15;
export const monthsInQuarter: number = 3;
export const monthsInThreeQuarters: number = 9;
export const monthsInSemiYear: number = 6;
export const monthsInYear: number = 12;
export const minutesInHour: number = 60;
export const saveResourceOptions = {
	saveAndEstablish: 1,
	saveAndNotEstablish: 2
};
export const contentDispositionLength = 2;
export const textFieldMaxLength = {
	tiny: 50,
	large: 4000
};
export const defaultSetTimeout: number = 1000;
export const defaultBreadcrumbsLength: number = 2;
export const radix: number = 10;
export const getPlanGenerationStatusRate = 1000;

export const approvalStatusTypes = {
	modified: '3fc6ffa8-9388-4aad-a30b-8b1ccbd0400c',
	checkedin: 'f10b55bd-10c3-4f90-899a-48c237cc52e0',
	verified: 'a8ff6598-9c90-463e-9be8-08683c695725'
};

export const notificationJobTypes = {
	dueSoon: 'eeca9d34-c1bd-4a24-8562-caaef0e1f510',
	overdue: 'e69761d6-02ac-4156-8f46-6b56fd99614a',
	dueToday: 'b3df1a58-acef-4f3b-a0f4-1594d1b1b61c'
};

export const notificationDatepartTypes = {
	month: 'f8b89b3c-8c9e-47c8-9a2e-9c5417d1a838',
	day: 'baba0540-0282-4aca-a53a-fb064ce2d509'
};

export const guid = {
	empty: '00000000-0000-0000-0000-000000000000'
};

export const iqResourceTypes = {
	asset: '69db52d9-19a3-47f9-a9c4-13cf6f1fe124',
	externalResource: '2eb30ba6-4638-48f3-bf50-48ca28ab3e64',
	plan: 'c3478fa8-a2e3-4dbd-8427-5255fdac8173',
	item: '4930be3a-bb02-4903-af4d-7674611056d5',
	team: '91b53798-ba55-4909-94a7-77fd7553a5d5',
	scope: 'a389f0b8-5839-4845-a184-7b7473184253',
	teamMember: '34b1ff97-5ef4-408a-a36b-8761e3f21ceb',
	issue: '5d1b0594-5006-44ae-8c78-b2445f750645',
	externalResourceContact: '411a868d-9806-4533-94c3-7bd6e399792b',
	itemDocument: 'a4595a42-7f9f-4741-8eab-8d9144a7b52f',
	externalResourceType: '21040771-ce63-4552-96d5-fa17b19b3fdd'
};

export const bytesPerMegaByte = 1024;
export const maxUploadFileSizeInMegaBytes = 100;
export const maxUploadFileSizeInBytes = maxUploadFileSizeInMegaBytes * bytesPerMegaByte * bytesPerMegaByte;
export const maxEmailAttachmentsSizeInMegaBytes = 30;
export const maxEmailAttachmentsSizeInBytes = maxEmailAttachmentsSizeInMegaBytes * bytesPerMegaByte * bytesPerMegaByte;

export const reportGroups = {
	iQ: '825ab1e8-a599-46f4-b080-4df6038c6d4d',
	portal: '8593cc1a-22eb-44df-b3dc-7cd07a8cd687',
	portalCRE: '561db122-32b4-4de2-a1ac-d1ddcd76cd52',
	bc: 'd47db17b-46b2-434c-a234-e5b628876dfc'
};

// INCIDENTS
export const voiceType = {
	// call to record
	ctr: 'ctr',
	// text to speech
	tts: 'tts'
};

// filterTypes
export const filterTypes = {
	role: 'role',
	entity: 'entity',
	manual: 'manual',
	geoLocation: 'geoLocation',
	allUsers: 'allUsers'
};

// This will come from settings later. Need to implement default responses setting.
export const globalDefaultResponses = [
	{
		order: 1,
		key: 'OK',
		definition: ''
	},
	{
		order: 2,
		key: 'NOT',
		definition: ''
	}
];
// END INCIDENTS

// MAP SETTINGS
export const googleMapAPITeramAndServiceLink = 'https://cloud.google.com/maps-platform/terms';

export const defaultCoordinates = {
	lat: 39.50,
	lng: -98.35
};

export const defaultRadius = 150;
export const metersPerFeet = 0.3048;
export const metersPerKilometer = 1000;
export const metersPerMile = 1609.344;
export const feetPerMeter = 3.28084;
export const milesPerMeter = 0.000621371;
export const kilometersPerMeter = 0.001;

export const defaultImageAddress = {
	profile: 'https://cdn.preparisapp.com/images/userdetail_default_256.png',
	marker: {
		panic: 'https://cdn.preparisapp.com/images/panic.png',
		panicLatest: 'https://cdn.preparisapp.com/images/panic-2.png',
		user: 'https://cdn.preparisapp.com/images/male-2.png',
		location: 'https://cdn.preparisapp.com/images/office-building.png',
		home: 'https://cdn.preparisapp.com/images/home.png',
		star: 'https://cdn.preparisapp.com/images/star.png'
	},
	businessContinuityPlan: 'https://cdn.preparisapp.com/images/business-continuity-plan.png',
	home: {
		alerts: 'https://cdn.preparisapp.com/images/preparis-alerts-logo-package/Horizontal/PNG/preparis-alerts-logo-horizontal-color.png',
		planner: 'https://cdn.preparisapp.com/images/preparis-planner-logo-package/Horizontal/PNG/preparis-planner-logo-horizontal-color.png',
		incidentManagement: 'https://cdn.preparisapp.com/images/preparis-incident-manager-logo-package/Horizontal/PNG/preparis-incident-manager-logo-horizontal-color.png'
	},
	login: {
		logo: 'https://cdn.preparisapp.com/images/preparis-logo-package/PNG/preparis-logo-color.png'
	},
	preparisLogoColor: 'https://cdn.preparisapp.com/images/preparis-logo-package/PNG/preparis-logo-color.png',
	preparisLogoWhite: 'https://cdn.preparisapp.com/images/preparis-logo-package/PNG/preparis-logo-white.png',
	preparisLogoReversed: 'https://cdn.preparisapp.com/images/preparis-logo-package/PNG/preparis-logo-reversed.png',
	preparisLogoBlack: 'https://cdn.preparisapp.com/images/preparis-logo-package/PNG/preparis-logo-black.png',
	themes: {
		dev: 'https://cdn.preparisapp.com/stylesheets/dev/_theme.css',
		qa: 'https://cdn.preparisapp.com/stylesheets/qa/_theme.css',
		demo: 'https://cdn.preparisapp.com/stylesheets/demo/_theme.css',
		prod: 'https://cdn.preparisapp.com/stylesheets/prod/_theme.css'
	}
};

export const geofenceMapTypeFillColor = {
	roadmap: '#000',
	hybrid: '#FFF'
};

export const geofenceMapTypeStrokeColor = {
	roadmap: '#000',
	hybrid: '#FFF'
};

export const geofenceMapTypeFillColors = {
	option0: '#202020',
	option1: '#0000A0',
	option2: '#004400',
	option3: '#FF0000',
	option4: '#FF8888',
	option5: '#FFFF00',
	option6: '#800080',
	option7: '#404000',
	option8: '#00FFFF',
	option9: '#FF00FF'
};

export const specialCharacters = {
	doubleDash: '—'
};

export const geofenceTypes = {
	circle: '70dec8c9-bda7-4fad-93cb-a7f877990481',
	rectangle: '8ae65a66-6f4a-46a5-a61c-b785d00baa58',
	polygon: 'cbf680fd-23e2-4ae0-8674-34de30d5c8d3'
};

export const mapShapeTypes = {
	circle: 'circle',
	rectangle: 'rectangle',
	polygon: 'polygon'
};

export const googleMapDrawingModes = [
	'polygon',
	'rectangle',
	'circle'
];

export const googleMapTypeIds = {
	roadmap: 'roadmap',
	satellite: 'satellite'
};

export const shapeFillOpacity = {
	none: 0,
	default: 0.5,
	high: 0.6
};
export const maxAutoZoomLevel: number = 16;
export const minAutoZoomLevel: number = 4;
export const defaultAutoZoomLevel: number = 5;
export const zoomLevelForZipCodes: number = 10;
export const userMarkerOptions = {
	animation: 'DROP',
	draggable: false,
	icon: defaultImageAddress.marker.user
};
export const locationMarkerOptions = {
	// animation: 'DROP',
	draggable: false,
	icon: defaultImageAddress.marker.location
};
export const userAddressMarkerOptions = {
	animation: 'DROP',
	draggable: false,
	icon: defaultImageAddress.marker.home
};
export const panicMarkerOptions = {
	animation: 'DROP',
	draggable: false,
	icon: defaultImageAddress.marker.panic
};
export const latestPanicMarkerOptions = {
	animation: 'DROP',
	draggable: false,
	icon: defaultImageAddress.marker.panicLatest
};
export const postalCodeIconOptions = {
	url: defaultImageAddress.marker.user,
	anchor: [0, 0],
	size: [0, 0]
};
export const pointOfInterestMarkerOptions = {
	animation: 'NONE',
	draggable: false,
	icon: defaultImageAddress.marker.star
};
export const mapPoiOffStyles = [
	{
		featureType: 'poi',
		stylers: [{ visibility: 'on' }]
	}];

export const mapPoiOnStyles = [
	{
		featureType: 'poi',
		stylers: [{ visibility: 'off' }]
	},
	{
		featureType: 'poi.business',
		stylers: [{ visibility: 'on' }]
	}];

export const postalCodeKmlBaseUrl = 'https://cdn.preparisapp.com/kmls/us-zips/zip';

export const defaultZoomLevelWithMarker: number = 15;
// END MAP SETTINGS

export const debounceDuration = 1000; // in milliSeconds
export const shortDebounceDuration = 500; // in milliSeconds

export const publicAlertSubscriptionPreferenceTypeIds = {
	urgency: '22c25d14-08ff-4502-8019-0a3c1f97401a',
	category: '96e371af-affb-4d86-8281-11517cfa66d7',
	zone: '2e4841a5-5be6-4007-9cc5-6c2c3a58de81',
	certainty: '839223b1-c263-4da7-b007-9d91aa09b915',
	severity: '93abafd3-745a-48bb-960f-9dd12defe46f',
	event: '17782e9a-68b6-4ed1-9d9c-ec5f8e43785b'
};

export const publicAlertResponseTypeIds = {
	execute: 'c7a34473-7183-4e2e-b756-04b9c32ebbe3',
	evacuate: '0995bcde-00c8-4467-a0aa-3a014ab15aa6',
	assess: '53ae7081-b44c-417c-9a72-6a5aad1e8fc9',
	allClear: '751fb01c-9432-4ace-a74e-a610b455fd2d',
	prepare: 'b792c7eb-d99f-4e65-b63c-ad3b67ce4896',
	none: '3f55456c-51ab-456d-9b45-b07abe435bb0',
	monitor: '7b3c1528-b287-4f65-ba81-c8d3cb479b9e',
	shelter: 'c28d1607-d74e-4d68-98a9-d622ea27a18f',
	avoid: '034cff6b-a371-4fea-b8f3-ece5cfefea81'
};

export const applicationVerticalTypes = {
	standard: '3089683e-a1c9-4c91-9b87-1e3888a9cfcf',
	realEstate: 'f1d39814-6de9-481b-9abe-d655d396a64d'
};

export const maximumCustomResponses = 10;

export const incidentStateTypeIds = {
	resolved: 'b06420f9-3736-4765-a131-0f961b6f452a',
	active: 'cd360796-2659-47ce-b3d4-23bc602345c3'
};

export const documentStatuses = {
	notExist: 0,
	exist: 1,
	deleted: 2
};

export const incidentTypes = {
	notSet: '2e94c52f-43e2-4bc5-8e84-279e03671a42',
	panic: '7d212c3f-c0e6-4e4c-85c2-a6d693c9279b',
	other: 'ce7f8a3e-ae7c-49ce-a979-df38ea5ba03d',
	exercise: 'ed9e9a3a-4be7-4503-a3ef-382d448f3f74'
};

export const messageTypeIds = {
	sms: 'b538c044-d2f3-4635-8754-3a4bf0acacd2',
	emails: '8301370d-5bde-4ea4-b24c-5c8edba5b4d9',
	voiceMails: 'dbb15ac6-2cb3-4a94-bc67-d0ad335a18a0',
	conferenceCalls: '48055fd5-968a-456e-903c-0d30c944df38',
	pushMessages: '84e86a87-5754-447a-82de-d47823eff7d4',
	desktop: 'f410319c-4ad7-49cc-8e03-bab7d22831ff'
};

export const userPhoneNumberTypeIds = {
	security: '9da9c256-8283-4a0a-bd3d-10df73e1f43b',
	office: '20509910-5326-4057-b21f-5c694b314692',
	emergency: '5322a58a-8683-449b-a562-b0577de3fa74',
	personal: '5eec12d5-595f-4123-a613-c63e701ae993',
	additionalPersonal: '391dca7b-aac3-4875-94e2-2ef680c7f35f',
	mobile: '33f27328-8fee-4835-b020-cd6d48bb39cd',
	dontCall: guid.empty
};

export const addressTypes = {
	main: '8bbe4178-8adb-48ae-9035-6d683f855916',
	businessShipping: '4e8f4def-a04e-48b3-ae4e-3ec7c1fe506a',
	businessDefault: '4c8906d9-cf17-4d5a-9498-c5ae9482d2fd',
	businessBilling: 'd1d25517-47b3-4b01-aaae-ea811e10e959',
	personal: '301dc123-0bfb-421d-81b5-daadbb756e96'
};

export const userPhoneNumberSubTypeIds = {
	standardUS: 'ffabdff8-4b2c-4655-9edc-8195c303419d',
	oversears: '8337fbb1-9a43-45eb-aafa-19c877e762fc'
};

export const userEmailAddressTypeIds = {
	personal: 'acb4f38f-3642-472a-8de2-dcead28584a2',
	billing: 'd440a213-3ab4-4fb9-b306-5f567141ac53',
	business: 'd2b1d156-bdf9-4223-9368-825a448025ad'
};

export const participantHistoryLogTypeIds = {
	system: '771ce4e9-9060-42dd-a604-3c2c8ca7ce2b',
	portal: '948ced59-ce0b-4880-a3fc-59ea41152bfe',
	vendor: 'c30ff52b-79f0-4ae1-9229-8aae8dcd6120',
	user: 'b795f824-be42-4b98-946c-948cb00d84a0'
};

export const participantHistoryLogStateTypeIds = {
	amd: 'e8659348-cafc-4233-9b58-ab415dd2270a',
	any: 'a6300d17-79ff-4c86-ac45-f0517d2487e5',
	busy: '1ab2aefe-63c4-4fe5-a62a-9abb3ecf39d6',
	congestion: '952ee18a-1ffa-4e29-8098-305b63dec01d',
	error: 'dc23f301-8979-4d0b-bb71-f48e61074c32',
	listened: '364346b6-d07b-40ea-9752-d4b7763a7177',
	messageRejected: '4b3ce2ed-3dbe-4b74-84b3-b9861c1f9725',
	messageResponse: '64b0eedf-e4e7-452f-8116-33a5c41c722b',
	messageSent: '949aad76-24e9-46d6-ba9b-8578f2b7633a',
	noAnswer: 'af853525-c90a-4757-92bf-984ac1c4c41e',
	noReply: 'a5d29944-e767-4171-b15c-7b8fc1f861b7',
	notOk: 'fd824937-89dd-486e-b995-f7ce6d9049ea',
	null: 'ea3d35a1-b557-4f04-a385-f36b4b4299d7',
	ok: '933e1261-e43e-4c25-96af-0fca4f901285',
	online: 'f3caefe0-015e-48f9-8b79-4227b462ee72',
	pickup: '4fe91b86-4eaa-4420-b415-bc00f5508c9d',
	preparisDevNot: '8989ce99-0b6b-4a7a-bb44-8e4e1fac765b',
	preparisDevOk: '4cd187c7-5ef0-4422-9927-548626048837',
	preparisQaNot: '167ab3d3-9e29-4667-bb40-d1df84f45174',
	preparisQaOk: '38e5b8d3-8f53-45b7-a39b-55dac6001c44',
	unknown: '905b62ba-7efc-478d-a4ea-af256637373d',
	voiceError: '6554a2da-f9dd-4fcc-9b69-a7cf7b22f632',
	spamReport: '9f312ac2-dd5a-4f5c-9cc6-59bb39cb9221',
	unsubscribe: '6ddd7877-809f-430b-b6e2-b21b05a26f86',
	open: '8761cd3d-a1fb-434b-9d3e-7398a20f983e',
	click: '71018e9e-5f24-4b27-a4b0-0628793f658d',
	bounce: 'ebcbcdc9-8994-412d-88d3-138f54f7d5f9',
	deferred: 'a042f4b5-86cc-4fb7-bc73-4d7de8b70abd',
	delivered: 'a2d45282-b413-48b2-b374-33e261783c92',
	dropped: '23888bc7-dc6e-4ed0-a6e5-36e66f6297e6',
	processed: '398a0593-2c99-471b-8ee4-8cb08fd15cc8'
};

export const entitySettingTypeIds = {
	allowDesktopAlertsApp: '59f87c63-971f-4995-8d5b-dec1342a0ced',
	allowSelfClearForEmergencyButtonPress: 'c3d1551b-d9e8-4973-b15a-ecf360b2a1c4',
	allowSelfRegistration: '77b5b111-e147-4588-bad1-daa5e3ced309',
	allowUserEmailSuppression: 'a8bf8f18-e969-4208-99a3-4e842866fcce',
	allowUserManageLocation: '8cecfd1c-f86b-4911-af83-5c50f990b5d3',
	allowUserManagePassword: 'e34420ca-b779-4a9c-a10d-06cfc9bdafbd',
	allowUserSmsSuppression: 'c8dde0e4-e63a-4697-8285-1308b2813cd5',
	allowUserVoiceSuppression: 'cb442589-687a-4044-baaf-3c5b4dbb1b77',
	allowUsersToRespondViaLink: '5ccc9024-cd6a-4a1a-bb93-711513f5f6f9',
	autoArchiveForceResolution: '6e0a1408-b1cb-414b-8c73-11a13dfe5bc1',
	autoArchiveIntervalDays: 'eb587f02-4ce2-4aab-b9d7-9956cdc1bf81',
	bcImpactSeverityDescriptionIsOn: '1b394fd2-55e4-4804-9f6a-1e6efd0dcf8e',
	bcNewTeamLeadIntroductoryEmailBody: '00d9a93c-786b-459b-8b49-611358bf5e7b',
	bcNewTeamLeadIntroductoryEmailSubject: 'ffc5eac2-1e87-4b8c-8659-fa049dc196fc',
	bcNewTeamMemberIntroductoryEmailBody: '7863a659-21b7-4c9c-a287-7fb0752a0e63',
	bcNewTeamMemberIntroductoryEmailSubject: '413eb6e4-dd94-48ae-8ea7-3efe7b9d9f76',
	bcPlanWatermarkIsOn: '5979e203-74c8-417e-9198-60653e93853c',
	bcPlanWatermarkText: '69263aaf-7c3a-41ea-b03d-7ebf29225746',
	bcTeamLeadDueDateNowEmailBody: '36ae8281-7976-4d4f-b132-c7258aae3232',
	bcTeamLeadDueDateNowEmailSubject: 'fe84fef4-4ae0-4d40-9fc6-434fe63e825f',
	bcTeamLeadDueDateReminderEmailBody: '92a85dbe-c814-4c1b-82a3-8d1d3bf5a4a5',
	bcTeamLeadDueDateReminderEmailSubject: '3c6c961d-1af3-4026-99f8-81ee93350ea7',
	bcTeamLeadIntroductoryEmailBody: '0991e1fa-8449-46e1-aa5a-bdd280a7f19b',
	bcTeamLeadIntroductoryEmailSubject: '4b4ffb79-79b0-40dc-87dc-f4fe8c61ddcf',
	bcTeamMemberDueDateNowEmailBody: '2d6c8cf3-8d50-40fe-b136-60e87857d60c',
	bcTeamMemberDueDateNowEmailSubject: 'a619790e-14d7-4dbd-9d5a-ee711cb51f8f',
	bcTeamMemberDueDateReminderEmailBody: 'b27cbc7c-6759-4762-ad4f-975c1a777318',
	bcTeamMemberDueDateReminderEmailSubject: '4d73fa25-5593-47ca-b860-02f4dcdf3e1f',
	bcTeamMemberIntroductoryEmailBody: '8b0db5b8-af7d-46b3-8a32-7a04524b8758',
	bcTeamMemberIntroductoryEmailSubject: '5fad450e-9d09-4f71-9009-2528b85b740e',
	bulkUploadFailureEmailBody: '4ca62e1c-db32-4387-9801-2874ed58f945',
	bulkUploadFailureSubject: '77909a2e-3ad5-47b9-9829-ae67b9d75055',
	bulkUploadIncorrectPasswordEmailBody: 'f5502fbf-04ac-470f-a03f-78b1fdee020f',
	bulkUploadIncorrectPasswordSubject: '52f485cc-daa0-480b-9f29-c10f90822867',
	bulkUploadResultsEmailBody: '6bab75fe-0452-40cb-999d-da99a9616199',
	bulkUploadResultsSubject: '6d6d4263-82de-4192-9eca-2925b6211e16',
	bulkUploadUnknownUserEmailBody: '847160a3-f9a5-49ae-a934-747677d6f12c',
	bulkUploadUnknownUserSubject: '9ed007a2-8cf9-4644-828a-26f2255bb4c8',
	callInAnnouncementPhoneNumber: '226c7332-71ec-4d46-93c0-fa582587afb5',
	changedPasswordEmailBody: 'e23deecf-2198-4abb-b4d7-b6123ff7cc54',
	changedPasswordSubject: '1a7e5fc6-3965-461c-b50b-1c7ffa46aeab',
	customPasswordPolity: 'cc74dff1-08b9-403d-b972-1e8ebb200048',
	defaultPhoneRequired: '8cceebe8-0e02-4842-aad2-8afdc9f16cf9',
	defaultUserInitiatedMessagesGroup: '5b220717-fcf7-4642-89ff-8292f2e21026',
	emailChangedEmailBody: 'e98bef02-dfcf-47e6-8fe9-5bdd039c572f',
	emailChangedSubject: 'e776e34e-d41d-4cf8-9112-8bd9a2a47be4',
	emergencyButtonEnableDesktopDeliveryMethod: 'fbf2a6b4-95f4-4a09-a890-1281262215d9',
	emergencyButtonEnableEmailDeliveryMethod: 'de3ca789-c153-462e-a66c-22239b71f2ea',
	emergencyButtonEnablePushDeliveryMethod: '481eb66d-d3d6-461f-9f6f-4e2837968d36',
	emergencyButtonEnableSmsDeliveryMethod: 'bc6f0f9a-dc49-494f-a0f2-0e4062199c46',
	emergencyButtonEnableVoiceDeliveryMethod: '61e4bc1f-43ba-4e64-a718-27f529c49817',
	enableAutoArchive: 'add524d4-b072-49d7-9ab1-51e7d66b4d36',
	enablePanic: 'aa3fe0ff-123d-4fb3-9c44-3e5957070416',
	enhancedSecurity: 'ac8dc4a7-9f9a-44c1-a8dc-c1081fbc0cd6',
	escalationTimeDelay: '57402bd3-a769-423c-a29c-fb3730ca8b94',
	excludeUserNameInEmail: 'ea3518f3-4fad-4338-8df9-74e64be70e1f',
	forgotPasswordBody: '6af8704e-28d3-403c-9180-b7b099aa432e',
	forgotPasswordSubject: '784220ab-15f2-46a7-bcfa-114f211e81e5',
	groupType: '9b815bdb-3dc6-4926-a8cc-499a76fdd9fc',
	includePersonalContactDefault: '1bfa23e6-0fed-4e18-ad27-e40393a37f44',
	includePersonalContactEmailDefault: 'ffa0e6f7-7f14-4ee7-b531-39bb53716c32',
	includePersonalContactPhoneDefault: 'b64c6008-b049-4834-ba33-5cb3eef55eba',
	inviteUserBody: '60a22425-96b3-41d9-b634-eb3330d8456a',
	inviteUserBodyCRE: 'c40808c6-c835-4491-ab12-21d22eb42229',
	inviteUserSubject: '9136e126-bf8c-47b3-b670-451c3e34da21',
	licenseMaxBCM: '5CD7BD86-1D30-4D65-AE73-433137B41C31',
	licenseMaxSME: '07E59A2E-A2C1-4C9B-9940-AB168E100200',
	licenseMaxTeamLeads: 'C4938C10-8072-407D-BEF5-E8F0B4DE8364',
	licenseOverage: '8A81A542-020B-4BBB-826E-DDA1CEA90C2E',
	limitUserManageProfile: '48812b52-3b65-4834-a997-823cc2f1367b',
	maxRecoveryDocuments: '980be629-6dd9-4a80-a232-0961031c568c',
	notificationEmergencyResponseEmailBody: '79821198-b36a-4d70-919c-d20ba9c3c301',
	notificationEmergencyResponseEmailSubject: '4c9b207a-7160-481c-bf87-c335e47f3932',
	officePhoneEnabledForVoiceDefault: 'a233afb4-5753-407e-acb9-26e41c981b2b',
	passwordExpiration: '965fa472-7b46-41c5-82ab-d5d5cc50563e',
	passwordLength: '680776a5-2d75-4902-b413-8662cf0fe774',
	passwordLowerCaseLetters: 'e6c9927e-e283-4212-9e38-125d58e718ec',
	passwordNumbers: '30b793ff-d71a-44bb-86e4-72f161cb6c26',
	passwordReuse: '261cd2c6-cf69-4443-9663-4227068b9b57',
	passwordSymbols: 'c9312ba5-1475-4362-8b58-4b9a95c44e5e',
	passwordUpperCaseLetters: 'ec3b0816-558a-452c-96e0-9e5b38ade552',
	portalAnnouncementNumber: '226c7332-71ec-4d46-93c0-fa582587afb5',
	portalAnnouncementSid: '64c3ca33-1410-4ea9-9736-20bae3812305',
	portalConferenceCallNumber: 'e104c1df-4f53-46ba-9d57-434268c1ce12',
	portalEmailFromAddress: 'd26c64b3-5e71-4021-ad8c-e143f75b9ee3',
	portalEmailFromName: '3754b3df-137c-4feb-a06f-240b5f3fb1c8',
	portalMaxUsers: '18f04539-be53-490b-99be-8e17d8ab4717',
	portalMaxLocations: '5ba34307-acfb-4594-9a7b-f49dfd5edf9e',
	portalMobileAllowAccess: 'd0679f42-f6fb-4162-9668-d12564117eef',
	portalVoiceDisplayNumber: 'b1dcada5-4d5b-4288-b926-a9587a8d87a0',
	prefixDisableForMsgAndEmail: '6d6eb300-8a9c-4f30-b230-bf8989ade9c2',
	proximityRadiusForEmergencyButtonPress: '4a114507-b9d5-4d01-8176-92b988528ff8',
	proximityRadiusMeasurementTypeForEmergencyButtonPress: '9c04ddd4-67f2-4991-98e9-092e8678b0eb',
	requireProximityForEmergencyButtonPress: '52322aff-04f1-41f6-8198-154488e0c5db',
	sendMessageBody: '68754ff6-e680-4e00-a124-932047f4cd95',
	sendMessageSubject: '7c51bf56-25e3-4013-9ceb-d0c7649449b1',
	sendRegistrationEmail: 'ba5abc0e-a44d-426a-b040-135a1c776612',
	useMultifactorAuthentication: '1ff49f75-99ca-4ba1-8472-6dc89e856d6d',
	userGroupAdditionEmailBody: 'f27f2698-2e56-4b9a-a21c-a14f0b451547',
	userGroupAdditionSubject: 'd02897b2-5116-46f4-9ab6-92852ea192ae',
	userGroupRemovalEmailBody: 'e5a09b96-b5df-45d4-a4b1-dffa58378548',
	userGroupRemovalSubject: 'ebd9190b-f663-4e56-ac4a-8e968371a9eb',
	portalPrivacyLinkAddress: '91e90011-df72-4766-941a-6ace5e0134a4',
	portalTermOfServiceLinkAddress: '8a72b5b2-dd5b-4ade-8851-09c70f0b42da',
	markAlertEmailAsUrgent: '6c8a7de4-b60d-4f64-877e-cb8ffb0c365c',
	allowOfficePhoneSMS: 'b23ffb35-e82c-4172-9241-c17e983be67f',
	portalAboutUsLink: '9deec427-19b0-4acf-afdc-35696f683451',
	portalSupportLink: 'e2c303e7-330b-468a-a88a-fb4d9f7b8b14',
	portalSubdomainName: 'd547b4ef-f63e-460f-97c2-cf5b8d3c09b1',
	geolocalizationPortalSetting: 'b30a4582-cbe2-4203-9b6b-aa472c31dd7f',
	riskNewAssessmentRecipientEmailBody: '15392396-29c3-4ba4-9b92-229264f17c83',
	riskNewAssessmentRecipientEmailSubject: 'af361417-2df8-4f24-be22-2bb63c05a0f6',
	riskAssessmentRecipientEmailBody: 'f64f8bd7-c401-42a4-a5ff-b196d10e8f4a',
	riskAssessmentRecipientEmailSubject: '5092d97b-e800-463b-bdb4-50b5e7198b83',
	riskAssessmentRecipientDueDateNowEmailBody: 'd03c375c-dad2-4550-b30d-54e757c9d3cd',
	riskAssessmentRecipientDueDateNowEmailSubject: '6707b837-e3db-41aa-bdad-36d8db8fc366',
	riskAssessmentRecipientDueDateReminderEmailBody: 'e3a4197d-32a6-43a1-b122-384870746f9f',
	riskAssessmentRecipientDueDateReminderEmailSubject: '137df516-0eb8-40d2-a717-a344a16117d3',
	riskNewAssessmentApproverEmailBody: '5d53f7bb-0364-42a7-9a8e-6f84df391136',
	riskNewAssessmentApproverEmailSubject: '3edd1ba3-9a1f-46da-8ac2-f845f5242394',
	riskAssessmentApproverEmailBody: '2e8985b5-6e67-4268-851b-ee569aa46a86',
	riskAssessmentApproverEmailSubject: '0536354f-6374-4e79-9199-907a682a0fe2',
	portalSalesforceAccountId: '981847dc-43d3-4c12-84dd-9f8ea88963af',
	availableSeats: '00f577f1-fc17-49e6-9fde-697c98befd6b',
	notes: '4f2354da-aceb-429e-abb0-d33b1e2e6112',
	emergencyReadiness: 'cbd893ad-1a5a-447a-8d20-f1bf7f6d39db',
	locationType: '6dc816ea-8c2b-4438-b6a6-718cb6a4d824',
	bcNewAnnexLeadIntroductoryEmailSubject: '051fcb72-4e51-41f1-b97e-412868eea292',
	bcNewAnnexLeadIntroductoryEmailBody: 'ce14107f-a52f-4583-ac89-f8394bf205c0',
	bcAnnexIntroductoryEmailSubject: '513fb16e-b93d-40f4-a51e-6f43526cbdf9',
	bcAnnexIntroductoryEmailBody: '7154ae16-4db3-41dd-90e2-3fc0beef0faa',
	bcAnnexLeadDueDateNowEmailBody: '47414cde-eadc-4cc7-8be3-530f72ac3a8b',
	bcAnnexLeadDueDateNowEmailSubject: '0bc32572-625c-4f43-b3bd-11698c995eca',
	bcAnnexLeadDueDateReminderEmailBody: '0badd44e-8cdd-4c83-bf2f-d23e9745071f',
	bcAnnexLeadDueDateReminderEmailSubject: 'd90237e3-9d84-4203-af85-1606b007b7af',
	managerNotificationEmergencyResponseEmailBody: 'dea6b024-2347-4c6d-87a4-4b770297197e',
	managerNotificationEmergencyResponseEmailSubject: 'b263dfdb-39c1-4d3a-bfaf-7bf578c38e15',
	bcTechnologyLeadIntroductionEmailSubject: 'b764db8b-d2b5-4422-b710-556704ad6186',
	bcTechnologyLeadIntroductoryEmailBody: '0e1270b2-9527-4114-b0fd-4c2fc4aac448',
	bcTechnologyNewLeadIntroductoryEmailBody: 'af89c6d7-2485-4bca-8a3e-a18aef7f5c13',
	bcTechnologyNewLeadIntroductoryEmailSubject: 'abd759ab-24b7-4313-b5eb-2db43dfeeda0'
};

export const increments = {
	one: 1,
	two: 2,
	three: 3,
	four: 4,
	five: 5,
	six: 6,
	seven: 7
};

// SEND MESSAGE
export const sendMessageSteps = {
	followUp: 0,
	options: 1,
	messages: 2,
	automaticReply: 3,
	filters: 4,
	summary: 5,
	schedule: 6,
	save: 7,
	pending: 8,
	success: 99
};

export const messageResponseTypes = {
	new: '0',
	everyone: '1',
	limited: '2',
	incidentCreator: '3'
};
// END SEND MESSAGE

// CREATE INCIDENT
export const createIncidentSteps = {
	details: 0,
	affectedUsers: 1,
	summary: 2,
	success: 99
};

// CONFERENCE CALL
export const conferenceCallSteps = {
	followUp: 0,
	options: 1,
	filters: 2,
	summary: 3,
	schedule: 4,
	success: 99
};
// END CONFERENCE CALL

// userResponseTypeId to participantHistoryLogStateTypeId
export const userResponseTypeIdToParticipantHistoryLogStateTypeId = {
	// option 01
	'fc95e14a-982e-46ea-892f-bcc0c1f1e08c': '6f446595-c8d6-47fd-8172-10f68db20d9d',
	// option 02
	'ca2881ad-2951-4399-92fa-e0db477d67c7': '530085c3-c519-4dc5-956f-c4d1003dc373',
	// option 03
	'760c4ce1-8b62-4b2e-b31b-66d0b5b6df9e': '6e9ee33e-a7cf-484c-94e7-2c2aad83d224',
	// option 04
	'ca580bf2-7fc9-44fb-8046-caf24dceec4b': '8caa6ed5-1d55-42d2-b9c3-cfa4860668f5',
	// option 05
	'3fe2232a-5806-431c-9d57-3c247441e21f': '18e43880-c295-4b08-b38c-e051114c2564',
	// option 06
	'61d8eb67-6f67-4b91-b432-a7b9446dd1e0': '58a0c203-10e0-46b5-8130-e12b864caeec',
	// option 07
	'b2eae584-2054-44c2-b19a-ad35c579c7de': '1c554737-dd7a-4c54-a63a-f437ce4e491a',
	// option 08
	'cad01fef-0255-451d-8f9f-384e859968fa': 'ea8a9733-364e-40d6-9ad2-19a02b625447',
	// option 09
	'e04ac2d6-5cea-400c-959d-d1ff4b62b925': 'b669b465-8c2d-4d87-8b35-f22913b8532b',
	// option 10
	'33867d33-ec46-4a7b-ae39-5b942370d8da': 'c090f894-c1e2-45b8-82fa-0df70e5df0d6'
};

export const userResponseTypeIdToColor = {
	// option 01
	'fc95e14a-982e-46ea-892f-bcc0c1f1e08c': 'option01Color',
	// option 02
	'ca2881ad-2951-4399-92fa-e0db477d67c7': 'option02Color',
	// option 03
	'760c4ce1-8b62-4b2e-b31b-66d0b5b6df9e': 'option03Color',
	// option 04
	'ca580bf2-7fc9-44fb-8046-caf24dceec4b': 'option04Color',
	// option 05
	'3fe2232a-5806-431c-9d57-3c247441e21f': 'option05Color',
	// option 06
	'61d8eb67-6f67-4b91-b432-a7b9446dd1e0': 'option06Color',
	// option 07
	'b2eae584-2054-44c2-b19a-ad35c579c7de': 'option07Color',
	// option 08
	'cad01fef-0255-451d-8f9f-384e859968fa': 'option08Color',
	// option 09
	'e04ac2d6-5cea-400c-959d-d1ff4b62b925': 'option09Color',
	// option 10
	'33867d33-ec46-4a7b-ae39-5b942370d8da': 'option10Color',
	'unknown': 'unknownColor',
	'noResponse': 'noResponseColor'
};

export const userResponseTypeIdToBackground = {
	// option 01
	'fc95e14a-982e-46ea-892f-bcc0c1f1e08c': 'option01Bg',
	// option 02
	'ca2881ad-2951-4399-92fa-e0db477d67c7': 'option02Bg',
	// option 03
	'760c4ce1-8b62-4b2e-b31b-66d0b5b6df9e': 'option03Bg',
	// option 04
	'ca580bf2-7fc9-44fb-8046-caf24dceec4b': 'option04Bg',
	// option 05
	'3fe2232a-5806-431c-9d57-3c247441e21f': 'option05Bg',
	// option 06
	'61d8eb67-6f67-4b91-b432-a7b9446dd1e0': 'option06Bg',
	// option 07
	'b2eae584-2054-44c2-b19a-ad35c579c7de': 'option07Bg',
	// option 08
	'cad01fef-0255-451d-8f9f-384e859968fa': 'option08Bg',
	// option 09
	'e04ac2d6-5cea-400c-959d-d1ff4b62b925': 'option09Bg',
	// option 10
	'33867d33-ec46-4a7b-ae39-5b942370d8da': 'option10Bg',
	'unknown': 'unknownBg',
	'noResponse': 'noResponseBg'
};

export const customFieldDataTypeIds = {
	Numeric: '5f31785c-4d3c-4d68-a834-da37c2bc0a76',
	ShortText: '0f6f2372-1e4c-4d67-ad13-615a0540488c',
	LongText: 'b9847d16-19b5-4ed4-a4e3-d7f07533ab5a',
	TrueFalse: 'd8bd3d80-d25d-4056-8754-a740f2396792',
	SelectOne: 'aad6f035-05f9-42d3-b6ea-682371340875',
	SelectMany: '76c83029-8439-42ad-90e6-7f0ff54b732c',
	DateTime: '1eccbe40-1b3b-461b-95df-35f1fe6a8af2'
};

export const userCustomFieldStatusTypeIds = {
	Active: '0dd44108-690c-45ee-ad20-d15a322334cf',
	Deleted: 'ff7d44f3-3e12-40f3-a211-2c8e04893608'
};

export const customFieldStatusTypeIds = {
	Active: 'f1d4f59a-64d9-43be-bebf-958ef924f2b0',
	Deleted: '579f09ec-b8ab-4941-be36-3a8addcf9895'
};

export const customFieldTypeIds = {
	User: '0741ab49-72f7-4555-b93c-b462cbd96007',
	Incident: 'b2e4df73-e41c-4476-a6f5-50d7e94a8bcb',
	BusinessContinuity: '72bf9737-378a-4171-bfb1-deef31a7f9f9',
	BusinessContinuityTechnology: 'ea473ce9-8796-4d7d-b74d-a154a56c1d5f'
};

export const customFieldOptionStatusTypeIDs = {
	Active: 'b5da6970-6125-4904-b87f-c39187d4fd8a',
	Deleted: '6858a7cd-7a99-49f1-99e6-1a556f7e6f80'
};

export const desktopSeverityTypeIds = {
	Alarm: '691a8410-fa5e-4d33-9d19-a0dced297dbc',
	Warning: 'f049b0ab-9426-4577-aaa5-02b196ae9585',
	Info: 'a33cbd7c-f974-40ac-a882-6c00ff095fc4'
};

export const iqArchivedKeys = {
	Assets: 'includeArchivedIqAssets',
	ExternalResources: 'includeArchivedIqExternalResources',
	Issues: 'includeArchivedIqIssues',
	Items: 'includeArchivedIqItems'
};

export const iqAssetStatusTypeIds = {
	archived: 'f2db5c2e-bc21-474d-b935-92e0c5e80a4b',
	active: '1895c65b-4aa7-4123-84dd-83d502d51534'
};

export const iqItemStatusTypeIds = {
	active: '8f57c93d-74fd-4171-bd79-776c1ef0c4ed',
	archived: 'b66a315f-9e92-41af-a48c-be1d9c7f5e3c'
};

export const iqExternalResourceStatusTypeIds = {
	archived: '7da64802-a2a2-4825-ad19-ae975ec6f866',
	active: 'ba2a392b-392b-40bc-b78c-80a3c20a8ca9'
};

export const panicStateTypes = {
	active: '4bc8b944-5232-47fe-9abb-0f1e3b2ffc5a',
	scheduled: 'c35bb398-7217-4631-ac54-6795c4a4e35a',
	deleted: '6a142f87-0a45-4085-bd96-cbaae3eb7193',
	archived: 'f67e91df-b756-423c-87e5-7cb2be6650e9',
	resolved: '5a77f9c0-725c-45ac-a202-1b779768e645'
};

export const storageKeys = {
	aboutUsUrl: 'aboutUsUrl',
	actionPlanIncidentId: 'actionPlanIncidentId',
	adpSSOState: 'adpSSOState',
	allowMobileAccess: 'allowMobileAccess',
	brandingTerms: 'brandingterms',
	cacheBustingValue: 'cacheBustingValue',
	currentPortalId: 'currentPortalId',
	currentPortalName: 'currentPortalName',
	currentPortalSalesforceId: 'currentPortalSalesforceId',
	dateTimeRootPortalCreated: 'dateTimeRootPortalCreated',
	endpoint: 'endpoints',
	exerciseId: 'exerciseId',
	hasBusinessContinuityAssignments: 'hasBusinessContinuityAssignments',
	hasBusinessContinuityManagers: 'hasBusinessContinuityManagers',
	hasSubPortals: 'hasSubPortals',
	headerInfo: 'headerInfo',
	idle: 'idle',
	impactCategories: 'impactCategories',
	impactSeverities: 'impactSeverities',
	impactTimeSpans: 'impactTimeSpans',
	isBusinessContinuityAnnexLead: 'isBusinessContinuityAnnexLead',
	isBusinessContinuitySME: 'isBusinessContinuitySME',
	isBusinessContinuityTeamLead: 'isBusinessContinuityTeamLead',
	isExercise: 'isExercise',
	isIncidentHistoryArchivedIncluded: 'isIncidentHistoryArchivedIncluded',
	isSelfRegistered: 'isSelfRegistered',
	isSuperAdmin: 'isSuperAdmin',
	lastLoggedInUserId: 'lastLoggedInUserId',
	originalDestination: 'originalDestination',
	portalFeatures: 'portalFeatures',
	portalIndustry: 'portalIndustry',
	portalPlanTypes: 'portalPlanTypes',
	portalSite: 'portalSite',
	preferredLanguage: 'preferredLanguage',
	primaryLocationId: 'primaryLocationId',
	privacyUrl: 'privacyUrl',
	revisionItemTypes: 'revisionItemTypes',
	rootPortalId: 'rootPortalId',
	rootPortalName: 'rootPortalName',
	scopeId: 'scopeId',
	ssoAuthentication: 'ssoAuthentication',
	supportUrl: 'supportUrl',
	teamId: 'teamId',
	termOfServiceUrl: 'termOfServiceUrl',
	termsAndConditionsAccepted: 'termsAndConditionsAccepted',
	token: 'token',
	userId: 'userId',
	userName: 'userName',
	userProfileName: 'userProfileName',
	userRights: 'userRights',
	usersTableState: 'usersTableState',
	verticalTypeId: 'verticalTypeId',
	isBusinessContinuityTechnologyOwner: 'isBusinessContinuityTechnologyOwner'
};

export const panicResolutionReasonTypes = {
	mistake: '1f27e582-30a7-4d28-8b0f-1bf901443ced',
	clear: 'f7a6d9db-ccea-452a-b43c-2d6aa124a380',
	checkIn: '42e54001-e9d7-4463-96af-3ab32aefe3ca',
	custom: '7379b171-5a78-4c74-b892-c3c416b4ddd7'
};

export const documentPlanVisibilityOptionsTypeIds = {
	thisTeamOnly: '91c79cbd-0995-4f78-ba70-2e7ab4ca3b82',
	thisTeamAndSubTeams: '1dbbef88-43fc-4e93-84e5-8ab4489b9e49',
	allTeamsThisScope: '1215c23f-9e4b-4786-9d09-269d8f37553e',
	allTeamsAllScopes: 'a8a36b72-ffcf-48a6-ab11-1ef9cd521a7e'
};

export const userStateTypes = {
	Deleted: 'c16326f1-36bb-45a9-8f61-326713e8ad4a',
	Active: 'c1ff019b-b3f6-411c-b04e-59294e9090fe',
	Unapproved: '9d77d0d5-5781-4597-b1d9-77924da05213',
	Locked: '78408f30-b350-446a-9d03-b7659af9cce4',
	Pending: 'b5d6bc28-5e6d-4bd1-b028-f7e9a6c6b7a1',
	Inactive: 'ffb8849c-4dc6-4c66-9d00-f93ac2ef260b'
};

export const categoryTypeIds = {
	workplaceThreats: 'f6f02c10-41a9-4b87-b865-db8c56214a1e',
	terrorism: 'f3210a1a-da7a-4b88-b3e9-0a66cc8d7d94',
	other: 'e77215bb-7c16-4def-adf3-23dcb7d5776a',
	naturalDisasters: 'ba6be0af-c836-4941-ab04-6af689fc9fdc',
	health: 'e47788ff-7799-46f2-81da-11177c6f4165',
	firstAid: 'c7206ad9-a3e2-4c8f-8914-b09dc76357ed',
	cyberThreats: '5b368d17-c2e1-4ab6-b0a3-344352e8fd70'
};

export const documentTypes = {
	folder: 0,
	file: 1
};

export const deleteFolderCheckReasons = {
	ok: 0,
	accessDenied: 1,
	cannotDeleteRoot: 2,
	folderHasContents: 3
};

export const bookmarkTypeIds = {
	Document: '6428c669-f952-4558-87df-1359eefbab9c',
	Page: 'cb8dfca6-0caf-4967-9dd4-d1e795cead28'
};

export const myPreparisTabs = {
	document: 0,
	bookmark: 1
};

// RESOURCES
export const entityContentTypeIds = {
	threats: '148a1689-577b-4ca5-b47b-e124ab249b42',
	tabletops: 'bb073508-593c-40bb-8c39-6bf5aa801c0c',
	checklists: '40e1b2f9-e47a-4c6c-93fb-c4684f88705c'
};
// END RESOURCES

export const roleBundleTypes = {
	administrator: '7731ed42-8255-4f10-a541-23d2af16a56a',
	tenantAdministrator: '87dc9bbd-8e64-4d4e-8ec8-3e9989902069',
	propertyExecutive: 'eea381b8-7285-4112-a042-4e6a767156da',
	propertyMessageOnly: 'b897cf3b-f450-4112-ba94-4ff073058c7a',
	propertyManager: '2bcac73f-40a9-4c1b-83cc-53610186159f',
	programManager: '91036032-40fa-466e-851f-5b353cae71a5',
	messageOnly: '600be691-af43-4b1a-b6d5-602fbacece8f',
	tenantCrisisTeam: '6affb11e-01af-48a6-a301-7fdfe87f76d6',
	propertyStaff: 'e11b32f6-e775-4521-b2bf-c23977c35339',
	crisisTeam: '87d41223-6864-4651-8744-c8c3fb3b2faf',
	tenantEmployee: '35ab65b0-4b24-42ec-8695-dc40c040bc0e',
	tenantMessageOnly: '3e2cba06-c925-4173-9deb-e395bbe12991',
	employee: '377c3773-cfce-4a7f-8379-ee2e26eed253',
	superAdmin: 'a0402862-e1d4-4150-97ed-5677665281f2',
	propertySuperAdmin: 'ccd7e5b1-8b12-49eb-bf3d-e7021d851af7'
};

export const roleBundleTypeNames = [
	{ id: roleBundleTypes.administrator, name: 'Administrator' },
	{ id: roleBundleTypes.tenantAdministrator, name: 'Tenant Administrator' },
	{ id: roleBundleTypes.propertyExecutive, name: 'Property Executive' },
	{ id: roleBundleTypes.propertyMessageOnly, name: 'Property Message Only' },
	{ id: roleBundleTypes.propertyManager, name: 'Property Manager' },
	{ id: roleBundleTypes.programManager, name: 'Program Manager' },
	{ id: roleBundleTypes.messageOnly, name: 'Message Only' },
	{ id: roleBundleTypes.tenantCrisisTeam, name: 'Tenant CrisisTeam' },
	{ id: roleBundleTypes.propertyStaff, name: 'Property Staff' },
	{ id: roleBundleTypes.crisisTeam, name: 'Crisis Team' },
	{ id: roleBundleTypes.tenantEmployee, name: 'Tenant Employee' },
	{ id: roleBundleTypes.tenantMessageOnly, name: 'Tenant Message Only' },
	{ id: roleBundleTypes.employee, name: 'Employee' },
	{ id: roleBundleTypes.superAdmin, name: 'SuperAdmin' },
	{ id: roleBundleTypes.propertySuperAdmin, name: 'Property SuperAdmin' }
];

export const roleBundleTypeRoleTypes = {
	optional: '3fa97dcc-81f6-4ea1-9669-47702f6e5ac6',
	default: '0503db38-26b7-49fd-98d1-9422fa87db52',
	required: '5c51e75e-fbf6-41f7-ad50-cb2a500f0217'
};

export const entityPhoneNumberTypeIds = {
	emergency: '18d2f03d-0736-4a77-a9f0-2cac86ff3c08',
	main: '42281af0-1f22-4ac8-b366-412e182de359',
	personal: '94ad4ffb-0837-4221-95d8-497fcef4de7b',
	security: '94e22f80-9163-432e-a12d-849f4891ca58',
	mobile: 'e1acc799-a72c-4c3f-a959-a8c6a2106f03',
	fax: '2342506d-1f9a-4e52-8b4f-d692f3d1b3d6',
	work: 'f23bd62b-e915-4fbd-9e02-f55e2087eae7'
};

export const entityPhoneNumberSubTypeIds = {
	standard_us: 'b4f1e6f9-d824-4f8b-8b4d-071d176fd9f3',
	overseas: 'e07182e2-6b17-420b-a613-33bf652b7e44'
};

export const entityStatusTypeIds = {
	suspended: 'dbffe542-4e84-446f-b79b-1b7d379421f2',
	deleted: '847ebbef-b0c9-48a3-9891-212833ddfac6',
	inactive: '46ed19c7-2a4a-47b4-b983-879229ef4c45',
	pending: '45df2299-cc49-4d91-9a68-a391e009dbf3',
	ok: 'fc42fcf8-02c3-4f8e-a9b2-cb179cf47c3f',
	active: '4fdcaecc-b770-4202-a697-d52460223fe5',
	nonpayment: 'b5020905-c8ea-4d6d-baf6-dad5c3d2a2cf'
};

export const entityGroupTypeIds = {
	business_continuity: 1,
	corporate_incident_management: 2,
	local_incident_management: 3,
	disaster_recovery: 4,
	other: 5
};

export const userActivityFlagTypes = {
	contractConfirmed: 'e6834553-ff8e-48f6-9b89-6b1d677d874b',
	initialRegistrationSent: '1fa8bbf9-89c5-417a-89e5-2af2d9059d5a',
	termsAccepted: '9a660099-8cfb-40a1-be5b-7bc191406ca5',
	userFirstLogin: 'fffe0629-b5a0-47e4-81c3-0c957314e6b7',
	userLatestInvitationSent: '6ce5b80f-ce5a-41bd-adcb-1d0ecaeb246a',
	userLatestLogin: 'a70dfe8e-aaf7-4eca-a401-8eb6995cef8d'
};

export const alertResponseSetTypeIds = {
	noResponse: {
		id: 'b4d5b83f-3d89-4ccc-b317-4a344f7a1a17',
		name: 'No Response Required'
	},
	checkBox: {
		id: '4ffea198-da6c-44f2-936e-6e9efb76c278',
		name: 'Check Box with OK'
	},
	ok: {
		id: '81a2bf4d-85ec-419a-8125-747a54166e23',
		name: 'OK'
	},
	yesNo: {
		id: '70f3974a-e072-470b-8a5b-aca7c300ecb4',
		name: 'Yes/No'
	}
};

export const alertResponseTypeIds = {
	ok: '202d40a0-2f0d-46ab-85df-4d5172394763',
	check: '271ea524-82e7-4ae2-a21b-66d4c020911c',
	cancel: '57d4f581-c7e1-4423-80b8-f1372bc3815e',
	yes: '549c9960-8bbc-4266-9be4-dcf3c1f6af1b',
	no: '870e47d3-0839-4f77-8a6c-47c5ffa9930a'
};

export const alertStateTypeIds = {
	active: {
		id: 'dd4a396d-3a22-4d24-9958-3b3738c9325c',
		name: 'Active'
	},
	inactive: {
		id: '5e550133-1652-4ee3-9a2a-5f4c23762283',
		name: 'Inactive'
	}
};

export const alertFilterTypeIds = {
	entity: '578d04ab-e2ec-4fbf-bfba-d4a2249806a0',
	roleBundle: '869ff599-2ddd-4224-92ab-e9b30cee9a29',
	role: '6582cc14-aa74-49f8-9ad9-5b377937824f',
	vertical: '45c6f678-95d7-46eb-85ae-10b3116e37e4',
	geofence: '980a3283-4aeb-4866-a025-dad505fd4a1e'
};

export const locationIncludeOptions = {
	userAddress: 0,
	userGeolocation: 1,
	userLocation: 2
};

export const securityModuleKeys = {
	agilityAlerts: 'Agility Alerts',
	documents: 'Documents',
	training: 'Training',
	resources: 'Resources',
	reports: 'Reports',
	manage: 'Manage',
	help: 'Help',
	myProfile: 'My Profile',
	myPreparis: 'My Preparis',
	administration: 'Administration',
	iQ: 'iQ',
	panic: 'Panic',
	liteMessaging: 'Lite Messaging',
	recoveryProfiles: 'Recovery Profiles',
	agilityPlanner: 'Agility Planner',
	incidentManagement: 'Incident Management',
	agilitySafetyPass: 'Agility Safety Pass',
	agilityRisk: 'Agility Risk',
	agilityExerciseManager: 'Agility Exercise Manager'
};

export const messageProcessingStatusTypes = {
	Submitting: 0,
	Sending: 1,
	Completed: 2
};

export const maxMessageProcessingAge: number = 10;

export const permissionFeatures = {
	agilityAlerts: {
		key: 'agilityAlerts',
		actions: {
			alerts: 'Alerts',
			sendEmergencyMessage: 'Send Emergency Message',
			configureEmergencyMessage: 'Configure Emergency Message',
			conferenceCall: 'Conference Call',
			splashMessage: 'Splash Message',
			callInAnnouncement: 'Call-In Announcement',
			viewAllOptions: 'View All Options',
			newIncident: 'New Incident',
			incidentHistory: 'Incident History',
			crisisTeam: 'Crisis Team',
			simulation: 'Simulation',
			manageMessages: 'Manage Messages'
		}
	},
	documents: {
		key: 'Documents',
		actions: {
			documents: 'Documents',
			addUploadDocuments: 'Add/Upload Documents',
			deleteDocuments: 'Delete Documents',
			editDocuments: 'Edit Documents'
		}
	},
	training: {
		key: 'Training',
		actions: {
			training: 'Training',
			goToTraining: 'Go to Training',
			courseName: 'Course Name'
		}
	},
	resources: {
		key: 'Resources',
		actions: {
			resources: 'Resources',
			viewAllResources: 'View All Resources',
			threatTypeIcon: 'Threat Type Icon',
			resourceName: 'Resource Name'
		}
	},
	reports: {
		key: 'Reports',
		actions: {
			reports: 'Reports',
			adminReports: 'Admin Reports',
			incidentsReports: 'Incident Reports',
			trainingReports: 'Training Reports',
			incidentManagementReports: 'Incident Management Reports'
		}
	},
	manage: {
		key: 'Manage',
		actions: {
			manage: 'Manage',
			roles: 'Roles',
			users: 'Users',
			locations: 'Locations',
			groups: 'Groups',
			departments: 'Departments',
			savedMessages: 'Saved Messages',
			companyProfile: 'Company Profile',
			security: 'Security',
			systemEmails: 'System Emails',
			tenants: 'Tenants',
			navigatePortal: 'Navigate Portal',
			companySettings: 'Company Settings',
			customFields: 'Custom Fields',
			companyApi: 'Company Api',
			manageSettings: 'Manage Settings',
			selfRegistration: 'Self Registration',
			configureBulkUpload: 'Configure Bulk Upload'
		}
	},
	help: {
		key: 'Help',
		actions: {
			help: 'Help'
		}
	},
	myProfile: {
		key: 'My Profile',
		actions: {
			myProfile: 'My Profile'
		}
	},
	myPreparis: {
		key: 'My Preparis',
		actions: {
			myPreparis: 'My Preparis'
		}
	},
	administration: {
		key: 'Administration',
		actions: {
			managePortal: 'Manage Portal',
			managePlanner: 'Manage Industries'
		}
	},
	iQ: {
		key: 'iQ',
		actions: {
			iQ: 'iQ',
			admin: 'Admin',
			overview: 'Overview',
			scopes: 'Scopes',
			teams: 'Teams',
			teamMembers: 'TeamMembers',
			createTeamMember: 'Create TeamMember',
			deleteTeamMember: 'Delete TeamMember',
			updateTeamMember: 'Update TeamMember',
			verifyTeamMember: 'Verify TeamMember',
			reports: 'Reports',
			plans: 'Plans',
			complianceSchedule: 'Compliance Schedule',
			createTeamResource: 'Create Team Resource',
			deleteTeamResource: 'Delete Team Resource',
			archiveTeamResource: 'Archive Team Resource',
			verifyTeamResource: 'Verify Team Resource',
			updateTeamResource: 'Update Team Resource',
			createIssue: 'Create Issue',
			deleteIssue: 'Delete Issue',
			resolveIssue: 'Resolve Issue',
			updateIssue: 'Update Issue',
			complianceCategory: 'Compliance Category',
			priority: 'Priority',
			establishDate: 'Establish Date',
			issueAssignee: 'Issue Assignee',
			updatePlan: 'Update Plan',
			verifyPlan: 'Verify Plan',
			generatePlan: 'Generate Plan',
			createPlan: 'Create Plan',
			selectEstablishedDate: 'Select Established Date',
			reassignTeamMemberTasks: 'Reassign TeamMember Tasks',
			reassignTeamMemberTasksAcrossTeam: 'Reassign TeamMember Tasks Across Team',
			moveTeamResource: 'Move Team Resource'
		}
	},
	recoveryProfiles: {
		key: 'recoveryProfiles',
		actions: {
			viewRecoveryProfile: 'View Recovery Profile',
			editRecoveryProfile: 'Edit Recovery Profile',
			editRecoveryProfileGenerator: 'Edit Recovery Profile Generator',
			editRecoveryProfileContact: 'Edit Recovery Profile Contact',
			editRecoveryProfileSite: 'Edit Recovery Profile Site',
			createRecoveryProfile: 'Create Recovery Profile'
		}
	},
	agilityPlanner: {
		key: 'agilityPlanner',
		actions: {
			businessContinuityManagement: 'Manage Agility Planner',
			businessContinuityAudit: 'View Agility Planner'
		}
	},
	incidentManagement: {
		key: 'incidentManagement',
		actions: {
			managePlans: 'Manage Plans',
			viewPlans: 'View Plans',
			incidentManagement: 'Incident Management'
		}
	},
	agilitySafetyPass: {
		key: 'agilitySafetyPass',
		actions: {
			safetyPass: 'safetyPass'
		}
	},
	agilityRisk: {
		key: 'agilityRisk',
		actions: {
			risk: 'risk'
		}
	},
	exerciseManagement: {
		key: 'exerciseManagement',
		actions: {
			exerciseManager: 'Exercise Manager'
		}
	}
};

// declare this after securityModuleKeys && permissionFeatures
export const moduleFeatures: any = [
	{
		module: securityModuleKeys.agilityAlerts,
		features: [
			permissionFeatures.agilityAlerts.key,
			permissionFeatures.manage.key,
			permissionFeatures.reports.key
		]
	},
	{
		module: securityModuleKeys.documents,
		features: [permissionFeatures.documents.key]
	},
	{
		module: securityModuleKeys.training,
		features: [
			permissionFeatures.training.key,
			permissionFeatures.reports.key]
	},
	{
		module: securityModuleKeys.resources,
		features: [permissionFeatures.resources.key]
	},
	{
		module: securityModuleKeys.reports,
		features: [permissionFeatures.reports.key]
	},
	{
		module: securityModuleKeys.manage,
		features: [permissionFeatures.manage.key]
	},
	{
		module: securityModuleKeys.help,
		features: [permissionFeatures.help.key]
	},
	{
		module: securityModuleKeys.myProfile,
		features: [permissionFeatures.myProfile.key]
	},
	{
		module: securityModuleKeys.myPreparis,
		features: [permissionFeatures.myPreparis.key]
	},
	{
		module: securityModuleKeys.administration,
		features: [permissionFeatures.administration.key]
	},
	{
		module: securityModuleKeys.panic,
		features: [
			permissionFeatures.manage.key,
			permissionFeatures.agilityAlerts.key]
	},
	{
		module: securityModuleKeys.liteMessaging,
		features: [permissionFeatures.agilityAlerts.key]
	},
	{
		module: securityModuleKeys.iQ,
		features: [permissionFeatures.iQ.key]
	},
	{
		module: securityModuleKeys.recoveryProfiles,
		features: [permissionFeatures.recoveryProfiles.key]
	},
	{
		module: securityModuleKeys.agilityPlanner,
		features: [permissionFeatures.agilityPlanner.key]
	},
	{
		module: securityModuleKeys.incidentManagement,
		features: [
			permissionFeatures.agilityAlerts.key,
			permissionFeatures.manage.key,
			permissionFeatures.reports.key,
			permissionFeatures.agilityPlanner.key,
			permissionFeatures.incidentManagement.key
		]
	},
	{
		module: securityModuleKeys.agilitySafetyPass,
		features: [permissionFeatures.agilitySafetyPass.key]
	},
	{
		module: securityModuleKeys.agilityRisk,
		features: [permissionFeatures.agilityRisk.key]
	},
	{
		module: securityModuleKeys.agilityExerciseManager,
		features: [permissionFeatures.exerciseManagement.key]
	}
];

export const managePasswordOptions = {
	sendUserEmail: 1,
	manuallySet: 2
};

export const entityNameMaxLength = 100;
export const defaultPageSize = 50;

export const maxNumberOfConferenceCallParticipants = 100;

export const splashMessageSteps = {
	options: 1,
	filters: 2,
	summary: 4
};

export const alertTypeIds = {
	question: 'bf4e272e-da34-43d7-b4ef-3bd70b9c95f2',
	information: 'ffd42ed1-8d64-452f-a3a2-91a82ea76ca9',
	warning: 'efd0c884-599c-4009-9436-4dc8e8979ff8',
	cancel: '733a680d-335d-49fd-a960-bdd884ade301'
};

export const deleteEntityErrorCodes = {
	userAssociation: 7004,
	panicTrigger: 7010,
	uii: 7011,
	alertSubscription: 7012,
	savedMessage: 7013,
	documentPermission: 7014,
	entityNameRequired: 7015,
	callInAnnouncementPhoneNumber: 7016,
	activeChildPortals: 7017,
	bulkUploadDefaultEntity: 7019,
	bulkUploadDefaultRole: 7020,
	incidentCommunicationGroup: 7021
};

export const unitOfMeasure = {
	mile: 'dce1161f-5b2d-4240-9476-e98545c70f2f',
	feet: '1206bd88-ea66-41f7-9f88-0404516bdf32',
	kilometer: 'ab9f51b0-0760-4e6b-99a0-3a5a7daa6f7b',
	meter: '82d281fa-3edf-49f9-944d-be816c168bf5'
};

// LAUNCHDARKLY FEATURE FLAGS
export const launchDarklyFeatureFlags = {
	// EXAMPLE: property: {name: string, key: string, subject: string, class: string, entitySettingTypeId: string}
	customEmergencyButtons: {
		name: 'Custom Emergency Buttons',
		key: 'custom-emergency-buttons',
		class: 'custom-emergency-buttons',
		entitySettingTypeId: 'f34b63ad-9f2d-43e1-bba5-0791d337fd68'
	},
	agilityRisk: {
		name: 'Agility Risk',
		key: 'agility-risk',
		class: 'agility-risk',
		entitySettingTypeId: '720da857-4318-46da-91e9-a7dfdde566f8'
	}
};

export const imperialSystemKeys = [
	'US',
	'GB',
	'MM',
	'LR'
];

//
export const openWeatherMapAppId: string = 'c0b07086bcf9ab31715248cd06194545';
export const weatherIconBaseUrl: string = 'https://openweathermap.org/img/wn/';
export const defaultCompanyLogoUrl = defaultImageAddress.preparisLogoColor;

export const emergencyButtonTypes = {
	panic: '49ea5c1c-4786-476e-99e6-d1d5ba462b37',
	scheduledCheckIn: '473f3cd6-144a-43cc-8973-d098586b892a',
	custom: '163fb86c-6ee5-499a-8cfc-7556086a890b',
	reportIncident: '943a0d96-0122-45e4-a877-beaeabe94b30'
};

export const emergencyButtonRecipientCohortTypes = {
	proximity: '4deec6eb-acdb-4f73-b34c-fad0b36cad77',
	designatedRecipients: '78c0b26d-e7b2-4b02-80e7-fe0f38d6e618'
};

export const emergencyButtonRecipientStatusTypes = {
	active: '3b59a281-d00c-4614-b95e-576fcccf7fd8',
	deleted: '7792163c-e5cd-4b16-8d59-d17172f8e12c'
};

export const emergencyButtonStatusType = {
	active: 'af6967f3-ce9f-4149-95f9-9cea7bc89612',
	deleted: '2a1691c8-fca4-4022-a38b-f8ef745b4d6f'
};

export const defaultEmergencyButtonProximity = 152.4; // in meter

export const defaultSelfRegisteredUserFirstName = 'OptIn';
export const defaultSelfRegisteredUserLastName = 'User';

export const requestForUserResponseNumeric = {
	no: '0',
	yes: '1',
	yesWithCustomResponses: '2'
};

export const systemBulkUploadNotificationEmail: string = 'bulk@preparis.com';

export const bcGenericIndustryId = '56397415-6ac4-4c21-9101-e95158e9aac5';

export const bcTemplateFunctionalAreaStatusTypes = {
	active: 'aae1dff4-d206-4e72-ad0c-c64a30910d29',
	deleted: 'df43ce3e-8908-46d1-b12c-8dc11eefe949'
};

export const bcPortalFunctionalAreaStatusTypes = {
	active: '5dced3d8-305d-4d12-8558-bc4fadbfe3de',
	deleted: '77c44216-5c38-4118-be36-3202ea3763ae'
};

export const bcTemplateProcessStatusTypes = {
	active: '4e718261-f95d-48ad-90ec-d3c07fdce7f1',
	deleted: 'a4a584d3-4645-4967-a103-224f93db1734'
};

export const bcPortalProcessStatusTypes = {
	active: 'bca9e36e-b82a-48d4-9def-b51a0114233e',
	deleted: '75b84d32-73a3-470a-bf8f-cf9e7082e7b6'
};

export const bcStrategyTypes = {
	workFromHome: '9c004e84-4eb4-44f1-a008-cd33f5211014',
	temporaryLocation: '704acb8f-ed9b-4f11-ab37-0f0139895d5b',
	moveTheWork: 'e56b1600-219c-4985-b8fa-2b31228d201c',
	suspend: '984817b1-c6ee-4c5e-ace7-e558843bdfcb'
};

export const bcRevisionItemTypes = {
	none: '',
	buildYourTeam: '9a8ca3b0-c29c-429a-bae3-5106ebc9eef6',
	sendTeamLeadEmail: '8075edbe-224e-4af0-accf-095cc08a650d',
	validateProcess: '3001b73a-eff1-442a-9bf0-31ef0002f995',
	sendAssignmentEmail: 'e7bd0842-eadc-483f-b9c5-9a3d5047b1f4',
	impactAssessment: 'd731177f-8663-44e8-a243-b058b4bf9527',
	rto: 'be1d75e6-dac5-459f-ae5c-d565b20022de',
	criticalDates: 'a190d9cc-bf54-4cd4-80ba-9d1ac8028b3e',
	continuityStrategy: '056594f5-b6f0-46fb-9855-1303b611e293',
	impactFinancial: '6c046473-8b67-4037-b8e5-d806800d0b06',
	impactClient: '70d4a412-d41f-4c98-962f-7a45ffeebeeb',
	impactEmployee: '886647d0-77ba-4cc1-9366-70d15506f63e',
	impactBrand: 'c3c00b14-80dd-4325-8849-2aebd26bd2d5',
	impactServices: '881236ae-0445-4c49-9356-30910be262b4',
	impactCompliance: '92a17340-8be5-455d-81be-7cc58228eaa5',
	customImpactCategory1: '5b964445-c68b-4bfa-9dda-b4602410a06d',
	customImpactCategory2: '449f6d1f-4485-4f0a-9d9c-1ffde1ed0dff',
	customImpactCategory3: '5e5c3225-d304-4ae0-824b-949a6609943f',
	processLocation: '951b6fb4-14db-4cd2-92ab-f2d24aa38b4f',
	processDependencies: '79272c21-fcdf-4624-94c4-e6c95ba7c03d',
	departmentOOS: '8169c5dd-85c0-4861-9983-1c7bf24e72fb',
	departmentDOA: 'f8e8fb86-f246-4892-a9cc-a889b99fe2b1',
	departmentTeams: 'b2c8a5da-8e90-4657-8e9b-000962841860',
	departmentAlternateLeader: '3cba5e70-bd1b-4cb8-8eea-dce7e515db94',
	departmentLocations: '5ddccee3-fe67-4206-8f80-4ac92b7b6382',
	buildAnnex: '9866e964-4fd7-4b93-a247-afd1b2abae50',
	departmentDescription: '8ee5ce4f-5fba-43a9-95b1-42f668a6e97c',
	organizationOOS: '451cd7cd-e99a-42ff-aa2e-213063801e55',
	organizationDOA: 'd6686ed4-ef96-48f4-8f58-3c98227baaa7',
	organizationTeams: 'b7b3c55a-bcb9-4846-ab25-faa09ddc6254',
	supersession: 'fd9bc961-2d0b-4fc3-932a-894f55463717',
	generalQuestions: '94caacc3-832a-4aec-b5da-63a1679e4eff',
	trackProcesses: '78eab4a2-ac01-415a-b5f9-a859b64387a4',
	trackDepartments: '184fb5cd-a848-4b5e-9384-94057e62a2b1',
	trackAnnexes: 'f7518654-5f95-47c7-a01c-6cd833423f2e',
	annexSections: 'dfd9839e-9f89-4d18-8442-cf4920068326',
	annexAgencies: '139e56cf-346e-4bdd-90b5-02e55fd3d25d',
	annexActionList: '7fdf1dcc-df3d-4394-b9e6-96ca9a91b290',
	sendAnnexEmail: 'bb95d0b1-482b-4c98-8006-165082f396b5',
	buildTechnology: 'deb3139e-5f75-441d-982c-cf0ae4d61c5f',
	sendTechnologyEmail: 'ba765aaf-562e-4f9a-8e18-e9748a13f180',
	trackTechnologies: 'f07ab2cd-4f19-428f-8b9f-54982abfa079',
	technologyVendors: '2c9d3e1d-b028-4859-b2cd-40860d103670',
	technologyDependencies: '9f52df24-261e-4967-85ad-65c5299fa6fe',
	technologyApplicationDetails: '4c04b488-542b-49aa-9b99-851a44f4e22b',
	technologyTasks: '637b9412-ead5-4656-9bfd-9c856ac152aa',
	technologyRecoveryTime: '7c484880-6dc1-4413-8954-010db5e6ee52'
};

export const bcRevisionStateType = {
	notStarted: '283e3ffe-81a9-4705-afeb-df44e5543333',
	inProcess: '49a808b0-1065-4082-8818-e06aa98fe1d2',
	complete: '8ea8a66c-b36e-46e6-ba98-e2c3e2d59d69',
	new: 'e870e637-7e4c-4025-a2ba-a703281cf2b8'
};

export const revisionScopes = {
	organization: {
		key: 'Organization',
		categories: {
			introduction: 'Introduction',
			generalInformation: 'General Information',
			keyPersonnel: 'Key Personnel',
			manageDepartments: 'Manage Departments',
			manageAnnexes: 'Manage Annexes',
			manageTechnologies: 'Manage Technologies'
		}
	},
	department: {
		key: 'Department',
		categories: {
			introduction: 'Introduction',
			departmentInformation: 'Department Information',
			departmentAlternateLeader: 'Department Information',
			keyPersonnel: 'Key Personnel',
			locations: 'Department Locations',
			validateProcesses: 'Validate Processes'
		}
	},
	annex: {
		key: 'Annex',
		categories: {
			introduction: 'Introduction',
			configureAnnex: 'Configure Annex'
		}
	},
	technology: {
		key: 'Technology',
		categories: {
			introduction: 'Introduction',
			manageTechnology: 'Configure Technology'
		}
	},
	process: {
		key: 'Process',
		categories: {
			processAnalysis: 'Process Analysis'
		}
	}
};

export const departmentPlansStatusTypes = {
	active: 'bab01bac-cd7b-4e9f-9ed5-b785f1ded19a',
	inactive: '41ea291c-614a-4f10-a8cb-50fe3790ccaf',
	standBy: '7454fd59-17ae-4ca6-80d8-abedd8c1a922'
};

export const bcImpactCategories = {
	financial: 'bc7b959d-2a5e-428a-903b-6794f69c1dd5',
	customer: '9a9fa3f6-2f6d-4b68-afa5-24eb5d275a45',
	employee: 'afb921bc-612d-4eb5-8e33-c3d5f691e8ce',
	reputational: 'd8e42916-779c-4efe-b032-69660864df6b',
	operational: '5b0d9dcd-3f6e-46f4-a351-bcb586213524',
	compliance: '6ca6c4f4-83f5-408b-8c6d-d3b98a9fefd9',
	customCategory1: 'c7587720-ab2e-4886-8ed2-c46413aae16d',
	customCategory2: 'afd51976-c6cf-4980-a3ec-ab3ae13b2f29',
	customCategory3: '3ff3fad7-5fee-4cdf-a6ca-3886908bf84f'
};

export const bcImpactSeverity = {
	none: '62468e04-8e9b-44fe-98bd-e76fbb96c0bb',
	insignificant: 'e2908f3e-53c6-4e16-9466-8d36a1160780',
	significant: '6b920b19-5c6d-4b79-82dc-39b4c4a3eb77',
	catastrophic: 'a99a303f-489e-44e8-9d10-801790b88c8d'
};

export const bcStatusStyle = {
	none: {
		status: bcRevisionStateType.notStarted,
		statusText: 'businessContinuity.status.none',
		icon: 'fas fa-arrow-right startIcon',
		buttonStyle: 'btn btn-primary startButton',
		buttonText: 'businessContinuity.bcProgressStatusTypes.notStarted',
		revisionItemType: null
	},
	inProgress: {
		status: bcRevisionStateType.inProcess,
		statusText: 'businessContinuity.status.inProcess',
		icon: 'fas fa-history continueIcon',
		buttonStyle: 'btn btn-warning continueButton',
		buttonText: 'businessContinuity.bcProgressStatusTypes.inProcess',
		revisionItemType: null
	},
	done: {
		status: bcRevisionStateType.complete,
		statusText: 'businessContinuity.status.complete',
		icon: 'fas fa-check revisitIcon',
		buttonStyle: 'btn btn-success revisitButton',
		buttonText: 'businessContinuity.bcProgressStatusTypes.complete',
		revisionItemType: null
	},
	new: {
		status: bcRevisionStateType.new,
		statusText: 'businessContinuity.status.new',
		icon: 'fas fa-check revisitIcon',
		buttonStyle: 'btn btn-success revisitButton',
		buttonText: 'businessContinuity.bcProgressStatusTypes.new',
		revisionItemType: null
	}
};

export const bcCriticalDateTypes = {
	endOfFrequency: '92cd42bd-ce4d-4283-97ac-c3ec1195d70e',
	specificDate: 'e1b03189-60b6-497c-82ac-c1a7b9e8e894',
	firstAndFifteenth: '3f31641c-ba7f-4472-9aaa-ee29e8b72c63',
	fifteenthAndLast: '13735f83-e8cd-4e4c-866e-3a3282a61294'
};

export const bcCriticalDateFrequencyTypes = {
	weekly: '65beff11-16e2-4d10-88d6-44c8e6905f0a',
	biWeekly: '1ac7e9f2-6c67-43bf-8f50-76be76fd0896',
	monthly: '70662b94-e200-4768-a09c-1eae7a165ab1',
	quarterly: 'b633a480-e939-46d1-afcb-409e3378456e',
	semiAnnually: 'f26778a9-3671-4abb-8769-25b7d4c3fac2',
	annually: '11db17dc-912e-4915-9353-19187b388ce2',
	oneTimeEvent: '4624e41d-bf75-47e0-8743-406a8f585c97',
	semiMonthly: 'c3bb50ae-250e-440f-9918-d5c6eb99ac2a'
};

export const bcProcessCriticalDateStatusTypes = {
	active: '12b35115-800d-4767-a266-30bb009a2279',
	deleted: '1047907e-1e2b-43db-a353-c746c4d43b01'
};

export const bcSiteStatusTypes = {
	active: '6d39ff0b-afac-45e8-bcb0-15673d70ce56',
	deleted: 'e8adc44e-6a9a-4346-8dcd-48990c87949b'
};

export const bcEquipmentTypeStatusTypes = {
	active: '8319268d-8568-44ca-bf71-803adac845ab',
	deleted: '45356e6d-0e58-4d1d-b641-7e3056106ded'
};

export const bcProcessStrategyResourceStatusTypes = {
	active: 'dfa05d5e-7ec3-4d2e-8170-bbad882951c5',
	deleted: 'f1455375-c7c7-4f4c-bc24-118300af869f'
};

export const bcVendorStatusTypes = {
	active: 'd355632b-46ab-4376-9591-318f44a505c1',
	deleted: '64e7a674-cff8-498e-8b38-cddfc8f1ab99'
};

export const bcAgencyStatusTypes = {
	active: '6b22af36-53b4-4df3-9c0b-8c9639d03091',
	deleted: '24712603-02df-4303-85f2-5286c3334fd3'
};

export const bcIssueStateTypes = {
	open: '6171be3d-bd24-4f9c-a9cd-d571c513780d',
	closed: '8c7d2b2e-c08c-4ef0-881c-e386d86c2245',
	archived: '1ed8f21b-edcc-4408-9e7c-ebb6447bafe0'
};

export const bcProcessStrategyLocationTypes = {
	internalLocation: '226d7117-02fb-409a-a4da-29cca8689300',
	externalPointOfInterest: '376adada-6e30-40f8-b5c0-b6bd8af71001'
};

export const bcTechnologyStatusTypes = {
	active: '9255219c-a219-4f5b-9ab2-130289fdbb92',
	deleted: '9acacd24-6d00-449b-989d-3509d19d5861'
};

export const industries = {
	generic: '56397415-6ac4-4c21-9101-e95158e9aac5',
	banking: 'e5e2af36-6c33-430b-b6cf-70c26b1bc039',
	legal: '184fc1ef-0b4a-41f2-aee2-0b4cc3e1a3fa',
	government: 'afc8954c-6677-4730-b6c4-5388c4d210d3'
};

// PLANS
export const planGenJobTypeIds = {
	consolidated: 'fd709a67-fa2c-4689-980e-a11b4cbe57f7',
	department: 'cebb76b9-9465-4254-a54f-c411f1ca6e4c',
	companyWide: '30df90eb-d85d-4430-b003-d7b3e4cb1af9',
	location: 'baad782c-d1bc-4eac-b647-094862c059e4',
	technology: 'b4ef29d3-e31b-4fb9-8c49-444dc113086c',
	consolidatedLocations: '4db62b4e-4402-4bb5-a7de-67dadf8ff07e',
	consolidatedTechnology: '121e0d5e-c1cf-4c1f-8e40-6290b97ba6a6'
};

export const bCPlanGenJobStatusTypes = {
	failed: '2bf06528-913d-496f-9176-058a9d68557f',
	completed: '982fc0cc-6e87-4ff7-b606-1e542b72c170',
	inProgress: '7ce3ac2f-bc7d-45a0-b6f2-5825ccedc67e',
	pending: 'a422e601-22ff-44ea-b24e-715b0e534ece',
	canceled: '5403d1da-6067-4446-a5fc-c861daa210dc'
};
// END PLANS

export const safetyPassUrl = 'https://www.crisisgo.net';

export const bcPlanActivationProcessStateTypes = {
	notStarted: '21bd2678-c59f-4560-93ae-deb923c213f8',
	inProgress: '9d69bef1-ca64-44d5-8863-938acaf2a73c',
	skip: '9e63cc17-fe86-415a-8f7e-38cacb95231b',
	done: '38e93907-960b-4401-90d8-ec928b6833a2'
};

export const bCStrategyInstructionsMaxDisplayLength = 750;

export const bcPlanActivationIssueStatusTypes = {
	active: '70cd9cf8-947a-43a1-8f19-f27b58da065e',
	resolved: '2e70fb00-5551-42f8-8057-a3a45dcfbb19',
	deleted: '217f317c-3c2f-4125-aab1-3cd952c53c38'
};

export const languages = {
	english: 'en',
	spanish: 'es',
	portuguese: 'pt',
	french: 'fr'
};

export const impactTimespanStatusTypes = {
	active: 'e37def28-175b-4543-89b4-af86220f1bba',
	deleted: 'ca9e9cf4-4543-4e8c-a4a3-3f3a238c4bce'
};

export const impactCategoryStatusTypes = {
	active: '23e6f400-19f6-43cb-bde0-1472478ecd34',
	deleted: '061fc02b-f371-4032-9039-1ac2bbdc8dbe'
};

export const impactSeverityStatusTypes = {
	active: 'e5967fb9-16ce-4545-a5f5-2b78014c9016',
	deleted: 'd47736db-07a0-4627-9c8e-a74cf310e057'
};

export const timeDurationUnit = {
	second: 'e5967fb9-16ce-4545-a5f5-2b78014c9016',
	minute: '368095b8-685e-4f99-a3c5-c44bbd30783c',
	hour: '41bb6fc1-df74-42b5-8f41-e55e5a4570ed',
	day: '0cab3e34-0f19-4cc6-b580-cc8dc70af2d5',
	month: '42f2eb93-6cb4-4d62-8b54-7f0357a1f8b2',
	year: '9372a9f2-4391-4a8b-80ec-9c6150a48f23'
};

export const maxTimespanDuration: number = 3153600000;

export const customFieldDataTypes = {
	Numeric: customFieldDataTypeIds.Numeric,
	ShortText: customFieldDataTypeIds.ShortText,
	LongText: customFieldDataTypeIds.LongText,
	TrueFalse: customFieldDataTypeIds.TrueFalse,
	SelectOne: customFieldDataTypeIds.SelectOne,
	DateTime: customFieldDataTypeIds.DateTime,
	SelectMany: customFieldDataTypeIds.SelectMany
};

export const portalProcessStrategyStatusTypes = {
	active: 'c29b7d09-fd49-440a-b540-c213302ac0e6',
	deleted: '8b480039-2a87-4b00-917a-33891029e5fa'
};

export const bcPortalProcessLocationStatusTypes = {
	active: 'c60d99bf-eb57-4880-8a73-9e879ecb5fdc',
	deleted: '8a106a9f-3528-4a44-890d-e433dc1f5edb'
};

export const bcPortalProcessDependencyStatusTypes = {
	active: 'c60d99bf-eb57-4880-8a73-9e879ecb5fdc',
	deleted: '8a106a9f-3528-4a44-890d-e433dc1f5edb'
};

export const maxMapMarkers: number = 500;

export const mapEvents = {
	click: 'click',
	dragStart: 'dragstart',
	dragEnd: 'dragend',
	insertAt: 'insert_at',
	removeAt: 'remove_at',
	setAt: 'set_at',
	zoomChanged: 'zoom_changed',
	centerChanged: 'center_changed',
	radiusChanged: 'radius_changed',
	boundsChanged: 'bounds_changed',
	overlayComplete: 'overlaycomplete',
	drawingModeChanged: 'drawingmode_changed',
	mapTypeIdChanged: 'maptypeid_changed'
};

export const exerciseScenarioStatusTypes = {
	active: 'ee759707-d3df-4bb3-8ab7-5c82ad3cb7ad',
	deleted: '1525da95-3a35-469f-b44f-c52dae5ec5e5'
};

export const exerciseTypeStatusTypes = {
	active: 'ae51d2f8-8bed-4ab9-80c0-be08d1fa9c99',
	deleted: 'e1bf63c9-573d-442a-80b9-15282b01ee23'
};

export const threadHazardStatusTypes = {
	active: '3c47a3ea-fe55-4eca-b502-b6f82e767913',
	deleted: '3b18a2ff-edc7-47df-859d-5d4529fc3668'
};

export const exerciseStatusTypes = {
	active: 'e6edb99e-4bf1-47c6-b9db-a2b7232e709b',
	deleted: '60a5b704-36b7-4103-beb7-50f413ab670f'
};

export const dateFormat = {
	short: 'M/d/yyyy h:mm a', // original: 'M/d/yy, h:mm a' | 6/15/15, 9:03 AM
	medium: 'MMM d, yyyy h:mm a', // original: 'MMM d, y, h:mm:ss a' | Jun 15, 2015 9:03:01 AM
	long: 'MMMM d, yyyy h:mm:ss a z', // original: 'MMMM d, y, h:mm:ss a z' | June 15, 2015 at 9:03:01 AM GMT+1
	shortDate: 'M/d/yyyy', // original: 'M/d/yy' | 6/15/15
	longDate: 'MMMM d, yyyy', // original: 'MMMM d, y' | June 15, 2015
	mediumDate: 'MMM d, yyyy' // original: 'MMM d, y' | Jun 15, 2015
};

/* RISK */
export const threatStatus = {
	active: '2bace5d8-e733-4490-9757-d08016a61a3a',
	deleted: 'a2e7af24-44db-40d6-9b7c-7e1e4e442345'
};

export const threatTypeStatus = {
	active: 'b4844386-f76a-4d26-9d31-e2746d0d0edc',
	deleted: '791d4fe8-56f7-45c6-a98f-7114918b02cf'
};

export const targetTypeIds = {
	company: '47290013-afae-43a5-911b-1bfa1095b510',
	department: '3f3f7660-277f-4720-a20f-9419ff1959ce',
	location: '0a6087ef-d302-49b8-b65e-bc5462a06644'
};

export const equationIds = {
	IxP: '610af901-b8cd-4ec2-8673-ca62dcf6c595'
};

export const assessmentStatus = {
	open: '4463eaa8-ae9e-43e2-8e34-da8898d57ef4',
	inProgress: 'a3b50d78-c125-4c26-ba51-de32cca676bc',
	waitingApproval: '69a590af-589a-4d97-8da2-a6b6a662a2c2',
	closed: '960bb1de-4877-47a1-abe8-2e8d959332b5',
	abandoned: '308c36d9-aa9b-451a-946a-d0f7a18abfd3',
	archived: '9904d155-d695-453b-b740-9f6ef6c70a21'

};

export const initiativeStatus = {
	open: 'a109f806-8c7c-4e05-af23-2e599203779a',
	closed: '16932fbe-9899-4bf9-ba04-023cec89f77e',
	archived: '12973e70-6d7e-4d5a-9390-6989096d37e4'
};
/* END RISK */

/* Plan Builder */
export const planSectionStatusTypes = {
	active: '990e415c-cbc0-4ed3-8ba2-39963c1a4989',
	deleted: '4276c980-2bf6-426c-a019-c4a602501998'
};

export const sectionPagesOrientationIds = {
	landscape: 'abe29cd6-0691-4d38-a659-c5c15d622298',
	portrait: 'd1eea99a-b5be-4254-9492-58b314f791bd'
};

export const planTemplateTargetTypeIds = {
	location: 'a10ff881-3bc7-49a6-a510-75bacc74c406',
	department: '8096b45c-4f66-4d11-833c-9069aedc4601',
	company: 'bab04d39-b705-4d2c-a2c4-ddbab1b95f18',
	companyITDR: '8b7c9d7e-23bd-4f44-9886-c34b96c51a29',
	continuityOfGovernment: 'e9c53029-3abf-414d-a65f-a7b01410b898',
	emergencyOperations: 'cb1626ab-4b50-4c24-acbb-db172f8d189f',
	continuityOfOperationPlanning: '97f99702-2176-4e3f-8b4d-2115aeb5a87d',
	technology: '68b2f458-4436-4976-ac3c-524cd44d2a84'
};

export const planTemplateTypeIds = {
	disasterRecovery: 'a5a1b20b-bbce-425e-b4ef-04e065cf3288',
	emergencyOperations: '66861628-0dac-48a3-9f23-504112ec27fb',
	businessContinuity: 'b1bac3a9-992b-4bfe-adff-72bd9bfe6db6',
	continuityOfOperationPlanning: 'e502ec4f-823d-46bd-9caf-8b92a7d734d4'
};

/* END PLAN BUILDER */

export const dialogueWidths = {
	sm: '25%',
	md: '50%',
	lg: '75%',
	xlg: '90%'
};

export const planDataElementTypes = {
	datatable: 'e7b70e41-8706-4df8-b477-0979e75d5ed8',
	tag: 'c11c0429-b14f-4f6a-a9c7-d37e936bf5bd'
};

export const emergencyReadinessStatusId = {
	active: 'eba4d57d-ce65-4e50-99e8-26c1d71eb14d',
	deleted: '1a460c6e-1ab3-4502-9503-871975ec0c7e'
};

export const locationTypeStatusId = {
	active: 'b10d3150-c3a8-40e7-b7e6-5d9f03d574e9',
	deleted: 'c5c49e37-342f-4437-81f9-7022495b4b78'
};

export const annexStatusTypes = {
	active: '186d93f7-6b5b-481e-8595-6489be262752',
	deleted: '09306542-5289-4d73-a224-c4b220e626d5'
};

export const portalSectionStatusTypes: { active: string, inactive: string } = {
	active: '0d6296e8-1ed9-4fd0-bdc8-b04a8686499b',
	inactive: '2e8ec456-ea8b-4635-a5e7-86a706c1bc37'
};

export const generalQuestions = {
	massCare: '6dd9471f-c5ca-49e4-a874-0d17465cc1aa',
	unmet: 'bcba2fd7-be1e-4670-864f-69df8a7e0d33',
	EOC: '1fdb19de-f3ea-4f2c-91a9-bc9150dcb776',
	EMA: 'ea8d37f4-0f3a-456c-a5ae-c51ba5526378',
	training: '911f48fa-e670-4ccc-97a9-ed82d10bba5e',
	Supersession: '890c80cc-e3e9-4874-bf71-f6679ff99b10'
};

export const portalActionStatusTypeId: { active: string, deleted: string } = {
	active: '96b99525-16f0-447d-83dd-7f1418ca82b6',
	deleted: 'eda32e62-516f-4289-a616-c111be3b8e12'
};

export const planTargetEntityType = {
	[planTemplateTargetTypeIds.department]: 'department',
	[planTemplateTargetTypeIds.continuityOfOperationPlanning]: 'department',
	[planTemplateTargetTypeIds.location]: 'location',
	[planTemplateTargetTypeIds.company]: 'company',
	[planTemplateTargetTypeIds.continuityOfGovernment]: 'company',
	[planTemplateTargetTypeIds.emergencyOperations]: 'company',
	[planTemplateTargetTypeIds.companyITDR]: 'company',
	[planTemplateTargetTypeIds.technology]: 'technology'
};

export const userStringLanguageId: { en: string, es: string, pt: string, fr: string } = {
	en: 'bc11855d-857f-40ac-ba23-162f64faa6f6',
	es: '1b2348f4-e53e-45a2-ad9a-113bb6442247',
	pt: 'bcaeddcf-8228-48ed-9b78-56a9a8b350a4',
	fr: 'bd5083d4-e939-434f-ad83-ad7dce602b25'
};

export const userStringTypeIds = {
	sms: '562c3891-9d37-4343-b33e-5b52c3f0f113',
	email: '5fad5368-03c3-482e-84b7-976413e904fd'
};

export const technologyDependencyStatusTypeIds = {
	active: '8884FC46-6214-4BF2-A335-26E3C13BB041',
	deleted: '02F9BE07-CAB3-45EC-B444-9535C2A48AA9'
};
