import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { env } from '@app/app.constants';
import { Utility } from '@app/providers/utility';
import { EndpointService } from '@app/providers/endpoint.service';
import * as moment from 'moment';
import { LoggerService } from '@app/providers/logger.service';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	private _requestData = null;
	private _encodedFieldSuffix = 'AsBase64';

	constructor(public http: HttpClient,
		private utility: Utility,
		private endpoints: EndpointService,
		private logger: LoggerService) {
		this._requestData = {
			applicationId: env.applicationId,
			versionId: env.versionId,
			clientTransactionKey: '00000',
			deviceIdAsBase64: btoa(env.id),
			token: '',
			entityId: ''
		};
	}

	get(url: string) {
		return new Promise((resolve, reject) => {
			this.http.get<any>(url)
				.subscribe((response: any) => {
					resolve(response);
				}, (error: any) => {
					this.logger.logError('Api Service Error', {
						url: url,
						error: JSON.stringify(error)
					});
					reject(error);
				});
		});
	}

	post(endpointName: string, data: any, isBlob?: boolean, asRaw?: boolean) {
		return new Promise((resolve, reject) => {
			const endpoint = this.endpoints.find(endpointName.toLowerCase());
			const request = this.createBaseRequest();

			if (endpoint === '') {
				console.log(`endpoint not found: ${endpointName}`);
				reject(`endpoint not found: ${endpointName}`);
			}
			Object.assign(request, this._processRequest(_.cloneDeep(data)));
			this.http.post<any>(endpoint, request, this.createRequestOptions(isBlob, asRaw))
				.subscribe((response: any) => {
					if (!isBlob) {
						const res = this._unwarp(response);
						if (res.errorCode) {
							reject(res);
						}
						else {
							resolve(res);
						}
					}
					else {
						resolve(response);
					}
				}, (error: any) => {
					this.logger.logError('Api Service Error', {
						error: JSON.stringify(error),
						request: JSON.stringify(request)
					});
					reject(error);
				});
		});
	}

	private createRequestOptions(isBlob: boolean, asRaw?: boolean) {
		const token = this.utility.getToken();
		const reqOptions: any = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'token': token ? token : ''
			}),
			responseType: (isBlob) ? 'blob' : 'json',
			observe: asRaw ? 'response' : 'body'
		};

		return reqOptions;
	}

	private createBaseRequest() {
		const newRequestData = {};
		Object.assign(this._requestData,
			{
				token: this.utility.getToken(),
				entityId: this.utility.getCurrentPortalId(),
				rootEntityId: this.utility.getCurrentPortalId(),
				portalId: this.utility.getCurrentPortalId(),
				createdById: this.utility.getUserId(),
				lastModifiedById: this.utility.getUserId()
			});
		return Object.assign(newRequestData, this._requestData);
	}

	private _unwarp(response) {
		if (!response || this._getStatusCode(response) !== 0) {
			return ({
				errorCode: this._getStatusCode(response),
				errorMessage: this._getStatusMessage(response),
				errorCodes: response.errorCodes
			});
		}

		if (response && !response.items) {
			return this._processResponse(response);
		}
		if (response && response.items) {
			return this._processResponse(response.items);
		}

		return this._processResponse(response);
	}

	private _getStatusCode(response) {
		return (response && response.statusCode) ? response.statusCode : 0;
	}

	private _getStatusMessage(response) {
		try {
			const message = env.useRawErrorMessage ? response?.errorMessage : response.errorMessageToUser;
			return atob(message);
		}
		catch (e) {
			return response?.errorMessageToUser;
		}
	}

	private _processResponse(responseBody) {
		return this._traverseBody(responseBody, this._processResponseField);
	}

	private _processRequest(requestBody) {
		return this._traverseBody(requestBody, this._processRequestField);
	}

	private _traverseBody(object, func) {
		for (const property in object) {
			if (!object.hasOwnProperty(property)) {
				continue;
			}

			func.apply(this, [object, property, object[property]]);

			if (object[property] !== null && typeof (object[property]) === 'object') {
				this._traverseBody(object[property], func);
			}
		}
		return object;
	}

	private _processResponseField(object, key, value) {
		if (!key || typeof (key) === 'object') {
			return;
		}

		if (key.indexOf(this._encodedFieldSuffix) > -1) {
			try {
				object[key.replace(this._encodedFieldSuffix, '')] = (value == null) ? null : this.utility.base64Decode(value);
			}
			catch (e) {
				object[key.replace(this._encodedFieldSuffix, '')] = value;
			}
		}
		else {
			this._convertDateTime(object, key, value);
		}
	}

	private _processRequestField(object, key, value) {
		if (!key || typeof (key) === 'object') {
			return;
		}

		if (key.indexOf(this._encodedFieldSuffix) === -1) {
			const base64Key = key + this._encodedFieldSuffix;
			if (object[base64Key] !== undefined) {
				object[base64Key] = this.utility.base64Encode(value);
				object[key] = null;
			}
		}
		else {
			this._convertDateTime(object, key, value);
		}
	}

	private _convertDateTime(object, key, value) {
		if (!value) {
			return;
		}

		// convert javascript datetime string to moment datetime
		if (typeof value === 'string') {
			const datetimeRegex = /\/Date\((\d+)\)\//g;

			if (datetimeRegex.test(value)) {
				object[key] = moment(value).toDate();
				return;
			}
		}
	}
}
