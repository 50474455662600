<div class="modal-header">
    <h4 class="modal-title">{{header}}</h4>
    <button id="dismissButton" type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <i class="fa fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <p>{{body}}</p>
    <form id="confirmationForm" class="mt-3" [formGroup]="confirmationForm" novalidate>
        <label class="form-label" for="comment">{{ 'common.comment' | translate }}</label>
        <textarea class="form-control" id="comment" aria-label="comment"
               formControlName="comment" name="comment" maxlength="4000"
               [(ngModel)]="comment" [required]="requireComment"></textarea>
    </form>
</div>
<div class="modal-footer">
    <button id="cancelButton" type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">{{ 'common.cancel' | translate }}</button>
    <button id="closeButton" type="button" class="btn {{confirmButtonClass}}" (click)="close()">{{ confirmButtonText }}</button>
</div>
