<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">{{'idleTimeout.title' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="signOut()">
            <i class="fa fa-times"></i>
        </button>
    </div>
    <div class="modal-body">
        <p>
            <ngb-progressbar id="timeRemaining" type="warning" [striped]="true" [value]="timeRemaining" [max]="maxVal"
                             [animated]="true">{{timeRemaining}}
            </ngb-progressbar>
        </p>
    </div>
    <div class="modal-footer">
        <button id="signOutButton" type="button" class="btn btn-secondary" (click)="signOut()">{{'idleTimeout.signOut' | translate}}</button>
        <button id="extendSessionButton" type="button" class="btn btn-primary" (click)="extendSession()">{{'idleTimeout.extendSession' | translate}}</button>
    </div>
</div>

