import { Injectable } from '@angular/core';

@Injectable()
export abstract class LoggerService {
	abstract logDebug(message: string, properties?: any);

	abstract logInfo(message: string, properties?: any);

	abstract logError(message: string, properties?: any);

	abstract logWarning(message: string, properties?: any);

	abstract logCritical(message: string, properties?: any);
}
