import { Injectable } from '@angular/core';
import { env, storageKeys } from '@app/app.constants';
import { Router } from '@angular/router';
import { Utility } from '@app/providers/utility';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '@app/providers/local-storage.service';

@Injectable({
	providedIn: 'root'
})
export class SsoService {
	private env = env;

	constructor(private router: Router,
		private http: HttpClient,
		private utility: Utility,
		private localStorage: LocalStorageService) {
	}

	public signOut() {
	}

	public initiate(params: any, originalDestination: string) {
		// clear session before setting 'originalDestination'
		this.clearExistingSession(params);

		if (originalDestination) {
			this.localStorage.set(storageKeys.originalDestination, { name: originalDestination });
		}

		console.log(`Params SsoService: ${JSON.stringify(params)}`);
		if (params.id) {
			// redirect to sso application
			window.location.href = `${this.env.ssoApplicationUrl}/sso/${params.id}?portal=true`;
		}
		else {
			this.initiateAdpSSO();
		}
	}

	// this method is not used. Sso app is redirecting to login component and passing id-token to login
	public handleResponse(params: any) {
		console.log('In SsoService.handleResponse');
	}

	public initiateAdpSSO() {
		// get adp authorization url from integration service - there is ADPAuthorizationRequestHandler endpoint
		const uri = this.env.adpIntegrationAuthorizationURLEndpoint;
		const state = this.utility.getGuid();

		this.localStorage.set(storageKeys.adpSSOState, state);

		this.http.post<any>(uri, { state: state }).toPromise()
			.then((resp: any) => {
				// nothing for now
				console.log(`Calling for authorization to '${resp}'`);
				window.location.href = resp;
			})
			.catch((err: any) => {
				// nothing for now
				console.error('Failed to call authorization');
			});
	}

	private clearExistingSession(params: any) {
		if (this.utility.getCurrentPortalId() !== params.id) {
			this.localStorage.clear();
		}
	}
}
