<div class="modal-header">
    <h4 class="modal-title">
        <i class="fa fa-lg fa-bullhorn"></i>
        <span class="ms-3">{{ alertName }}</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <i class="fa fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <div [innerHTML]="alertBody" class="splashMessageBody"></div>
</div>
<div class="modal-footer">
    <div>
        <div *ngIf="isResponseSetType(alertResponseSetTypes.yesNo)">
            <button class="btn btn-secondary" type="button"
                    (click)="decline()">{{ 'common.no' | translate}}
            </button>
            <button class="btn btn-primary"
                    type="button" (click)="accept()">{{ 'common.yes' | translate }}
            </button>
        </div>
        <div *ngIf="isResponseSetType(alertResponseSetTypes.noResponse)">
            <button class="btn btn-primary" type="button"
                    (click)="accept()">{{ 'common.close' | translate }}
            </button>
        </div>
        <div *ngIf="isResponseSetType(alertResponseSetTypes.checkBox)">
            <div class="float-start" *ngIf="acceptedClicked && !hasAgreed">
                <span class="colorRed500">{{ 'splashMessage.msgMustAgreeToSplash' | translate}}</span>
            </div>
            <span class="acknowledgeSplash">
                <input type="checkbox" id="hasAgreed" [(ngModel)]="hasAgreed">
                <label class="form-label" for="hasAgreed">{{ 'splashMessage.lblAcknowledgedSplash' | translate}}</label>
            </span>
            <button class="btn btn-primary" type="button"
                    (click)="accept()">{{ 'common.accept' | translate}}
            </button>
        </div>
        <div *ngIf="isResponseSetType(alertResponseSetTypes.ok)">
            <button class="btn btn-primary" type="button"
                    (click)="accept()">{{ 'common.ok' | translate}}
            </button>
        </div>
    </div>
</div>
