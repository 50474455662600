import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@app/providers/api.service';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { storageKeys } from '@app/app.constants';

@Injectable({
	providedIn: 'root'
})
export class TranslationService {
	constructor(private translate: TranslateService,
		private api: ApiService,
		private storageSvc: LocalStorageService) {
	}

	public reloadLang() {
		this.translate.use(this.storageSvc.get(storageKeys.preferredLanguage));
	}

	public mergePortalTranslations(lang?: string) {
		if (!lang) {
			lang = this.translate.currentLang;
		}

		return this.getPortalBrandingTerms()
			.then((_) => {
				this.translate.getTranslation(lang).subscribe((res) => {
					// looks like the translation should not be set - it is just working because it is a ref
					// this.translate.setTranslation(lang, res);
				});
			})
			.catch()
			.then();
	}

	private getPortalBrandingTerms() {
		const brandingTerms: any = this.storageSvc.get(storageKeys.brandingTerms);
		if (brandingTerms && brandingTerms.length > 0) {
			return Promise.resolve(null);
		}

		return this.api.post('selectbrandingterms', {})
			.then((res: any) => {
				this.storageSvc.set(storageKeys.brandingTerms, res);
			})
			.catch((_) => {
				this.storageSvc.set(storageKeys.brandingTerms, []);
			});
	}
}
