import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BreadCrumb } from '@app/shared/breadcrumb.model';
import { ToastrService } from 'ngx-toastr';
import { roleTypes, serviceErrorCodes } from '@app/app.constants';
import { TranslateService } from '@ngx-translate/core';

export class BaseFormComponent {
	breadcrumbs = Array<BreadCrumb>();
	isUserAccountableForTeam: boolean = false;
	isUserApproverForTeam: boolean = false;
	canVerify: boolean = false;
	canUpdate: boolean = false;
	canDelete: boolean = false;
	canSetEstablishDate: boolean = false;
	requireApproval: boolean = false;
	canCheckIn: boolean = false;
	canDecline: boolean = false;
	canApprove: boolean = false;
	iQRoles: Array<string> = [
		roleTypes.iQAdministration,
		roleTypes.iQManagement,
		roleTypes.iQTeamAccess,
		roleTypes.iQAssignmentAccess
	];

	public submitted: boolean = false;

	constructor() {
	}

	canSubmit(form: UntypedFormGroup): boolean {
		if (form.invalid) {
			this.markControlsAsTouched(form);
			return false;
		}

		return true;
	}

	markControlsAsTouched(control: AbstractControl) {
		if (control instanceof UntypedFormControl) {
			control.markAsTouched({ onlySelf: true });
			return;
		}

		if (control instanceof UntypedFormArray) {
			const array = control as UntypedFormArray;
			array.controls.forEach(ctrl => this.markControlsAsTouched(ctrl));
			return;
		}

		if (control instanceof UntypedFormGroup) {
			const form = control as UntypedFormGroup;

			Object.keys(form.controls).forEach((field) => {
				const c = form.get(field);
				this.markControlsAsTouched(c);
			});
		}
	}

	updateFormValueAndValidity(form: AbstractControl) {
		this.updateControlValueAndValidity(form);
		const f = form as UntypedFormGroup;
		f.updateValueAndValidity({ onlySelf: true });
	}

	updateControlValueAndValidity(control: AbstractControl) {
		if (control instanceof UntypedFormControl) {
			control.updateValueAndValidity({ onlySelf: true });
			return;
		}

		if (control instanceof UntypedFormArray) {
			const array = control as UntypedFormArray;
			array.controls.forEach(ctrl => this.updateControlValueAndValidity(ctrl));
			return;
		}

		if (control instanceof UntypedFormGroup) {
			const form = control as UntypedFormGroup;

			Object.keys(form.controls).forEach((field) => {
				const c = form.get(field);
				this.updateControlValueAndValidity(c);
			});
		}
	}

	public appendBreadcrumb(name: string, state?: string) {
		const breadcrumb: BreadCrumb = new BreadCrumb(name, state);

		this.breadcrumbs.push(breadcrumb);
	}

	public processLoadingErrorToastr(error: any, name: string, toastrService: ToastrService, translateService: TranslateService) {
		let errorMsg = '';
		switch (error.errorCode) {
			case serviceErrorCodes.notFound:
				errorMsg = translateService.instant('errorMessages.notFoundError', { name: name });
				break;
			default:
				errorMsg = translateService.instant('errorMessages.loadingError', { name: name });
				break;
		}

		toastrService.error(errorMsg);
	}
}
