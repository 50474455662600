import { Injectable } from '@angular/core';
import { ApiService } from '@app/providers/api.service';
import { IncidentType } from '@app/shared/incidents/incidentType.model';
import * as _ from 'lodash';
import { SendMessage } from '@app/shared/incidents/sendMessage.model';
import { Utility } from '@app/providers/utility';
import { messageResponseTypes, messageTypeIds, permissionFeatures, securityModuleKeys } from '@app/app.constants';
import { PendingMessage } from '@app/shared/incidents/pendingMessage.model';
import { Subject } from 'rxjs';
import { Message } from '@app/shared/incidents/message.model';
import { Incident } from '@app/shared/incidents/incident.model';
import { ConferenceCall } from '@app/shared/incidents/conferenceCall.model';
import { TranslateService } from '@ngx-translate/core';
import { SelectFollowUpMessagingUsers } from '@app/shared/incidents/SelectFollowUpMessagingUsers.model';
import { MessageFilter } from '@app/shared/training/message-filter.model';
import { CallInAnnouncement } from '@app/shared/call-in-announcement.model';
import { SplashMessage } from '@app/shared/incidents/splashMessage.model';
import { CustomResponse } from '@app/shared/incidents/customResponse.model';
import { PermissionService } from '@app/providers/permission.service';
import { incidentModuleState } from '@app/app.enum';
import { MessageTranslateResult, RawMessage } from '@app/shared/translation.model';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { IncidentRetrospective } from '@app/shared/incidents/incident-retrospective.model';

@Injectable({
	providedIn: 'root'
})
export class IncidentService {
	// participant reload request event broadcasting
	private _reloadParticipant = new Subject<{ messageId: string, userId: string }>();
	reloadParticipant = this._reloadParticipant.asObservable();
	// participant latest response updated
	private _participantLatestResponseUpdated = new Subject<{ messageId: string, userId: string, latestResponse: any }>();
	participantLatestResponseUpdated = this._participantLatestResponseUpdated.asObservable();
	// message reload request event broadcasting
	private _reloadMessage = new Subject<Message>();
	reloadMessage = this._reloadMessage.asObservable();
	// message or participant expanded event broadcasting
	private _messageOrParticipantExpandedStateChange = new Subject<{ messageId: string, userId: string, state: boolean }>();
	messageOrParticipantExpandedStateChange = this._messageOrParticipantExpandedStateChange.asObservable();
	// incident is loading follow up message for send message flow
	private _loadingFollowUpMessageUsersStatus = new Subject<{ incidentId: string, isLoadingFollowUpMessageUsers: boolean }>();
	loadingFollowUpMessageUsersStatus = this._loadingFollowUpMessageUsersStatus.asObservable();
	// incident communication to users
	private _incidentCommunication = new Subject<{ activeMessageTypeId: string, section: string, communicationTypes: { conferenceCalls: boolean, sms: boolean, emails: boolean } }>();
	incidentCommunication = this._incidentCommunication.asObservable();

	constructor(public api: ApiService,
		private utility: Utility,
		public translateService: TranslateService,
		private permissionService: PermissionService,
		private bcService: BusinessContinuityService) {
	}

	get canAccessAGAlertsHistory(): boolean {
		return this.incidentModuleState === incidentModuleState.agilityAlerts
		  && this.permissionService.hasPermission(permissionFeatures.agilityAlerts.key, [permissionFeatures.agilityAlerts.actions.newIncident, permissionFeatures.agilityAlerts.actions.incidentHistory]);
	}

	get canAccessNewAGAlert(): boolean {
		return this.incidentModuleState === incidentModuleState.agilityAlerts
		  && this.permissionService.hasPermission(permissionFeatures.agilityAlerts.key, permissionFeatures.agilityAlerts.actions.newIncident);
	}

	get canAccessIncidentManagementHistory(): boolean {
		return this.incidentModuleState === incidentModuleState.incidentManagement
		  && this.permissionService.hasPermission(permissionFeatures.incidentManagement.key, permissionFeatures.incidentManagement.actions.incidentManagement);
	}

	get incidentModuleState(): incidentModuleState {
		if (this.permissionService.hasModule(securityModuleKeys.agilityAlerts)) {
			return this.permissionService.hasModule(securityModuleKeys.incidentManagement) ? incidentModuleState.both : incidentModuleState.agilityAlerts;
		}
		else {
			return this.permissionService.hasModule(securityModuleKeys.incidentManagement) ? incidentModuleState.incidentManagement : incidentModuleState.none;
		}
	}

	get isExercise(): boolean {
		return this.bcService.isExercise;
	}

	get exerciseId(): string {
		return this.bcService.exerciseId;
	}

	broadcastReloadParticipant(messageParticipant: { messageId: string, userId: string }) {
		this._reloadParticipant.next(messageParticipant);
	}

	broadcastParticipantLatestResponseUpdated(messageId: string, userId: string, latestResponse: any) {
		this._participantLatestResponseUpdated.next({
			messageId: messageId,
			userId: userId,
			latestResponse: latestResponse
		});
	}

	broadcastReloadMessage(message: Message) {
		this._reloadMessage.next(message);
	}

	broadcastMessageOrParticipantExpandedStateChange(messageId: string, userId: string, state: boolean) {
		this._messageOrParticipantExpandedStateChange.next({ messageId: messageId, userId: userId, state: state });
	}

	broadcastIncidentCommunication(incidentCommunication: { activeMessageTypeId: string, section: string, communicationTypes: { conferenceCalls: boolean, sms: boolean, emails: boolean } }) {
		this._incidentCommunication.next(incidentCommunication);
	}

	getMessagingIncidentListIncidentTypes() {
		let incidentTypes: Array<IncidentType> = [];

		const promise = new Promise((resolve, reject) => {
			this.api.post('getmessagingincidentlistincidenttypes', {}).then((res: any) => {
				incidentTypes = res;
				incidentTypes = _.orderBy(incidentTypes, [incidentType => incidentType.incidentTypeName.toLowerCase()], ['asc']);

				// move Not Set to 1st
				const indexOfNotSet = _.findIndex(incidentTypes, { incidentTypeName: 'Not Set' });

				if (indexOfNotSet) {
					const notSet = incidentTypes.splice(indexOfNotSet, 1);
					incidentTypes.unshift(notSet[0]);
				}

				// move Other to last
				const indexOfOther = _.findIndex(incidentTypes, { incidentTypeName: 'Other' });

				if (indexOfOther) {
					const other = incidentTypes.splice(indexOfOther, 1);
					incidentTypes.push(other[0]);
				}

				resolve(incidentTypes);
			});
		});

		return promise;
	}

	translateMessages(jobId: string, incidentId: string, messages: Array<RawMessage>): Promise<{ jobId: string, messages: Array<MessageTranslateResult> }> {
		const data = {
			parentEntityId: this.utility.getCurrentPortalId(),
			messageTranslateJobId: jobId,
			incidentId: incidentId,
			messages: messages
		};

		return this.api.post('translatemessages', data) as any;
	}

	callToRecordSendMessage(sendMessage: SendMessage) {
		const data = {
			parentEntityId: this.utility.getCurrentPortalId(),
			jobId: sendMessage.voiceMailRecordingId,
			phoneNumber: sendMessage.callToRecordPhonenumber,
			incidentId: sendMessage.incidentId
		};

		return this.api.post('calltorecordmessage', data);
	}

	getCallToRecordSendMessage(sendMessage: SendMessage) {
		return this.api.post('getrecordedmessage', {
			parentEntityId: this.utility.getCurrentPortalId(),
			jobId: sendMessage.voiceMailRecordingId
		});
	}

	getMessageParticipantUsers(recipientRoleBundleIds,
		recipientLocationIds,
		recipientDepartmentIds,
		recipientTenantIds,
		recipientGroupIds,
		geofences,
		additionalDepartmentIds,
		additionalGroupIds,
		additionalTenantIds,
		additionalLocationIds,
		additionalRoleBundleIds,
		sendToAllUsers: boolean,
		includeUserAddress: boolean,
		includeUserGeolocation: boolean,
		includeUserLocation: boolean,
		includePersonalContactEmail?,
		includePersonalContactPhone?,
		includeOfficeContactInfo?) {
		return this.api.post('getmessageparticipantusers', {
			recipientRoleBundleIds: recipientRoleBundleIds,
			recipientLocationIds: recipientLocationIds,
			recipientDepartmentIds: recipientDepartmentIds,
			recipientTenantIds: recipientTenantIds,
			recipientGroupIds: recipientGroupIds,
			geofences: geofences,
			additionalDepartmentIds: additionalDepartmentIds,
			additionalGroupIds: additionalGroupIds,
			additionalTenantIds: additionalTenantIds,
			additionalLocationIds: additionalLocationIds,
			additionalRoleBundleIds: additionalRoleBundleIds,
			sendToAllUsers: sendToAllUsers,
			includeUserAddress: includeUserAddress,
			includeUserGeolocation: includeUserGeolocation,
			includeUserLocation: includeUserLocation,
			includePersonalContactEmail: includePersonalContactEmail,
			includePersonalContactPhone: includePersonalContactPhone,
			includeOfficeContactInfo: includeOfficeContactInfo
		});
	}

	getPortalUserResponseTypeDefaults() {
		return this.api.post('getportaluserresponsetypedefaults', {});
	}

	savePortalUserResponseTypeDefaults(data: any[]) {
		return this.api.post('saveportaluserresponsetypedefaults', { userResponseTypeDefaults: data });
	}

	saveScheduledMessage(sendMessage: SendMessage) {
		sendMessage.parentEntityId = this.utility.getCurrentPortalId();
		sendMessage.scheduledMessageId = sendMessage.scheduledMessageId || this.utility.getGuid();

		return this.api.post('savescheduledmessage', sendMessage);
	}

	saveScheduledConferenceCall(conferenceCall: ConferenceCall) {
		conferenceCall.parentEntityId = this.utility.getCurrentPortalId();
		conferenceCall.scheduledConferenceCallId = conferenceCall.scheduledConferenceCallId || this.utility.getGuid();
		conferenceCall.incidentId = conferenceCall.incidentId || this.utility.getGuid();

		return this.api.post('savescheduledconferencecall', conferenceCall);
	}

	getScheduledConferenceCalls() {
		const parentEntityId = this.utility.getCurrentPortalId();

		return this.api.post('getscheduledconferencecalls', { parentEntityId: parentEntityId });
	}

	getScheduledConferenceCall(scheduledConferenceCallId: string) {
		const parentEntityId = this.utility.getCurrentPortalId();

		return this.api.post('getscheduledconferencecall', {
			parentEntityId: parentEntityId,
			scheduledConferenceCallId: scheduledConferenceCallId
		});
	}

	deleteScheduledConferenceCall(scheduledConferenceCallId: string) {
		const parentEntityId = this.utility.getCurrentPortalId();

		return this.api.post('deletescheduledconferencecall', {
			parentEntityId: parentEntityId,
			scheduledConferenceCallId: scheduledConferenceCallId
		});
	}

	sendMessage(sendMessage: SendMessage) {
		return this.api.post('sendmessage', sendMessage);
	}

	getMessagingIncidentDetailed(incidentId: string) {
		return this.api.post('getmessagingincidentdetailed', { incidentId: incidentId });
	}

	deleteScheduledMessage(scheduledMessageId: string) {
		return this.api.post('deletescheduledmessage', {
			scheduledMessageId: scheduledMessageId
		});
	}

	getScheduledMessage(incidentId: string, scheduledMessageId: string) {
		return this.api.post('getscheduledmessage', {
			incidentId: incidentId,
			scheduledMessageId: scheduledMessageId
		});
	}

	saveMessageTemplate(sendMessage: SendMessage) {
		return this.api.post('savemessagingtemplate', sendMessage);
	}

	getMessageTemplateList() {
		return this.api.post('getmessagingtemplatelist', {});
	}

	getMessageTemplates(pinnedOnly: boolean) {
		return this.api.post('getmessagingtemplates', {
			pinnedOnly: pinnedOnly
		});
	}

	getMessagingTemplate(messagingTemplateId: string) {
		return this.api.post('getmessagingtemplate', {
			messagingTemplateId: messagingTemplateId
		});
	}

	deleteMessagingTemplate(messagingTemplateId: string) {
		return this.api.post('deletemessagingtemplate', {
			messagingTemplateId: messagingTemplateId
		});
	}

	saveMessageTemplatePin(messagingTemplateId: string) {
		return this.api.post('savemessagetemplatepin', {
			messagingTemplateId: messagingTemplateId
		});
	}

	deleteMessageTemplatePin(messagingTemplateId: string) {
		return this.api.post('deletemessagetemplatepin', {
			messagingTemplateId: messagingTemplateId
		});
	}

	getIncidents(maxRecords?: number, includeArchived?: boolean, activeOnly?: boolean) {
		return this.api.post('getmessagingincidentlist', {
			maxRecords: maxRecords ? maxRecords : 0,
			includeArchived: includeArchived ? 1 : 0,
			activeOnly: activeOnly
		});
	}

	getIncidentsPaged(searchPhrase: string, pageNumber: number, pageSize: number, includeArchived: boolean, startDate?: Date, endDate?: Date, activeOnly?: boolean) {
		return this.api.post('getmessagingincidentlist', {
			searchPhraseAsBase64: this.utility.base64Encode(searchPhrase),
			pageNumber: pageNumber,
			pageSize: pageSize,
			includeArchived: includeArchived ? 1 : 0,
			startDate: startDate,
			endDate: endDate,
			activeOnly: activeOnly
		});
	}

	selectIncidents(incidentName: string,
		incidentDescription: string,
		incidentTypes: Array<string>,
		planActivationStates: Array<number>,
		incidentStates: Array<string>,
		startDate: Date,
		endDate: Date,
		sortColumn: string,
		sortDesc: boolean,
		pageNumber: number,
		pageSize: number,
		maxRecords: number = 0,
		isExercise: boolean = null) {
		return this.api.post('selectIncidents', {
			incidentNameAsBase64: this.utility.base64Encode(incidentName),
			incidentDescriptionAsBase64: this.utility.base64Encode(incidentDescription),
			incidentTypes: incidentTypes ? incidentTypes : [],
			planActivationStates: planActivationStates,
			incidentStates: incidentStates ? incidentStates : [],
			startDate: startDate,
			endDate: endDate,
			sortColumn: sortColumn,
			sortDesc: sortDesc,
			pageNumber: pageNumber,
			pageSize: pageSize,
			maxRecords: maxRecords,
			forExercise: isExercise == null ? this.isExercise : isExercise
		});
	}

	exportIncidents(incidentName: string,
		incidentDescription: string,
		incidentTypes: Array<string>,
		planActivationStates: Array<number>,
		incidentStates: Array<string>,
		startDate: Date,
		endDate: Date,
		sortColumn: string,
		sortDesc: boolean,
		pageNumber: number,
		pageSize: number,
		exerciseMode: boolean) {
		return this.api.post('exportIncidents', {
			incidentNameAsBase64: this.utility.base64Encode(incidentName),
			incidentDescriptionAsBase64: this.utility.base64Encode(incidentDescription),
			incidentTypes: incidentTypes ? incidentTypes : [],
			planActivationStates: planActivationStates,
			incidentStates: incidentStates ? incidentStates : [],
			startDate: startDate,
			endDate: endDate,
			sortColumn: sortColumn,
			sortDesc: sortDesc,
			pageNumber: pageNumber,
			pageSize: pageSize,
			forExercise: exerciseMode
		}, true);
	}

	selectIncident(incidentId, excludeFilters?: boolean) {
		return this.api.post('selectIncident', { incidentId: incidentId, excludeFilters: excludeFilters });
	}

	getIncidentDocuments(incidentId) {
		return this.api.post('getincidentdocuments', { incidentId: incidentId });
	}

	checkIncidentDocumentStatus(incidentId, fileNameAsBase64) {
		return this.api.post('checkincidentdocumentstatus', {
			incidentId: incidentId,
			fileNameAsBase64: fileNameAsBase64
		});
	}

	createOrUpdateIncidentDocument(document) {
		return this.api.post('createorupdateincidentdocument', document);
	}

	deleteIncidentDocument(document) {
		return this.api.post('deleteincidentdocument', document);
	}

	getIncidentDocumentAsStream(document) {
		return this.api.post('getincidentdocumentasstream', document, true);
	}

	archiveIncident(incidentId, doArchive) {
		return this.api.post('archiveincident', {
			incidentId: incidentId,
			isArchived: doArchive,
			parentEntityId: this.utility.getCurrentPortalId()
		});
	}

	updateMessagingIncidentDetails(incidentDetails) {
		incidentDetails.parentEntityId = this.utility.getCurrentPortalId();
		incidentDetails.isExercise = this.isExercise;
		incidentDetails.exerciseId = this.exerciseId;
		return this.api.post('updatemessagingincidentdetails', incidentDetails);
	}

	getIncidentNotes(incidentId: string) {
		return this.api.post('getmessagingincidentnotes', { incidentId: incidentId });
	}

	deleteIncidentNote(incidentId: string, incidentNoteId: string) {
		return this.api.post('deleteincidentnote', { incidentId: incidentId, incidentNoteId: incidentNoteId });
	}

	saveIncidentNote(note: any) {
		return this.api.post('updatemessagingincidentnotes', note);
	}

	getAllMessagesByIncidentId(incidentId) {
		return this.api.post('selectalljobsbyincident', { incidentId: incidentId });
	}

	getJobsByMessage(messageId: string) {
		return this.api.post('selectjobsbymessage', { messageId: messageId });
	}

	getMessageSummary(messageId,
		filterLogEnable,
		filterLogTypeIds,
		includeParticipants: boolean,
		pageNumber: number,
		pageSize: number,
		filters?: MessageFilter,
		filterResponseTypeId?: string,
		searchPhrase?: string) {
		if (filters == null) {
			filters = new MessageFilter();
		}
		return this.api.post('getjobmessagesummary', {
			messageId: messageId,
			filterLogEnable: filterLogEnable,
			filterLogTypeIds: filterLogTypeIds,
			includeParticipants: includeParticipants,
			pageSize: pageSize,
			pageNumber: pageNumber,
			filters: filters,
			filterResponseTypeId: filterResponseTypeId,
			searchPhraseAsBase64: this.utility.base64Encode(searchPhrase)
		});
	}

	getMessageParticipantLogs(incidentId, messageId, userId, filterLogEnable, filterLogTypeIds) {
		return this.api.post('getmessageparticipantlogs', {
			incidentId: incidentId,
			participantUserId: userId,
			filterLogEnable: filterLogEnable,
			filterLogTypeIds: filterLogTypeIds,
			messageId: messageId
		});
	}

	getMessageParticipantsLatestLogs(incidentId,
		filterLogEnable,
		filterLogTypeIds,
		pageNumber: number,
		pageSize: number,
		filters?: MessageFilter,
		filterResponseTypeId?: string,
		searchPhrase?: string) {
		if (filters == null) {
			filters = new MessageFilter();
		}
		return this.api.post('getlatestmessagingparticpantlogsbyincidentid', {
			incidentId: incidentId,
			filterLogEnable: filterLogEnable,
			filterLogTypeIds: filterLogTypeIds,
			pageSize: pageSize,
			pageNumber: pageNumber,
			filters: filters,
			filterResponseTypeId: filterResponseTypeId,
			searchPhraseAsBase64: this.utility.base64Encode(searchPhrase)
		});
	}

	getMessagingIncidentEvents(incidentId) {
		return this.api.post('getmessagingincidentevents', {
			incidentId: incidentId
		});
	}

	updateMessageParticipantStatus(incidentId: string, messageId: string, userId: string, userResponseTypeGuid: string) {
		return this.api.post('updatemessageparticipantstatus', {
			incidentId: incidentId,
			messageId: messageId,
			participantUserId: userId,
			userResponseTypeId: userResponseTypeGuid
		});
	}

	getJobTypeLeftIcon(messageTypeId: string) {
		switch (messageTypeId) {
			case messageTypeIds.sms:
				return 'fa fa-comment';
			case messageTypeIds.emails:
				return 'fa fa-envelope-o';
			case messageTypeIds.voiceMails:
				return 'fa fa-phone';
			case messageTypeIds.conferenceCalls:
				return 'pp-conference-call';
			case messageTypeIds.pushMessages:
				return 'fa fa-mobile';
			case messageTypeIds.desktop:
				return 'fa fa-desktop';
			default:
				return '';
		}
	}

	getJobTypeTooltip(messageTypeId: string) {
		switch (messageTypeId) {
			case messageTypeIds.sms:
				return this.translateService.instant('sendMessage.sms');
			case messageTypeIds.emails:
				return this.translateService.instant('sendMessage.email');
			case messageTypeIds.voiceMails:
				return this.translateService.instant('sendMessage.voice');
			case messageTypeIds.conferenceCalls:
				return this.translateService.instant('conferenceCall.conferenceCall');
			case messageTypeIds.pushMessages:
				return this.translateService.instant('sendMessage.pushMessage');
			case messageTypeIds.desktop:
				return this.translateService.instant('sendMessage.desktop');
			default:
				return '';
		}
	}

	sanitizeResponseMessage(message: string): string {
		if (!message) {
			return message;
		}

		const startIndx = message.indexOf('<http');
		if (startIndx < 0) {
			return message;
		}
		const endIndx = message.indexOf('>', startIndx);
		if (endIndx < 0 || endIndx > message.length - 2) {
			return message;
		}
		return message.slice(0, startIndx) + message.slice(endIndx + 1);
	}

	savePendingMessage(pendingMessage: PendingMessage, sendMessage: SendMessage) {
		// Since this is saving/updating a new Pending Message
		pendingMessage.createdById = this.utility.getUserId();
		pendingMessage.lastModifiedBy = this.utility.getUserId();

		// sendMessage & pendingMessage MUST be in this order
		const request = Object.assign(sendMessage, pendingMessage);
		return this.api.post('savependingmessage', request);
	}

	getPendingMessageList() {
		return this.api.post('getpendingmessagelist', {});
	}

	getPendingMessage(pendingMessageId: string) {
		return this.api.post('getpendingmessage', { pendingMessageId: pendingMessageId });
	}

	deletePendingMessage(pendingMessageId: string) {
		return this.api.post('deletependingmessage', { pendingMessageId: pendingMessageId });
	}

	getEntitySettingsList() {
		return this.api.post('getentitysettingslist', {});
	}

	selectFollowUpMessagingUsers(selectFollowUpMessagingUsers: SelectFollowUpMessagingUsers) {
		this._loadingFollowUpMessageUsersStatus.next({
			incidentId: selectFollowUpMessagingUsers.incidentId,
			isLoadingFollowUpMessageUsers: true
		});
		selectFollowUpMessagingUsers.parentEntityId = this.utility.getCurrentPortalId();

		if (selectFollowUpMessagingUsers.responseType === messageResponseTypes.limited) {
			selectFollowUpMessagingUsers.responseCodes = selectFollowUpMessagingUsers.limitedTypes.join();
			selectFollowUpMessagingUsers.responseCodesAsBase64 = this.utility.base64Encode(selectFollowUpMessagingUsers.limitedTypes.join());
		}
		else {
			selectFollowUpMessagingUsers.responseCodesAsBase64 = '';
		}

		return this.api.post('selectfollowupmessagingusers', selectFollowUpMessagingUsers).then((res) => {
			this._loadingFollowUpMessageUsersStatus.next({
				incidentId: selectFollowUpMessagingUsers.incidentId,
				isLoadingFollowUpMessageUsers: false
			});
			return res;
		});
	}

	getIncidentUserResponseTypes(incidentId: string) {
		return this.api.post('getmessagingincidentuserresponsetypes', { incidentId: incidentId });
	}

	updateMessagingIncident(incident: Incident) {
		incident.isExercise = this.isExercise;
		incident.exerciseId = this.exerciseId;
		return this.api.post('updatemessagingincident', incident);
	}

	getPublicAlertInfoMessage(publicAlertInfoId: string) {
		return this.api.post('getpublicalertinfomessage', { publicAlertInfoId: publicAlertInfoId });
	}

	sendConferenceCall(conferenceCall: ConferenceCall) {
		conferenceCall.parentEntityId = this.utility.getCurrentPortalId();

		// additionalMessage encode
		if (conferenceCall.sendDetailToSMS && conferenceCall.additionalSmsMessage) {
			conferenceCall.additionalSmsMessageAsBase64 = this.utility.base64Encode(conferenceCall.additionalSmsMessage);
		}
		else {
			delete conferenceCall.additionalSmsMessageAsBase64;
			delete conferenceCall.additionalSmsMessage;
		}
		if (conferenceCall.sendDetailToEmail && conferenceCall.additionalEmailBody) {
			conferenceCall.additionalEmailBodyAsBase64 = this.utility.base64Encode(conferenceCall.additionalEmailBody);
		}
		else {
			delete conferenceCall.additionalEmailBodyAsBase64;
			delete conferenceCall.additionalEmailBody;
		}

		return this.api.post('sendconferencecall', conferenceCall);
	}

	cancelResendMessage(message: Message) {
		return this.api.post('cancelresendformessage', message);
	}

	getIncidentTypeTriggers() {
		return this.api.post('getincidenttypetriggers', {});
	}

	saveIncidentTypeTriggers(triggersToSave: Array<any>, triggersToDelete: Array<any>) {
		const parms = {
			triggersToSave: triggersToSave,
			triggersToDelete: triggersToDelete
		};
		return this.api.post('saveincidenttypetriggers', parms);
	}

	getCrisisTeamLite(entityId: string) {
		return this.api.post('getcrisisteamlite', {
			entityId: entityId
		});
	}

	getCrisisTeamsLite() {
		return this.api.post('getcrisisteamslite', null);
	}

	respondToAlert(alertId: string, responseTypeId: string) {
		const request = {
			alertId: alertId,
			responseTypeId: responseTypeId
		};
		return this.api.post('respondtoalert', request);
	}

	getActiveAlerts() {
		return this.api.post('getactivealerts', null);
	}

	getCallinAnnouncements() {
		return this.api.post('getannouncements', { parentEntityId: this.utility.getCurrentPortalId() });
	}

	getCallinAnnouncement(announcementId: string) {
		return this.api.post('getannouncement', {
			parentEntityId: this.utility.getCurrentPortalId(),
			announcementId: announcementId
		});
	}

	getCallinAnnouncementHierarchy() {
		return this.api.post('selectcallinannouncementsoptions', {});
	}

	saveCallinAnnouncementOption(option: any) {
		return this.api.post('savecallinannouncementsoption', { option: option });
	}

	deleteCallinAnnouncementOption(optionId: string, parentOptionId) {
		return this.api.post('deletecallinannouncementsoption', {
			announcementOptionId: optionId,
			parentAnnouncementOptionId: parentOptionId
		});
	}

	deleteAllCallinAnnouncementOptions() {
		return this.api.post('deleteallcallinannouncementsoptions', {});
	}

	copyCallinAnnouncementOption(optionId: string, parentOptionId: string, copySubHierarchy: boolean) {
		return this.api.post('copycallinannouncementoptions', {
			announcementOptionId: optionId,
			parentAnnouncementOptionId: parentOptionId,
			copySubHierarchy: copySubHierarchy
		});
	}

	getAlertsByEntityId() {
		return this.api.post('getalertsbyentityid', { entityId: this.utility.getCurrentPortalId() });
	}

	getAlertWithDetails(alertId) {
		return this.api.post('getalertwithdetails', { alertId: alertId });
	}

	getCrisisteams() {
		return this.api.post('getcrisisteams', {});
	}

	getcrisisteam(locatonId: string) {
		return this.api.post('getcrisisteam', { entityId: locatonId });
	}

	updateAlertStatus(alertId, newStatusTypeId) {
		return this.api.post('updatealertstatus', {
			alertId: alertId,
			alertStateTypeId: newStatusTypeId,
			updatingUserId: this.utility.getUserId()
		});
	}

	updateSplashMessage(splashMessage: SplashMessage) {
		return this.api.post('updatealertdetails', splashMessage);
	}

	deleteAlert(alertId: string) {
		return this.api.post('deletealert', { alertId: alertId });
	}

	getMessageParticipantUserIds(recipientRoleBundleIds,
		recipientLocationIds,
		recipientDepartmentIds,
		recipientTenantIds,
		recipientGroupIds,
		geofences,
		additionalDepartmentIds,
		additionalGroupIds,
		additionalTenantIds,
		additionalLocationIds,
		additionalRoleBundleIds,
		sendToAllUsers: boolean,
		includeUserAddress: boolean,
		includeUserGeolocation: boolean,
		includeUserLocation: boolean,
		includePersonalContactEmail?,
		includePersonalContactPhone?,
		includeOfficeContactInfo?) {
		return this.api.post('GetMessageParticipantUserIds', {
			recipientRoleBundleIds: recipientRoleBundleIds,
			recipientLocationIds: recipientLocationIds,
			recipientDepartmentIds: recipientDepartmentIds,
			recipientTenantIds: recipientTenantIds,
			recipientGroupIds: recipientGroupIds,
			geofences: geofences,
			additionalDepartmentIds: additionalDepartmentIds,
			additionalGroupIds: additionalGroupIds,
			additionalTenantIds: additionalTenantIds,
			additionalLocationIds: additionalLocationIds,
			additionalRoleBundleIds: additionalRoleBundleIds,
			sendToAllUsers: sendToAllUsers,
			includeUserAddress: includeUserAddress,
			includeUserGeolocation: includeUserGeolocation,
			includeUserLocation: includeUserLocation,
			includePersonalContactEmail: includePersonalContactEmail,
			includePersonalContactPhone: includePersonalContactPhone,
			includeOfficeContactInfo: includeOfficeContactInfo
		});
	}

	getMessageParticipantsUsersCount(recipientRoleBundleIds,
		recipientLocationIds,
		recipientDepartmentIds,
		recipientTenantIds,
		recipientGroupIds,
		geofences,
		additionalDepartmentIds,
		additionalGroupIds,
		additionalTenantIds,
		additionalLocationIds,
		additionalRoleBundleIds,
		sendToAllUsers: boolean,
		includeUserAddress: boolean,
		includeUserGeolocation: boolean,
		includeUserLocation: boolean,
		includePersonalContactEmail?,
		includePersonalContactPhone?,
		includeOfficeContactInfo?) {
		return this.api.post('getmessageparticipantuserscount', {
			recipientRoleBundleIds: recipientRoleBundleIds,
			recipientLocationIds: recipientLocationIds,
			recipientDepartmentIds: recipientDepartmentIds,
			recipientTenantIds: recipientTenantIds,
			recipientGroupIds: recipientGroupIds,
			geofences: geofences,
			additionalDepartmentIds: additionalDepartmentIds,
			additionalGroupIds: additionalGroupIds,
			additionalTenantIds: additionalTenantIds,
			additionalLocationIds: additionalLocationIds,
			additionalRoleBundleIds: additionalRoleBundleIds,
			sendToAllUsers: sendToAllUsers,
			includeUserAddress: includeUserAddress,
			includeUserGeolocation: includeUserGeolocation,
			includeUserLocation: includeUserLocation,
			includePersonalContactEmail: includePersonalContactEmail,
			includePersonalContactPhone: includePersonalContactPhone,
			includeOfficeContactInfo: includeOfficeContactInfo
		});
	}

	GetLatestMessagingParticipantSummary(incidentId: string) {
		return this.api.post('GetLatestMessagingParticipantSummary', {
			incidentId: incidentId
		});
	}

	getIncidentWatcher(incidentId) {
		return this.api.post('GetIncidentWatcher', {
			incidentId: incidentId
		});
	}

	watchIncident(incidentId) {
		return this.api.post('WatchIncident', {
			incidentId: incidentId
		});
	}

	getMessageParticipantUsersByPage(recipientRoleBundleIds,
		recipientLocationIds,
		recipientDepartmentIds,
		recipientTenantIds,
		recipientGroupIds,
		geofences,
		additionalDepartmentIds,
		additionalGroupIds,
		additionalTenantIds,
		additionalLocationIds,
		additionalRoleBundleIds,
		sendToAllUsers: boolean,
		includeUserAddress: boolean,
		includeUserGeolocation: boolean,
		includeUserLocation: boolean,
		includePersonalContactEmail?,
		includePersonalContactPhone?,
		includeOfficeContactInfo?,
		pageNumber?: number,
		pageSize?: number,
		searchPhrase?: string) {
		return this.api.post('getmessageparticipantusersbypage', {
			recipientRoleBundleIds: recipientRoleBundleIds,
			recipientLocationIds: recipientLocationIds,
			recipientDepartmentIds: recipientDepartmentIds,
			recipientTenantIds: recipientTenantIds,
			recipientGroupIds: recipientGroupIds,
			geofences: geofences,
			additionalDepartmentIds: additionalDepartmentIds,
			additionalGroupIds: additionalGroupIds,
			additionalTenantIds: additionalTenantIds,
			additionalLocationIds: additionalLocationIds,
			additionalRoleBundleIds: additionalRoleBundleIds,
			sendToAllUsers: sendToAllUsers,
			includeUserAddress: includeUserAddress,
			includeUserGeolocation: includeUserGeolocation,
			includeUserLocation: includeUserLocation,
			includePersonalContactEmail: includePersonalContactEmail,
			includePersonalContactPhone: includePersonalContactPhone,
			includeOfficeContactInfo: includeOfficeContactInfo,
			pageNumber: pageNumber,
			pageSize: pageSize,
			searchPhraseAsBase64: this.utility.base64Encode(searchPhrase)
		});
	}

	getMessageProcessingStatus(incidentId: string): Promise<any> {
		return this.api.post('selectmessageprocessingstatus', {
			incidentId: incidentId
		});
	}

	deactivateAnnouncement(announcementId: string): Promise<any> {
		return this.api.post('deactivateannouncement', {
			parentEntityId: this.utility.getCurrentPortalId(),
			announcementId: announcementId
		});
	}

	activateAnnouncement(announcementId: string): Promise<any> {
		return this.api.post('activateannouncement', {
			parentEntityId: this.utility.getCurrentPortalId(),
			announcementId: announcementId
		});
	}

	callToRecordAnnouncement(callinAnnoucement: CallInAnnouncement): Promise<any> {
		return this.api.post('calltorecordannouncement', {
			parentEntityId: this.utility.getCurrentPortalId(),
			announcementId: callinAnnoucement.announcementId,
			description: callinAnnoucement.description,
			phoneNumber: callinAnnoucement.phoneNumber
		});
	}

	getRecordedAnnouncement(announcementId: string): Promise<any> {
		return this.api.post('getrecordedannouncement', {
			parentEntityId: this.utility.getCurrentPortalId(),
			announcementId: announcementId
		});
	}

	deleteAnnouncement(announcementId: string): Promise<any> {
		return this.api.post('deleteannouncement', {
			parentEntityId: this.utility.getCurrentPortalId(),
			announcementId: announcementId
		});
	}

	saveAnnouncement(announcement: CallInAnnouncement) {
		return this.api.post('saveannouncement', {
			parentEntityId: this.utility.getCurrentPortalId(),
			announcementId: announcement.announcementId,
			description: announcement.description,
			textToSpeech: announcement.textToSpeech,
			audioUrl: announcement.audioUrl
		});
	}

	createAlert(alert: SplashMessage): Promise<any> {
		alert.alertId = alert.alertId ? alert.alertId : this.utility.getGuid();
		return this.api.post('createalert', alert);
	}

	compileResponsesAsString(responseOptions: Array<CustomResponse>): string {
		let responsesAsString: string = '';

		responseOptions.forEach((response, index) => {
			const responseToken = response.definition && response.definition.length > 0 ? response.key + '=' + response.definition : response.key;
			responsesAsString = responsesAsString.concat(responseToken);
			// add ', '
			if (index !== responseOptions.length - 1) {
				responsesAsString = responsesAsString.concat(', ');
			}
		});

		return responsesAsString;
	}

	selectIncidentRetrospective(incidentId: string): Promise<any> {
		return this.api.post('selectincidentretrospective', {
			incidentId: incidentId
		});
	}

	saveIncidentRetrospective(retrospective: IncidentRetrospective): Promise<any> {
		return this.api.post('saveincidentretrospective', {
			retrospective: retrospective
		});
	}

	generateAuditReport(incidentId: string, utcOffset: number): Promise<Blob> {
		return this.api.post('generateincidentretrospectiveauditreport', {
			incidentId: incidentId,
			utcOffset: utcOffset
		}, true) as Promise<Blob>;
	}

	// END INCIDENT MANAGER
}
