import { Audit } from '@app/shared/audit.model';

export class ProcessCriticalDate extends Audit {
	processCriticalDateId: string;
	processCriticalDateName: string;
	processCriticalDateNameAsBase64: string = '';
	criticalDateFrequencyTypeId: string;
	criticalDateTypeId: string;
	beginsOnDateAbsolute: AbsoluteDateModel;
	rangeDaysBefore: number;
	rangeDaysAfter: number;
	processCriticalDateStatusTypeId: string;
	templateProcessCriticalDateId: string;
	dueDate: Date;
	dueDateAbsolute: AbsoluteDateModel;

	// front end display purpose
	isRenaming: boolean = false;
	alias: string = null;
	isTemplate: boolean = false;
}

export class AbsoluteDateModel {
	year: number;
	month: number;
	day: number;

	constructor(year: number, month: number, day: number) {
		this.year = year;
		this.month = month;
		this.day = day;
	}
}
