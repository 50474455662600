export enum bcStrategyResourceType {
	strategy,
	location,
	vendor,
	employee,
	assignee,
	equipment,
	technology,
	instructions
}

export enum bcStrategyChangeEventType {
	add,
	remove,
	update
}

export enum SortDirection {
	Ascending = 1,
	Descending = -1,
	Neutral = 0
}

export enum MoveIqResourceToTeamModalStep {
	selectingTeam,
	selectingTeamMember,
	selectingRecoverySite
}

export enum TeamsViewType {
	orgChart,
	tree,
	treeTable
}

export enum OptionsViewType {
	orgChart,
	tree,
	treeTable
}

export enum BulkUploadTypes {
	Unknown,
	Department,
	Location,
	User,
	Tenant,
	Group,
	IqTeamMember,
	IqItem
}

export enum ProcessCriticalDateExampleType {
	weekly,
	monthly,
	oneTime
}

export enum iQTeamTreeTypes {
	scope,
	team
}

export enum ModalReturnType {
	save,
	delete,
	cancel,
	move,
	success,
	error,
	copy
}

export enum NotificationSettingActionType {
	add,
	customize
}

export enum VerificationStatus {
	established,
	notestablished
}

export enum incidentModuleState {
	none,
	agilityAlerts,
	incidentManagement,
	both
}

export enum planActivationStates {
	no,
	yes,
	both
}

export enum messageTypes {
	sms,
	email,
	voice,
	Conference,
	CallInAnnouncement,
	push,
	desktop
}

export enum messageContentTypes {
	subject,
	body
}

export enum contentFormat {
	text,
	html
}

export enum fileCondition {
	clean,
	isMalicious,
	hasVirus,
	notSupported,
	overSize
}

export enum AssessmentSortField {
	none,
	TargetName,
	TargetType,
	RecipientName,
	PriorityNumber,
	CreatedDate,
	Status
}

export enum assessmentListFilters {
	assessmentTargetName,
	assessmentSearch,
	assessmentTargetTypes,
	assessmentRecipientName,
	assessmentPriorityNumber,
	assessmentTargetDates,
	assessmentStatus,
	assessmentPageRowChange,
	assessmentSortDirectionChange
}

export enum entityResourceTypes {
	portalLogo,
	portalFooterLogo,
	portalFooterPoweredByLogo
}
