import { Component, OnInit } from '@angular/core';
import { SsoService } from '@app/providers/sso.service';
import { ActivatedRoute } from '@angular/router';
import { storageKeys } from '@app/app.constants';

@Component({
	selector: 'app-sso',
	template: ''
})
export class SsoComponent implements OnInit {
	constructor(private ssoService: SsoService,
		private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.ssoService.initiate(this.route.snapshot.params, this.route.snapshot.queryParams[storageKeys.originalDestination]);
	}
}
