import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Utility } from '@app/providers/utility';
import { AuthService } from '@app/providers/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { defaultImageAddress } from '@app/app.constants';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
	errorCode: string;
	loading: boolean;
	requestResetPasswordResetTokenSuccess: boolean;
	requestResetPasswordTokenFormSubmitted: boolean;
	userName: string;
	requestResetPasswordTokenForm: UntypedFormGroup;
	errorMessages: Array<string>;
	public defaultImageAddress = defaultImageAddress;

	constructor(private authSVC: AuthService,
		private formBuilder: UntypedFormBuilder,
		private translateSVC: TranslateService,
		private utility: Utility,
		private route: ActivatedRoute,
		private router: Router) {
		if (this.router.getCurrentNavigation().extras?.state?.errorCode) {
			this.errorCode = this.router.getCurrentNavigation().extras?.state?.errorCode;
		}
	}

	ngOnInit() {
		if (this.errorCode) {
			this.requestResetPasswordTokenFormSubmitted = true;
			this.setErrorMessage(this.errorCode, true);
		}
		this.userName = this.route.snapshot.queryParams.username;
		this.buildControls();
	}

	buildControls() {
		this.requestResetPasswordTokenForm = this.formBuilder.group({
			userName: ['', Validators.required]
		});
	}

	requestResetPasswordResetToken() {
		this.loading = true;
		this.requestResetPasswordTokenFormSubmitted = true;

		if (this.requestResetPasswordTokenForm.valid) {
			this.authSVC.requestResetPasswordResetToken(this.userName.trim(), window.location.hostname)
				.then((result: any) => {
				})
				.catch((error) => {
				})
				.then((_) => {
					// always set success - this will prevent discovering valid user names
					this.requestResetPasswordResetTokenSuccess = true;
					this.loading = false;
				});
		}
		else {
			this.setErrorMessage('msgValidateEmailFormat');
			this.loading = false;
		}
	}

	setErrorMessage(message: string, translate: boolean = true) {
		this.errorMessages = [];
		this.errorMessages.push(translate ? this.translateSVC.instant(`login.errors.${message}`) : message);
	}
}
