<router-outlet></router-outlet>

<button id="appSupportLink"
        class="fixBottomButton visible"
        pTooltip="{{'common.support' | translate}}"
        (click)="support()"
        target="_blank">
    <i class="fa fa-question"></i>
</button>

<button id="appScrollToTopButton"
        type="button"
        class="scrollToTopButton"
        (click)="scrollToTop()"
        [ngClass]="{'visible': scrollToTopButtonIsVisible}"
        pTooltip="{{'common.backToTop' | translate}}">
    <i class="fa fa-arrow-up"></i>
</button>
