import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-confirmation-modal',
	templateUrl: './support-modal.component.html',
	styleUrls: ['./support-modal.component.scss']
})
export class SupportModalComponent {
	email: string = 'Support@preparis.com';
	phone: string = '1-855-447-3750';

	constructor(public activeModal: NgbActiveModal) {
	}
}
