import { Injectable } from '@angular/core';
import { ApiService } from '@app/providers/api.service';
import { SsoSettings } from '@app/shared/sso-settings.model';
import { ApiSettings } from '@app/shared/api-settings.model';
import { Utility } from '@app/providers/utility';
import { SelfRegistrationSettings } from '@app/shared/self-registration-settings';
import { SelfRegisterUser } from '@app/shared/self-register-user.model';
import { PortalLDAPConfig } from '@app/shared/portal-ldap-config.model';

@Injectable({
	providedIn: 'root'
})
export class SecurityService {
	constructor(private api: ApiService,
		private utility: Utility) {
	}

	public getSsoConfig(portalId: string) {
		return this.api.post('getssosettings', { portalId: portalId })
			.then((resp: any) => {
				let ssoSettings: SsoSettings = resp.ssoSettings;
				if (ssoSettings === null) {
					ssoSettings = new SsoSettings();
					ssoSettings.portalId = portalId;
				}
				else {
					ssoSettings.useSso = true;
				}
				return ssoSettings;
			})
			.catch((error) => {
				// Unable to get settings
				console.error('Unable to retrieve sso settings');
			});
	}

	public saveSsoConfig(ssoSettings: SsoSettings, isDelete) {
		const parms = {
			isDelete: isDelete,
			ssoSettings: ssoSettings
		};
		return this.api.post('savessosettings', parms);
	}

	public getApiConfig(portalId: string, includeAllApis: boolean) {
		const parms = {
			portalId: portalId,
			includeAllApis: includeAllApis
		};
		return this.api.post('getapisettings', parms);
	}

	public saveApiConfig(apiSettings: ApiSettings) {
		const parms = {
			apiSettings: apiSettings
		};
		return this.api.post('saveapisettings', parms);
	}

	public getCountries() {
		return this.api.post('getcountries', {});
	}

	public getLanguages() {
		return this.api.post('getlanguages', {});
	}

	public getPortalSelfRegistration() {
		return this.api.post('getportalselfregistration', {});
	}

	public savePortalSelfRegistration(request: SelfRegistrationSettings) {
		return this.api.post('saveportalselfregistration', request);
	}

	public deletePortalSelfRegistration() {
		return this.api.post('deleteportalselfregistration', {});
	}

	public getPortalSelfRegistrationByCode(registrationCode: string) {
		return this.api.post('getportalselfregistrationbycode', {
			registrationCodeAsBase64: this.utility.base64Encode(registrationCode)
		});
	}

	public getPortalSelfRegistrationByPortalId(portalId: string) {
		return this.api.post('getportalselfregistrationbyportalid', { portalId: portalId });
	}

	public isValidUserName(portalId: string, userName: string, userId: string, includeMessageOnlyUsers: boolean = false) {
		return this.api.post('isvalidusername', {
			portalId: portalId,
			userNameAsBase64: this.utility.base64Encode(userName),
			newUserId: userId,
			includeMessageOnlyUsers: includeMessageOnlyUsers
		});
	}

	public saveSelfRegisterUser(user: SelfRegisterUser) {
		return this.api.post('saveSelfRegisterUser', user);
	}

	public deleteSelfRegisterUser() {
		return this.api.post('deleteselfregisteruser', {});
	}

	public getSelfRegisterUser() {
		return this.api.post('getselfregisteruser', {});
	}

	public getLDAPConfig(portalId) {
		const parms = {
			portalId: portalId
		};
		return this.api.post('GetPortalLDAPConfiguration', parms);
	}

	public saveLDAPConfig(ldapConfig: PortalLDAPConfig) {
		const parms = {
			ldapConfig: ldapConfig
		};
		return this.api.post('SavePortalLDAPConfiguration', parms);
	}

	public getUserString(userStringTypeId: string, userStringLanguageId?: string): Promise<any> {
		return this.api.post('GetUserString', {
			userStringTypeId: userStringTypeId,
			userStringLanguageId: userStringLanguageId
		});
	}
}
