<div class="modal-header">
    <h4 class="modal-title"></h4>
    <input style="display: none"/>
    <button id="dismissButton" type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <i class="fa fa-times"></i>
    </button>
</div>
<div class="modal-body mb-3 mx-3">
    <div [innerHTML]="'support.description' | translate"></div>
    <p>{{'support.email' | translate}} <a href="mailto:{{email}}">{{email}}</a></p>
    <p>{{'support.phone' | translate}} <a href="tel:{{phone}}">{{phone}}</a> {{'support.phoneOption' | translate}}</p>
</div>
