import { NgModule } from '@angular/core';
import { BcImpactAnalysisChartComponent } from '@app/components/business-continuity/bc-process-analysis/bc-impact-analysis/bc-impact-analysis-chart/bc-impact-analysis-chart.component';
import { BcVendorModalComponent } from '@app/components/business-continuity/modals/bc-vendor/bc-vendor-modal.component';
import { BcTechnologiesListComponent } from '@app/components/business-continuity/bc-technologies/bc-technologies-list/bc-technologies-list.component';
import { BcTechnologyModalComponent } from '@app/components/business-continuity/modals/bc-technology/bc-technology-modal.component';
import { BcEquipmentTypeModalComponent } from '@app/components/business-continuity/modals/bc-equipment-type/bc-equipment-type-modal.component';
import { BaseBusinessContinuityComponent } from '@app/components/base-form/base-business-continuity.component';
import { SharedModule } from '@app/modules/shared/shared.module';
import { BcResourcesAssignedOverlayPanelComponent } from '@app/components/business-continuity/overlay-panel/bc-resources-assigned-overlay-panel/bc-resources-assigned-overlay-panel.component';
import { BcCustomFieldValuesComponent } from '@app/components/business-continuity/bc-process-analysis/bc-process-strategy/bc-custom-field-values/bc-custom-field-values.component';
import { BcProcessAssigneesComponent } from '@app/components/business-continuity/bc-process-analysis/bc-process-strategy/bc-process-assignees/bc-process-assignees.component';
import { BcPlannerFooterComponent } from '@app/components/business-continuity/sidebars/bc-planner-footer/bc-planner-footer.component';
import { BcAuthorityDelegationComponent } from '@app/components/business-continuity/bc-authority-delegation/bc-authority-delegation/bc-authority-delegation.component';
import { BcAuthorityDelegationsAssigneesComponent } from '@app/components/business-continuity/bc-authority-delegation/authority-delegations-assignees/bc-authority-delegations-assignees.component';
import { BcAuthorityDelegationsComponent } from '@app/components/business-continuity/bc-authority-delegation/bc-authority-delegations/bc-authority-delegations.component';
import { BcTeamsComponent } from '@app/components/business-continuity/bc-teams/bc-teams/bc-teams.component';
import { BcTeamComponent } from '@app/components/business-continuity/bc-teams/bc-team/bc-team.component';
import { BcOrdersOfSuccessionsComponent } from '@app/components/business-continuity/bc-orders-of-successions/bc-orders-of-successions/bc-orders-of-successions.component';
import { BcOrdersOfSuccessionComponent } from '@app/components/business-continuity/bc-orders-of-successions/bc-orders-of-succession/bc-orders-of-succession.component';
import { AssignTeamModalComponent } from '@app/components/business-continuity/bc-teams/modals/assign-team-modal/assign-team-modal.component';
import { BcManageIssuesModalComponent } from '@app/components/business-continuity/modals/bc-manage-issues/bc-manage-issues-modal.component';
import { BcIssueComponent } from '@app/components/business-continuity/bc-issues/bc-issue/bc-issue.component';
import { TechnologyTypesModalComponent } from '@app/components/business-continuity/bc-technologies/technology-types-modal/technology-types-modal.component';
import { BcPlansAssignedOverlayPanelComponent } from '@app/components/business-continuity/overlay-panel/bc-plans-assigned-overlay-panel/bc-plans-assigned-overlay-panel.component';

@NgModule({
	imports: [
		SharedModule
	],
	// any other things (components, directives, etc.)
	declarations: [
		BaseBusinessContinuityComponent,
		BcImpactAnalysisChartComponent,
		BcVendorModalComponent,
		BcTechnologiesListComponent,
		BcTechnologyModalComponent,
		BcEquipmentTypeModalComponent,
		BcResourcesAssignedOverlayPanelComponent,
		BcPlansAssignedOverlayPanelComponent,
		BcCustomFieldValuesComponent,
		BcProcessAssigneesComponent,
		BcPlannerFooterComponent,
		BcAuthorityDelegationComponent,
		BcAuthorityDelegationsAssigneesComponent,
		BcAuthorityDelegationsComponent,
		BcTeamsComponent,
		BcTeamComponent,
		BcOrdersOfSuccessionsComponent,
		BcOrdersOfSuccessionComponent,
		AssignTeamModalComponent,
		BcIssueComponent,
		BcManageIssuesModalComponent,
		TechnologyTypesModalComponent
	],
	// everything that you want to share to other modules
	exports: [
		SharedModule,
		BaseBusinessContinuityComponent,
		BcImpactAnalysisChartComponent,
		BcVendorModalComponent,
		BcTechnologiesListComponent,
		BcTechnologyModalComponent,
		BcEquipmentTypeModalComponent,
		BcResourcesAssignedOverlayPanelComponent,
		BcPlansAssignedOverlayPanelComponent,
		BcCustomFieldValuesComponent,
		BcProcessAssigneesComponent,
		BcPlannerFooterComponent,
		BcAuthorityDelegationComponent,
		BcAuthorityDelegationsAssigneesComponent,
		BcAuthorityDelegationsComponent,
		BcTeamsComponent,
		BcTeamComponent,
		BcOrdersOfSuccessionsComponent,
		BcOrdersOfSuccessionComponent,
		AssignTeamModalComponent,
		BcIssueComponent,
		BcManageIssuesModalComponent,
		TechnologyTypesModalComponent
	]
})
export class BcSharedModule {
	constructor() {
	}
}
