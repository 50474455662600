interface ILocales {
	[lng: string]: string
}

const brandingTranslationDefaults = new Map<string, ILocales>([
	[
		'[[Alerts]]',
		{
			en: 'Alerts',
			es: 'Alertas',
			pt: 'Alertas',
			fr: 'Alertes'
		}
	],
	[
		'[[alerts]]',
		{
			en: 'alerts',
			es: 'alertas',
			pt: 'alertas',
			fr: 'alertes'
		}
	],
	[
		'[[Alert]]',
		{
			en: 'Alert',
			es: 'Alerta',
			pt: 'Alerta',
			fr: 'Alerte'
		}
	],
	[
		'[[alert]]',
		{
			en: 'alert',
			es: 'alerta',
			pt: 'alerta',
			fr: 'alerte'
		}
	],
	[
		'[[Appendices]]',
		{
			en: 'Appendices',
			es: 'Apéndices',
			pt: 'Apêndices',
			fr: 'Annexes'
		}
	],
	[
		'[[appendices]]',
		{
			en: 'appendices',
			es: 'apéndices',
			pt: 'apêndices',
			fr: 'annexes'
		}
	],
	[
		'[[Appendix]]',
		{
			en: 'Appendix',
			es: 'Apéndice',
			pt: 'Apêndice',
			fr: 'Annexe'
		}
	],
	[
		'[[appendix]]',
		{
			en: 'appendix',
			es: 'apéndice',
			pt: 'apêndice',
			fr: 'annexe'
		}
	],
	[
		'[[Applications]]',
		{
			en: 'Applications',
			es: 'Aplicaciones',
			pt: 'Aplicações',
			fr: 'Applications'
		}
	],
	[
		'[[applications]]',
		{
			en: 'applications',
			es: 'alicaciones',
			pt: 'aplicações',
			fr: 'applications'
		}
	],
	[
		'[[Application]]',
		{
			en: 'Application',
			es: 'Aplicación',
			pt: 'Aplicativo',
			fr: 'L\'application'
		}
	],
	[
		'[[application]]',
		{
			en: 'application',
			es: 'aplicación',
			pt: 'aplicativo',
			fr: 'L\'application'
		}
	],
	[
		'[[Assets]]',
		{
			en: 'Assets',
			es: 'Activos',
			pt: 'Ativos',
			fr: 'Actifs'
		}
	],
	[
		'[[assets]]',
		{
			en: 'assets',
			es: 'activos',
			pt: 'ativos',
			fr: 'actifs'
		}
	],
	[
		'[[Asset]]',
		{
			en: 'Asset',
			es: 'Activo',
			pt: 'Ativo',
			fr: 'Actif'

		}
	],
	[
		'[[asset]]',
		{
			en: 'asset',
			es: 'activo',
			pt: 'ativo',
			fr: 'actif'

		}
	],
	[
		'[[BIA]]',
		{
			en: 'BIA',
			es: 'BIA',
			pt: 'BIA',
			fr: 'BIA'
		}
	],
	[
		'[[BIAs]]',
		{
			en: 'BIAs',
			es: 'BIAs',
			pt: 'BIAs',
			fr: 'BIAs'
		}
	],
	[
		'[[bia]]',
		{
			en: 'bia',
			es: 'bia',
			pt: 'bia',
			fr: 'bia'
		}
	],
	[
		'[[bias]]',
		{
			en: 'bias',
			es: 'bias',
			pt: 'bias',
			fr: 'bias'
		}
	],
	[
		'[[Business Impact Analysis]]',
		{
			en: 'Business Impact Analysis',
			es: 'Análisis de impacto del negocio',
			pt: 'Análise de impacto nos negócios',
			fr: 'Analyse d\'impact sur les entreprises'
		}
	],
	[
		'[[business impact analysis]]',
		{
			en: 'business impact analysis',
			es: 'análisis de impacto del negocio',
			pt: 'análise de impacto nos negócios',
			fr: 'analyse d\'impact sur les entreprises'
		}
	],
	[
		'[[Clients]]',
		{
			en: 'Clients',
			es: 'Clientes',
			pt: 'Clientes',
			fr: 'Clients'
		}
	],
	[
		'[[clients]]',
		{
			en: 'clients',
			es: 'clientes',
			pt: 'clientes',
			fr: 'clients'
		}
	],
	[
		'[[Client]]',
		{
			en: 'Client',
			es: 'Cliente',
			pt: 'Clientes',
			fr: 'Clientèle'
		}
	],
	[
		'[[client]]',
		{
			en: 'client',
			es: 'cliente',
			pt: 'clientes',
			fr: 'clientèle'
		}
	],
	[
		'[[Customers]]',
		{
			en: 'Clients',
			es: 'Clientes',
			pt: 'Clientes',
			fr: 'Clients'
		}
	],
	[
		'[[customers]]',
		{
			en: 'clients',
			es: 'clientes',
			pt: 'clientes',
			fr: 'clients'
		}
	],
	[
		'[[Customer]]',
		{
			en: 'Client',
			es: 'Cliente',
			pt: 'Clientes',
			fr: 'Clientèle'
		}
	],
	[
		'[[customer]]',
		{
			en: 'client',
			es: 'cliente',
			pt: 'clientes',
			fr: 'clientèle'
		}
	],
	[
		'[[Departments]]',
		{
			en: 'Departments',
			es: 'Departments',
			pt: 'Departments',
			fr: 'Départements'
		}
	],
	[
		'[[departments]]',
		{
			en: 'departments',
			es: 'departments',
			pt: 'departments',
			fr: 'départements'
		}
	],
	[
		'[[Department]]',
		{
			en: 'Department',
			es: 'Departamento',
			pt: 'Abteilung',
			fr: 'Département'
		}
	],
	[
		'[[department]]',
		{
			en: 'department',
			es: 'departamento',
			pt: 'abteilung',
			fr: 'département'
		}
	],
	[
		'[[scope]]',
		{
			en: 'scope',
			es: 'alcance',
			pt: 'escopo',
			fr: 'portée'

		}
	],
	[
		'[[team]]',
		{
			en: 'team',
			es: 'equipo',
			pt: 'equipe',
			fr: 'équipe'
		}
	],
	[
		'[[Scopes]]',
		{
			en: 'Scopes',
			es: 'Alcances',
			pt: 'Escopos',
			fr: 'Portées'
		}
	],
	[
		'[[Teams]]',
		{
			en: 'Teams',
			es: 'Equipos',
			pt: 'Equipes',
			fr: 'Équipes'
		}
	],
	[
		'[[teams]]',
		{
			en: 'teams',
			es: 'equipos',
			pt: 'equipes',
			fr: 'équipes'
		}
	],
	[
		'[[Team Members]]',
		{
			en: 'Team Members',
			es: 'Miembros de Equipo',
			pt: 'Membros do Time',
			fr: 'membres de l\'équipe'
		}
	],
	[
		'[[Planner]]',
		{
			en: 'Planner',
			es: 'Planificación',
			pt: 'Planificação',
			fr: 'Planificateur'
		}
	],
	[
		'[[planner]]',
		{
			en: 'planner',
			es: 'planificación',
			pt: 'planificação',
			fr: 'planificateur'
		}
	],
	[
		'[[External Resources]]',
		{
			en: 'External Resources',
			es: 'Recursos Externos',
			pt: 'Recursos Externos',
			fr: 'Ressources Externes'
		}
	],
	[
		'[[External Resources]]',
		{
			en: 'External Resources',
			es: 'Recursos Externos',
			pt: 'Recursos Externos',
			fr: 'Ressources Externes'
		}
	],
	[
		'[[External Resource]]',
		{
			en: 'External Resource',
			es: 'Recurso Externo',
			pt: 'Recurso Externo',
			fr: 'Ressource Externe'
		}
	],
	[
		'[[external resource]]',
		{
			en: 'external resource',
			es: 'recurso externo',
			pt: 'recurso externo',
			fr: 'ressource externe'
		}
	],
	[
		'[[Plans]]',
		{
			en: 'Plans',
			es: 'Planes',
			pt: 'Planos',
			fr: 'Plans'
		}
	],
	[
		'[[Report]]',
		{
			en: 'Report',
			es: 'Reporte',
			pt: 'Relatório',
			fr: 'Rapport'
		}
	],
	[
		'[[Location]]',
		{
			en: 'Location',
			es: 'Ubicación',
			pt: 'Localização',
			fr: 'Localisation'
		}
	],
	[
		'[[Locations]]',
		{
			en: 'Locations',
			es: 'Ubicaciones',
			pt: 'Localizações',
			fr: 'Localisation'
		}
	],
	[
		'[[locations]]',
		{
			en: 'locations',
			es: 'ubicaciones',
			pt: 'localizações',
			fr: 'localisation'
		}
	],
	[
		'[[Plan]]',
		{
			en: 'Plan',
			es: 'Plan',
			pt: 'Plano',
			fr: 'Plan'
		}
	],
	[
		'[[Team]]',
		{
			en: 'Team',
			es: 'Equipo',
			pt: 'Equipe',
			fr: 'L\'équipe'
		}
	],
	[
		'[[Process]]',
		{
			en: 'Process',
			es: 'Proceso',
			pt: 'Processo',
			fr: 'Processus'
		}
	],
	[
		'[[Team Member]]',
		{
			en: 'Team Member',
			es: 'Miembro de Equipo',
			pt: 'Membro da Equipe',
			fr: 'Membre de l\'équipe'
		}
	],
	[
		'[[team member]]',
		{
			en: 'team member',
			es: 'miembro de equipo',
			pt: 'membro da equipe',
			fr: 'membre de l\'équipe'
		}
	],
	[
		'[[Processes]]',
		{
			en: 'Processes',
			es: 'Procesos',
			pt: 'Processos',
			fr: 'Processus'
		}
	],
	[
		'[[Equipments]]',
		{
			en: 'Equipments',
			es: 'Equipamientos',
			pt: 'Equipamentos',
			fr: 'Équipements'
		}
	],
	[
		'[[Equipment]]',
		{
			en: 'Equipment',
			es: 'Equipamiento',
			pt: 'Equipamento',
			fr: 'Equipement'
		}
	],
	[
		'[[equipments]]',
		{
			en: 'equipments',
			es: 'equipamientos',
			pt: 'equipamentos',
			fr: 'équipements'
		}
	],
	[
		'[[Equipment]]',
		{
			en: 'equipment',
			es: 'equipamiento',
			pt: 'equipamento',
			fr: 'equipement'
		}
	],
	[
		'[[Exercise Managers]]',
		{
			en: 'Exercise Managers',
			es: 'Gestores de ejercicios',
			pt: 'Gerentes de exercícios',
			fr: 'Gestionnaires d\'exercices'
		}
	],
	[
		'[[exercise managers]]',
		{
			en: 'exercise managers',
			es: 'gestores de ejercicios',
			pt: 'gerentes de exercícios',
			fr: 'gestionnaires d\'exercices'
		}
	],
	[
		'[[Exercise Manager]]',
		{
			en: 'Exercise Manager',
			es: 'Gestor de ejercicios',
			pt: 'Gerente de exercícios',
			fr: 'Gestionnaire d\'exercice'
		}
	],
	[
		'[[exercise manager]]',
		{
			en: 'exercise manager',
			es: 'gestor de ejercicios',
			pt: 'gerente de exercícios',
			fr: 'gestionnaire d\'exercice'
		}
	],
	[
		'[[Incident Managers]]',
		{
			en: 'Incident Managers',
			es: 'Gestores de incidentes',
			pt: 'Gerentes de incidentes',
			fr: 'Gestionnaires d\'incidents'
		}
	],
	[
		'[[incident managers]]',
		{
			en: 'incident managers',
			es: 'gestores de incidentes',
			pt: 'gerentes de incidentes',
			fr: 'gestionnaires d\'incidents'
		}
	],
	[
		'[[Incident Manager]]',
		{
			en: 'Incident Manager',
			es: 'Gestor de incidentes',
			pt: 'Gerente de incidentes',
			fr: 'Gestionnaire d\'incidents'
		}
	],
	[
		'[[incident manager]]',
		{
			en: 'incident manager',
			es: 'gestor de incidentes',
			pt: 'gerente de incidentes',
			fr: 'gestionnaire d\'incidents'
		}
	],
	[
		'[[Issues]]',
		{
			en: 'Issues',
			es: 'Asuntos',
			pt: 'Situações',
			fr: 'Enjeux'
		}
	],
	[
		'[[issues]]',
		{
			en: 'issues',
			es: 'asuntos',
			pt: 'situações',
			fr: 'enjeux'
		}
	],
	[
		'[[Issue]]',
		{
			en: 'Issue',
			es: 'Asunto',
			pt: 'Assunto',
			fr: 'Enjeu'
		}
	],
	[
		'[[issue]]',
		{
			en: 'issue',
			es: 'asunto',
			pt: 'situaçõe',
			fr: 'enjeu'
		}
	],
	[
		'[[Items]]',
		{
			en: 'Items',
			es: 'Artículos',
			pt: 'Unit',
			fr: 'Articles'
		}
	],
	[
		'[[items]]',
		{
			en: 'items',
			es: 'artículos',
			pt: 'unit',
			fr: 'articles'
		}
	],
	[
		'[[Item]]',
		{
			en: 'Item',
			es: 'Artículo',
			pt: 'Item',
			fr: 'Objet'
		}
	],
	[
		'[[item]]',
		{
			en: 'item',
			es: 'artículo',
			pt: 'item',
			fr: 'objet'
		}
	],
	[
		'[[Technologies]]',
		{
			en: 'Technologies',
			es: 'Tecnologías',
			pt: 'Tecnologias',
			fr: 'Technologies'
		}
	],
	[
		'[[technologies]]',
		{
			en: 'technologies',
			es: 'tecnologías',
			pt: 'tecnologias',
			fr: 'technologies'
		}
	],
	[
		'[[technology]]',
		{
			en: 'technology',
			es: 'tecnología',
			pt: 'tecnologia',
			fr: 'technologie'
		}
	],
	[
		'[[Settings]]',
		{
			en: 'Settings',
			es: 'Configuraciones',
			pt: 'Configurações',
			fr: 'Paramètres'

		}
	],
	[
		'[[settings]]',
		{
			en: 'settings',
			es: 'configuraciones',
			pt: 'configurações',
			fr: 'paramètres'

		}
	],
	[
		'[[setting]]',
		{
			en: 'setting',
			es: 'configuración',
			pt: 'configuração',
			fr: 'réglage'
		}
	],
	[
		'[[Reports]]',
		{
			en: 'Reports',
			es: 'Reportes',
			pt: 'Informes',
			fr: 'Rapports'
		}
	],
	[
		'[[reports]]',
		{
			en: 'reports',
			es: 'reportes',
			pt: 'informes',
			fr: 'rapports'
		}
	],
	[
		'[[Report]]',
		{
			en: 'Report',
			es: 'Reporte',
			pt: 'Informe',
			fr: 'Rapport'
		}
	],
	[
		'[[report]]',
		{
			en: 'report',
			es: 'reporte',
			pt: 'informe',
			fr: 'rapport'
		}
	],
	[
		'[[Risk]]',
		{
			en: 'Risk',
			es: 'Riesgo',
			pt: 'Risco',
			fr: 'Risque'
		}
	],
	[
		'[[risk]]',
		{
			en: 'risk',
			es: 'riesgo',
			pt: 'risco',
			fr: 'risque'
		}
	]
]);

interface ITranslations {
	[key: string]: string | ITranslations | ITranslations[]
}

export { brandingTranslationDefaults, ITranslations, ILocales };
